import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { About } from "../../app/models/about";
import { useAppDispatch } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import AppTextInput from "../../app/components/AppTextInput";
import { fetchAboutsAsync } from "./aboutsSlice";
import { toast } from "react-toastify";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppNumberInput from "../../app/components/AppNumberInput";
import AppRichTextEditor from "../../app/components/AppRichTextEditor.";

interface Props {
    about?: About;
    cancelEdit: () => void;
}

export default function AboutForm({ about, cancelEdit }: Props) {
    const dispatch = useAppDispatch();
    const { control, reset, handleSubmit, formState: { isDirty, isSubmitting } } = useForm({
        // resolver: yupResolver(aboutValidationSchema)
    });

    useEffect(() => {
        if (about && !isDirty) reset(about);
    }, [about, reset, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        console.log("Submitting data:", data);

        try {
            const aboutUpdateDto = {
                id: about?.id,
                name: about?.name,
                title: data?.title,
                description: data.description || about?.description,
                text: data.text || about?.text,
                link: data?.link,
                isInFooter: data?.isInFooter,
                footerColumn: data?.footerColumn,
                footerColumnTitle: data?.footerColumnTitle,
                footerColumnOrder: data?.footerColumnOrder,
                footerIsVisibile: data?.footerIsVisibile
            };

            console.log("AboutUpdateDto:", aboutUpdateDto);

            if (about) {
                await agent.Abouts.update(aboutUpdateDto);
                toast.success('Edytowano');
            } else {
                await agent.Abouts.create(data);
                toast.success('Dodano');
            }
            dispatch(fetchAboutsAsync());
            cancelEdit();
        } catch (error: any) {
            console.log(error.response.data);
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Edytor
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} >
                        <AppTextInput control={control} name='title' label='Tytuł' disabled={false} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <AppTextInput control={control} name='description' label='Opis' disabled={false} />
                    </Grid>
                    {about?.name !== 'contact' && about?.name !== 'privacy' && about?.name !== 'regulations' && (
                        <Grid item xs={12} sm={12} >
                            <AppRichTextEditor control={control} name='text' label='Tekst' />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} >
                        <AppCheckbox control={control} name='isInFooter' label='Jest w stopce' disabled={false} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <AppCheckbox control={control} name='footerIsVisibile' label='Widoczne' disabled={false} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <AppTextInput control={control} name='footerColumnTitle' label='Nazwa kolumny w stopce' disabled={false} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <AppTextInput control={control} name='link' label='Link w stopce' disabled={false} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <AppNumberInput control={control} name='footerColumn' label='Numer kolumny w stopce' disabled={false} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <AppNumberInput control={control} name='footerColumnOrder' label='Kolejność w kolumnie w stopce' disabled={true} />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='outlined' color='inherit'>Anuluj</Button>
                    <LoadingButton disabled={!isDirty} loading={isSubmitting} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                </Box>
            </form>
        </Box>
    );
}