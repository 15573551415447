import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppPagination from "../../../../app/components/AppPagination";
import RadioButtonGroup from "../../../../app/components/AppRadioButtonGroup";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppSelector, useAppDispatch, RootState } from "../../../../app/store/configureStore";
import { setFooterInvisible, setFooterVisible } from "../../../../app/util/util";
import useAgreements from "../../../../app/hooks/useAgreements";
import AgreementForm from "../AgreementForm";
import { Agreement } from "../../../../app/models/agreement";
import { setAgreementsPageNumber, setAgreementsParams } from "../agreementsSlice";
import AgreementsList from "./AgreementsList";
import { useLocation } from "react-router-dom";
import AppReturn from "../../../../app/components/AppReturn";
import AppUniversalSearch from "../../../../app/components/AppUniversalSearch";

const sortOptions = [
  { value: 'dateDesc', label: 'Data zawarcia umowy od najnowszej' },
  { value: 'date', label: 'Data zawarcia umowy od najstarszej' },
  { value: 'id', label: 'Numer umowy rosnąco \u2191' },
  { value: '_', label: 'Numer umowy malejąco \u2193' },
]

export default function Agreements() {
  const { agreements, agreementsFiltersLoaded, metaData } = useAgreements();
  const { agreementsParams } = useAppSelector(state => state.agreements);
  const [selectedAgreement, setSelectedAgreement] = useState<Agreement | undefined>(undefined);
  const [exportToPdf, setExportToPdf] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [editMode, setEditMode] = useState(location.state ? location.state.openForm : false);
  const investmentPublicId = location.state ? location.state.investmentPublicId : 0;
  const userPhone = location.state ? location.state.userPhone : '';
  const userDisplayName = location.state ? location.state.userDisplayName : '';
  const userEmail = location.state ? location.state.userEmail : '';

  function cancelEdit() {
    setSelectedAgreement(undefined);
    setEditMode(false);
    setExportToPdf(false);
  }

  function handleCardSelectAgreement(agreement: Agreement, exportPdf: boolean = false) {
    setSelectedAgreement(agreement);
    setEditMode(true);
    setExportToPdf(exportPdf);
  }

  if (!agreementsFiltersLoaded) { setFooterInvisible(); return <LoadingComponent /> }
  setFooterVisible();

  if (editMode) return <AgreementForm
    agreement={selectedAgreement}
    cancelEdit={cancelEdit}
    investmentPublicId={investmentPublicId}
    userPhone={userPhone}
    userEmail={userEmail}
    userDisplayName={userDisplayName}
    exportToPdf={exportToPdf}
  />

  return (
    <>
      <AppBreadcrumbs />
      <AppReturn title="Umowy" setFunction={() => setEditMode(true)} />
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ mb: 2 }}>
            <AppUniversalSearch
              sliceName="agreements"
              searchParamsSelector={(state: RootState) => state.agreements.agreementsParams}
              setSearchParams={setAgreementsParams}
              label='Szukaj'
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <RadioButtonGroup
              selectedValue={agreementsParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setAgreementsParams({ orderBy: e.target.value }))}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <AgreementsList agreements={agreements} handleCardSelectAgreement={handleCardSelectAgreement} />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9} sx={{ my: 2 }} >
          {metaData &&
            <AppPagination
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setAgreementsPageNumber({ pageNumber: page }))}
            />}
        </Grid>
      </Grid>
    </>
  )
}