import { Edit } from "@mui/icons-material";
import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import AppPagination from "../../../app/components/AppPagination";
import { RootState, useAppDispatch } from "../../../app/store/configureStore";
import AdminUserForm from "./AdminUserForm";
import useAdminUsers from "../../../app/hooks/useAdminUsers";
import { AdminUser } from "../../../app/models/adminUser";
import { setAdminUsersPageNumber, setAdminUsersParams } from "./adminUsersSlice";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminUserRoles from "./roles/AdminUserRoles";
import AdminRolesManager from "./roles/AdminRolesManager";
import AppReturn from "../../../app/components/AppReturn";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppUniversalSearch from "../../../app/components/AppUniversalSearch";
import { formatToLocalDate, formatToLocalTime } from "../../../app/util/util";

export default function AdminUsersManager() {
    const { adminUsers, metaData } = useAdminUsers();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [editModeRoles, setEditModeRoles] = useState(false);
    const [selectedAdminUser, setSelectedAdminUser] = useState<AdminUser | undefined>(undefined);
    const [openRolesModal, setOpenRolesModal] = useState(false);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const isLessThanMd = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery('(max-width: 900px)');
    const [loadingUsers, setLoadingUsers] = useState(true);

    useEffect(() => {
        if (adminUsers.length > 0 || metaData) {
            setLoadingUsers(false);
        }
    }, [adminUsers, metaData]);

    function cancelEdit() {
        if (selectedAdminUser) setSelectedAdminUser(undefined);
        setEditMode(false);
    }

    function cancelEditRoles() {
        if (selectedAdminUser) setSelectedAdminUser(undefined);
        setEditModeRoles(false);
    }

    function handleSelectAdminUser(adminUser: AdminUser) {
        setSelectedAdminUser(adminUser);
        setEditMode(true);
    }

    function handleOpenRolesModal(adminUser: AdminUser) {
        setSelectedAdminUser(adminUser);
        setOpenRolesModal(true);
    }

    function handleCloseRolesModa() {
        setOpenRolesModal(false);
    }

    if (editMode) return <AdminUserForm adminUser={selectedAdminUser} cancelEdit={cancelEdit} />

    return (
        <>
            <AppBreadcrumbs />

            {isMobile ? (
                <>
                    <AppReturn title="Użytkownicy" />
                    <Button
                        onClick={() => setEditModeRoles(true)}
                        sx={{ width: '100%', mb: 2 }}
                        size='large'
                        variant='outlined'
                    >
                        Role
                    </Button>
                    {editModeRoles && <AdminRolesManager cancelEditRoles={cancelEditRoles} />}
                </>
            ) : (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <AppReturn title="Użytkownicy" />
                    <Box display="flex" alignItems="center">
                        <Button
                            onClick={() => setEditModeRoles(true)}
                            sx={{ m: 2 }}
                            size='large'
                            variant='outlined'
                        >
                            Role
                        </Button>
                        {editModeRoles && <AdminRolesManager cancelEditRoles={cancelEditRoles} />}
                    </Box>
                </Box>
            )}

            {isMd && (
                <>
                    <Paper sx={{ mb: 2 }}>
                        <AppUniversalSearch
                            sliceName="adminUsers"
                            searchParamsSelector={(state: RootState) => state.adminUsers.adminUsersParams}
                            setSearchParams={setAdminUsersParams}
                            label='Szukaj wpisując nazwisko/imię zamawiającego, liczbą dany dzień, miesiąc, rok lub fragment komentarza/uwagi'
                        />
                    </Paper>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Użytkownik</TableCell>
                                    <TableCell align="left">Potwierdzony email</TableCell>
                                    <TableCell align="left">Wyświetlana nazwa</TableCell>
                                    <TableCell align="left">Nazwisko i imię użytkownika</TableCell>
                                    <TableCell align="left">Telefon</TableCell>
                                    <TableCell align="left">Data rejestracji</TableCell>
                                    <TableCell align="left">Blokada konta</TableCell>
                                    <TableCell align="left">Błędne logowania</TableCell>
                                    <TableCell align="left">Ulica budynek/mieszkanie</TableCell>
                                    <TableCell align="center">Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loadingUsers ? (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {adminUsers.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <Typography variant='h6' color={(theme) => theme.palette.text.primary}>Brak użytkowników</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            adminUsers && adminUsers.map((adminUser) => (
                                                <TableRow
                                                    key={adminUser.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>
                                                        {adminUser.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {adminUser.emailConfirmed}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {adminUser.displayName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {adminUser.lastName} {adminUser.firstName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {adminUser.phone}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <span>{formatToLocalDate(adminUser.registerDate.toString())}<br />{formatToLocalTime(adminUser.registerDate.toString())}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {adminUser.lockoutEnabled ? (<Typography variant="body2">Tak</Typography>) : (<Typography variant="body2">Nie</Typography>)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {adminUser.accessFailedCount}
                                                    </TableCell>
                                                    <TableCell>
                                                        {adminUser.addressStreet} {adminUser.addressBuilding} {adminUser.addressPremises && `/ ${adminUser.addressPremises}`} {adminUser.addressZip && adminUser.addressCity && `| ${adminUser.addressZip} ${adminUser.addressCity}`}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            variant='outlined'
                                                            sx={{ m: 1 }}
                                                            onClick={() => handleSelectAdminUser(adminUser)}
                                                            startIcon={<Edit />}
                                                            color='warning'
                                                            title="Edytuj"
                                                        />
                                                        <Button
                                                            variant='outlined'
                                                            sx={{ m: 1 }}
                                                            onClick={() => handleOpenRolesModal(adminUser)}
                                                            startIcon={<AccountCircleIcon />}
                                                            color='info'
                                                            title="Role"
                                                        />
                                                        {openRolesModal ? <AdminUserRoles handleCloseModal={handleCloseRolesModa} adminUser={selectedAdminUser!} /> : ''}
                                                    </TableCell>
                                                </TableRow>
                                            )))
                                        }
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        metaData &&
                        <Box sx={{ pt: 2 }}>
                            <AppPagination metaData={metaData}
                                onPageChange={(page: number) => dispatch(setAdminUsersPageNumber({ pageNumber: page }))}
                            />
                        </Box>
                    }
                </>
            )}

            {isLessThanMd && (
                <>
                    <Paper sx={{ mb: 2 }}>
                        <AppUniversalSearch
                            sliceName="adminUsers"
                            searchParamsSelector={(state: RootState) => state.adminUsers.adminUsersParams}
                            setSearchParams={setAdminUsersParams}
                            label='Szukaj'
                        />
                    </Paper>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Użytkownik</TableCell>
                                    <TableCell align="left">Wyświetlana nazwa</TableCell>
                                    <TableCell align="center">Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {adminUsers && adminUsers.map((adminUser) => (
                                    <TableRow
                                        key={adminUser.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            {adminUser.email}
                                        </TableCell>
                                        <TableCell align="left">
                                            {adminUser.displayName}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant='outlined'
                                                sx={{ m: 1 }}
                                                onClick={() => handleSelectAdminUser(adminUser)}
                                                startIcon={<Edit />}
                                                color='warning'
                                                title="Edytuj"
                                            />
                                            <Button
                                                variant='outlined'
                                                sx={{ m: 1 }}
                                                onClick={() => handleOpenRolesModal(adminUser)}
                                                startIcon={<AccountCircleIcon />}
                                                color='info'
                                                title="Role"
                                            />
                                            {openRolesModal ? <AdminUserRoles handleCloseModal={handleCloseRolesModa} adminUser={selectedAdminUser!} /> : ''}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        metaData &&
                        <Box sx={{ pt: 2 }}>
                            <AppPagination metaData={metaData}
                                onPageChange={(page: number) => dispatch(setAdminUsersPageNumber({ pageNumber: page }))}
                            />
                        </Box>
                    }
                </>
            )}
        </>
    )
}
