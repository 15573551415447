import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ChatComment } from "../../../app/models/comment";
import { RootState } from "../../../app/store/configureStore";

const commentsAdapter = createEntityAdapter<ChatComment>();

export const clientHelpInOrderCommentsSlice = createSlice({
    name: "clientHelpInOrderComments",
    initialState: commentsAdapter.getInitialState({
        status: 'idle',
        helpInClientOrderCommentsLoaded: false,
        selectedClientOrder: 0,
        messages: []
    }),
    reducers: {
        setSelectedClientOrder: (state, action) => {
            state.selectedClientOrder = action.payload;
        },
        setComments: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        },
        updateMessage: (state, action) => {
            commentsAdapter.upsertOne(state, action.payload);
        },
        loadMessage: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        }
    }
});

export const clientHelpInOrderCommentsSelectors = commentsAdapter.getSelectors((state: RootState) => state.clientHelpInOrderComments);

export const {setSelectedClientOrder, updateMessage, loadMessage, setComments } = clientHelpInOrderCommentsSlice.actions;