import { Box, Grid, Paper } from "@mui/material";
import { useState } from "react";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppPagination from "../../../../app/components/AppPagination";
import RadioButtonGroup from "../../../../app/components/AppRadioButtonGroup";
import useClientInvestments from "../../../../app/hooks/useClientInvestments";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { Investment, InvestmentState } from "../../../../app/models/investment";
import { RootState, useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { setFooterInvisible, setFooterVisible } from "../../../../app/util/util";
import ClientInvestmentForm from "../ClientInvestmentForm";
import { setClientInvestmentsParams, setClientInvestmentsPageNumber } from "../clientInvestmentsSlice";
import ClientInvestmentsList from "./ClientInvestmentsList";
import CheckboxButtons from "../../../../app/components/AppCheckboxButtons";
import AppReturn from "../../../../app/components/AppReturn";
import AppUniversalSearch from "../../../../app/components/AppUniversalSearch";

const sortOptions = [
  { value: 'dateDesc', label: 'Data dodania malejąco' },
  { value: 'lastUpdated', label: 'Ostatnio modyfikowane' }
]

export default function ClientInvestments() {
  const { clientInvestments, clientInvestmentsFiltersLoaded, metaData } = useClientInvestments();
  const { clientInvestmentsParams } = useAppSelector(state => state.clientInvestments);
  const [selectedClientInvestment, setSelectedClientInvestment] = useState<Investment | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();

  const stateNamesMap: { [key in keyof typeof InvestmentState]: string } = {
    WAITING_FOR_CONFIRMATION: "Oczekiwanie na potwierdzenie",
    ADMIN_ACCEPTED: "Zaakceptowane przez admina",
    ADMIN_REJECTED: "Odrzucone przez admina",
    CLIENT_ACCEPTED: "Zaakceptowane przez klienta",
    CLIENT_REJECTED: "Odrzucone przez klienta",
    CLIENT_DATA_FILLED: "Dane klienta wypełnione",
    COMPLETED: "Zakończone",
    CANCELED: "Anulowane",
  };

  function cancelEdit() {
    if (selectedClientInvestment) setSelectedClientInvestment(undefined);
    setEditMode(false);
  }

  function handleCardSelectClientInvestment(clientInvestment: Investment) {
    setSelectedClientInvestment(clientInvestment);
    console.log(clientInvestment.state, 'ClientInvestments');
    setEditMode(true);
  }

  if (!clientInvestmentsFiltersLoaded) { setFooterInvisible(); return <LoadingComponent /> }
  setFooterVisible();

  if (editMode) return <ClientInvestmentForm clientInvestment={selectedClientInvestment} cancelEdit={cancelEdit} />

  return (
    <Box>
      <AppBreadcrumbs />
      <AppReturn title={"Projekty inwestycyjne"} setFunction={() => setEditMode(true)} />

      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ mb: 2 }}>
            <AppUniversalSearch
              sliceName="clientInvestments"
              searchParamsSelector={(state: RootState) => state.clientInvestments.clientInvestmentsParams}
              setSearchParams={setClientInvestmentsParams}
              label='Szukaj'
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <RadioButtonGroup
              selectedValue={clientInvestmentsParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setClientInvestmentsParams({ orderBy: e.target.value }))}
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <CheckboxButtons
              items={stateNamesMap}
              checked={clientInvestmentsParams.states}
              onChange={(checkedItems: string[]) => dispatch(setClientInvestmentsParams({ states: checkedItems }))}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <ClientInvestmentsList clientInvestments={clientInvestments} handleCardSelectClientInvestment={handleCardSelectClientInvestment} cancelEdit={cancelEdit} />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9} sx={{ my: 2 }} >
          {metaData &&
            <AppPagination
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setClientInvestmentsPageNumber({ pageNumber: page }))}
            />}
        </Grid>
      </Grid>
    </Box>
  )
}