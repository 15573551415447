import { useEffect } from "react";
import { adminUsersSelectors, fetchAdminUsersAsync, fetchAdminUsersFilters } from "../../features/admin/users/adminUsersSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useAdminUsers() {
    const adminUsers = useAppSelector(adminUsersSelectors.selectAll);
    const {adminUsersLoaded, adminUsersFiltersLoaded, metaData} = useAppSelector(state => state.adminUsers);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!adminUsersLoaded) dispatch(fetchAdminUsersAsync());
  }, [adminUsersLoaded, dispatch])

  useEffect(() => {
    if (!adminUsersFiltersLoaded) dispatch(fetchAdminUsersFilters());
  }, [adminUsersFiltersLoaded, dispatch])

  return {
    adminUsers,
    adminUsersLoaded,
    adminUsersFiltersLoaded,
    //brands,
    //types,
    metaData
  }
}