import * as yup from 'yup';

export const userValidationSchema = yup.object({
    lastName: yup.string().nullable(),
    firstName: yup.string().required('Podanie imienia jest wymagane').nullable(),
    phone: yup.string(),
    pesel: yup.string().required('Podanie PESEL-u jest wymagane').nullable(),
    isAdmin: yup.bool(),
    comment: yup.string().nullable(),

    addressStreet: yup.string().required('Podanie ulicy jest wymagane').nullable(),
    addressBuilding: yup.string().required('Podanie nr budynku jest wymagane').nullable(),
    addressPremises: yup.string().nullable(),
    addressZip: yup.string().required('Podanie kodu pocztowego jest wymagane').nullable(),
    addressCity: yup.string().required('Podanie miasta jest wymagane').nullable(),
    addressState: yup.string().required('Podanie województwa jest wymagane').nullable(),
    addressCountry: yup.string().required('Podanie kraju jest wymagane').nullable(),
});