import { useEffect } from "react";
import { adminInvestmentsSelectors, fetchAdminInvestmentsAsync, fetchAdminInvestmentsFilters } from "../../features/admin/adminInvestments/adminInvestmentsSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useAdminInvestments() {
    const adminInvestments = useAppSelector(adminInvestmentsSelectors.selectAll);
    const {adminInvestmentsLoaded, adminInvestmentsFiltersLoaded, states, metaData} = useAppSelector(state => state.adminInvestments);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!adminInvestmentsLoaded) dispatch(fetchAdminInvestmentsAsync());
  }, [adminInvestmentsLoaded, dispatch])

  useEffect(() => {
    if (!adminInvestmentsFiltersLoaded) dispatch(fetchAdminInvestmentsFilters());
  }, [adminInvestmentsFiltersLoaded, dispatch])

  return {
    adminInvestments,
    adminInvestmentsLoaded,
    adminInvestmentsFiltersLoaded,
    states,
    //types,
    metaData
  }
}