import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { MetaData } from "../../../app/models/pagination";
import { Document, DocumentsParams } from "../../../app/models/document";
import { RootState } from "../../../app/store/configureStore";

interface ClientDocumentsState {
    clientDocumentsLoaded: boolean;
    clientDocumentsFiltersLoaded: boolean;
    status: string;
    // brands: string[];
    // types: string[];
    clientDocumentsParams: DocumentsParams;
    metaData: MetaData | null;
}

const clientDocumentsAdapter = createEntityAdapter<Document>();

function getAxiosParams(clientDocumentsParams: DocumentsParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', clientDocumentsParams.pageNumber.toString());
    params.append('pageSize', clientDocumentsParams.pageSize.toString());
    params.append('orderBy', clientDocumentsParams.orderBy);
    if (clientDocumentsParams.searchTerm) params.append('searchTerm', clientDocumentsParams.searchTerm);
    // if (serviceParams.brands.length > 0) params.append('brands', serviceParams.brands.toString());
    // if (serviceParams.types.length > 0) params.append('types', serviceParams.types.toString());
    return params;
}

export const fetchClientDocumentsAsync = createAsyncThunk<Document[], void, {state: RootState} >(
    'clientDocuments/fetchClientDocumentsAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().clientDocuments.clientDocumentsParams);
        try {
            const response = await agent.Client.ClientDocuments.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchClientDocumentAsync = createAsyncThunk<Document, number>(
    'clientDocuments/fetchClientDocumentAsync',
    async (documentId, thunkAPI) => {
        try {
            return await agent.Client.ClientDocuments.details(documentId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'date'//,
        // brands: [],
        // types: []
    }
}

export const clientDocumentsSlice = createSlice({
    name: 'clientDocuments',
    initialState: clientDocumentsAdapter.getInitialState<ClientDocumentsState>({
        clientDocumentsLoaded: false,
        clientDocumentsFiltersLoaded: false,
        status: 'idle',
        // brands: [],
        // types: [],
        clientDocumentsParams: initParams(),
        metaData: null
    }),
    reducers: {
        setClientDocumentsParams: (state, action) => {
            state.clientDocumentsLoaded = false;
            state.clientDocumentsParams = {...state.clientDocumentsParams, ...action.payload, pageNumber: 1};
        },
        setClientDocumentsPageNumber: (state, action) => {
            state.clientDocumentsLoaded = false;
            state.clientDocumentsParams = {...state.clientDocumentsParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetClientDocumentsParams: (state) => {
            state.clientDocumentsParams = initParams();
        },
        setClientDocument: (state, action) => {
            clientDocumentsAdapter.upsertOne(state, action.payload);
            state.clientDocumentsLoaded = false;
        },
        removeClientDocument: (state, action) => {
            clientDocumentsAdapter.removeOne(state, action.payload);
            state.clientDocumentsLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchClientDocumentsAsync.pending, (state) => {
                state.status = 'pendingFetchClientDocuments';
            });
            builder.addCase(fetchClientDocumentsAsync.fulfilled, (state, action) => {
                clientDocumentsAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.clientDocumentsLoaded = true;
            });
            builder.addCase(fetchClientDocumentsAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchClientDocumentAsync.pending, (state) => {
                state.status = 'pendingFetchClientDocument';
            });
            builder.addCase(fetchClientDocumentAsync.fulfilled, (state, action) => {
                clientDocumentsAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchClientDocumentAsync.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const clientDocumentsSelectors = clientDocumentsAdapter.getSelectors((state: RootState) => state.clientDocuments);

export const {setClientDocumentsParams, resetClientDocumentsParams, setMetaData, setClientDocumentsPageNumber, setClientDocument, removeClientDocument} = clientDocumentsSlice.actions;