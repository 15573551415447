import { TextField } from "@mui/material";
import { UseControllerProps, useController, FieldValues } from "react-hook-form";
import { useEffect, useState } from "react";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    label: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
}

export default function AppTextInput<T extends FieldValues>({ error, helperText, ...props }: Props<T>) {
    const { fieldState, field } = useController<T>(props);
    const [labelShrink, setLabelShrink] = useState(false);

    useEffect(() => {
        // Ustawienie stanu 'labelShrink' w zależności od wartości inputa
        setLabelShrink(!!field.value);
    }, [field.value]);

    return (
        <TextField
            {...props}
            {...field}
            multiline={props.multiline}
            rows={props.rows}
            type={props.type}
            fullWidth
            variant="outlined"
            error={error || !!fieldState.error}
            helperText={helperText || fieldState.error?.message}
            InputLabelProps={{ shrink: labelShrink }} // Zmiana stanu 'shrink' etykiety
        />
    );
}