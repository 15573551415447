import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/configureStore";
import { debounce } from "../util/util";

interface SearchProps {
    sliceName: string;
    searchParamsSelector: (state: any) => any;
    setSearchParams: (params: any) => any;
    label: string;
}

export default function AppUniversalSearch({ sliceName, searchParamsSelector, setSearchParams, label }: SearchProps) {
    const searchParams = useAppSelector(searchParamsSelector);
    const [searchTerm, setSearchTerm] = useState(searchParams.searchTerm);
    const dispatch = useAppDispatch();

    const debouncedSearchRef = useRef(
        debounce((value: string) => {
            dispatch(setSearchParams({ searchTerm: value }));
        }, 2000)
    );

    useEffect(() => {
        const debouncedSearch = debouncedSearchRef.current;
        return () => {
            debouncedSearch.cancel();
        };
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        debouncedSearchRef.current(event.target.value);
    };

    return (
        <TextField
            name={`${sliceName}-search`}
            label={label}
            variant='outlined'
            fullWidth
            value={searchTerm || ''}
            onChange={handleSearchChange}
        />
    );
}