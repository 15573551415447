import { Grid } from "@mui/material";
import { Investment } from "../../../../app/models/investment";
import { useAppSelector } from "../../../../app/store/configureStore";
import ClientInvestmentCard from "./ClientInvestmentCard";
import ClientInvestmentCardSkeleton from "./ClientInvestmentCardSkeleton";

interface Props {
    clientInvestments: Investment[];
    handleCardSelectClientInvestment?: (handledClientInvestment: Investment) => void;
    cancelEdit: () => void;
}

export default function ClientInvestmentsList({ clientInvestments, handleCardSelectClientInvestment, cancelEdit }: Props) {
    const { clientInvestmentsLoaded } = useAppSelector(state => state.clientInvestments);
    return (
        <Grid container spacing={4}>
            {clientInvestments.map(( clientInvestment, index ) => (
                <Grid key={index} item xs={12} sm={12} md={6}>
                    {!clientInvestmentsLoaded ? (
                        <ClientInvestmentCardSkeleton />
                    ) : (
                        <ClientInvestmentCard investment={clientInvestment}
                            handleCardSelectInvestment={handleCardSelectClientInvestment}
                            cancelEdit={cancelEdit}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}