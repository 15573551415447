import { LoadingButton } from "@mui/lab";
import { Box, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip, CircularProgress, Button } from "@mui/material";
import AppPagination from "../../../../app/components/AppPagination";
import { useState, useEffect } from "react";
import agent from "../../../../app/api/agent";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "../../../../app/store/configureStore";
import { Document } from "../../../../app/models/document";
import useClientDocuments from "../../../../app/hooks/useClientDocuments";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppUniversalSearch from "../../../../app/components/AppUniversalSearch";
import { setClientDocument, setClientDocumentsPageNumber, setClientDocumentsParams } from "../clientDocumentsSlice";
import { formatToLocalDate } from "../../../../app/util/util";

export default function ClientDocumentsManager() {
    const { clientDocuments, metaData } = useClientDocuments();
    const [openSignDialog, setOpenSignDialog] = useState(false);
    const [documentToSign, setDocumentToSign] = useState<Document | null>(null);
    const [loading, setLoading] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (clientDocuments.length > 0 || metaData) {
            setLoadingDocuments(false);
        }
    }, [clientDocuments, metaData]);

    const handleOpenSignDialog = (document: Document) => {
        setDocumentToSign(document);
        setOpenSignDialog(true);
    };

    const handleCloseSignDialog = () => {
        setOpenSignDialog(false);
    };

    const handleConfirmSignDialog = async () => {
        setLoading(true);
        if (documentToSign) {
            try {
                const response = await agent.Client.ClientDocuments.addSignatureByClient(documentToSign.id);
                toast.success('Podpisano dokument');
                dispatch(setClientDocument(response));
            } catch (error) {
                console.log(error);
                toast.error('Nie udało się podpisać dokumentu. Prawdopodobnie plik jest za duży lub nieprzystosowany do podpisania.');
            }
        }
        setLoading(false);
        handleCloseSignDialog();
    };

    const handleOpenDocument = async (documentId: number) => {
        try {
            const response = await agent.Client.ClientDocuments.getPreSignedUrl(documentId);
            const preSignedUrl = response.preSignedUrl;
            window.open(preSignedUrl, '_blank');
        } catch (error) {
            console.error("Error fetching pre-signed URL", error);
            toast.error("Nie udało się otworzyć dokumentu.");
        }
    };

    return (
        <>
            <AppBreadcrumbs />
            <Box display='flex' justifyContent='space-between'>
                <Typography sx={{ p: 2 }} variant='h4' color={(theme) => theme.palette.text.primary}>Dokumenty</Typography>
            </Box>

            <Paper sx={{ mb: 2 }}>
                <AppUniversalSearch
                    sliceName="clientDocuments"
                    searchParamsSelector={(state: RootState) => state.clientDocuments.clientDocumentsParams}
                    setSearchParams={setClientDocumentsParams}
                    label='Szukaj'
                />
            </Paper>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="left">Nazwa</TableCell>
                            <TableCell align="left">Data dodania</TableCell>
                            <TableCell align="left">Użytkownik</TableCell>
                            <TableCell align="left">Komentarz</TableCell>
                            <TableCell align="left">Data ostatniej edycji</TableCell>
                            <TableCell align="left">Format dokumentu</TableCell>
                            <TableCell align="center" style={{ minWidth: 355 }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingDocuments ? (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {clientDocuments.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            <Typography variant='h6' color={(theme) => theme.palette.text.primary}>Brak dokumentów</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    clientDocuments.map((item) => {
                                        const extension = item.filePath ? '.' + item.filePath.split('.').pop() : '';

                                        return (
                                            <TableRow
                                                key={item.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {item.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Box display='flex' alignItems='center'>
                                                        <span>{item.dateCreated ? formatToLocalDate(item.dateCreated.toString()) : 'Brak daty'}</span>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.userEmail}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.comment}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Box display='flex' alignItems='center'>
                                                        <span>{item.updatingDateTime ? formatToLocalDate(item.updatingDateTime.toString()) : 'Brak daty'}</span>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {extension}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {/* Przyciski otwierające dokument */}
                                                        <Button
                                                            sx={{ mr: 1 }}
                                                            variant='contained'
                                                            size="small"
                                                            title='Zobacz dokument'
                                                            onClick={() => handleOpenDocument(item.id)}
                                                            disabled={item.signedFilePath ? false : item.filePath ? false : true}
                                                        >
                                                            Zobacz dokument
                                                        </Button>
                                                        {/* Podpisz dokument */}
                                                        <Tooltip
                                                            title={!item.isSignedByAdmin ? "Aby podpisać dokument, musi być on podpisany przez Administratora." : ""}
                                                            disableHoverListener={item.isSignedByAdmin}
                                                            PopperProps={{
                                                                sx: {
                                                                    '& .MuiTooltip-tooltip': {
                                                                        textAlign: 'center',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <span>
                                                                <Button
                                                                    disabled={!item.isSignedByAdmin || item.isSignedByClient}
                                                                    variant='contained'
                                                                    onClick={() => handleOpenSignDialog(item)}
                                                                    size="small"
                                                                    color={item.isSignedByClient ? 'error' : 'inherit'}
                                                                >
                                                                    {item.isSignedByClient ? 'Podpisano' : 'Podpisz'}
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}
                            </>
                        )}
                    </TableBody>
                    <Dialog open={openSignDialog} onClose={handleCloseSignDialog}>
                        <DialogTitle>Podpisz dokument</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {documentToSign && documentToSign.isSignedByClient
                                    ? 'Ten dokument był już podpisany. Czy chcesz go podpisać ponownie?'
                                    : 'Czy na pewno chcesz podpisać dokument?'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color="error" onClick={handleCloseSignDialog}>
                                Anuluj
                            </Button>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                onClick={handleConfirmSignDialog}
                                loading={loading}
                            >
                                Podpisz
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </Table>
            </TableContainer>
            {metaData && (
                <Box color={(theme) => theme.palette.text.primary} sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setClientDocumentsPageNumber({ pageNumber: page }))}
                    />
                </Box>
            )}
        </>
    );
}