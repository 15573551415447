import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function currencyDivide100Format(amount: number) {
  return 'PLN ' + (amount / 100).toFixed(2);
}

export function currencyFormat(amount: number) {
  return 'PLN ' + amount.toFixed(2);
}

export function getPolishLiteral(n: number): string {
  if (n === 1) {
      return `${n} osoba`;
  } else if ((n % 10 >= 2 && n % 10 <= 4) && (n % 100 < 12 || n % 100 > 14)) {
      return `${n} osoby`;
  } else {
      return `${n} osób`;
  }
}

export const formatDate = (date: Date): string => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export function truncateToDate(str: string | undefined) {
  if (str) {
    return str.length > 10 ? str.substring(0, 10) + '' : str;
  }
}

export function truncateToTime(str: string | undefined) {
  if (str) {
    return str.length > 10 ? str.substring(11, 16) + '' : str;
  }
}

export function truncate(str: string | undefined, numberOfChars: number): string {
  if (!str) return ''; // Jeśli str jest undefined, zwróć pusty ciąg
  return str.length > numberOfChars ? str.substring(0, numberOfChars) + '...' : str;
}

export function setFooterVisible() {
  const footer = document.getElementById('footer');
  if (footer) {
    footer.hidden = false;
  }
}

export function setFooterInvisible() {
  const footer = document.getElementById('footer');
  if (footer) {
    footer.hidden = true;
  }
}

export function getInvestmentStatusTitle(statusNumber: number): string {
  switch (statusNumber) {
    case 0:
      return "Oczekiwanie na potwierdzenie";
    case 1:
      return "Zaakceptowano";
    case 2:
      return "Odrzucono";
    case 3:
      return "Zaakceptowane przez klienta";
    case 4:
      return "Odrzucone przez klienta";
    case 5:
      return "Klient wypełnił dane";
    case 6:
      return "Ukończono";
    case 7:
      return "Błąd";
    default:
      return "Błąd";
  }
}

export function getInvestmentStatusColor(currentState: number) {
  switch (currentState) {
    case 0:
      return 'orange';
    case 1:
      return 'green';
    case 2:
      return 'red';
    case 3:
      return 'green';
    case 4:
      return 'red';
    case 5:
      return 'green';
    case 6:
      return 'blue';
    case 7:
      return 'red';
    default:
      return '';
  }
}

export function getOrderStatusTitle(statusNumber: number): string {
  switch (statusNumber) {
    case 0:
      return "Utworzono zamówienie";
    case 1:
      return "Dodano skany (oczekiwanie na zatwierdzenie)";
    case 2:
      return "Zatwierdzono";
    case 3:
      return "Ukończono";
    case 4:
      return "Zapytanie o anulowanie";
    case 5:
      return "Anulowano";
    default:
      return "Błąd";
  }
}

export function getOrderStatusColor(currentState: number) {
  switch (currentState) {
    case 0:
      return 'orange';
    case 1:
      return 'blue';
    case 2:
      return 'green';
    case 3:
      return 'green';
    case 4:
      return 'gray';
    case 5:
      return 'gray';
    default:
      return 'red';
  }
}

type DebouncedFunction = ((...args: any[]) => void) & { cancel: () => void };

export function debounce(func: (...args: any[]) => void, wait: number): DebouncedFunction {
  let timeout: NodeJS.Timeout | null;
  const debounced = (...args: any[]) => {
    const later = () => {
      timeout = null;
      func(...args);
    };
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  debounced.cancel = () => {
    if (timeout) clearTimeout(timeout);
    timeout = null;
  };

  return debounced as DebouncedFunction;
}


export function getFileFormat(url: string) {
  // Szukamy ostatniego wystąpienia kropki w URL
  const lastDotPosition = url.lastIndexOf('.');

  // Jeśli kropka nie została znaleziona, zwracamy null
  if (lastDotPosition === -1) return null;

  // Wycinamy wszystko po ostatniej kropce
  const extension = url.substring(lastDotPosition + 1);

  // Sprawdzamy, czy po kropce nie ma znaków zapytania lub hash (#), które oznaczałyby parametry URL lub anchor
  const queryOrHashPosition = extension.search(/[#?]/);

  // Jeśli znaleźliśmy znak zapytania lub hash, oznacza to, że rozszerzenie zawiera fragmenty URL, które nie są częścią formatu pliku
  if (queryOrHashPosition !== -1) {
    // Wycinamy część do znaku zapytania lub hash
    return extension.substring(0, queryOrHashPosition).toLowerCase();
  }

  // Zwracamy rozszerzenie pliku w małych literach
  return extension.toLowerCase();
}

export const states = [
  'dolnośląskie',
  'kujawsko-pomorskie',
  'lubelskie',
  'lubuskie',
  'łódzkie',
  'małopolskie',
  'mazowieckie',
  'opolskie',
  'podkarpackie',
  'podlaskie',
  'pomorskie',
  'śląskie',
  'świętokrzyskie',
  'warmińsko-mazurskie',
  'wielkopolskie',
  'zachodniopomorskie'
];

export const exportContentToPdf = (content: string, title = 'Document') => {
  // Create a new window
  const printWindow = window.open('', '', 'height=500, width=800');

  if (printWindow) {
    // Write the content in the new window
    printWindow.document.write('<html><head><title>' + title + '</title>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');

    // Close the document to ensure everything is rendered
    printWindow.document.close();

    // Wait for the content to load and then print
    printWindow.onload = function () {
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 500); // Adjust the delay as needed
    };
  } else {
    console.error('Failed to open print window');
  }
};

export const stateNamesMap: { [key: string]: string } = {
  ORDER_CREATED: 'Utworzono zamówienie',
  SCANS_ADDED: 'Dodano skany (oczekiwanie na zatwierdzenie)',
  SCANS_ACCEPTED: 'Zatwierdzono',
  COMPLETED: 'Ukończono',
  CANCELLATION_REQUEST: 'Zapytanie o anulowanie',
  CANCELED: 'Anulowano',
}

export const paymentStateNamesMap: { [key: string]: string } = {
  PENDING:  'Oczekiwanie na płatność',
  WAITING_FOR_CONFIRMATION:  'Oczekiwanie na potwierdzenie płatności',
  COMPLETED:  'Zapłacono',
  CANCELED:  'Anulowano'
}

export function formatToLocalDate(dateString: string, format = 'DD-MM-YYYY') {
  return dayjs(dateString).tz('Europe/Warsaw').format(format);
}

export function formatToLocalTime(dateString: string, format = 'HH:mm') {
  return dayjs(dateString).tz('Europe/Warsaw').format(format);
}

export function orderFormCalculateSum(totalItem: number, unitDiscountedPrice: number) {
  return totalItem * unitDiscountedPrice;

}