import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import useStyles from './cardsStyle';

interface Props {
  avatar: string;
  title: string;
  name: string;
  text: string;
  star: number;
}

function Testimonial(props: Props) {
  const { classes } = useStyles();
  const { avatar, title, name, text, star } = props;
  const paperRef = useRef<HTMLDivElement>(null);

  const [showFullText, ] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [, setContainerWidth] = useState(300); // Domyślna szerokość

  useEffect(() => {
    if (paperRef.current) {
      setContainerWidth(paperRef.current.clientWidth);
    }
  }, []);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className={classes.testiCard}>
      <Paper className={classes.paper} ref={paperRef}>
        <Typography display="block" className={showFullText ? '' : classes.truncate}>
          {text}
        </Typography>
        {!showFullText && (
          <Button onClick={handleOpenDialog} className={classes.showMoreButton}>
            zobacz cały
          </Button>
        )}
        <div className={classes.rating}>
          {[...Array(star)].map((_, index) => (
            <StarIcon className={classes.starIcon} key={index} />
          ))}
          {[...Array(5 - star)].map((_, index) => (
            <StarIcon className={classes.starIconDisable} key={index} />
          ))}
        </div>
      </Paper>
      <div className={classes.person}>
        <Avatar alt={name} src={avatar} className={classes.avatar} />
        <div className={classes.name}>
          <Typography display="block" variant="h6">
            {name}
          </Typography>
          <Typography variant="caption" className={classes.title}>
            {title}
          </Typography>
        </div>
      </div>
      <Dialog open={showDialog} onClose={handleCloseDialog} aria-labelledby="dialog-title" fullWidth maxWidth="sm">
        <DialogContent>
          <Typography paragraph>
            {text}
          </Typography>
          <div className={classes.rating}>
            {[...Array(star)].map((_, index) => (
              <StarIcon className={classes.starIcon} key={index} />
            ))}
            {[...Array(5 - star)].map((_, index) => (
              <StarIcon className={classes.starIconDisable} key={index} />
            ))}
          </div>
          <div className={classes.person}>
            <Avatar alt={name} src={avatar} className={classes.avatar} />
            <div className={classes.name}>
              <Typography display="block" variant="h6">
                {name}
              </Typography>
              <Typography variant="caption" className={classes.title}>
                {title}
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ border: 1 }} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Testimonial.propTypes = {
  avatar: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  star: PropTypes.number.isRequired,
};

export default Testimonial;