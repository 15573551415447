import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppPagination from "../../../app/components/AppPagination";
import RadioButtonGroup from "../../../app/components/AppRadioButtonGroup";
import useSubservices from "../../../app/hooks/useSubservices";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Subservice } from "../../../app/models/subservice";
import { useAppSelector, useAppDispatch, RootState } from "../../../app/store/configureStore";
import { setFooterInvisible, setFooterVisible } from "../../../app/util/util";
import SubserviceForm from "../SubserviceForm";
import { setSubservicesParams, setSubservicesPageNumber } from "../subservicesSlice";
import SubservicesList from "./SubservicesList";
import AppReturn from "../../../app/components/AppReturn";
import AppUniversalSearch from "../../../app/components/AppUniversalSearch";

const sortOptions = [
  { value: '_', label: 'Id podusługi: \u2193' },
  { value: 'id', label: 'Id podusługi: \u2191' },
  { value: 'name', label: 'Nazwa: A - Z \u2191' },
  { value: 'nameDesc', label: 'Nazwa: Z - A \u2193' }
]

export default function Subservices() {
  const { subservices, subservicesFiltersLoaded, metaData } = useSubservices();
  const [editMode, setEditMode] = useState(false);
  const [selectedSubservice, setSelectedSubservice] = useState<Subservice | undefined>(undefined);
  const { subservicesParams } = useAppSelector(state => state.subservices);
  const dispatch = useAppDispatch();

  function cancelEdit() {
    if (selectedSubservice) setSelectedSubservice(undefined);
    setEditMode(false);
  }

  function handleCardSelectSubservice(subservice: Subservice) {
    setSelectedSubservice(subservice);
    setEditMode(true);
  }

  if (!subservicesFiltersLoaded) { setFooterInvisible(); return <LoadingComponent /> }
  setFooterVisible();

  if (editMode) return <SubserviceForm subservice={selectedSubservice} cancelEdit={cancelEdit} />

  return (
    <>
      <AppBreadcrumbs />
      <AppReturn title="Podusługi" setFunction={() => setEditMode(true)} />
      <Grid container columnSpacing={4}>
        <Grid item xs={12}>
          <Paper sx={{ mb: 2 }}>
            <AppUniversalSearch
              sliceName="subservices"
              searchParamsSelector={(state: RootState) => state.subservices.subservicesParams}
              setSearchParams={setSubservicesParams}
              label='Szukaj'
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ mb: 2, p: 2 }}>
            <RadioButtonGroup
              selectedValue={subservicesParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setSubservicesParams({ orderBy: e.target.value }))}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <SubservicesList subservices={subservices} handleCardSelectSubservice={handleCardSelectSubservice} />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9} sx={{ my: 2 }} >
          {metaData &&
            <AppPagination
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setSubservicesPageNumber({ pageNumber: page }))}
            />}
        </Grid>
      </Grid>
    </>
  )
}