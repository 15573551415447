import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { InvestmentParams, Investment } from "../../../app/models/investment";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";

interface AdminInvestmentsState {
    adminInvestmentsLoaded: boolean;
    adminInvestmentsFiltersLoaded: boolean;
    status: string;
    selectedAdminInvestment: number;
    states: string[];
    // types: string[];
    adminInvestmentsParams: InvestmentParams;
    metaData: MetaData | null;
}

const adminInvestmentsAdapter = createEntityAdapter<Investment>({
    selectId: (adminInvestment) => adminInvestment.publicId,
});

function getAxiosParams(adminInvestmentsParams: InvestmentParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', adminInvestmentsParams.pageNumber.toString());
    params.append('pageSize', adminInvestmentsParams.pageSize.toString());
    params.append('orderBy', adminInvestmentsParams.orderBy);
    if (adminInvestmentsParams.searchTerm) params.append('searchTerm', adminInvestmentsParams.searchTerm);
    if (adminInvestmentsParams.states.length > 0) params.append('states', adminInvestmentsParams.states.toString());
    // if (serviceParams.types.length > 0) params.append('types', serviceParams.types.toString());
    return params;
}

export const fetchAdminInvestmentsAsync = createAsyncThunk<Investment[], void, {state: RootState} >(
    'adminInvestments/fetchAdminInvestmentsAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().adminInvestments.adminInvestmentsParams);
        try {
            const response = await agent.Admin.Investments.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchAdminInvestmentAsync = createAsyncThunk<Investment, number>(
    'adminInvestments/fetchAdminInvestmentAsync',
    async (adminInvestmentId, thunkAPI) => {
        try {
            return await agent.Admin.Investments.details(adminInvestmentId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchAdminInvestmentsFilters = createAsyncThunk(
    'adminInvestments/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Admin.Investments.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'dateDesc',
        states: []
        // types: []
    }
}

export const adminInvestmentsSlice = createSlice({
    name: 'adminInvestments',
    initialState: adminInvestmentsAdapter.getInitialState<AdminInvestmentsState>({
        adminInvestmentsLoaded: false,
        adminInvestmentsFiltersLoaded: false,
        status: 'idle',
        selectedAdminInvestment: 0,
        states: [],
        adminInvestmentsParams: initParams(),
        metaData: null
    }),
    reducers: {
        setAdminInvestmentsParams: (state, action) => {
            state.adminInvestmentsLoaded = false;
            state.adminInvestmentsParams = {...state.adminInvestmentsParams, ...action.payload, pageNumber: 1};
        },
        setAdminInvestmentsPageNumber: (state, action) => {
            state.adminInvestmentsLoaded = false;
            state.adminInvestmentsParams = {...state.adminInvestmentsParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetAdminInvestmentsParams: (state) => {
            state.adminInvestmentsParams = initParams();
        },
        setAdminInvestment: (state, action) => {
            adminInvestmentsAdapter.upsertOne(state, action.payload);
            state.adminInvestmentsLoaded = false;
        },
        removeAdminInvestment: (state, action) => {
            adminInvestmentsAdapter.removeOne(state, action.payload);
            state.adminInvestmentsLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchAdminInvestmentsAsync.pending, (state) => {
                state.status = 'pendingFetchAdminInvestments';
            });
            builder.addCase(fetchAdminInvestmentsAsync.fulfilled, (state, action) => {
                adminInvestmentsAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.adminInvestmentsLoaded = true;
            });
            builder.addCase(fetchAdminInvestmentsAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchAdminInvestmentAsync.pending, (state) => {
                state.status = 'pendingFetchAdminInvestment';
            });
            builder.addCase(fetchAdminInvestmentAsync.fulfilled, (state, action) => {
                adminInvestmentsAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchAdminInvestmentAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchAdminInvestmentsFilters.pending, (state) => {
                state.status = 'pendingFetchAdminInvestmentsFilters';
            });
            builder.addCase(fetchAdminInvestmentsFilters.fulfilled, (state) => {
                state.adminInvestmentsFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchAdminInvestmentsFilters.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const adminInvestmentsSelectors = adminInvestmentsAdapter.getSelectors((state: RootState) => state.adminInvestments);

export const {setAdminInvestmentsParams, resetAdminInvestmentsParams, setMetaData, setAdminInvestmentsPageNumber, setAdminInvestment, removeAdminInvestment} = adminInvestmentsSlice.actions;