import * as yup from 'yup';
import { Investment, InvestmentState } from '../../../app/models/investment';

export const baseValidationSchema = {
    investmentName: yup.string().required('Temat projektu jest wymagany').nullable(),
    clientComment: yup.string().required('Komentarz/opis jest wymagany').nullable(),
    competition: yup.string().required('Rodzaj naboru/konkursu jest wymagany').nullable(),
    submissionDeadline: yup.string()
        .nullable()
        .test('is-date', 'Wprowadź prawidłową datę', value => !value || !isNaN(Date.parse(value))),
};

export function createValidationSchema(clientInvestment?: Investment) {
    return yup.object().shape({
        ...baseValidationSchema,
        clientInstitutionName: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Nazwa instytucji jest wymagana').nullable() : yup.string().nullable(),
        clientLastname: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Nazwisko jest wymagane').nullable() : yup.string().nullable(),
        clientFirstname: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Imię jest wymagane').nullable() : yup.string().nullable(),
        clientPhone: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Telefon jest wymagany').nullable() : yup.string().nullable(),
        clientEmail: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Email jest wymagany').nullable() : yup.string().nullable(),
        clientStreet: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Ulica jest wymagana').nullable() : yup.string().nullable(),
        clientBuilding: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Numer budynku jest wymagany').nullable() : yup.string().nullable(),
        clientZip: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Kod pocztowy jest wymagany').nullable() : yup.string().nullable(),
        clientCity: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Miasto jest wymagane').nullable() : yup.string().nullable(),
        clientState: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Województwo jest wymagane').nullable() : yup.string().nullable(),
        clientCountry: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Kraj jest wymagany').nullable() : yup.string().nullable(),
        clientNIP: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('NIP jest wymagany').nullable() : yup.string().nullable(),
        clientREGON: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('REGON jest wymagany').nullable() : yup.string().nullable(),
        clientKRS: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('KRS jest wymagany').nullable() : yup.string().nullable(),
        payerInstitutionName: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Nazwa instytucji płatnika jest wymagana').nullable() : yup.string().nullable(),
        payerRepresentative: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Przedstawiciel jest wymagany').nullable() : yup.string().nullable(),
        payerNIP: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('NIP płatnika jest wymagany').nullable() : yup.string().nullable(),
        payerStreet: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Ulica jest wymagana').nullable() : yup.string().nullable(),
        payerBuilding: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Numer budynku jest wymagany').nullable() : yup.string().nullable(),
        payerZip: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Kod pocztowy jest wymagany').nullable() : yup.string().nullable(),
        payerCity: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Miasto jest wymagane').nullable() : yup.string().nullable(),
        payerState: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Województwo jest wymagane').nullable() : yup.string().nullable(),
        payerCountry: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Kraj jest wymagany').nullable() : yup.string().nullable(),
        projectName: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Nazwa projektu jest wymagana').nullable() : yup.string().nullable(),
        projectDateStart: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string()
            .nullable()
            .test('is-date', 'Wprowadź prawidłową datę', value => !value || !isNaN(Date.parse(value))) : yup.string().nullable(),
        projectDateEnd: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string()
            .nullable()
            .test('is-date', 'Wprowadź prawidłową datę', value => !value || !isNaN(Date.parse(value))) : yup.string().nullable(),
        projectMaxBudget: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.number()
            .required('Maksymalny budżet projektu jest wymagany')
            .min(0, 'Budżet projektu nie może być ujemny') : yup.number().nullable(),
        projectDescription: clientInvestment?.state === InvestmentState.CLIENT_ACCEPTED ? yup.string().required('Opis projektu jest wymagany').nullable() : yup.string().nullable(),
    });
}

export const clientHelpInInvestmentValidationSchema = yup.object().shape({
    body: yup.string().required('Wiadomość jest wymagana'),
  });