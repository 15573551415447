import { useEffect } from "react";
import { useAppSelector, useAppDispatch, RootState } from "../store/configureStore";
import { aboutsSelectors, fetchAboutsAsync, fetchAboutsCount, updateAboutSortOrderAsync } from "../../features/about/aboutsSlice";
import { useSelector } from "react-redux";

export default function useAbouts() {
  const abouts = useAppSelector(aboutsSelectors.selectAll);
  const { aboutsLoaded, aboutsFiltersLoaded, metaData } = useAppSelector(state => state.abouts);
  const dispatch = useAppDispatch();
  const aboutsCount = useAppSelector(state => state.abouts.count);
  const updateStatus = useSelector((state: RootState) => state.abouts.updateStatus); // Załóżmy, że przechowujesz status operacji aktualizacji w stanie
  const { user } = useAppSelector(state => state.account);
  const isAdmin = user && user.roles?.includes('Admin');

  useEffect(() => {
    if (!aboutsLoaded) dispatch(fetchAboutsAsync());
  }, [aboutsLoaded, dispatch])

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchAboutsCount());
    }
  }, [dispatch, isAdmin]);

  const updateSortOrder = (id: number, newSortOrder: number) => {
    dispatch(updateAboutSortOrderAsync({ id, newSortOrder }));
  };

  useEffect(() => {
    if (isAdmin) {
      if (updateStatus == 'fulfilled') {
        dispatch(fetchAboutsAsync());
      }
    }
  }, [updateStatus, dispatch, isAdmin]);

  return {
    abouts,
    aboutsLoaded,
    aboutsFiltersLoaded,
    metaData,
    aboutsCount,
    updateSortOrder
  }
}