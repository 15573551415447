import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../store/configureStore";
import { fetchRolesAsync, rolesSelectors } from "../../features/admin/users/roles/rolesSlice";

export default function useRoles() {
    const roles = useAppSelector(rolesSelectors.selectAll);
    const {rolesLoaded} = useAppSelector(state => state.roles);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!rolesLoaded) dispatch(fetchRolesAsync());
  }, [rolesLoaded, dispatch])

  return {
    roles,
    rolesLoaded
  }
}