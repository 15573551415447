import { Box, Typography, Pagination, useTheme } from "@mui/material";
import { useState } from "react";
import { MetaData } from "../models/pagination";

interface Props {
    metaData: MetaData;
    onPageChange: (page: number) => void;
}

export default function AppPagination({ metaData, onPageChange }: Props) {
    const { currentPage, totalCount, totalPages, pageSize } = metaData;
    const [pageNumber, setPageNumber] = useState(currentPage);
    const theme = useTheme();

    function handlePageChange(page: number) {
        setPageNumber(page);
        onPageChange(page)
    }

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography sx={{ color: theme.palette.text.primary }}>
                Wyświetlane {(currentPage - 1) * pageSize + 1}-
                {currentPage * pageSize > totalCount
                    ? totalCount
                    : currentPage * pageSize} z {totalCount} elementów
            </Typography>
            <Pagination
                color='secondary'
                size='large'
                count={totalPages}
                page={pageNumber}
                onChange={(_e, page) => handlePageChange(page)}
            />
        </Box>
    )
}