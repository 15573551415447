import { Container, createTheme, CssBaseline, Direction, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import HomePage from "../../features/home/HomePage";
import appTheme, { AppThemeOptions } from "../theme/appTheme";
import LoadingBar from "react-top-loading-bar";
import { CacheProvider } from "@emotion/react";
import createCache from '@emotion/cache';
import { GlobalStyles, TssCacheProvider } from "tss-react";
import { appHomeStyles } from "./appHomeStyles";
import Header from "./header/Header";
import { useAppDispatch } from "../store/configureStore";
import { fetchCurrentUser } from "../../features/account/accountSlice";
import LoadingComponent from "./LoadingComponent";
import '../fonts/Square721DmNormal.ttf';
import Footer from "./footer/Footer";
import Cookies from "../components/Cookies/Cookies";
import { useHistoryTracker } from "../context/NavigationHistoryContext";

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const [initAppLoading, setInitAppLoading] = useState(true);
  const appThemeOptions = useState<AppThemeOptions>({ color: 'oceanBlue', mode: 'light' });
  const savedTheme = localStorage.getItem('luxiTheme') as 'light' | 'dark';
  const initialMode = savedTheme || 'light';

  const [theme, setTheme] = useState({
    ...appTheme({ color: 'oceanBlue', mode: initialMode }),
    direction: 'ltr' as Direction
  });

  // FROM HOMEPAGE OLAF TEMPLATE
  const { classes, cx } = appHomeStyles();

  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch])

  useEffect(() => {
    initApp().then(() => setInitAppLoading(false));
  }, [initApp])

  const toggleDarkTheme = () => {
    const newPaletteType = theme.palette.mode === 'light' ? 'dark' : 'light';
    localStorage.setItem('luxiTheme', newPaletteType);

    setTheme({
      ...appTheme({ ...appThemeOptions[0], mode: newPaletteType }),  // Uaktualnij motyw
      direction: theme.direction
    });
  };

  const toggleDirection = () => {
    let dir: any;
    document.dir = dir;
    // set theme
    setTheme({
      ...theme,
      direction: dir,
      palette: {
        ...theme.palette
      }
    });
  };

  const curLang = 'pl';

  useEffect(() => {
    // Set layout direction
    const themeDir = 'pl';
    document.dir = themeDir;
    document.documentElement.setAttribute('lang', curLang);

    const preloader = document.getElementById('preloader');
    if (preloader !== null || undefined) {
      setTimeout(() => {
        if (preloader)
          preloader.remove();
      }, 1500);
    }

    // Remove loading bar
    setLoading(0);
    setTimeout(() => {
      setLoading(100);
    }, 2000);
  }, [curLang]);

  const muiTheme = createTheme(theme);
  const cacheLTR = createCache({
    key: 'mui-style-ltr',
    //insertionPoint,
    prepend: true,
  });
  const tssCache = createCache({
    "key": "tss"
  });

  const { history, addHistoryItem } = useHistoryTracker();

  useEffect(() => {
    addHistoryItem(location.pathname);
  }, [location, addHistoryItem, history]);

  return (
    <CacheProvider value={cacheLTR}>
      <TssCacheProvider value={tssCache}>
        <ThemeProvider theme={muiTheme}>
          <ToastContainer theme="colored" position="bottom-right" hideProgressBar />
          <CssBaseline />
          <GlobalStyles styles={{
            'body': {
              '@media (max-width: 900px)': {
                marginTop: '70px',
              },
              '@media (width: 900px)': {
                marginTop: '0px', // Dodaj specjalny przypadek dla szerokości równej 900px
              }
            }
          }} />
          <LoadingBar
            height={3}
            color={theme.palette.primary.main}
            progress={loading}
            className="top-loading-bar"
          />
          {initAppLoading ? <LoadingComponent />
            : location.pathname === '/' ?
              <>
                <div id="main-wrap">
                  <HomePage
                    darkMode={theme.palette.mode === 'dark'}
                    onToggleDark={toggleDarkTheme}
                    onToggleDir={toggleDirection}
                  />
                </div>
                <section className={classes.spaceTopShort}>
                  <Footer darkMode={theme.palette.mode === 'dark'} />
                </section>
              </>
              :
              <>
                <div id="main-wrap">
                  {/* // Tutaj Header na wszystkie pozostałe podstrony, który nie zawiera ScrollSpy, ponieważ scroll przewija jedynie elementy na stronie głównej */}
                  <div style={{ backgroundColor: theme.palette.background02.paper }} className={cx(classes.mainWrap)}>
                    <Header
                      darkMode={theme.palette.mode === 'dark'}
                      onToggleDark={toggleDarkTheme}
                      onToggleDir={toggleDirection}
                    />
                    <main className={classes.containerWrap}>
                      <Container sx={{ pt: {}, mb: 2 }}>
                        <Outlet />
                      </Container>
                    </main>
                  </div>
                </div>
                <section id='footer' className={classes.spaceTopShort}>
                  <Footer darkMode={theme.palette.mode === 'dark'} />
                </section>
              </>
          }
          <Cookies />
        </ThemeProvider>
      </TssCacheProvider>
    </CacheProvider >
  )
}

export default App;