import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Resolver, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import AppTextInput from "../../../app/components/AppTextInput";
import { Investment } from "../../../app/models/investment";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { setClientInvestment } from "./clientInvestmentsSlice";
import { createValidationSchema } from "./clientInvestmentValidation";
import AppDateTimePicker from "../../../app/components/AppDateTimePicker";
import AppSelectStringsList from "../../../app/components/AppSelectStringsList";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppReturn from "../../../app/components/AppReturn";
import { states } from "../../../app/util/util";
import AppNumberInput from "../../../app/components/AppNumberInput";

interface Props {
    clientInvestment?: Investment;
    cancelEdit: () => void;
}

export interface InvestmentFormValues {
    id?: number;
    publicId?: string;
    state?: number;
    isActive?: boolean;
    isLastModify?: boolean;
    investmentName: string;
    clientComment: string;
    competition: string;
    submissionDeadline?: string;
    clientInstitutionName?: string;
    clientLastname?: string;
    clientFirstname?: string;
    clientPhone?: string;
    clientEmail?: string;
    clientStreet?: string;
    clientBuilding?: string;
    clientPremises?: string;
    clientZip?: string;
    clientCity?: string;
    clientState?: string;
    clientCountry?: string;
    clientNIP?: string;
    clientREGON?: string;
    clientKRS?: string;
    payerInstitutionName?: string;
    payerRepresentative?: string;
    payerNIP?: string;
    payerStreet?: string;
    payerBuilding?: string;
    payerPremises?: string;
    payerZip?: string;
    payerCity?: string;
    payerState?: string;
    payerCountry?: string;
    projectName?: string;
    projectDateStart?: string;
    projectDateEnd?: string;
    projectMaxBudget?: number;
    projectDescription?: string;
    clientExperience?: string;
    clientPotential?: string;
}

export default function ClientInvestmentForm({ clientInvestment, cancelEdit }: Props) {
    const { user } = useAppSelector(state => state.account);

    const resolver = useMemo(() => yupResolver(createValidationSchema(clientInvestment)), [clientInvestment]);

    const { control, reset, handleSubmit, formState: { errors, isDirty, isSubmitting } } = useForm<InvestmentFormValues>({
        resolver: resolver as unknown as Resolver<InvestmentFormValues>,
        defaultValues: clientInvestment ? {
            ...clientInvestment,
            publicId: clientInvestment.publicId?.toString(),
            projectDateStart: clientInvestment.projectDateStart ? new Date(clientInvestment.projectDateStart).toISOString() : undefined,
            projectDateEnd: clientInvestment.projectDateEnd ? new Date(clientInvestment.projectDateEnd).toISOString() : undefined
        } : {}
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (clientInvestment && !isDirty) {
            reset({
                ...clientInvestment,
                publicId: clientInvestment.publicId?.toString(),
                projectDateStart: clientInvestment.projectDateStart ? new Date(clientInvestment.projectDateStart).toISOString() : undefined,
                projectDateEnd: clientInvestment.projectDateEnd ? new Date(clientInvestment.projectDateEnd).toISOString() : undefined
            });
        }
    }, [clientInvestment, reset, isDirty]);

    async function handleSubmitData(data: InvestmentFormValues) {
        console.log(data);
        try {
            let response: Investment;
            if (clientInvestment?.state === 3) {
                const updatedInvestment = {
                    ...data,
                    state: 5,
                    userEmail: clientInvestment.userEmail,
                    userDisplayName: clientInvestment.userDisplayName,
                    userPhone: clientInvestment.userPhone
                };
                response = await agent.Client.Investments.update(updatedInvestment);
                console.log(updatedInvestment);
                toast.success('Uaktualniono projekt inwestycyjny');
            } else if (clientInvestment) {
                const updatedInvestment = {
                    ...data,
                    userEmail: clientInvestment.userEmail,
                    userDisplayName: clientInvestment.userDisplayName,
                    userPhone: clientInvestment.userPhone
                };
                response = await agent.Client.Investments.update(updatedInvestment);
                toast.success('Uaktualniono projekt inwestycyjny');
            } else {
                response = await agent.Client.Investments.create(data);
                toast.success('Utworzono projekt inwestycyjny');
            }
            dispatch(setClientInvestment(response));
            cancelEdit();
        } catch (error) {
            console.error(error);
            toast.error('Wystąpił problem podczas zapisywania projektu inwestycyjnego');
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.values(errors).map(error => error?.message ?? '').join(', ');
            toast.error(`Sprawdź formularz: ${errorMessages}`);
        }
    }, [errors]);

    function fillTestData() {
        if (clientInvestment?.state === 3) {
            const testData: Partial<InvestmentFormValues> = {
                id: clientInvestment?.id,
                publicId: clientInvestment?.publicId?.toString(),
                state: 5,
                isActive: true,
                isLastModify: true,
                investmentName: clientInvestment?.investmentName || "Test investmentName",
                clientComment: clientInvestment?.clientComment || "Test clientComment",
                competition: clientInvestment?.competition || "Test competition",
                clientInstitutionName: "Test clientInstitutionName",
                clientLastname: "Test clientLastname",
                clientFirstname: "Test clientFirstname",
                clientPhone: clientInvestment?.userPhone || "Test clientPhone",
                clientEmail: clientInvestment?.userEmail || "Test clientEmail",
                clientStreet: "Test clientStreet",
                clientBuilding: "Test clientBuilding",
                clientPremises: "Test clientPremises",
                clientZip: "Test clientZip",
                clientCity: "Test clientCity",
                clientState: states[0],
                clientCountry: "Test clientCountry",
                clientNIP: "Test clientNIP",
                clientREGON: "Test clientREGON",
                clientKRS: "Test clientKRS",
                payerInstitutionName: "Test payerInstitutionName",
                payerRepresentative: "Test payerRepresentative",
                payerNIP: "Test payerNIP",
                payerStreet: "Test payerStreet",
                payerBuilding: "Test payerBuilding",
                payerPremises: "Test payerPremises",
                payerZip: "Test payerZip",
                payerCity: "Test payerCity",
                payerState: states[0],
                payerCountry: "Test payerCountry",
                projectName: "Test projectName",
                projectMaxBudget: 1234,
                projectDescription: "Test projectDescription"
            };
            reset(testData);
        } else {
            const testData: Partial<InvestmentFormValues> = {
                state: 0,
                isActive: true,
                isLastModify: true,
                investmentName: "Test investmentName",
                clientComment: "Test clientComment",
                competition: "Test competition",
                clientPhone: clientInvestment?.userPhone,
                clientEmail: clientInvestment?.userEmail
            };
            reset(testData);
        }
    }

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn
                title={"Edycja projektu inwestycyjnego"}
                cancelEdit={cancelEdit}
                fillTestData={!!user && user.roles?.includes('Admin') ? fillTestData : undefined}
                isAdmin={!!user && user.roles?.includes('Admin')}
            />

            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    {clientInvestment ? (
                        <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={6}>
                                <Typography variant="h6">Nr projektu: {clientInvestment.publicId}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">Zamawiający: {clientInvestment.userDisplayName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">State: {clientInvestment.state}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='investmentName' label='Temat projektu' />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='clientComment' label='Krótki komentarz/opis' multiline rows={3} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='competition' label='Rodzaj naboru/konkursu lub link do naboru' multiline rows={3} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppDateTimePicker control={control} name='submissionDeadline' label='Termin składania wniosków' disabled={false} />
                        </Grid>
                    </Grid>
                    {clientInvestment?.state === 3 && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <Divider sx={{ mt: 3 }} />
                                <Typography variant="h4" sx={{ mt: 3 }}>
                                    Dane klienta:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppTextInput control={control} name='clientInstitutionName' label='Nazwa instytucji' />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppTextInput control={control} name='clientLastname' label='Nazwisko' />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppTextInput control={control} name='clientFirstname' label='Imię' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppTextInput control={control} name='clientPhone' label='Telefon' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppTextInput control={control} name='clientEmail' label='Email' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppTextInput control={control} name='clientStreet' label='Ulica' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='clientBuilding' label='Budynek' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='clientPremises' label='Nr lokalu' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='clientZip' label='Kod pocztowy' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='clientCity' label='Miasto' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppSelectStringsList control={control} name='clientState' label='Województwo' items={states} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='clientCountry' label='Kraj' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppTextInput control={control} name='clientNIP' label='NIP' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppTextInput control={control} name='clientREGON' label='REGON' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppTextInput control={control} name='clientKRS' label='KRS' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider sx={{ mt: 3 }} />
                                <Typography variant="h4" sx={{ mt: 3 }}>
                                    Dane płatnika:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AppTextInput control={control} name='payerInstitutionName' label='Nazwa instytucji płatnika' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppTextInput control={control} name='payerRepresentative' label='Przedstawiciel' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppTextInput control={control} name='payerNIP' label='NIP płatnika' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppTextInput control={control} name='payerStreet' label='Ulica' />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='payerBuilding' label='Budynek' />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='payerPremises' label='Nr lokalu' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='payerZip' label='Kod pocztowy' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='payerCity' label='Miasto' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppSelectStringsList control={control} name='payerState' label='Województwo' items={states} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AppTextInput control={control} name='payerCountry' label='Kraj' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider sx={{ mt: 3 }} />
                                <Typography variant="h4" sx={{ mt: 3 }}>
                                    Dane projektu:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AppTextInput control={control} name='projectName' label='Nazwa projektu' />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppDateTimePicker control={control} name='projectDateStart' label='Data rozpoczęcia projektu' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppDateTimePicker control={control} name='projectDateEnd' label='Data końca projektu' disabled={false} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AppNumberInput control={control} name='projectMaxBudget' label='Maksymalny budżet' />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AppTextInput control={control} name='projectDescription' label='Opis projektu' disabled={false} multiline rows={4} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider sx={{ mt: 3 }} />
                                <Typography variant="h4" sx={{ mt: 3 }}>
                                    Inne:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AppTextInput control={control} name='clientExperience' label='Doświadczenie klienta' />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AppTextInput control={control} name='clientPotential' label='Potencjał klienta' disabled={false} />
                            </Grid>
                        </Grid>
                    )}

                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelEdit} variant='outlined' color='primary'>Anuluj</Button>
                        <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                    </Box>
                </form>
            </Box >
        </>
    );
}