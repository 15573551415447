import { Button, Divider, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useHistoryTracker } from "../context/NavigationHistoryContext";
import { useAppDispatch } from "../store/configureStore";
import { fetchClientOrdersAsync } from "../../features/client/orders/clientOrdersSlice";
import { fetchAdminOrdersAsync } from "../../features/admin/orders/adminOrdersSlice";

export default function ServerError() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { history } = useHistoryTracker();
    const dispatch = useAppDispatch();

    const handleBack = () => {
        // Sprawdzenie, czy historia zawiera co najmniej dwa wpisy
        if (history.length >= 2) {
            const previousPath = history[history.length - 2]; // przedostatni wpis w historii

            if (previousPath === '/client/orders') {
                navigate(-1);
                dispatch(fetchClientOrdersAsync());
            } else if (previousPath === '/admin/orders') {
                navigate(-1);
                dispatch(fetchAdminOrdersAsync());
            } else {
                navigate(-1);
            }
        } else {
            navigate(-1);
        }
    };
    return (
        <Container component={Paper}>
            {state?.error ? (
                <>
                    <Typography variant="h3" color="error" gutterBottom>{state.error.title}</Typography>
                    <Divider />
                    <Typography>{state.error.detail || 'Błąd wewnętrzny serwera'}</Typography>
                </>
            ) : (
                <Typography variant="h5" gutterBottom>Błąd serwera</Typography>
            )}
            <Button onClick={handleBack}>Powróć do ostatniej strony</Button>
        </Container>
    )
}