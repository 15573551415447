import { useEffect } from "react";
import { fetchPossibleSubservices, fetchServicesAsync, fetchServicesFilters, fetchSubservicesFromService, servicesSelectors } from "../../features/services/servicesSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useServices() {
  const services = useAppSelector(servicesSelectors.selectAll);
  const { servicesLoaded, servicesFiltersLoaded, possibleSubservices, possibleSubservicesLoaded, metaData } = useAppSelector(state => state.services);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!servicesLoaded) dispatch(fetchServicesAsync());
  }, [servicesLoaded, dispatch])

  useEffect(() => {
    if (!servicesFiltersLoaded) dispatch(fetchServicesFilters());
  }, [servicesFiltersLoaded, dispatch])

  useEffect(() => {
    if (!possibleSubservicesLoaded) dispatch(fetchPossibleSubservices());
  }, [possibleSubservicesLoaded, dispatch])

  useEffect(() => {
    if (services.length > 0 && !servicesLoaded) {
      services.forEach(service => {
        if (service.subservices) {
          dispatch(fetchSubservicesFromService(service.subservices));
        }
      });
    }
  }, [services, servicesLoaded, dispatch]);

  return {
    services,
    servicesLoaded,
    servicesFiltersLoaded,
    possibleSubservices,
    possibleSubservicesLoaded,
    //brands,
    //types,
    metaData
  }
}