import * as yup from 'yup';

export const checkoutAccountSchemaValidation = [
    yup.object({
        lastName: yup.string().required('Podanie nazwiska jest wymagane').nullable(),
        firstName: yup.string().required('Podanie imienia jest wymagane').nullable(),
        phone: yup.string().required('Podanie telefonu jest wymagane').nullable(),
        addressStreet: yup.string().required('Podanie ulicy jest wymagane').nullable(),
        addressBuilding: yup.string().required('Podanie budynku jest wymagane').nullable(),
        // addressPremises: yup.string().required('Podanie lokalu jest wymagane').nullable(),
        addressZip: yup.string().required('Podanie kodu pocztowego jest wymagane').nullable(),
        addressCity: yup.string().required('Podanie miejscowości jest wymagane').nullable(),
        addressState: yup.string().required('Podanie województwa jest wymagane. Musimy wiedzieć, gdzie ma siedzibę oddziała NFZ, by Cię ubezpieczyć.').nullable(),
        addressCountry: yup.string().required('Podanie kraju jest wymagane').nullable(),
    }),
    yup.object(),
    yup.object()
]

export const checkoutAccountSchemaValidation02 = [
    yup.object({
        lastName: yup.string().required('Podanie nazwiska jest wymagane').nullable(),
        firstName: yup.string().required('Podanie imienia jest wymagane').nullable(),
        phone: yup.string().required('Podanie telefonu jest wymagane').nullable()
    })
]