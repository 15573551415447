import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Typography, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent, Alert, LinearProgress, Modal } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import NotFound from "../../../app/errors/NotFound";
import OrderHelpInOrderChat from "./AdminOrderHelpInOrderChat";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppTimeline, { Align, TimeLineElement } from "../../../app/components/AppTimeLine";
import { adminOrdersSelectors, fetchAdminOrderAsync, fetchAdminOrdersAsync, setAdminOrder } from "./adminOrdersSlice";
import { useForm, FieldValues } from "react-hook-form";
import agent from "../../../app/api/agent";
import { toast } from "react-toastify";
import { currencyFormat, formatToLocalDate, formatToLocalTime, getOrderStatusColor, getOrderStatusTitle } from "../../../app/util/util";
import { Order, OrderState } from "../../../app/models/order";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { fetchClientOrderAsync, setClientOrder } from "../../client/orders/clientOrdersSlice";
import AppReturn from "../../../app/components/AppReturn";
import AdminOrderScanUploadForm from "./AdminOrderScanUploadForm";
import { LoadingButton } from "@mui/lab";
import DownloadAndPreviewButtons from "../../../app/components/AppDownloadAndPreviewButtons";
import { ParticipantsList } from "../../../app/components/Order/OrderParticipantsList";
import AppDatePicker from "../../../app/components/AppDatePicker";
import dayjs from "dayjs";

export default function AdminOrderDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const order = useAppSelector(state => adminOrdersSelectors.selectById(state, id!));
    const { status: orderStatus } = useAppSelector(state => state.adminOrders);
    const [loading, setLoading] = useState(false);
    const [isTestAvailable, setIsTestAvailable] = useState(order?.isTestAvailable ? "Tak" : "Nie");
    const [timeLineElements, setTimeLineElements] = useState<TimeLineElement[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdatingTestToOrder, setIsUpdatingTestToOrder] = useState(false);
    const [isRejecting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { control, handleSubmit } = useForm<FieldValues>();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loadingAvailability, setLoadingAvailability] = useState(false);
    const [hasTestPath, setHasTestPath] = useState(false);
    const [hasKeyTestPath, setHasKeyTestPath] = useState(false);
    const user = useAppSelector(state => state.account.user);
    const isSuperAdmin = !!(user && user.roles?.includes('SuperAdmin'));
    const navigate = useNavigate();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    function getStatusIcon(currentState: number) {
        switch (currentState) {
            case 0:
                return <HourglassTopIcon />;
            case 1:
                return <HourglassTopIcon />;
            case 2:
                return <DoneIcon />;
            case 3:
                return <DoneIcon />;
            case 4:
                return <HelpOutlineIcon />;
            case 5:
                return <CancelIcon />;
            default:
                return <ErrorIcon />;
        }
    }

    useEffect(() => {
        if (!order) dispatch(fetchAdminOrderAsync(parseInt(id!)))
    }, [id, dispatch, order]);

    useEffect(() => {
        if (order) {
            setIsTestAvailable(order.isTestAvailable ? "Tak" : "Nie");
        }
    }, [order]);

    useEffect(() => {
        const fetchHistory = async () => {
            if (order) {
                setLoading(true);
                try {
                    const historyOfOrder: Order[] = await agent.Admin.Orders.getHistoryOfOrder(order.publicId);

                    const timelineData: TimeLineElement[] = [];
                    const statusTracker: { [key: number]: boolean } = {};

                    historyOfOrder.forEach(h => {
                        const isFirstOccurrence = !statusTracker[h.orderState];
                        statusTracker[h.orderState] = true;

                        timelineData.push({
                            dateAndTimeToDisplay: new Date(h.updatingDateTime).toLocaleString(),
                            align: Align.Left,
                            header: h.updatingUserIsAdmin == true ? 'Administrator' : 'Klient',
                            subtitle: isFirstOccurrence ? getOrderStatusTitle(h.orderState) : 'Edytowano',
                            icon: getStatusIcon(h.orderState),
                            iconColor: getOrderStatusColor(h.orderState)
                        });
                    });

                    setTimeLineElements(timelineData);
                } catch (error) {
                    console.error("Failed to fetch investment history:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchHistory();
    }, [order]);

    useEffect(() => {
        async function checkTestAvailability() {
            if (order && order.serviceId) {
                try {
                    setIsLoading(true); // Set loading to true before checking
                    const response = await agent.Admin.Orders.checkIfTestAndKeyTestIsInService(order.serviceId);
                    if (response) {
                        setHasTestPath(response.hasTestPath);
                        setHasKeyTestPath(response.hasKeyTestPath);
                    }
                } catch (error) {
                    console.error("Error checking test availability:", error);
                } finally {
                    setIsLoading(false); // Set loading to false after checking
                }
            } else {
                setIsLoading(false); // Set loading to false if order or serviceId is not available
            }
        }

        checkTestAvailability();
    }, [order, order?.packageTestPath, order?.serviceId]);

    if (orderStatus.includes('pending')) return <LoadingComponent />

    if (!order) return <NotFound />

    async function handleSubmitData(formData: FieldValues) {
        console.log(formData);
        setLoading(true);
        try {
            if (order) {
                const adjustedExamDate = dayjs(formData.examDate).add(2, 'hour').toISOString();

                await agent.Admin.Orders.approveExam(order.publicId, adjustedExamDate);
                toast.success('Zatwierdzono egzaminy');
                dispatch(fetchAdminOrderAsync(parseInt(id!)));
                dispatch(fetchClientOrderAsync(parseInt(id!)));
            } else {
                toast.error('Wystąpił błąd');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
        handleCloseModal();
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteOrder = async () => {
        setIsDeleting(true);
        try {
            await agent.Admin.Orders.delete(order.publicId);
            toast.success('Usunięto zamówienie');
            dispatch(fetchAdminOrdersAsync());
        } catch (error) {
            console.error('Wystąpił błąd podczas anulowania zamówienia:', error);
            toast.error('Nie udało się usunąć zamówienia.');
        } finally {
            setIsDeleting(false);
            handleCloseDeleteDialog();
            navigate("/admin/orders");
        }
    }

    const handleConfirmCancel = async () => {
        setIsCancelling(true);
        try {
            await agent.Admin.Orders.cancelOrder(order.publicId);
            toast.success('Zamówienie zostało anulowane.');
            dispatch(fetchAdminOrderAsync(parseInt(id!)));
        } catch (error) {
            console.error('Wystąpił błąd podczas anulowania zamówienia:', error);
            toast.error('Nie udało się anulować zamówienia.');
        } finally {
            setIsCancelling(false);
            handleCloseDialog();
        }
    };

    const handleRejectCancel = async () => {
        setIsCancelling(true);
        try {
            await agent.Admin.Orders.rejectCancellation(order.publicId);
            toast.success('Zapytanie o anulowanie zamówienia zostało odrzucone.');
            dispatch(fetchAdminOrderAsync(parseInt(id!)));
        } catch (error) {
            console.error('Wystąpił błąd podczas odrzucania anulowania zamówienia:', error);
            toast.error('Nie udało się odrzucić anulowania zamówienia.');
        } finally {
            setIsCancelling(false);
            handleCloseDialog();
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleUpdateServiceToOrder() {
        setIsUpdatingTestToOrder(true);
        try {
            await agent.Admin.Orders.updateServiceToOrder(parseInt(id!));
            toast.success("Test został dodany do zamówienia.");
            dispatch(fetchAdminOrderAsync(parseInt(id!)));
            dispatch(setAdminOrder(parseInt(id!)));
        } catch (error) {
            toast.error("Nie udało się dodać testu do zamówienia.");
            console.error("Error updating service to order:", error);
        } finally {
            setIsUpdatingTestToOrder(false);
        }
    }

    const handleChangeAvailability = async (event: SelectChangeEvent<string>) => {
        const previousValue = isTestAvailable;  // Store the previous value
        const newValue = event.target.value === "Tak";
        setIsTestAvailable(event.target.value);
        setLoadingAvailability(true);

        try {
            const response = await agent.Admin.Orders.changeAvailabilityFlag(parseInt(id!, 10), { isTestAvailable: newValue });

            if (response) {
                toast.success("Zmieniono dostępność formularza.");
                dispatch(setAdminOrder(response));
                dispatch(setClientOrder(response));
            } else {
                throw new Error('Niewłaściwa odpowiedź serwera.');
            }
        } catch (error) {
            console.error('Wystąpił błąd podczas zmiany dostępności testu:', error);
            toast.error('Nie udało się udostępnić testu.');
            setIsTestAvailable(previousValue);
        } finally {
            setLoadingAvailability(false);
        }
    };

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Zamówienie #" + order.publicId} backPath="/admin/orders" />

            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%', mb: 2 }}>
                <FormControl variant="outlined" sx={{ minWidth: 140 }}>
                    <InputLabel>Udostępnienie testu</InputLabel>
                    <Select
                        value={isTestAvailable}
                        onChange={handleChangeAvailability}
                        label="Udostępnienie testu"
                        disabled={loadingAvailability || order.packageTestPath == '' || order.packageTestPath == null || order.packageTestPath == undefined}
                    >
                        <MenuItem value="Tak">Tak</MenuItem>
                        <MenuItem value="Nie">Nie</MenuItem>
                    </Select>
                    {loadingAvailability && <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', right: '50%', marginTop: '-12px', marginRight: '-12px' }} />}
                </FormControl>
                {isSuperAdmin ? (
                    <Button
                        onClick={handleOpenDeleteDialog}
                        color="error"
                        variant='contained'
                        size='large'
                        sx={{}}
                    >
                        Usuń zamówienie
                    </Button>
                ) : ''
                }
                <Button
                    onClick={handleOpenDialog}
                    color="error"
                    variant='outlined'
                    size='large'
                    disabled={order.orderState == 5 ? true : false}
                    sx={{ mr: { xs: 0, md: 2 } }}
                >
                    {order.orderState == 4 ? 'Rozwiąż prośbę' : 'Anuluj zamówienie'}
                </Button>
            </Box>

            {isLoading || isUpdatingTestToOrder ? (
                <LinearProgress sx={{ mb: 2, mr: 2 }} />
            ) : (
                <>
                    {hasTestPath && hasKeyTestPath && !order.packageTestPath && !order.packageTestKeyPath && (
                        <Alert sx={{ mb: 2, mr: 2 }} severity="info">
                            Dostępny jest test i klucz odpowiedzi dla usługi "{order.serviceName}".{' '}
                            <a href="#" onClick={handleUpdateServiceToOrder}>Kliknij tutaj, aby dodać test i klucz odpowiedzi do zamówienia.</a>
                        </Alert>
                    )}
                    {hasTestPath && !order.packageTestPath && (
                        <Alert sx={{ mb: 2, mr: 2 }} severity="info">
                            Dostępny jest test dla usługi "{order.serviceName}".{' '}
                            <a href="#" onClick={handleUpdateServiceToOrder}>Kliknij tutaj, aby dodać test do zamówienia.</a>
                        </Alert>
                    )}
                    {hasKeyTestPath && !order.packageTestKeyPath && (
                        <Alert sx={{ mb: 2, mr: 2 }} severity="info">
                            Dostępny jest klucz odpowiedzi dla usługi "{order.serviceName}".{' '}
                            <a href="#" onClick={handleUpdateServiceToOrder}>Kliknij tutaj, aby dodać klucz odpowiedzi do zamówienia.</a>
                        </Alert>
                    )}
                    {!order.packageTestPath && !hasTestPath ? (
                        <Box>
                            <Alert severity="error" sx={{ mr: { xs: 0, md: 2 }, mb: 2 }}>
                                Usługa nie posiada wzoru arkusza egzaminu. Udostępnienie testów będzie możliwe po dodaniu arkusza do odpowiedniej usługi. {' '}
                                <a href={'/services/' + order.serviceId}>Przejdź do usługi</a>
                            </Alert>
                        </Box>
                    ) : null}
                    {!order.packageTestKeyPath && !hasKeyTestPath ? (
                        <Box>
                            <Alert severity="warning" sx={{ mr: { xs: 0, md: 2 }, mb: 2 }}>
                                Usługa nie posiada klucza odpowiedzi. Jeżeli chcesz go doodać to {' '}
                                <a href={'/services/' + order.serviceId}>przejdź do usługi.</a>
                            </Alert>
                        </Box>
                    ) : null}
                </>
            )}

            <Grid container>
                <Grid item md={7} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Podstawowe informacje'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.customeClientServiceName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa wskazana przez Klienta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.serviceName}
                                    </Typography>
                                    <Typography variant="overline">
                                        bazowa nazwa
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {formatToLocalDate(order.orderDateTime.toString())} {formatToLocalTime(order.orderDateTime.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        data i godzina złożenia zamówienia
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {formatToLocalDate(order.orderDateTime.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        proponowana data realizacji egzaminu
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.trainingProgram}
                                    </Typography>
                                    <Typography variant="overline">
                                        wskazany program szkolenia (jeżeli dotyczy)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.clientComment}
                                    </Typography>
                                    <Typography variant="overline">
                                        komentarz Klienta
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Cena'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {currencyFormat(order.totalDiscountedPrice)}
                                    </Typography>
                                    <Typography variant="overline">
                                        wartość zamówienia
                                    </Typography>
                                    <Typography variant="h5">
                                        {currencyFormat(order.unitDiscountedPrice)}
                                    </Typography>
                                    <Typography variant="overline">
                                        cena jedostkowa
                                    </Typography>
                                    <Typography variant="h5">
                                        {order.totalItem}
                                    </Typography>
                                    <Typography variant="overline">
                                        liczba osób
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Dane Klienta'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.userDisplayName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa Klienta
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h6">
                                        {order.userEmail}
                                    </Typography>
                                    <Typography variant="overline">
                                        email
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {order.userPhone}
                                    </Typography>
                                    <Typography variant="overline">
                                        telefon kontaktowy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.trainingProgram}
                                    </Typography>
                                    <Typography variant="overline">
                                        wskazany program szkolenia (jeżeli dotyczy)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.clientComment}
                                    </Typography>
                                    <Typography variant="overline">
                                        komentarz Klienta
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Dane Płatnika'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.payerClientName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa Płatnika
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.payerClientAddress}
                                    </Typography>
                                    <Typography variant="overline">
                                        adres Płatnika
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.payerClientNIP ? order.payerClientNIP : order.payerNIP}
                                    </Typography>
                                    <Typography variant="overline">
                                        NIP Płatnika
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Historia'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                                        <CircularProgress />
                                    </Box>
                                ) : <AppTimeline elements={timeLineElements} />}
                            </Grid>
                        </Grid>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mr: { xs: 0, md: 2 }, mb: 2 }}>
                        <CardHeader title='Korespondencja'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>
                                <OrderHelpInOrderChat orderId={parseInt(id!)} />
                            </Grid>
                        </Grid>
                    </Card>

                    {order.packageTestPath || order.packageTestKeyPath ? (
                        <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                            <CardHeader title='Pobierz arkusze egzaminacyjne'
                                titleTypographyProps={{
                                    sx: { fontWeight: 'bold', color: 'secondary' }
                                }} />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {order.packageTestPath ?
                                            <>
                                                <DownloadAndPreviewButtons order={{
                                                    path: order.packageTestPath
                                                }} count={false} />
                                                <Typography variant="overline">
                                                    egzamin
                                                </Typography>
                                            </> : ''
                                        }
                                        {order.packageTestPath && order.packageTestKeyPath && <Divider sx={{ mt: 1, mb: 2 }} />}
                                        {order.packageTestKeyPath ?
                                            <>
                                                <DownloadAndPreviewButtons order={{
                                                    path: order.packageTestKeyPath
                                                }} count={false} />
                                                <Typography variant="overline">
                                                    klucz odpowiedzi
                                                </Typography>
                                            </> : ''
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : <Alert severity="info" sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        W tym miejscu możliwe będzie pobranie testów egzaminacyjnych.
                    </Alert>
                    }

                    {order.solvedTestsPaths != null && order.solvedTestsPaths != '' ?
                        (
                            <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                                <CardHeader title='Lista uczestników'
                                    titleTypographyProps={{
                                        sx: { fontWeight: 'bold', color: 'secondary' }
                                    }} />
                                <Divider />
                                <CardContent>
                                    <ParticipantsList benefitients={order.benefitients} />
                                </CardContent>
                                <Divider />
                                <CardContent>
                                    {order.isExamApproved ? <Alert severity="info" sx={{ mb: 1 }}>Egzaminy zostały już zatwierdzone</Alert> : ''}
                                    <LoadingButton
                                        loading={loading}
                                        onClick={handleOpenModal}
                                        disabled={!(order.orderState === OrderState.SCANS_ACCEPTED || order.orderState === OrderState.SCANS_ADDED)}
                                        variant='outlined'
                                    >
                                        {order.isExamApproved ? 'Edytuj datę egzaminów' : 'Zatwierdź egzaminy uczestników'}
                                    </LoadingButton>
                                </CardContent>
                            </Card>
                        ) : ''}

                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Przesłane skany'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <DownloadAndPreviewButtons order={{
                                path: order.solvedTestsPaths
                            }} count={true} />
                        </CardContent>
                        <Divider />
                        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                sx={{ height: '40px' }}
                                variant='outlined'
                                onClick={handleOpen}
                                size="small"
                                title='Załącz'
                                color='success'
                            >
                                Załącz
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <AdminOrderScanUploadForm order={order} cancelUpload={handleClose} />
                </DialogContent>
            </Dialog>

            {/* <AdminExamApproval
                open={openModal}
                onClose={handleCloseModal}
                handleSubmitData={handleSubmitData}
                control={control}
                loading={loading}
                order={order}
            /> */}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Potwierdzenie zatwierdzenia egzaminu
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ my: 2 }}>
                        Wybierz datę egzaminu:
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitData)}>
                        <AppDatePicker
                            label="Data egzaminu"
                            disabled={false}
                            name="examDate"
                            control={control}
                            rules={{ required: 'Data egzaminu jest wymagana' }}
                        />
                        <Box sx={{ mt: 2 }}>
                            <LoadingButton loading={loading} type='submit' variant='outlined' color='success'>
                                Zapisz
                            </LoadingButton>
                            <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ ml: 2 }}>
                                Anuluj
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box width='100%' display="flex" justifyContent='center'>
                        {"Anulowanie zamówienia"}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {order.orderState == 4 ? 'Klient rozpoczął proces zapytania o anulowanie zamówienia, czy potwierdzasz anulowanie zamówienia?'
                            : order.isPaid ? 'Czy na pewno chcesz anulować opłacone zamówienie?' : 'Czy chcesz anulować zamówienie?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box width='100%' display="flex" justifyContent='space-between'>
                        {order.orderState !== 4 ? (
                            <>
                                <Button onClick={handleCloseDialog} variant='outlined' sx={{ ml: 2 }}>Nie</Button>
                                <LoadingButton
                                    onClick={handleConfirmCancel}
                                    autoFocus
                                    variant='outlined'
                                    color='error'
                                    loading={isCancelling}
                                    sx={{ mr: 2 }}
                                >
                                    Tak
                                </LoadingButton>
                            </>
                        ) : (
                            <>
                                <LoadingButton
                                    onClick={handleRejectCancel}
                                    variant='outlined'
                                    color="success"
                                    loading={isRejecting}
                                    sx={{ ml: 2 }}
                                >
                                    Odrzucam zapytanie
                                </LoadingButton>

                                <LoadingButton
                                    onClick={handleConfirmCancel}
                                    variant='outlined'
                                    color='error'
                                    loading={isCancelling}
                                    sx={{ mr: 2 }}
                                >
                                    Anuluję zamówienie
                                </LoadingButton>
                            </>
                        )}
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box width='100%' display="flex" textAlign='center' justifyContent='center'>
                        {"Usuwanie zamówienia #" + order.publicId}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box width='100%' display="flex" flexDirection="column" justifyContent="center">
                        <DialogContentText id="alert-dialog-description" textAlign="center">
                            Czy chesz usunąć zamówienie o numerze #{order.publicId}?
                        </DialogContentText>
                        <Alert severity="error" sx={{ mt: 2, p: 1, width: '100%', textAlign: 'center' }}>
                            Usunięcie zamówienia jest nieodwracalne!
                        </Alert>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box width='100%' display="flex" justifyContent='space-between'>
                        <Button onClick={handleCloseDeleteDialog} variant='outlined' sx={{ ml: 2 }}>Nie</Button>
                        <LoadingButton
                            onClick={handleDeleteOrder}
                            autoFocus
                            variant='contained'
                            color='error'
                            loading={isDeleting}
                            sx={{ mr: 2 }}
                        >
                            Tak
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}