import { LoadingButton } from "@mui/lab";
import { Box, Paper, Typography, Grid, Button, Divider } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import agent from "../../../app/api/agent";
import AppTextInput from "../../../app/components/AppTextInput";
import { User } from "../../../app/models/user";
import { useAppDispatch } from "../../../app/store/configureStore";
import { toast } from "react-toastify";
import { AdminUser } from "../../../app/models/adminUser";
import { setAdminUser } from "./adminUsersSlice";
import AppSelectStringsList from "../../../app/components/AppSelectStringsList";
import { states } from "../../../app/util/util";
import { userValidationSchema } from "./adminUserValidation";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
    adminUser?: AdminUser;
    cancelEdit: () => void;
}

export default function AdminUserForm({ adminUser, cancelEdit }: Props) {
    const { control, reset, handleSubmit, formState: { isDirty, isSubmitting } } = useForm({
        resolver: yupResolver(userValidationSchema)
    });
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (adminUser && !isDirty) reset(adminUser);
    }, [adminUser, reset, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        try {
            let response: User;
            if (adminUser) {
                response = await agent.Admin.Users.update(data);
                toast.success('Uaktualniono dane użytkownika');
            } else {
                response = await agent.Admin.Users.create(data);
                toast.success('Dodano użytkownika');
            }
            dispatch(setAdminUser(response));
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error('Wystąpił błąd podczas zapisywania danych');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Dane użytkownika
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                    Informacje podstawowe
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='lastName' label='Nazwisko' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='firstName' label='Imie' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='phone' label='Telefon' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='pesel' label='Pesel' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <AppTextInput control={control} name='comment' label='Komentarz' rows={3} multiline={true} />
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 4 }} />
                <Typography variant='h6' sx={{ mb: 2, mt: 2 }}>
                    Dane adresowe
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='addressStreet' label='Ulica' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <AppTextInput control={control} name='addressBuilding' label='Nr budynku' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <AppTextInput control={control} name='addressPremises' label='Nr lokalu' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <AppTextInput control={control} name='addressZip' label='Kod pocztowy' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <AppTextInput control={control} name='addressCity' label='Miasto' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <AppSelectStringsList control={control} name='addressState' label='Województwo' items={states} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <AppTextInput control={control} name='addressCountry' label='Kraj' />
                    </Grid>
                </Grid>

                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='outlined' color='inherit'>Anuluj</Button>
                    <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                </Box>
            </form>
        </Box>
    );
}