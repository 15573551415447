import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AppTextInput from "../../../app/components/AppTextInput";
import { Order } from "../../../app/models/order";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppReturn from "../../../app/components/AppReturn";
import { AdminOrderAddInvoiceFormValues } from "../../../app/models/adminOrderAddInvoiceFormValues";
import { adminOrderAddInvoiceValidationSchema } from "./adminOrderAddInvoiceValidation";
import AppNumberInput from "../../../app/components/AppNumberInput";
import OrderValueBox from "../../../app/components/Order/OrderValueBox";
import { useEffect, useState } from "react";
import { orderFormCalculateSum } from "../../../app/util/util";
import { fetchAdminOrdersAsync } from "./adminOrdersSlice";
import { useAppDispatch } from "../../../app/store/configureStore";
import agent from "../../../app/api/agent";

interface Props {
    order?: Order;
    cancelAddInvoice: () => void;
}

export default function AdminOrderAddInvoiceForm({ order, cancelAddInvoice }: Props) {
    const { control, handleSubmit, formState: { isValid, isSubmitting }, watch  } = useForm<AdminOrderAddInvoiceFormValues>({
        resolver: yupResolver(adminOrderAddInvoiceValidationSchema),
        defaultValues: {
            isDebug: false,
            orderId: order?.id,
            customeClientServiceName: order?.customeClientServiceName,
            payerClientName: order?.payerClientName,
            payerClientAddress: order?.payerClientAddress,
            payerClientNIP: order?.payerClientNIP,
            unitBasePrice: order?.unitBasePrice,
            unitDiscountedPrice: order?.unitDiscountedPrice,
            totalItem: order?.totalItem
        }
    });
    const unitDiscountedPrice = watch('unitDiscountedPrice');
    const totalItem = watch('totalItem');
    const [sum, setSum] = useState<number>(0);

    useEffect(() => {
        setSum(orderFormCalculateSum(totalItem, unitDiscountedPrice));
    },[totalItem, unitDiscountedPrice])

    const dispatch = useAppDispatch();
    async function handleSubmitData(data: AdminOrderAddInvoiceFormValues) {
        try {
                const response = await agent.BizInApi.addInvoice(data);
                if (response) toast(response)
                toast.success('FV została dodana');
            dispatch(fetchAdminOrdersAsync());
            cancelAddInvoice();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Wystawianie FV"} cancelAddInvoice={cancelAddInvoice} />
            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Dane do FV</Typography>
                    <Grid container spacing={3} marginBottom={3}>
                        <Grid item xs={12}>
                            <AppTextInput control={control} name='customeClientServiceName' label='Nazwa Egzaminu, jaka będzie widniała na FV' />
                        </Grid>
                        <Grid item xs={12}>
                            <AppTextInput control={control} name='payerClientName' label='Nazwa Klienta' />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <AppTextInput control={control} name='payerClientAddress' label='Adres Klienta' />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AppTextInput control={control} name='payerClientNIP' label='NIP Klienta' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppNumberInput control={control} name='unitBasePrice' label='Cena bazowa za jedną osobę' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppNumberInput control={control} name='unitDiscountedPrice' label='Cena po rabacie za jedną osobę' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppNumberInput control={control} name='totalItem' label='Liczba egzaminowanych osób' />
                        </Grid>
                    </Grid>
                    <OrderValueBox sum={sum} unitDiscountedPrice={unitDiscountedPrice} totalItem={totalItem} />
                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelAddInvoice} variant='outlined' color='primary'>Anuluj</Button>
                        <LoadingButton loading={isSubmitting} disabled={!isValid} type='submit' variant='outlined' color='success'>Nawiąż połączenie z BizIn i Zapisz</LoadingButton>
                    </Box>
                </form>
            </Box>
        </>
    )
}