import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ChatComment } from "../../../app/models/comment";
import { RootState } from "../../../app/store/configureStore";

const commentsAdapter = createEntityAdapter<ChatComment>();

export const adminHelpInOrderCommentsSlice = createSlice({
    name: "adminHelpInOrderComments",
    initialState: commentsAdapter.getInitialState({
        status: 'idle',
        helpInOrderCommentsLoaded: false,
        selectedOrder: 0,
        messages: []
    }),
    reducers: {
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload;
        },
        setComments: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        },
        updateMessage: (state, action) => {
            commentsAdapter.upsertOne(state, action.payload);
        },
        loadMessage: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        }
    }
});

export const helpInOrderCommentsSelectors = commentsAdapter.getSelectors((state: RootState) => state.adminHelpInOrderComments);

export const {setSelectedOrder, updateMessage, loadMessage, setComments } = adminHelpInOrderCommentsSlice.actions;