import { Box, Typography, useTheme } from "@mui/material";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import DOMPurify from "dompurify";
import { useAppDispatch } from "../../app/store/configureStore";
import useAbouts from "../../app/hooks/useAbouts";
import { useEffect } from "react";
import { fetchAboutByName } from "./aboutsSlice";

export default function Partners() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { abouts } = useAbouts();
    const aboutName = 'partners'; // Przykładowa nazwa, którą chcesz znaleźć
    const about = abouts.find(a => a.name === aboutName); // Wyszukuje po nazwie, jeśli masz pole 'name' w swoich danych

    console.log(about?.text);

    useEffect(() => {
        dispatch(fetchAboutByName(aboutName));
    }, [dispatch, aboutName]);

    let cleanHTML = '';
    if (about?.text) {
        cleanHTML = DOMPurify.sanitize(about?.text);
    }

    return (
        <>
            <AppBreadcrumbs />
            <Box display='flex' justifyContent='space-between'>
                <Typography sx={{ pl: 1, pb: 2, color: theme.palette.text.primary }} variant='h4'>Zaufani partnerzy</Typography>
            </Box>
            <Typography align="justify" gutterBottom paddingLeft={1}>
                <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
            </Typography>
        </>
    )
}