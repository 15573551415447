import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface EditPanelProps {
    title: string;
    fillTestData?: () => void;
    cancelEdit?: () => void;
    cancelAddInvoice?: () => void;
    isAdmin?: boolean;
    setFunction?: () => void;
    backPath?: string;
    addButtonTitle?: string;
}

export default function AppReturn({ title, fillTestData, cancelEdit, cancelAddInvoice, isAdmin = false, setFunction, backPath, addButtonTitle }: EditPanelProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width: 900px)');
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (backPath) {
            navigate(backPath);
        } else {
            cancelEdit?.();
        }
    };

    return (
        <>
            {isMobile ? (
                <>
                    <Typography color={(theme) => theme.palette.text.primary} sx={{ p: 2 }} variant='h4' textAlign='center'>{title}</Typography>
                    {isAdmin && fillTestData && (
                        <Button
                            onClick={fillTestData}
                            sx={{ width: '100%', mb: 2 }}
                            size='large'
                            variant='outlined'>
                            Wypełnij danymi testowymi
                        </Button>
                    )}
                    {setFunction && (
                        <Button
                            onClick={setFunction}
                            sx={{ mb: 2, mr: 2, width: '100%' }}
                            size='large'
                            variant='outlined'>
                            {addButtonTitle ? addButtonTitle : 'Dodaj'}
                        </Button>
                    )}
                    {cancelEdit && (
                        <Button
                            onClick={cancelEdit}
                            sx={{ mb: 2, width: '100%' }}
                            size='large'
                            variant='outlined'>
                            Powrót
                        </Button>
                    )}
                    {cancelAddInvoice && (
                        <Button
                            onClick={cancelAddInvoice}
                            sx={{ mb: 2, width: '100%' }}
                            size='large'
                            variant='outlined'>
                            Powrót
                        </Button>
                    )}
                    {backPath && (
                        <Button
                            onClick={handleBackClick}
                            sx={{ mb: 2, width: '100%' }}
                            size='large'
                            variant='outlined'>
                            Powrót
                        </Button>
                    )}
                </>
            ) : (
                <Box display='flex' justifyContent='space-between' textAlign='center'>
                    <Typography sx={{ color: theme.palette.text.primary }} variant='h4'>{title}</Typography>
                    {isAdmin && fillTestData && (
                        <Button
                            onClick={fillTestData}
                            sx={{ m: 2 }}
                            size='large'
                            variant='outlined'>
                            Wypełnij danymi testowymi
                        </Button>
                    )}
                    <Box>
                        {setFunction && (
                            <Button
                                onClick={setFunction}
                                sx={{ mb: 2, mr: 2 }}
                                size='large'
                                variant='outlined'>
                                {addButtonTitle ? addButtonTitle : 'Dodaj'}
                            </Button>
                        )}
                        {cancelEdit && (
                            <Button
                                onClick={cancelEdit}
                                sx={{ m: 2 }}
                                size='large'
                                variant='outlined'>
                                Powrót
                            </Button>
                        )}
                        {backPath && (
                            <Button
                                onClick={handleBackClick}
                                sx={{ m: 2 }}
                                size='large'
                                variant='outlined'>
                                Powrót
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </>
    )
}