import { Box, Grid, Typography } from "@mui/material";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import logo from '../../app/layout/wechsler_logo_w.svg';
import { useEffect } from "react";
import useAbouts from "../../app/hooks/useAbouts";
import { useAppDispatch } from "../../app/store/configureStore";
import { fetchAboutByName } from "./aboutsSlice";
import DOMPurify from "dompurify";


export default function Institution() {
    const dispatch = useAppDispatch();
    const { abouts } = useAbouts();
    const aboutName = 'institution'; // Przykładowa nazwa, którą chcesz znaleźć
    const about = abouts.find(a => a.name === aboutName); // Wyszukuje po nazwie, jeśli masz pole 'name' w swoich danych

    console.log(about?.text);

    useEffect(() => {
        dispatch(fetchAboutByName(aboutName));
    }, [dispatch, aboutName]);

    let cleanHTML = '';
    if (about?.text) {
        cleanHTML = DOMPurify.sanitize(about?.text);
    }

    return (
        <>
            <AppBreadcrumbs />
            <Box display='flex' justifyContent='space-between'>
                <Typography sx={{ pl: 1, pb: 2 }} variant='h4'>Informacje o placówce</Typography>
            </Box>
            <Grid container>
                <Grid item md={6} padding={1}>
                    <Typography align="justify" gutterBottom paddingLeft={1}>
                        {about?.description}
                    </Typography>
                    <Typography align="justify" gutterBottom paddingLeft={1}>
                        <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
                    </Typography>
                </Grid>
                <Grid item md={6} padding={3}>
                    {<img src={logo} />}
                </Grid>
            </Grid>



        </>
    )
}