import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { toast } from "react-toastify";
import { Subservice } from "../../../app/models/subservice";
import { AppDeleteModalProps } from "../../../app/models/appDeleteModalProps";
import agent from "../../../app/api/agent";
import AppDeleteModal from "../../../app/components/AppDeleteModal";
import { useAppDispatch } from "../../../app/store/configureStore";
import { removeSubservice } from "../subservicesSlice";

interface Props {
    subservice: Subservice;
    handleCardSelectSubservice?: (handledSubservice: Subservice) => void;
}

export default function SubserviceCard({ subservice, handleCardSelectSubservice }: Props) {
    const dispatch = useAppDispatch();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteModalData, setModalData] = useState<AppDeleteModalProps | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    function handleOpenDeleteModal(props: AppDeleteModalProps) {
        setModalData(props);
        setOpenDeleteModal(true);
    }

    function handleCloseDeleteModal() {
        setOpenDeleteModal(false);
    }

    function handleDeleteSubservice(id: number) {
        setLoading(true);
        if (id) {
            agent.Subservices.delete(id)
                .then(() => {
                    console.log(id)
                    dispatch(removeSubservice(id));
                    toast.success('Podusługa została usunięta')
                })
                .catch(error => {
                    toast.error('Podczas usuwania danych wystąpił błąd');
                    console.log(error);
                })
                .finally(() => setLoading(false));
            handleCloseDeleteModal();
            setModalData(undefined);
        }
    }

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="button">
                    {subservice.name}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{ mt: "auto" }}
            >
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    component={Link} to={`/subservices/${subservice.id}`}
                    size="small"
                    title='Więcej'
                >Więcej</Button>
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    onClick={() => handleCardSelectSubservice!(subservice)}
                    size="small"
                    title='Edycja'
                    color='warning'
                >Edycja</Button>
                <LoadingButton
                    variant='outlined'
                    size="large"
                    loading={loading}
                    startIcon={<Delete />} color='error'
                    onClick={
                        () => {
                            handleOpenDeleteModal({
                                itemDescriptionFirstLine: 'Czy na pewno chcesz usunąć podusługę?:',
                                itemDescriptionSecondLine: '# ' + subservice.id + ' ' + subservice.name,
                                handleCloseModal: handleCloseDeleteModal,
                                handleIntDeleteFunction: handleDeleteSubservice,
                                isIntDeleteFunction: true,
                                intId: subservice.id
                            })
                        }}
                    title='Usuń'
                />
                {openDeleteModal ? <AppDeleteModal modalDeleteModalProps={deleteModalData!} /> : ''}
            </CardActions>
        </Card >
    )
}
