import { useRef, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Carousel from 'react-slick';
import TestimonialCard from '../Cards/Testimonial';
import TitleIcon from '../Title/WithIcon';
import SquareParallax from '../Parallax/SingleSquare';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import imgAPI from '../../../temporaryImages/imgAPI.js';
import useStyle from './testiStyle';

const testiContent = [
  {
    text: '"Korzystałem z usług Centrum Egzaminacyjnego certsystem przy ' +
    'przygotowaniach do egzaminu zawodowego. Jestem pod dużym wrażeniem profesjonalizmu i ' +
    'skuteczności całego procesu. Platforma egzaminacyjna była intuicyjna w obsłudze, a materiały egzaminacyjne były bardzo dobrze przygotowane. ' +
    'Cenne było również to, że mogłem zdawać egzamin zdalnie, co zaoszczędziło mi dużo czasu. ' +
    'Po zdaniu egzaminu szybko otrzymałem certyfikat, co było ważne dla mojej dalszej ścieżki zawodowej. '+
    'Polecam to centrum każdemu, kto ceni sobie wysoką jakość usług i elastyczność."',
    avatar: imgAPI.avatar[10],
    name: 'Adam Chyb',
    title: 'adamchyb.pl - Shaping quality',
    rating: 5
  },
  {
    text: '"Jako osoba, która niedawno przeszła cały proces certyfikacji w ' +
    'Centrum Egzaminacyjnego certsystem, muszę przyznać, że jestem pod wrażeniem ich usług. ' +
    'Od pierwszego kontaktu, przez proces rejestracji, aż po sam egzamin - wszystko przebiegało gładko i bez żadnych problemów. ' +
    'Możliwość zdawania egzaminu zdalnie była dla mnie kluczowa, a platforma egzaminacyjna działała bez zarzutu. ' +
    'To, co najbardziej cenię, to szybka i wydajna komunikacja ze strony centrum. ' +
    'Odpowiedzi na moje zapytania były zawsze szybkie i wyczerpujące. Z pewnością skorzystam z ich usług ponownie w przyszłości."',
    avatar: imgAPI.avatar[1],
    name: 'Martyna Filpczak',
    title: 'bonumpublicum.pl',
    rating: 5
  },
  {
    text: '"Zdecydowanie rekomenduję egzaminy prowadzone przez Niepubliczne Centrum Kształcenia Zawodowego Wechsler Polska. ' +
    'Jako uczestnik procesu certyfikacji bardzo wysoko oceniam poziom merytoryczny oraz zaangażowanie, umiejętności i kompetencje osób przeprowadzających egzamin. ' + 
    'Wyrazy uznania należą się także za dyspozycyjność oraz profesjonalną koordynację po stronie firmy."',
    avatar: imgAPI.avatar[2],
    name: 'Mateusz Zych',
    title: 'Mat Max Medica',
    rating: 5
  },
  {
    text: '"Jestem bardzo zadowolona ze współpracy z NCKZ WECHSLER, która trwa już 2 lata. ' +
    'Egzaminy przeprowadzone rzetelnie i co ważne sprawnie. ' +
    'Obsługa zaangażowana i pomocna. Zawsze chętnie wracam i szczerze polecam to centrum egzaminacyjne."',
    avatar: imgAPI.avatar[3],
    name: 'Milena Adamczyk',
    title: 'saudespa.pl',
    rating: 5
  },
  {
    text: '"Niepubliczne Centrum Kształcenia Zawodowego Wechsler Polska w sposób profesjonalny i ' +
    'rzetelny przeprowadził proces certyfikacji posiadanych przeze mnie umiejętności. ' +
    'Całą organizację oceniam na najwyższy poziom. Centrum w mojej ocenie może pochwalić się profesjonalną obsługą klienta i ' +
    'co najważniejsze pomimo tego, że w ich ofercie znalazłem interesujący mnie egzamin to ' +
    'Centrum deklaruje możliwość przygotowania egzaminu z interesującego klienta zagadnienia. Na pewno z tej opcji skorzystam następnym razem!"',
    avatar: imgAPI.avatar[4],
    name: 'Krzysztof Szymański',
    title: 'Lifepass.pl | edumeo.pl',
    rating: 5
  },
  {
    text: '"Korzystamy z usług Centrum już od dwóch lat przy realizacji egzaminów zewnętrzych. ' +
    'Z dostępnej, bardzo szerokiej oferty egzaminów zawsze możemy dobrać pasujący do naszych potrzeb, a w razie potrzeby nie ma problemu z jego modyfikacją. ' +
    'Testy sprawdzane są „od ręki” i cały proces wydania certyfikatów jest bardzo szybki. Polecamy usługi Centrum WECHSLER z pełną odpowiedzialnością."',
    avatar: imgAPI.avatar[6],
    name: 'Damian Hada',
    title: 'kdgroup.pl',
    rating: 5
  }
];

function Testimonials() {
  // Theme breakpoints
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { classes } = useStyle();

  // Carousel Setting
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [{
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }, {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }]
  };

  // Carousel
  const slider = useRef<Carousel>(null);
  useEffect(() => {
    if (theme.direction === 'ltr' && window.innerWidth > 1279) {
      const limit = window.innerWidth > 1400 ? 3 : 2;
      const lastSlide = Math.floor(testiContent.length - limit);
      slider.current?.slickGoTo(lastSlide);
    }
  }, [theme.direction]);

  return (
    <div className={classes.root}>
      <SquareParallax />
      <div className={classes.carousel}>
        <Carousel ref={slider} {...settings}>
          {isDesktop && (
            <div className={classes.item}>
              <div className={classes.itemPropsFirst} />
            </div>
          )}
          {testiContent.map((item, index) => (
            <div key={index.toString()} className={classes.item}>
              <TestimonialCard
                avatar={item.avatar}
                title={item.title}
                name={item.name}
                text={item.text}
                star={item.rating}
              />
            </div>
          ))}
          {isDesktop && (
            <div className={classes.item}>
              <div className={classes.itemPropsLast} />
            </div>
          )}
        </Carousel>
      </div>
      <div className={classes.floatingTitle}>
        <Container fixed>
          <div className={classes.title}>
            <TitleIcon text='Opinie Klientów' icon="" />
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Testimonials;