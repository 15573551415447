import { Grid } from "@mui/material";
import { AppOrderBenefitientsStringsConnector } from "../AppOrderBenefitientsStringsConnector";
import { Control, FieldValues } from "react-hook-form";

interface Props {
    control: Control<FieldValues>;
    fields: Partial<Record<"id", string>>[];
    append: (value: FieldValues | FieldValues[]) => void;
    remove: (index?: number | number[] | undefined) => void;
    handleSetTotalItemRows: (count: number) => void;
}

export default function AppOrderFormBenefitients({ control, fields, append, remove, handleSetTotalItemRows }:Props) {
    return (
        <>
            <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <AppOrderBenefitientsStringsConnector
                        control={control}
                        label='Lista osób egzaminowanych'
                        fields={fields}
                        append={append}
                        remove={remove}
                        handleSetTotalItemRows={handleSetTotalItemRows}
                    />
                </Grid>
            </Grid>
        </>
    )
}