import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { MetaData } from "../../../app/models/pagination";
import { Document, DocumentsParams } from "../../../app/models/document";
import { RootState } from "../../../app/store/configureStore";

interface DocumentsState {
    documentsLoaded: boolean;
    documentsFiltersLoaded: boolean;
    status: string;
    // brands: string[];
    // types: string[];
    documentsParams: DocumentsParams;
    metaData: MetaData | null;
}

const documentsAdapter = createEntityAdapter<Document>();

function getAxiosParams(documentsParams: DocumentsParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', documentsParams.pageNumber.toString());
    params.append('pageSize', documentsParams.pageSize.toString());
    params.append('orderBy', documentsParams.orderBy);
    if (documentsParams.searchTerm) params.append('searchTerm', documentsParams.searchTerm);
    // if (serviceParams.brands.length > 0) params.append('brands', serviceParams.brands.toString());
    // if (serviceParams.types.length > 0) params.append('types', serviceParams.types.toString());
    return params;
}

export const fetchDocumentsAsync = createAsyncThunk<Document[], void, {state: RootState} >(
    'documents/fetchDocumentsAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().documents.documentsParams);
        try {
            const response = await agent.Admin.Documents.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchDocumentAsync = createAsyncThunk<Document, number>(
    'documents/fetchDocumentAsync',
    async (documentId, thunkAPI) => {
        try {
            return await agent.Admin.Documents.details(documentId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchDocumentsFilters = createAsyncThunk(
    'documents/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Admin.Documents.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'date'//,
        // brands: [],
        // types: []
    }
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState: documentsAdapter.getInitialState<DocumentsState>({
        documentsLoaded: false,
        documentsFiltersLoaded: false,
        status: 'idle',
        // brands: [],
        // types: [],
        documentsParams: initParams(),
        metaData: null
    }),
    reducers: {
        setDocumentsParams: (state, action) => {
            state.documentsLoaded = false;
            state.documentsParams = {...state.documentsParams, ...action.payload, pageNumber: 1};
        },
        setDocumentsPageNumber: (state, action) => {
            state.documentsLoaded = false;
            state.documentsParams = {...state.documentsParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetDocumentsParams: (state) => {
            state.documentsParams = initParams();
        },
        setDocument: (state, action) => {
            documentsAdapter.upsertOne(state, action.payload);
            state.documentsLoaded = false;
        },
        removeDocument: (state, action) => {
            documentsAdapter.removeOne(state, action.payload);
            state.documentsLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchDocumentsAsync.pending, (state) => {
                state.status = 'pendingFetchDocuments';
            });
            builder.addCase(fetchDocumentsAsync.fulfilled, (state, action) => {
                documentsAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.documentsLoaded = true;
            });
            builder.addCase(fetchDocumentsAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchDocumentAsync.pending, (state) => {
                state.status = 'pendingFetchDocument';
            });
            builder.addCase(fetchDocumentAsync.fulfilled, (state, action) => {
                documentsAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchDocumentAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchDocumentsFilters.pending, (state) => {
                state.status = 'pendingFetchDocumentsFilters';
            });
            builder.addCase(fetchDocumentsFilters.fulfilled, (state) => {
                // state.brands = action.payload.brands;
                // state.types = action.payload.types; 
                state.documentsFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchDocumentsFilters.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const documentsSelectors = documentsAdapter.getSelectors((state: RootState) => state.documents);

export const {setDocumentsParams, resetDocumentsParams, setMetaData, setDocumentsPageNumber, setDocument, removeDocument} = documentsSlice.actions;