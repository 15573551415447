import { Paper, Typography } from "@mui/material";


// Spis treści
const tableOfContents = [
    'Definicje',
    'Postanowienia ogólne',
    'Zasady korzystania z poszczególnych funkcji internetowych i usług elektronicznych udostępnianych w portalu',
    'Ochrona praw autorskich i innych praw',
    'Ochrona prywatności i ochrona danych osobowych',
    'Wymagania techniczne niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się WP',
    'Opłaty za korzystanie z Portalu',
    'Treści nieodpowiednie dla osób małoletnich',
    'Prawa i obowiązki użytkowników',
    'Odpowiedzialność WP',
    'Zmiany zasad korzystania z Portalu',
    'Zmiany serwisów internetowych i usług elektronicznych',
    'Reklamacje i korespondencja',
    'Zwroty',
]

// I
const definitions = [
    'WP : Wechsler Polska Spółka z Ograniczoną Odpowiedzialnością, z siedzibą przy ul. 1 Maja 191, 25-646 Kielce, wpisana do Krajowego Rejestru Sądowego Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy w Kielcach, X Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000421362, NIP 9591948415, REGON 260607568',
    'Portal/Serwis : Platforma walidacyjna i egzaminacyjna dostępna pod elektronicznym adresem www.certsystem.pl wraz z jej podsystemami dostępnymi pod adresem https://certsystem.pl'
]

// II
const generalProvisions = [
    'Portal udostępniamy jest przez WP na podstawie niniejszych Zasad, za wyjątkiem przypadków, gdy postanowienia niniejszych Zasad odsyłają do uregulowań szczególnych.',
    'Zasady korzystania z Portalu są dostępne dla wszystkich użytkowników Portalu pod adresem internetowym www.certsystem.pl',
    'Użytkownik Portalu wyraża zgodę na korzystanie z Portalu w oparciu o niniejsze Zasady poprzez skorzystanie z dowolnej strony internetowej, serwisu internetowego lub usługi elektronicznej udostępnianej w Portalu. Użytkownikiem może być wyłącznie osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną. W przypadku osób prawnych i jednostek organizacyjnych nieposiadających osobowości prawnej, utworzyć Konto w ich imieniu oraz dokonywać wszelkich czynności w ramach Serwisu może jedynie osoba umocowana do działania w tym zakresie w imieniu tych podmiotów.',
    'Prawem właściwym dla wszystkich stosunków prawnych wynikających z niniejszych Zasad jest prawo polskie. Wszelkie spory będą rozstrzygane przez polskie sądy powszechne właściwe terytorialnie dla siedziby WP.',
]

// III
const termsOfUse = [
    {
        point: 'W celu skorzystania z funkcjonalności Portalu klient musi dokonać Rejestracji Konta i korzystać z Portalu jako zalogowany Użytkownik. Konto daje Użytkownikowi możliwość korzystania m.in. z następujących funkcjonalności Portalu:',
        subpoints: [
            'Zarządzanie kontem użytkownika',
            'Składanie zamówień na przeprowadzenie usług',
            'Dokonywanie płatności za składane zamówienia',
            'Zarządzanie płatnościami i fakturami związanymi z usługami świadczonymi w Portalu przez WP',
            'Wydawanie dyspozycji realizacji na zakupione usługi'
        ]
    },
    { point: 'Warunkiem realizacji usługi jest dokonanie zamówienia za pośrednictwem Portalu, poprzez dodanie do koszyka usług znajdujących się w katalogu Egzaminy (https://certsystem.pl/Services). W katalogu egzaminów znajdują się wszystkie usługi wraz ze wskazaniem ceny za ich realizację. Katalog ten jest jednocześnie cennikiem usług.', subpoints: [''] },
    { point: 'Termin realizacji proponuje Klient. Termin realizacji nie może być wcześniejszy niż data składania zamówienia.', subpoints: [''] },
    { point: 'WP realizuje zamówione usługi w terminie 5 dni roboczych od dnia złożenia zamówienia', subpoints: [''] },
    { point: 'Korzystanie z poszczególnych funkcji internetowych lub usług elektronicznych udostępnianych przez WP w Portalu może odbywać się w oparciu o uregulowania szczególne, w szczególności odpowiednie regulaminy, cenniki, noty zawierające dane i informacje odnośnie przedmiotu, zakresu i parametrów tych serwisów lub usług.', subpoints: [''] },
    { point: 'Użytkownik informowany jest i zapoznaje się z uregulowaniami szczególnymi wymienionymi w punkcie poprzednim niniejszych Zasad na stronach internetowych Portalu przeznaczonych dla udostępniania funkcji lub usług określonych w punkcie II.3 powyżej, w szczególności przy pierwszym skorzystaniu z tych funkcji lub usług.', subpoints: [''] },
    { point: 'W zakresie, którego dotyczą uregulowania szczególne, nie mają zastosowania niniejsze Zasady.', subpoints: [''] }
]

// IV
// wpisany "ręcznie" ze względu na strukturę podpunktów w podpunkcie

// V
const privacyProtection = [
    'Ryzyka związane z korzystaniem z sieci Internet oraz funkcją i celem oprogramowania lub danych niebędących składnikiem treści serwisów internetowych lub usług elektronicznych udostępnianych w Portalu, WP podaje w Polityce prywatności. Polityka prywatności WP jest dostępna pod adresem www.certsystem.pl//Rodo/Privacy',
    'Dodatkowo, zasady przetwarzania danych osobowych użytkowników Portalu określają uregulowanie szczególne wymienione w punkcie II niniejszych Zasad, dotyczące poszczególnych serwisów internetowych i usług elektronicznych udostępnianych w ramach Portalu.',
    'WP zastrzega sobie prawo do udostępniania danych użytkownika Portalu oraz danych przedstawiciela ustawowego lub opiekuna prawnego użytkownika Portalu, a także treści pochodzących od i rozpowszechnianych w Portalu przez użytkowników, jeżeli obowiązek udostępnienia uprawnionym osobom wynika z obowiązujących przepisów prawa.'
]

// VI
const technicalRequirements = [
    'WP dokłada starań, aby korzystanie z Portalu było możliwe dla użytkowników wszystkich popularnych przeglądarek internetowych, systemów operacyjnych, typów komputerów oraz typów połączeń internetowych. Jednakże WP nie gwarantuje i nie odpowiada za to, że każda kombinacja tych czynników umożliwia korzystanie z Portalu lub jego poszczególnych elementów, w szczególności usług elektronicznych udostępnianych w Portalu.',
    'Minimalne wymagania techniczne umożliwiające użytkownikowi korzystanie z Portalu, to przeglądarka internetowa (najlepiej IE 6.0 lub wersje wyższe albo przeglądarka oparta na silniku Mozilla 1.0 lub wersjach wyższych). Jednakże możliwość korzystania z poszczególnych serwisów internetowych lub usług elektronicznych dostępnych w Portalu może być uzależniona od dokonania określonych konfiguracji komputera użytkownika Portalu oraz spełnienia innych wymagań technicznych, o których użytkownik Portalu informowany jest w uregulowaniach szczególnych określonych w punkcie II niniejszych Zasad lub/i na stronach internetowych Portalu przeznaczonych dla udostępniania tych serwisów lub usług.'
]

// VII
const feesForUsingThePortal = [
    { point: 'Założenie konta i korzystanie z funkcjonalności zarządzania kontem użytkownika jest bezpłatne.', subpoints: [''] },
    { point: 'Realizacja usług, na które pozwala Portal odbywa się zgodnie z cennikiem wskanym w katalogu Egzaminów (https://certsystem.pl/Services)', subpoints: [''] },
    { point: 'Wybór sposobu płatności dokonuje się podczas finalizacji zamówienia.', subpoints: [''] },
    { point: 'Opłacenie usług możliwe jest z dołu na podstawie FV z 14-dniowym terminem płatności, jeżeli Klient należy do grona Klientów PREMIUM. Pozostali Klienci mogą zrealizować płatności za pośrednictwem systemu PayU.', subpoints: [''] },
    { point: 'W przypadku, gdy WP pobiera opłaty za korzystanie z serwisu internetowego lub usługi elektronicznej udostępnianej w Portalu, jest to wyraźnie komunikowane użytkownikowi Portalu, w szczególności w uregulowaniach szczególnych określonych w punkcie III niniejszych Zasad lub/i na stronach internetowych Portalu przeznaczonych dla udostępniania tych serwisów lub usług.', subpoints: [''] },
    {
        point: 'WP zwaraca jednak uwagę, że:',
        subpoints: [
            'korzystanie z Portalu może wiązać się dla użytkownika Portalu z opłatami na rzecz osób trzecich z tytułu uzyskania dostępu do sieci Internet i transmisji danych, za co WP nie ponosi odpowiedzialności; nadto opłaty te nie są pobierane przez WP i nie stanowią dla WP wynagrodzenia za korzystanie z Portalu;',
            'WP nie ponosi odpowiedzialności za opłaty z tytułu korzystania z serwisów internetowych i usług elektronicznych świadczonych przez osoby trzecie, do których można uzyskać dostęp za pośrednictwem Portalu; nadto opłaty te nie są pobierane przez WP;'
        ]
    }
]

// VIII wpisane ręcznie, bo krótkie

// IX
const rightsAndObligationsOfUsers = [
    { point: 'Korzystając z Portalu, użytkownicy Portalu są zobowiązani do przestrzegania przepisów prawa i postanowień niniejszych Zasad oraz uregulowań szczególnych określonych w punkcie II niniejszych Zasad, a także zasad współżycia społecznego i dobrych obyczajów.', subpoints: [''] },
    { point: 'Zakazane jest dostarczanie przez użytkowników Portalu treści o charakterze bezprawnym.', subpoints: [''] },
    {
        point: 'WP jest uprawniona do zaprzestania udostępniania Portalu lub jego poszczególnych elementów dla użytkownika Portalu, w przypadku:',
        subpoints: [
            'naruszenia przez użytkownika Portalu przepisów prawa, postanowień niniejszych Zasad, uregulowań szczególnych określonych w punkcie II niniejszych Zasad, zasad współżycia społecznego lub dobrych obyczajów;',
            'w przypadku działania przez użytkownika Portalu na szkodę WP lub innych użytkowników Portalu lub innych osób;',
            'w przypadkach określonych w uregulowaniach szczególnych określonych w punkcie II niniejszych Zasad;'
        ]
    },
]

// X
const responsibility = [
    {
        point: 'WP nie gwarantuje nieprzerwanej i niezakłóconej dostępności Portalu (lub jego poszczególnych części) dla wszystkich użytkowników Portalu. WP jest uprawniona do przerw lub zakłóceń w dostępności i korzystaniu z Portalu, w szczególności, gdy jest to spowodowane:',
        subpoints: [
            'koniecznością naprawy, rozbudowy, modyfikacji lub konserwacji sprzętu albo oprogramowania;',
            'przyczynami niezależnymi od WP (siła wyższa, działania lub zaniechania osób trzecich);'
        ]
    },
    { point: 'WP nie ponosi odpowiedzialności za treść reklam zamieszczanych w Portalu oraz za wszelkie treści pochodzące od i rozpowszechniane w Portalu przez inne osoby niż WP (w szczególności treści pochodzące od i rozpowszechniane przez samych użytkowników Portalu). Udostępnienie przez WP możliwości zamieszczenia i rozpowszechniania treści wskazanych w zdaniu poprzednim ogranicza się jedynie do udostępnienia rozwiązań technicznych.', subpoints: [''] },
    { point: 'WP nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonywanie świadczeń w ramach serwisów internetowych lub/i usług elektronicznych udostępnianych w Portalu, jeżeli jest to spowodowane przez osoby trzecie (w szczególności operatorów telekomunikacyjnych, dostawców łączy telekomunikacyjnych i energii elektrycznej). Ograniczenie, o którym mowa w zdaniu poprzednim, obejmuje także odpowiedzialność z tytułu szkód spowodowanych przez wirusy komputerowe. WP jest jednak odpowiedzialna jak za własne działanie lub zaniechanie za działania lub zaniechania osób, z których pomocą zobowiązanie wykonuje, jak również osób, którym wykonanie zobowiązań powierza. WP jest także odpowiedzialna w przypadkach, o których mowa w niniejszym punkcie, jeśli odpowiedzialność wynika z obowiązujących przepisów prawa.', subpoints: [''] },
    { point: 'WP nie ponosi odpowiedzialności z tytułu szkód spowodowanych działaniami lub zaniechaniami użytkowników Portalu, w szczególności za korzystanie przez nich z Portalu w sposób niezgodny z obowiązującymi przepisami prawa lub niniejszymi Zasadami lub uregulowaniami szczególnymi określonymi w niniejszej Sekcji oraz szkód wynikających z podania niepełnych lub nieprawdziwych danych.', subpoints: [''] },
    { point: 'WP nie ponosi odpowiedzialności za utratę danych spowodowaną awarią sprzętu, oprogramowania lub też okolicznościami niezależnymi od WP.', subpoints: [''] },
    { point: 'WP nie ponosi odpowiedzialności za treści otrzymywane i wysyłane przez użytkowników Portalu za pośrednictwem sieci Internet.', subpoints: [''] },
    { point: 'W przypadku gdy będzie to uzasadnione względami technicznymi, WP nie gwarantuje udostępniania Portalu WP (w całości lub w części) osobom, które bez zgody WP dokonują modyfikacji lub blokowania treści prezentowanych w ramach Portalu WP (w szczególności za pomocą narzędzi, takich jak przeglądarki internetowe lub inne aplikacje).', subpoints: [''] },
]

const changesToTheRules = [
    'Niniejsze Zasady korzystania z Portalu Wirtualna Polska wchodzą w życie z dniem ich opublikowania na stronie internetowej określonej w punkcie I.2 w sekcji Aktualności.',
    'WP zastrzega sobie prawo do zmiany niniejszych Zasad w każdym czasie. Zmiany niniejszych Zasad wchodzą w życie i są wiążące dla użytkowników Portalu z chwilą ich opublikowania (w szczególności poprzez opublikowanie treści niniejszych Zasad zawierających zmiany) na stronie internetowej określonej w punkcie I.2 w sekcji Aktualności, o ile nie zastrzeżono inaczej.',
    'Użytkownik Portalu zobowiązany jest do bieżącego zapoznawania się ze zmianami niniejszych Zasad.'
]

const changesToWebsites = [
    'Z zastrzeżeniem postanowień uregulowań szczególnych określonych w punkcie III niniejszych Zasad, WP zastrzega sobie prawo do zmiany lub zaprzestania udostępniania serwisów internetowych udostępnianych w Portalu, rezygnacji ze świadczenia usług elektronicznych lub wprowadzania zmian do tych usług, a także wprowadzania nowych serwisów internetowych lub usług elektronicznych w Portalu, w każdym czasie.',
    'Z zastrzeżeniem postanowień uregulowań szczególnych określonych w punkcie II niniejszych Zasad, WP może informować użytkowników Portalu o zmianach lub zaprzestaniu udostępniania serwisów internetowych lub usług elektronicznych w szczególności poprzez zamieszczenie stosownego oświadczenia WP na stronach internetowych Portalu.'
]

const complaintsAndCorrespondence = [
    'Użytkownik Portalu oraz przedstawiciel ustawowy lub opiekun prawny użytkownika Portalu nie posiadającego zdolności do czynności prawnych powinien kierować wszelką korespondencję oraz reklamacje w sprawach związanych z korzystaniem z Portalu za pomocą poczty email pod adres egzaminy@wechsler.pl, o ile postanowienia uregulowań szczególnych określonych w punkcie III niniejszych Zasad nie stanowią inaczej.',
    'O ile postanowienia uregulowań szczególnych określonych w punkcie III niniejszych Zasad nie stanowią inaczej, WP rozpatruje reklamacje w terminie 7 dni roboczych od otrzymania reklamacji, przy czym okres ten może zostać przedłużony maksymalnie o kolejne 14 dni roboczych w przypadku, gdy rozpoznanie reklamacji wymaga dłuższego czasu. WP poinformuje użytkownika Portalu o przedłużeniu okresu rozpoznania reklamacji, wskazując okres o jaki ulegnie przedłużeniu rozpatrywanie reklamacji oraz przyczynę przedłużenia. Informacja o przedłużeniu rozpoznania reklamacji i jego przyczynie zostanie udzielona użytkownikowi Portalu przed upływem siedmiodniowego okresu, o którym mowa w pierwszym zdaniu niniejszego punktu Zasad. w przypadku, gdy złożona reklamacja zawiera braki uniemożliwiające jej rozpatrzenie, WP o ile uzna, że jest to konieczne do prawidłowego rozpatrzenia reklamacji, niezwłocznie wzywa reklamującego do jej uzupełnienia, określając termin, nie krótszy niż 7 dni roboczych i zakres tego uzupełnienia, z pouczeniem, że nieuzupełnienie reklamacji w określonym terminie spowoduje pozostawienie reklamacji bez rozpoznania. Po bezskutecznym upływie wyznaczonego terminu reklamację pozostawia się bez rozpoznania.',
    'Korespondencję związaną z korzystaniem z Portalu oraz odpowiedź na reklamacje, WP kieruje na adres poczty elektronicznej udostępniony jej przez użytkownika Portalu, a w przypadku użytkownika Portalu nie posiadającego zdolności do czynności prawnych, dodatkowo na udostępniony jej adres poczty elektronicznej przedstawiciela ustawowego lub opiekuna prawnego takiego użytkownika Portalu.'
]

// const storeContact = [
//     {
//         point: 'Klient może kontaktować się ze Sprzedawcą w szczególności:',
//         subpoints: [
//             'za pośrednictwem poczty elektronicznej, na adres: biuro@psce.pl',
//             'pisemnie na adres: ul. Wolności 24/2.17, 58-260 Bielawa',
//             'a także telefonicznie pod numerem: +48 664 388 680 w godz. 08:00-16:00 w dni robocze.'
//         ]
//     },
//     { point: 'Numer rachunku bankowego Sprzedawcy prowadzony w Santander Bank Polska S.A. PL 81 1090 2284 0000 0001 3525 3941', subpoints: [''] }
// ]

export default function Regulations() {
    return (
        <Paper sx={{ px: 4, py: 4, borderRadius: '12px' }}>

            <Typography variant="h4" textAlign='center' gutterBottom>
                Zasady korzystania z portalu
            </Typography>

            <Typography variant="h5" textAlign='left' gutterBottom>
                Spis treści:
            </Typography>
            <ol style={{ listStyleType: 'upper-roman', marginBottom: 25 }}>
                {tableOfContents.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                I. Definicje
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {definitions.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                II. Postanowienia ogólne
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {generalProvisions.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                III. Zasady korzystania z poszczególnych funkcji internetowych i usług elektronicznych udostępnianych w portalu
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {termsOfUse.map((item) => (
                    <li key={item.point}>{item.point}{item.subpoints[0] === '' ? '' :
                        <ul>
                            {item.subpoints.map((subpoint) => (
                                <li key={subpoint}>
                                    {subpoint}
                                </li>
                            ))}
                        </ul>}
                    </li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                IV. Ochrona praw autorskich i innych praw
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                <li>
                    Wszelkie prawa do Portalu oraz jego wszystkich elementów (w tym oprogramowania, układu funkcjonalnego, elementów graficznych, baz danych i utworów prezentowanych w ramach Portalu), za wyjątkiem treści pochodzących od i rozpowszechnianych przez użytkowników Portalu, należą do WP lub/i do podmiotów współpracujących z WP i podlegają ochronie prawnej. Portal jak i poszczególne serwisy internetowe są dostępne jako całość i jako całość stanowią utwór do którego wszelkie prawa przysługują WP. WP podejmuje niezbędne środki technologiczne i prawne w celu ochrony integralności Portalu, kontroli sposobu korzystania z Portalu jak i poszczególnych serwisów internetowych, mających na celu ograniczenie korzystania z Portalu w sposób mogący zakłócać jego funkcjonowanie. Z wykorzystaniem środków technicznych WP kontroluje sposób korzystania z Portalu, w tym korzystanie z Portalu z pominięciem poszczególnych treści informacyjnych, promocyjnych i reklamowych czyli fakt, pobierania stron internetowych Portalu lub poszczególnych serwisów internetowych z pominięciem poszczególnych treści tych stron internetowych. Pobieranie Portalu z wykorzystaniem przeglądarek internetowych możliwe jest wyłącznie w sposób nie naruszający praw WP. Korzystanie z Portalu w sposób naruszający jego integralność jest zabronione.
                </li>
                <li>
                    Zastrzeżenie, o którym mowa w punkcie IV.1 powyżej, dotyczy w szczególności:
                    <ul>
                        <li>
                            utworów podlegających ochronie na podstawie ustawy z dnia 04 lutego 1994 r. o prawie autorskim i prawach pokrewnych (tj. Dz.U. z 2006 r., nr 90, poz. 631 z późn. zm.), w szczególności: literackich, publicystycznych, naukowych, kartograficznych, programów komputerowych, plastycznych, fotograficznych, wzornictwa przemysłowego, architektonicznych, architektoniczno-urbanistycznych, urbanistycznych, muzycznych, słowno-muzycznych, scenicznych, sceniczno-muzycznych, choreograficznych, pantomimicznych, audiowizualnych (w tym filmowych) i innych;
                        </li>
                        <li>baz danych podlegających ochronie na podstawie ustawy z dnia 27 lipca 2001 r. o ochronie baz danych (Dz.U. nr 128, poz. 1402, z późn. zm.);</li>
                        <li>znaków towarowych podlegających ochronie na podstawie ustawy z dnia 30 czerwca 2000 r. prawo własności przemysłowej (tj. Dz.U. z 2003 r., nr 119, poz. 1117 z późn. zm.);</li>
                        <li>nazw domen internetowych;</li>
                        <li>innych treści i usług podlegających ochronie na podstawie właściwych przepisów, w tym na podstawie ustawy z dnia 16 kwietnia 1993 r. o zwalczaniu nieuczciwej konkurencji (tj. Dz.U. z 2003 r., nr 153 poz. 1503, z późn. zm);</li>
                    </ul>
                </li>
                <li>
                    Korzystanie z Portalu nie oznacza nabycia jakichkolwiek praw do Portalu ani jego poszczególnych elementów. w szczególności
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li>użytkownicy Portalu mogą korzystać z elementów Portalu (w tym utworów i baz danych udostępnianych w ramach Portalu) wyłącznie w zakresie własnego użytku osobistego, chyba że ich wykorzystanie w szerszym zakresie umożliwiają obowiązujące przepisy lub wyraźna zgoda WP (ewentualnie innych uprawnionych podmiotów);</li>
                        <li>
                            poza przypadkami określonymi w punkcie IV.3.a powyżej, bez wyraźnej zgody WP (ewentualnie innego uprawnionego podmiotu) zabrania się w szczególności podejmowania następujących działań w odniesieniu do Portalu, jego poszczególnych części oraz treści prezentowanych w Portalu:
                            <ul>
                                <li>w zakresie utrwalania i zwielokrotniania treści - wytwarzanie określoną techniką egzemplarzy jakichkolwiek treści, w tym techniką drukarską, reprograficzną, zapisu magnetycznego oraz techniką cyfrową;</li>
                                <li>w zakresie obrotu oryginałem albo egzemplarzami, na których treści utrwalono - wprowadzanie do obrotu, użyczenie lub najem oryginału albo egzemplarzy;</li>
                                <li>w zakresie rozpowszechniania treści w sposób inny niż określony powyżej - publiczne wykonanie, wystawienie, wyświetlenie, odtworzenie oraz nadawanie i reemitowanie, a także publiczne udostępnianie utworu w taki sposób, aby każdy mógł mieć do niego dostęp w miejscu i w czasie przez siebie wybranym;</li>
                                <li>w odniesieniu do programów komputerowych: trwałego lub czasowego zwielokrotnienia programu komputerowego w całości lub w części jakimikolwiek środkami i w jakiejkolwiek formie; tłumaczenia, przystosowywania, zmiany układu lub jakichkolwiek innych zmian w programie komputerowym; rozpowszechniania, w tym użyczenia lub najmu, programu komputerowego lub jego kopii;</li>
                                <li>wykorzystywanie w całości lub w istotnej - co do jakości lub ilości - części;</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>W celu uniknięcia wątpliwości, niezależnie od innych zastrzeżeń zawartych w niniejszych "Zasadach", WP zastrzega, że bez jej zgody zabronione jest dalsze rozpowszechnianie treści pochodzących z Portalu obejmujących aktualne artykuły na tematy polityczne, gospodarcze lub religijne, w szczególności sporządzane w formie tekstowej, tekstowo-graficznej, jak i tekstu połączonego ze zbiorem ("galerią") zdjęć.</li>
                <li>Osoba, która chciałaby wykorzystać treści (w szczególności utwory) udostępniane w ramach Portalu w szerszym zakresie niż wskazany w punkcie IV.3 niniejszych Zasad lub ma wątpliwości co dopuszczalnego zakresu korzystania z tych treści (w tym utworów), powinna skontaktować się z WP.</li>
                <li>Modyfikowanie lub blokowanie, bez zgody WP, treści (w szczególności wyrażonych słowem, plastycznych, fotograficznych, muzycznych, słowno-muzycznych i audiowizualnych) prezentowanych w ramach Portalu WP może stanowić naruszenie obowiązujących przepisów, a także praw WP lub innych osób. Dotyczy to w szczególności wykorzystywania w tym celu narzędzi, takich jak przeglądarki internetowe lub inne aplikacje, mające możliwość modyfikowania lub blokowania treści przekazywanych w ramach Portalu WP, w tym treści o charakterze reklamowym.</li>
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                V. Ochrona prywatności i ochrona danych osobowych
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {privacyProtection.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                VI. Wymagania techniczne niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się WP
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {technicalRequirements.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                VII. Opłaty za korzystanie z Portalu
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {feesForUsingThePortal.map((item) => (
                    <li key={item.point}>{item.point}{item.subpoints[0] === '' ? '' :
                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            {item.subpoints.map((subpoint) => (
                                <li key={subpoint}>
                                    {subpoint}
                                </li>
                            ))}
                        </ol>}
                    </li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                VIII. Treści nieodpowiednie dla osób małoletnich
            </Typography>
            <Typography gutterBottom marginBottom={2}>
                Na stronach internetowych Portalu i za jego pośrednictwem można uzyskać dostęp do treści, które mogą okazać się nieodpowiednie dla osób małoletnich. WP podejmuje starania w celu odpowiedniego oznaczania takich treści, jednakże z uwagi na specyfikę usług świadczonych drogą elektroniczną nie może zapewnić pełnego zabezpieczenia osób małoletnich przed dostępem do treści, które nie są dla nich przeznaczone (w szczególności małoletni użytkownik Portalu może zignorować odpowiednie komunikaty i ostrzeżenia wprowadzone przez WP).
            </Typography>

            <Typography variant="h5" textAlign='left' gutterBottom>
                IX. Prawa i obowiązki użytkowników
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {rightsAndObligationsOfUsers.map((item) => (
                    <li key={item.point}>{item.point}{item.subpoints[0] === '' ? '' :
                        <ol>
                            {item.subpoints.map((subpoint) => (
                                <li key={subpoint}>
                                    {subpoint}
                                </li>
                            ))}
                        </ol>}
                    </li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                X. Odpowiedzialność WP
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {responsibility.map((item) => (
                    <li key={item.point}>{item.point}{item.subpoints[0] === '' ? '' :
                        <ol>
                            {item.subpoints.map((subpoint) => (
                                <li key={subpoint}>
                                    {subpoint}
                                </li>
                            ))}
                        </ol>}
                    </li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                XI. Zmiany zasad korzystania z Portalu
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {changesToTheRules.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                XII. Zmiany serwisów internetowych i usług elektronicznych
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {changesToWebsites.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                XIII. Reklamacje i korespondencja
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                {complaintsAndCorrespondence.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ol>

            <Typography variant="h5" textAlign='left' gutterBottom>
                XIV. Zwroty
            </Typography>
            <ol style={{ listStyleType: 'decimal', marginBottom: 25 }}>
                <li>W przypadku rezygnacji z zamówienia po dokonaniu opłaty za jego realizację Klientowi przysługuje zwrot pieniędzy. Zgodnie z obowiązującym prawem w kwestiach zwrotów i odstąpienia Klienta od umowy bez podania przyczyny obowiązują przepisy Ustawy z dnia 30 maja 2014 r. o prawach konsumenta (art. 27).</li>
            </ol>
            
        </Paper>
    )
}