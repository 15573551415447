import { Grid } from "@mui/material";
import { Agreement } from "../../../../app/models/agreement";
import { useAppSelector } from "../../../../app/store/configureStore";
import AgreementCardSkeleton from "./AgreementCardSkeleton";
import AgreementCard from "./AgreementCard";

interface Props {
    agreements: Agreement[];
    handleCardSelectAgreement: (handledAgreement: Agreement, exportPdf?: boolean) => void;
}

export default function AgreementsList({ agreements, handleCardSelectAgreement }: Props) {
    const { agreementsLoaded } = useAppSelector(state => state.agreements);
    return (
        <Grid container spacing={4} >
            {agreements.map(agreement => (
                <Grid key={agreement.id} item xs={12} sm={12} md={6}>
                    {!agreementsLoaded ? (
                        <AgreementCardSkeleton />
                    ) : (
                        <AgreementCard 
                            agreement={agreement}
                            handleCardSelectAgreement={handleCardSelectAgreement}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}