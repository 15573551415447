import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import agent from '../../app/api/agent';
import { toast } from 'react-toastify';
import { router } from '../../app/router/Routes';

export default function ChangeEmail() {
    const { register, handleSubmit, setError, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'all'
    });

    function handleApiErrors(errorResponse: any) {
        const errors = errorResponse?.errors;
    
        if (errors) {
            const errorArray = Array.isArray(errors) ? errors : [errors];
            errorArray.forEach((error: string) => {
                if (error.includes('Password')) {
                    setError('password', { message: error });
                } else if (error.includes('NewEmail')) {
                    setError('newEmail', { message: error });
                } else {
                    toast.error(error);
                }
            });
        } else if (errorResponse?.title) {
            // Jeśli jest `title`, oznacza to, że mamy komunikat błędu z API
            toast.error(errorResponse.title);
        }
    }

    return (
        <Container component={Paper} maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', aligntItems: 'center', p: 4 }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Zmiana adresu email
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit((data) =>
                    agent.Account.updateAccountEmail(data)
                        .then(() => {
                            toast.success('Email został zmieniony. Możesz wylogować się i zalogować z użyciem nowego adresu email.');
                            router.navigate("/account/changeEmailSuccess");
                        })
                        .catch(error => handleApiErrors(error)))
                }
                noValidate
                sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Nowy Email"
                    autoFocus
                    {...register('newEmail', {
                        required: 'Email jest wymagany',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email jest nieprawidłowy'
                        }
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message?.toString()}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Dotychczasowe hasło"
                    type="password"
                    {...register('password', {
                        required: 'Hasło jest wymagane'
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message?.toString()}
                />

                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Zmień email
                </LoadingButton>
            </Box>
        </Container>
    );
}