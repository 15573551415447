import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../store/configureStore";
import { agreementsSelectors, fetchAgreementsAsync, fetchAgreementsFilters } from "../../features/admin/agreements/agreementsSlice";

export default function useAgreements() {
    const agreements = useAppSelector(agreementsSelectors.selectAll);
    const {agreementsLoaded, agreementsFiltersLoaded, metaData} = useAppSelector(state => state.agreements);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!agreementsLoaded) dispatch(fetchAgreementsAsync());
  }, [agreementsLoaded, dispatch])

  useEffect(() => {
    if (!agreementsFiltersLoaded) dispatch(fetchAgreementsFilters());
  }, [agreementsFiltersLoaded, dispatch])

  return {
    agreements,
    agreementsLoaded,
    agreementsFiltersLoaded,
    metaData
  }
}