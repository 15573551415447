import { useEffect } from "react";
import { clientOrdersSelectors, fetchClientOrdersAsync, fetchClientOrdersFilters } from "../../features/client/orders/clientOrdersSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useClientOrders() {
    const clientOrders = useAppSelector(clientOrdersSelectors.selectAll);
    const {clientOrdersLoaded, clientOrdersFiltersLoaded, metaData} = useAppSelector(state => state.clientOrders);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!clientOrdersLoaded) dispatch(fetchClientOrdersAsync());
  }, [clientOrdersLoaded, dispatch])

  useEffect(() => {
    if (!clientOrdersFiltersLoaded) dispatch(fetchClientOrdersFilters());
  }, [clientOrdersFiltersLoaded, dispatch])

  return {
    clientOrders,
    clientOrdersLoaded,
    clientOrdersFiltersLoaded,
    //brands,
    //types,
    metaData
  }
}