import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { router } from "../../app/router/Routes";
import { checkoutAccountSchemaValidation02 } from "./checkoutAccountSchemaValidation";
import EditAccountDataForm from "./EditAccountDataForm";


export default function EditAccount() {
    const [, setLoading] = useState(false);
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(checkoutAccountSchemaValidation02[0])
    });
    const { formState } = methods;
    const { isDirty, isSubmitting } = formState;

    useEffect(() => {
        agent.Account.fetchAccountData()
            .then(response => {
                if (response) {
                    const sanitizedData = {
                        lastName: response.lastName || "",
                        firstName: response.firstName || "",
                        phone: response.phone || ""
                    };
                    methods.reset({ ...methods.getValues(), ...sanitizedData });
                }
            })
    }, [methods]);

    async function submitAccountUpdate(data: FieldValues) {
        console.log("Inside submitAccountUpdate");
        setLoading(true);
        console.log(data);
        try {
            await agent.Account.updateAccountData(data)
                .then(() => {
                    toast.success('Dane zostały pomyślnie zaktualizowane')
                    router.navigate('/client')
                }).catch((error) => {
                    console.log(error);
                    toast.error(error)
                })
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <form onSubmit={methods.handleSubmit(submitAccountUpdate)}>
                    <EditAccountDataForm />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            loading={isSubmitting} 
                            disabled={!isDirty}
                            variant="outlined"
                            type='submit'
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </form>
            </Paper>
        </FormProvider>
    );
}