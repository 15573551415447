import * as yup from 'yup';

interface ServiceFormData {
    isActive: boolean;
    name: string;
    basePrice: number;
    category: string;
    subservices: string;
}

export const serviceValidationSchema: yup.ObjectSchema<ServiceFormData> = yup.object({
    isActive: yup.boolean().required('Wskazanie usługi jest wymagane'),
    name: yup.string().required('Wskazanie nazwy jest wymagane'),
    basePrice: yup.number().required('Wskazanie ceny jest wymagane'),
    category: yup.string().required('Wskazanie kategorii jest wymagane'),
    subservices: yup.string().required('Wskazanie podusługi jest wymagane')
});