import DoneIcon from '@mui/icons-material/Done';
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { signOut } from "../../features/account/accountSlice";
import { useNavigate } from "react-router-dom";

export default function ChangeEmailSuccess() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [counter, setCounter] = useState(10);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(signOut());
            navigate('/login');
        }, 10000);

        const countdown = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => {
            clearTimeout(timer);
            clearInterval(countdown);
        };
    }, [dispatch, navigate]);

    const handleSignOut = () => {
        dispatch(signOut());
        navigate('/login');
    };

    return (
        <Box sx={{ textAlign: "center" }}>
            <Typography variant='h3' gutterBottom>
                <DoneIcon color="success" fontSize="large" sx={{ marginRight: 2 }} />
                Zmiana adresu email zakończona sukcesem
            </Typography>
            <Typography variant='h4' gutterBottom>
                {`Zostaniesz wylogowany za ${counter} sekund.`}
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSignOut}
                sx={{ mt: 2 }}
            >
                Kliknij aby się wylogować, a następnie zaloguj się, by korzystać z nowego adresu email
            </Button>
        </Box>
    );
}