import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useStyles from './cardsStyle';
import { router } from '../../router/Routes';

interface ServicesProps {
    img: string;
    title: string;
    desc?: string;
    link: string;
    button: string;
}

function Services(props: ServicesProps) {
    const { classes } = useStyles();
    const {
        img,
        title,
        link,
        button
    } = props;
    return (
        <Paper className={classes.defaultCard}>
            <figure style={{ width: '100%', overflow: 'hidden' }}>
                <img src={img} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </figure>
            <Typography display='flex' justifyContent='center' variant="h6">{title}</Typography>
            <Button onClick={() => router.navigate(link)} variant="outlined" fullWidth color="primary" className={classes.button}>
                {button}
            </Button>
        </Paper>
    );
}

Services.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
};

export default Services;