import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ChatComment } from "../../../app/models/comment";
import { RootState } from "../../../app/store/configureStore";

const commentsAdapter = createEntityAdapter<ChatComment>();

export const adminHelpInInvestmentCommentsSlice = createSlice({
    name: "adminHelpInInvestmentComments",
    initialState: commentsAdapter.getInitialState({
        status: 'idle',
        helpInAdminInvestmentCommentsLoaded: false,
        selectedAdminInvestment: 0,
        messages: []
    }),
    reducers: {
        setSelectedAdminInvestment: (state, action) => {
            state.selectedAdminInvestment = action.payload;
        },
        setAdminHelpInInvestmentComments: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        },
        updateAdminHelpInInvestmentMessage: (state, action) => {
            commentsAdapter.upsertOne(state, action.payload);
        },
        loadAdminHelpInInvestmentMessage: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        }
    }
});

export const adminHelpInInvestmentCommentsSelectors = commentsAdapter.getSelectors((state: RootState) => state.adminHelpInInvestmentComments);

export const {setSelectedAdminInvestment, updateAdminHelpInInvestmentMessage, loadAdminHelpInInvestmentMessage, setAdminHelpInInvestmentComments } = adminHelpInInvestmentCommentsSlice.actions;