import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, List, ListItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { toast } from "react-toastify";
import { Service } from "../../../app/models/service";
import { AppDeleteModalProps } from "../../../app/models/appDeleteModalProps";
import agent from "../../../app/api/agent";
import { removeService } from "../servicesSlice";
import AppDeleteModal from "../../../app/components/AppDeleteModal";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import AutocompleteInputOption from "../../../app/models/autocompleteInputOption";
import { currencyFormat } from "../../../app/util/util";

interface Props {
    service: Service;
    handleCardSelectService?: (handledService: Service) => void;
    possibleSubservices: AutocompleteInputOption[];
}

export default function ServiceCard({ service, handleCardSelectService, possibleSubservices }: Props) {
    const dispatch = useAppDispatch();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteModalData, setModalData] = useState<AppDeleteModalProps | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const defaultServicePicture = `${import.meta.env.VITE_URL}images/defaultServicePicture.webp`;
    const { user } = useAppSelector(state => state.account);

    function handleOpenDeleteModal(props: AppDeleteModalProps) {
        setModalData(props);
        setOpenDeleteModal(true);
    }

    function handleCloseDeleteModal() {
        setOpenDeleteModal(false);
    }

    function handleDeleteService(id: number) {
        setLoading(true);
        if (id) {
            agent.Services.delete(id)
                .then(() => {
                    console.log(id)
                    dispatch(removeService(id));
                    toast.success('Usługa została usunięta')
                })
                .catch(error => {
                    toast.error('Podczas usuwania danych wystąpił błąd');
                    console.log(error);
                })
                .finally(() => setLoading(false));
            handleCloseDeleteModal();
            setModalData(undefined);
        }
    }

    function filterOptionsByIds(options: AutocompleteInputOption[], idsString: string): AutocompleteInputOption[] {
        // Rozdzielenie id stringa na tablicę id jako liczby
        const ids = idsString.split(',').map(idStr => Number(idStr.trim()));

        // Zwraca tylko te opcje, które mają id obecne w tablicy id
        return options.filter(option => ids.includes(option.id));
    }

    const filteredSubservicesOptions = filterOptionsByIds(possibleSubservices, service.subservices);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {service.imagePath === '' || service.imagePath === null || service.imagePath === undefined ?
                <CardMedia
                    sx={{ height: 140 }}
                    image={defaultServicePicture}
                    title={service.name}
                />
                :
                <CardMedia
                    sx={{ height: 140 }}
                    image={service.imagePath}
                    title={service.name}
                />
            }
            <CardHeader
                title={service.name}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
            />
            <Divider />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h6">Cena: {currencyFormat(service.basePrice)}</Typography>
                <Typography gutterBottom variant="h6">{service.category}</Typography>
                <Typography gutterBottom variant="h6">Podusługi (moduły):</Typography>
                <List>
                    {filteredSubservicesOptions.map(subservice => (
                        <ListItem key={subservice.id}>{subservice.name}</ListItem>
                    ))}
                </List>
            </CardContent>
            <CardActions>
                <Button
                    sx={{ mr: 1, width: '100px', height: '40px' }}
                    variant='outlined'
                    component={Link} to={`/services/${service.id}`}
                    size="small"
                    title='Więcej'
                >Więcej</Button>
                {user && user.roles?.includes('Admin') && (
                    <>
                        <Button
                            sx={{ mr: 1, width: '100px', height: '40px' }}
                            variant='outlined'
                            onClick={() => handleCardSelectService!(service)}
                            size="small"
                            title='Edycja'
                            color='warning'
                        >Edycja</Button>
                        <LoadingButton
                            sx={{ mr: 1, width: '100px', height: '40px' }}
                            variant='outlined'
                            loading={loading}
                            size='small'
                            color='error'
                            onClick={
                                () => {
                                    handleOpenDeleteModal({
                                        itemDescriptionFirstLine: 'Czy na pewno chcesz usunąć usługę?:',
                                        itemDescriptionSecondLine: '# ' + service.id + ' ' + service.name,
                                        handleCloseModal: handleCloseDeleteModal,
                                        handleIntDeleteFunction: handleDeleteService,
                                        isIntDeleteFunction: true,
                                        intId: service.id
                                    })
                                }}
                            title='Usuń'
                        >Usuń</LoadingButton>
                    </>
                )}
                {openDeleteModal ? <AppDeleteModal modalDeleteModalProps={deleteModalData!} /> : ''}
            </CardActions>
        </Card >
    )
}