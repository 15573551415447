import logo from '../../layout/wechsler_logo_w.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useText } from '../../theme/common';
import useStyles from './aboutStyle';
import useTitle from '../Title/titleStyle';
import Counter from '../Counter/Counter';

function About() {
  const theme = useTheme();
  const { classes: text } = useText();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { classes, cx } = useStyles();
  const { classes: title } = useTitle();
  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={6}>
          <Grid item md={5} xs={12}>
            <div>
              <Typography variant="h5" className={cx(text.title)} sx={{ mb: 6, textAlign: 'center', display: { xs: 'flex', sm: 'flex', md: 'none'} }}>
                Niepubliczne Centrum Kształcenia Zawodowego
              </Typography>
              <img src={logo} alt="logo" className={classes.imgToFront} />

              {isDesktop && (
                <div className={classes.puzzle}>
                  <div className={classes.pieceBig}>
                    <span />
                  </div>
                  <div className={classes.pieceSmallTop}>
                    <span />
                  </div>
                  <div className={classes.pieceSmallBottom}>
                    <span />
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={7} xs={12}>
            <Typography className={cx(title.default, text.subtitle)} variant="h4">
              do tej pory posiadamy:
            </Typography>
            <Counter />
            {isMobile ? (
              <blockquote style={{ textAlign: 'center' }}>
                a liczby wciąż rosną!
              </blockquote>
            ) : (
              <blockquote>
                a liczby wciąż rosną!
              </blockquote>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default About;