import { Box, Typography, Button, Paper, Grid, Alert } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import AppDropzone from "../../../app/components/AppDropzone";
import { Order } from "../../../app/models/order";
import { useAppDispatch } from "../../../app/store/configureStore";
import { fetchClientOrderAsync } from "./clientOrdersSlice";
import { LoadingButton } from "@mui/lab";

interface Props {
    order: Order;
    cancelUpload: () => void;
}

export default function OrderScanUploadForm({ order, cancelUpload }: Props) {
    const { control, handleSubmit, formState: { isSubmitting, isDirty }, watch } = useForm({
        // resolver: yupResolver(serviceValidationSchema)
    });
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    async function handleSubmitData(data: FieldValues) {
        try {
            data.id = order.id;
            data.benefitients = order.benefitients;
            data.lastName = order.lastName;
            data.firstName = order.firstName;
            data.pesel = order.pesel;
            data.birthPlace = order.birthPlace;
            data.birthDate = order.birthDate;
            data.isActive = order.isActive;
            data.publicId = order.publicId;
            data.userId = order.userId;
            data.userEmail = order.userEmail;
            data.userDisplayName = order.userDisplayName;
            data.userPhone = order.userPhone;
            data.orderDateTime = order.orderDateTime;
            data.updatingUserId = order.updatingUserId;
            data.updatingUserIsAdmin = order.updatingUserIsAdmin;
            data.updatingUserEmail = order.updatingUserEmail;
            data.updatingUserDisplayName = order.updatingUserDisplayName;
            data.updatingUserPhone = order.updatingUserPhone;
            data.updatingDateTime = order.updatingDateTime;
            data.serviceId = order.serviceId;
            data.serviceName = order.serviceName;
            data.customeClientServiceName = order.customeClientServiceName;
            data.clientComment = order.clientComment;
            data.publicComment = order.publicComment;
            data.internalComment = order.internalComment;
            data.trainingProgram = order.trainingProgram;
            data.educationEffects = order.educationEffects;
            data.payerInstitutionName = order.payerInstitutionName;
            data.payerLastName = order.payerLastName;
            data.payerFirstName = order.payerFirstName;
            data.payerStreet = order.payerStreet;
            data.payerBuilding = order.payerBuilding;
            data.payerPremises = order.payerPremises;
            data.payerZip = order.payerZip;
            data.payerCity = order.payerCity;
            data.payerState = order.payerState;
            data.payerCountry = order.payerCountry;
            data.payerNIP = order.payerNIP;
            data.preposedDateTime = order.preposedDateTime;
            data.placeOfServiceAndAnotherInformations = order.placeOfServiceAndAnotherInformations;
            data.payerClientName = order.payerClientName;
            data.payerClientAddress = order.payerClientAddress;
            data.payerClientNIP = order.payerClientNIP;

            if (order) {
                await agent.Client.Orders.update(data);
                toast.success('Uaktualniono plik');
            } else {
                await agent.Client.Orders.create(data);
                toast.success('Dodano plik');
            }
            dispatch(fetchClientOrderAsync(order.publicId)); // Dodane: odświeżenie zamówienia po dodaniu załącznika
            cancelUpload();
        } catch (error) {
            console.log(error);
        }
    }

    const lastImageLink = order?.solvedTestsPaths?.split("; ").pop();

    return (
        <>
            <Box display='flex' justifyContent='center'>
                <Typography sx={{ p: 2 }} variant='h4'>Dodaj skan do zamówienia</Typography>
            </Box>
            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    <Grid container spacing={3} marginBottom={3}>
                        <Grid item xs={12}>
                            <AppDropzone
                                name="file"
                                control={control}
                                acceptedFormats="jpg,png,pdf,zip"
                                rules={{ required: "Wymagane pole" }}
                            />
                            {watchFile ? (
                                watchFile.name?.endsWith('.pdf') || watchFile.name?.endsWith('.zip') ? (
                                    <Typography style={{ marginTop: 35 }}>{`Załadowany plik: ${watchFile.name}`}</Typography>
                                ) : (
                                    <img src={watchFile.preview} alt="Plik" style={{ maxHeight: 200, marginTop: 35 }} />
                                )
                            ) : (
                                lastImageLink ? (
                                    lastImageLink.endsWith('.pdf') || lastImageLink.endsWith('.zip') ? (
                                        <Typography style={{ marginTop: 35 }}>{`Ostatni dodany plik: ${lastImageLink.split('/').pop()}`}</Typography>
                                    ) : (
                                        <img src={lastImageLink} alt="Alternatywny podgląd" style={{ maxHeight: 200, marginTop: 35 }} />
                                    )
                                ) : (
                                    <Typography style={{ marginTop: 35 }}>Nie załadowano jeszcze żadnego pliku.</Typography>
                                )
                            )}
                            <Alert sx={{ mt: 1 }} severity="info">Załaduj skan dla wybranego zamówienia. Dozwolone formaty to JPG, PDF, PNG i ZIP.</Alert>
                            {order.solvedTestsPaths == "" || order.solvedTestsPaths == null ? '' : (
                                <Alert sx={{ mt: 1 }} severity="error">Dla tej usługi zostały już dodane skany, jeżeli dodasz nowy skan wymagane będzie ponowne zatwierdzenie testów przez administratora.</Alert>
                            )}
                        </Grid>
                    </Grid>

                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelUpload} variant='outlined' color='primary'>Anuluj</Button>
                        <LoadingButton disabled={!isDirty} loading={isSubmitting} type='submit' variant='outlined' color='success'>Dodaj skan</LoadingButton>
                    </Box>
                </form>
            </Box>
        </>
    )
}