import * as yup from 'yup';
import { Investment, InvestmentState } from '../../../app/models/investment';

export const baseValidationSchema = {
  investmentName: yup.string().required('Temat projektu jest wymagany').nullable(),
  clientComment: yup.string().required('Komentarz/opis jest wymagany').nullable(),
  competition: yup.string().required('Rodzaj naboru/konkursu jest wymagany').nullable(),
  submissionDeadline: yup.string()
      .nullable()
      .test('is-date', 'Wprowadź prawidłową datę', value => !value || !isNaN(Date.parse(value))),
};

export function adminInvestmentValidationSchema(adminInvestment?: Investment) {
  const isAdminAccepted = adminInvestment?.state === InvestmentState.ADMIN_ACCEPTED;

  return yup.object().shape({
  ...baseValidationSchema,
  price: isAdminAccepted ? yup.number()
    .test('is-not-zero', 'Budżet projektu nie może wynosić zero', value => {
      return Number(value) !== 0;
    })
    .required('Maksymalny budżet projektu jest wymagany')
    .test('is-number', 'Wprowadź prawidłową liczbę', value => {
      return !value || !isNaN(Number(value));
    }) : yup.string().nullable(),
  state: isAdminAccepted ? yup
    .number()
    .required('Akceptuj/Odrzuć jest wymagane')
    .oneOf([1, 2], 'Wybierz poprawną opcję')
    : yup.string().nullable()
  }) 
}

export const adminInvestmentValuationValidationSchema = yup.object().shape({
  price: yup.number()
    .test('is-not-zero', 'Budżet projektu nie może wynosić zero', value => {
      return Number(value) !== 0;
    })
    .required('Maksymalny budżet projektu jest wymagany')
    .test('is-number', 'Wprowadź prawidłową liczbę', value => {
      return !value || !isNaN(Number(value));
    }),
  state: yup
    .number()
    .required('Akceptuj/Odrzuć jest wymagane')
    .oneOf([1, 2], 'Wybierz poprawną opcję')
});

export const adminHelpInInvestmentValidationSchema = yup.object().shape({
  body: yup.string().required('Wiadomość jest wymagana'),
});