import { createTheme } from '@mui/material/styles';

export interface Palette {
    primary: {
        light: string;
        main: string;
        dark: string;
        contrastText?: string;
    };
    secondary: {
        light: string;
        main: string;
        dark: string;
        contrastText?: string;
    };
}

export interface Theme {
    palette: Palette;
}

export interface Themes {
    [themeName: string]: Theme;
}

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        text: {
            primary: '#212121', // Ciemny kolor tekstu dla jasnego trybu
            secondary: '#757575' // Jaśniejszy kolor tekstu dla jasnego trybu
        }
    },
    components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              '@media (max-width:600px)': { // Dla ekranów o szerokości do 600px, co odpowiada punktowi przełamania xs w Material-UI
                marginTop: '100px', // Dostosuj tę wartość do wysokości Twojego AppBar
              },
            },
          },
        },
      },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: {
            primary: '#E0E0E0', // Jasny kolor tekstu dla ciemnego trybu
            secondary: '#BDBDBD'
        }
    }
});

export const themes: Themes = {
    oceanBlue: {
        palette: {
            primary: {
                light: '#BBDEFB',
                main: '#2196F3',
                dark: '#0D47A1'
            },
            secondary: {
                light: '#B2EBF2',
                main: '#00BCD4',
                dark: '#006064'
            }
        }
    },
    greenLeaf: {
        palette: {
            primary: {
                light: '#C8E6C9',
                main: '#4CAF50',
                dark: '#1B5E20'
            },
            secondary: {
                light: '#CFD8DC',
                main: '#607D8B',
                dark: '#37474F'
            }
        }
    },
    money: {
        palette: {
            primary: {
                light: '#C8E6C9',
                main: '#4CAF50',
                dark: '#2E7D32'
            },
            secondary: {
                light: '#FFECB3',
                main: '#FFA000',
                dark: '#FF6F00'
            }
        }
    },
    grayscale: {
        palette: {
            primary: {
                light: '#CFD8DC',
                main: '#546E7A',
                dark: '#263238'
            },
            secondary: {
                light: '#D7CCC8',
                main: '#6D4C41',
                dark: '#3E2723'
            }
        }
    },
    cloud: {
        palette: {
            primary: {
                light: '#B3E5FC',
                main: '#03A9F4',
                dark: '#01579B'
            },
            secondary: {
                light: '#FFE0B2',
                main: '#FF9800',
                dark: '#E65100'
            }
        }
    },
    joker: {
        palette: {
            primary: {
                light: '#D1C4E9',
                main: '#673AB7',
                dark: '#311B92'
            },
            secondary: {
                light: '#B2EBF2',
                main: '#00BCD4',
                dark: '#006064'
            }
        }
    },
    violet: {
        palette: {
            primary: {
                light: '#F8BBD0',
                main: '#EC407A',
                dark: '#880E4F'
            },
            secondary: {
                light: '#D1C4E9',
                main: '#673AB7',
                dark: '#311B92'
            }
        }
    },
    violeta: {
        palette: {
            primary: {
                light: '#E1BEE7',
                main: '#9C27B0',
                dark: '#6A1B9A'
            },
            secondary: {
                light: '#DCEDC8',
                main: '#8BC34A',
                dark: '#558B2F'
            }
        }
    },
    roseGold: {
        palette: {
            primary: {
                light: '#FCE4EC',
                main: '#EC407A',
                dark: '#D81B60',
                contrastText: '#fff',
            },
            secondary: {
                light: '#FFF8E1',
                main: '#FFA000',
                dark: '#FF6F00',
                contrastText: '#fff',
            },
        },
    },
    deepBlue: {
        palette: {
            primary: {
                light: '#E8EAF6',
                main: '#3F51B5',
                dark: '#283593',
                contrastText: '#fff',
            },
            secondary: {
                light: '#E1F5FE',
                main: '#03A9F4',
                dark: '#0277BD',
                contrastText: '#fff',
            },
        },
    },
    ubuntu: {
        palette: {
            primary: {
                light: '#FFF3E0',
                main: '#EF6C00',
                dark: '#E65100',
                contrastText: '#fff',
            },
            secondary: {
                light: '#F3E5F5',
                main: '#9C27B0',
                dark: '#7B1FA2',
                contrastText: '#fff',
            },
        },
    },
    geenNature: {
        palette: {
            primary: {
                light: '#E0F7FA',
                main: '#009688',
                dark: '#00695C',
                contrastText: '#fff',
            },
            secondary: {
                light: '#F1F8E9',
                main: '#689F38',
                dark: '#33691E',
                contrastText: '#fff',
            },
        },
    },
    vampire: {
        palette: {
            primary: {
                light: '#FFEBEE',
                main: '#f44336',
                dark: '#E53935',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ECEFF1',
                main: '#607D8B',
                dark: '#455A64',
                contrastText: '#fff',
            },
        },
    },
    mint: {
        palette: {
            primary: {
                light: '#bbdefb',
                main: '#2196f3',
                dark: '#0d47a1',
                contrastText: '#fff',
            },
            secondary: {
                light: '#b3e5fc',
                main: '#03a9f4',
                dark: '#01579b',
                contrastText: '#fff',
            },
        },
    },
    oceanSky: {
        palette: {
            primary: {
                light: '#E3F2FD',
                main: '#2196F3',
                dark: '#1565C0',
                contrastText: '#fff',
            },
            secondary: {
                light: '#E0F2F1',
                main: '#00BFA5',
                dark: '#00796B',
                contrastText: '#fff',
            },
        },
    },
    gold: {
        palette: {
            primary: {
                light: '#FFF9C4',
                main: '#FF9100',
                dark: '#FF6D00',
                contrastText: '#fff',
            },
            secondary: {
                light: '#EFEBE9',
                main: '#8D6E63',
                dark: '#4E342E',
                contrastText: '#fff',
            },
        },
    },
    botani: {
        palette: {
            primary: {
                light: '#DCEDC8',
                main: '#689F38',
                dark: '#33691E',
                contrastText: '#fff',
            },
            secondary: {
                light: '#FCE4EC',
                main: '#F06292',
                dark: '#AD1457',
                contrastText: '#fff',
            },
        },
    },
    littleQueen: {
        palette: {
            primary: {
                light: '#E0F7FA',
                main: '#00BCD4',
                dark: '#006064',
                contrastText: '#fff',
            },
            secondary: {
                light: '#FCE4EC',
                main: '#F06292',
                dark: '#AD1457',
                contrastText: '#fff',
            },
        },
    },
    purple: {
        palette: {
            primary: {
                light: '#f3e5f5',
                main: '#9c27b0',
                dark: '#6a1b9a',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ea80fc',
                main: '#aa00ff',
                dark: '#4a148c',
                contrastText: '#fff',
            },
        },
    },
    black: {
        palette: {
            primary: {
                light: '#9e9e9e',
                main: '#212121',
                dark: '#000',
                contrastText: '#fff',
            },
            secondary: {
                light: '#E0E0E0',
                main: '#757575',
                dark: '#424242',
                contrastText: '#fff',
            },
        },
    },
    brown: {
        palette: {
            primary: {
                light: '#d7ccc8',
                main: '#795548',
                dark: '#4e342e',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ccb0b0',
                main: '#794848',
                dark: '#3e2723',
                contrastText: '#fff',
            },
        },
    }
};