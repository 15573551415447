import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import NotFound from "../../app/errors/NotFound";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { subservicesSelectors, fetchSubserviceAsync } from "./subservicesSlice";
import AppReturn from "../../app/components/AppReturn";

export default function SubsubserviceDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const subservice = useAppSelector(state => subservicesSelectors.selectById(state, id!));
    const { status: subserviceStatus } = useAppSelector(state => state.subservices);

    useEffect(() => {
        if (!subservice) dispatch(fetchSubserviceAsync(parseInt(id!)))
    }, [id, dispatch, subservice])

    if (subserviceStatus.includes('pending')) return <LoadingComponent />

    if (!subservice) return <NotFound />

    // Dzielenie treści na poszczególne efekty
    const effectsList = subservice.effects ? subservice.effects.split(';').filter(effect => effect.trim() !== '') : [];

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Podusługa #" + subservice.id} backPath="/subservices"/>
            
            <Box component={Paper} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                    {subservice.id}
                </Typography>
                <Typography variant='h6' sx={{ mb: 4 }}>
                    {subservice.name}
                </Typography>
                <Typography variant='h6'>
                    Efekty:
                    {effectsList.length > 0 && (
                        <ol>
                            {effectsList.map((effect, index) => (
                                <li key={index}>{effect.trim()}</li>
                            ))}
                        </ol>
                    )}
                </Typography>
            </Box>
        </>
    );
}