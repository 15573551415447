import { Grid, Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import { router } from "../../app/router/Routes";

export default function EditAccountDataForm() {
    const { control } = useFormContext();

    function handleChangeEmail() {
        router.navigate('/account/changeEmail')
    }

    return (
        <>
            <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name="lastName" label="Nazwisko" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name="firstName" label="Imię" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name="phone" label="Telefon" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button onClick={handleChangeEmail} size='large' variant='outlined'
                        sx={{
                            mt: 1,
                            borderRadius: '15px',
                            color: "primary.light"
                        }}>Zmień email</Button>
                </Grid>
            </Grid>
        </>
    )
}