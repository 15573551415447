import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Resolver, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import AppTextInput from "../../../app/components/AppTextInput";
import { Investment } from "../../../app/models/investment";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { fetchAdminInvestmentAsync, setAdminInvestment } from "./adminInvestmentsSlice";
import AppDateTimePicker from "../../../app/components/AppDateTimePicker";
import AppNumberInput from "../../../app/components/AppNumberInput";
import AppSelect from "../../../app/components/AppSelect";
import { getInvestmentStatusTitle, states } from "../../../app/util/util";
import AppSelectStringsList from "../../../app/components/AppSelectStringsList";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppReturn from "../../../app/components/AppReturn";
import { fetchClientInvestmentAsync, setClientInvestment } from "../../client/clientInvestments/clientInvestmentsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { adminInvestmentValidationSchema } from "./adminInvestmentValidation";

interface Props {
    adminInvestment?: Investment;
    cancelEdit: () => void;
}

interface InvestmentFormValues {
    investmentName: string;
    clientComment: string;
    competition: string;
    submissionDeadline?: string;
    price: number;
    state?: number;
    publicId?: string;
    isActive: boolean;
    isLastModify: boolean;
    clientInstitutionName?: string;
    clientLastname?: string;
    clientFirstname?: string;
    clientPhone?: string;
    clientEmail?: string;
    clientStreet?: string;
    clientBuilding?: string;
    clientPremises?: string;
    clientZip?: string;
    clientCity?: string;
    clientState?: string;
    clientCountry?: string;
    clientNIP?: string;
    clientREGON?: string;
    clientKRS?: string;
    payerInstitutionName?: string;
    payerRepresentative?: string;
    payerNIP?: string;
    payerStreet?: string;
    payerBuilding?: string;
    payerPremises?: string;
    payerZip?: string;
    payerCity?: string;
    payerState?: string;
    payerCountry?: string;
    projectName?: string;
    projectDateStart?: string;
    projectDateEnd?: string;
    projectMaxBudget?: number;
    projectDescription?: string;
    clientExperience?: string;
    clientPotential?: string;
    publicComment?: string;
    internalComment?: string;
    isNowCreated: boolean;
}

export default function AdminInvestmentForm({ adminInvestment, cancelEdit }: Props) {
    const resolver = useMemo(() => yupResolver(adminInvestmentValidationSchema(adminInvestment)), [adminInvestment]);

    const { control, reset, handleSubmit, formState: { errors, isDirty, isSubmitting } } = useForm<InvestmentFormValues>({
        resolver: resolver as unknown as Resolver<InvestmentFormValues>,
    });
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.account);

    useEffect(() => {
        if (adminInvestment && !isDirty) {
            reset({
                ...adminInvestment,
                publicId: adminInvestment.publicId?.toString(),
                projectDateStart: adminInvestment.projectDateStart ? new Date(adminInvestment.projectDateStart).toISOString() : undefined,
                projectDateEnd: adminInvestment.projectDateEnd ? new Date(adminInvestment.projectDateEnd).toISOString() : undefined
            });
        }
    }, [adminInvestment, reset, isDirty]);

    async function handleSubmitData(data: InvestmentFormValues) {
        data.publicComment = data.publicComment === undefined ? "" : data.publicComment;
        data.internalComment = data.internalComment === undefined ? "" : data.internalComment;

        try {
            let response: Investment;
            if (adminInvestment) {
                response = await agent.Admin.Investments.update(data);
                console.log(response);
                toast.success('Uaktualniono projekt inwestycyjny');
            } else {
                data.isNowCreated = true;
                response = await agent.Admin.Investments.create(data);
                console.log(response);
                toast.success('Utworzono projekt inwestycyjny');
            }
            dispatch(setAdminInvestment(response));
            dispatch(setClientInvestment(response));
            dispatch(fetchAdminInvestmentAsync(response.id))
            dispatch(fetchClientInvestmentAsync(response.id))
            cancelEdit();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.values(errors).map(error => error?.message ?? '').join(', ');
            toast.error(`Sprawdź formularz: ${errorMessages}`);
        }
    }, [errors]);

    function fillTestData() {
        const testData: Partial<InvestmentFormValues> = {
            state: adminInvestment?.state === undefined ? 0 : 1,
            isActive: true,
            isLastModify: true,
            investmentName: "Test investmentName",
            clientComment: "Test clientComment",
            competition: "Test competition",
            submissionDeadline: new Date().toISOString(),
            publicComment: "TestAdmin publicComment",
            price: 1234,
            internalComment: "TestAdmin internalComment",
        };
        reset(testData);
    }

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn
                title={"Edytuj"}
                cancelEdit={cancelEdit}
                fillTestData={!!user && user.roles?.includes('Admin') ? fillTestData : undefined}
                isAdmin={!!user && user.roles?.includes('Admin')}
            />
            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    {adminInvestment ? (
                        <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Nr projektu: {adminInvestment.publicId}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Zamawiający: {adminInvestment.userDisplayName}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Status: {getInvestmentStatusTitle(adminInvestment.state)}</Typography>
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5">Wypełnione przez klienta:</Typography>
                            <Divider sx={{ mb: 1, mt: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='investmentName' label='Twoja nazwa projektu' />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='clientComment' label='Krótki komentarz/opis' multiline rows={3} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='competition' label='Rodzaj naboru/konkursu lub link do naboru' multiline rows={3} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppDateTimePicker control={control} name='submissionDeadline' label='Termin składania wniosków' disabled={false} />
                        </Grid>
                        {adminInvestment?.state === 0 ? null : (
                            <>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h5">Wypełnione przez admina:</Typography>
                                    <Divider sx={{ mb: 1, mt: 1 }} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='publicComment' label='Komentarz' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppNumberInput control={control} name='price' label='Wycena' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='internalComment' label='Wewnętrzny komentarz (niewidoczny dla użytkownika)' />
                                </Grid>
                                {adminInvestment?.state === 1 || adminInvestment?.state === 2 ? (
                                    <Grid item xs={12} sm={12} md={6}>
                                        <AppSelect
                                            control={control}
                                            name='state'
                                            label='Akceptuj/Odrzuć'
                                            items={[
                                                { label: 'Akceptuj', value: 1 },
                                                { label: 'Odrzuć', value: 2 }
                                            ]}
                                        />
                                    </Grid>
                                ) : null}
                            </>
                        )}
                        {adminInvestment?.state === 3 || adminInvestment?.state === 5 ? (
                            <>
                                <Grid item xs={12} sm={12}>
                                    <Divider sx={{ mt: 3 }} />
                                    <Typography variant="h4" sx={{ mt: 3 }}>
                                        Dane klienta:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='clientInstitutionName' label='Nazwa instytucji' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='clientLastname' label='Nazwisko' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='clientFirstname' label='Imię' />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextInput control={control} name='clientPhone' label='Telefon' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextInput control={control} name='clientEmail' label='Email' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextInput control={control} name='clientStreet' label='Ulica' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='clientBuilding' label='Budynek' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='clientPremises' label='Nr lokalu' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='clientZip' label='Kod pocztowy' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='clientCity' label='Miasto' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppSelectStringsList control={control} name='clientState' label='Województwo' items={states} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='clientCountry' label='Kraj' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='clientNIP' label='NIP' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='clientREGON' label='REGON' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='clientKRS' label='KRS' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Divider sx={{ mt: 3 }} />
                                    <Typography variant="h4" sx={{ mt: 3 }}>
                                        Dane płatnika:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='payerInstitutionName' label='Nazwa instytucji płatnika' />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextInput control={control} name='payerRepresentative' label='Przedstawiciel' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextInput control={control} name='payerNIP' label='NIP płatnika' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextInput control={control} name='payerStreet' label='Ulica' />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='payerBuilding' label='Budynek' />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='payerPremises' label='Nr lokalu' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='payerZip' label='Kod pocztowy' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='payerCity' label='Miasto' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppSelectStringsList control={control} name='payerState' label='Województwo' items={states} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <AppTextInput control={control} name='payerCountry' label='Kraj' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Divider sx={{ mt: 3 }} />
                                    <Typography variant="h4" sx={{ mt: 3 }}>
                                        Dane projektu:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='projectName' label='Nazwa projektu' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppDateTimePicker control={control} name='projectDateStart' label='Data rozpoczęcia projektu' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppDateTimePicker control={control} name='projectDateEnd' label='Data końca projektu' disabled={false} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AppTextInput control={control} name='projectMaxBudget' label='Maksymalny budżet' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='projectDescription' label='Opis projektu' disabled={false} multiline rows={4} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Divider sx={{ mt: 3 }} />
                                    <Typography variant="h4" sx={{ mt: 3 }}>
                                        Inne:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='clientExperience' label='Doświadczenie klienta' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='clientPotential' label='Potencjał klienta' disabled={false} />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelEdit} variant='outlined' color='primary'>Anuluj</Button>
                        <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                    </Box>
                </form >
            </Box >
        </>
    );
}