import { Select, FormControl, InputLabel, MenuItem, Box, FormHelperText } from '@mui/material';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

interface SelectProps<T extends FieldValues> extends UseControllerProps<T> {
    label: string;
    items: { label: string; value: any }[];
    disabled?: boolean;  // Dodana właściwość disabled
}

function AppSelect<T extends FieldValues>({ control, name, label, items, disabled }: SelectProps<T>) {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error },
    } = useController<T>({
        name,
        control,
        defaultValue: items.length > 0 ? items[0].value : '',
    });

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth error={invalid} disabled={disabled}>
                <InputLabel id={`${name}-label`}>{label}</InputLabel>
                <Select
                    labelId={`${name}-label`}
                    id={name}
                    value={value ?? ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    label={label}
                    disabled={disabled}
                >
                    {items.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
        </Box>
    );
}

export default AppSelect;