import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import useQuery from "../../app/util/hooks";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ForwardToInboxOutlined } from "@mui/icons-material";
import { router } from "../../app/router/Routes";

export default function ConfirmEmail() {

    const email = useQuery().get('email') as string;
    const token = useQuery().get('token') as string;

    const Status = {
        Verifying: 'Verifying',
        Failed: 'Failed',
        Success: 'Success'
    }

    const [status, setStatus] = useState(Status.Verifying);

    function handleConfirmEmailResend() {
        agent.Account.resendEmailConfirm(email).then(() => {
            toast.success('Email weryfikacyjny przesłany ponownie - sprawdź swoją pocztę email');
        }).catch(error => console.log(error));
    }

    useEffect(() => {
        agent.Account.verifyEmail(token, email).then(() => {
            setStatus(Status.Success)
        }).catch(() => {
            setStatus(Status.Failed)
        })
    }, [Status.Failed, Status.Success, token, email])

    function getBody() {
        switch (status) {
            case Status.Verifying:
                return <p>Weryfikuję...</p>;
            case Status.Failed:
                return (
                    <div>
                        <Typography color={(theme) => theme.palette.text.primary} variant='body1' gutterBottom marginBottom={2}>Weryfikacja zakończona niepowodzeniem. Możesz ponowić wysłanie emaila weryfikacyjnego.</Typography>
                        <Button variant='outlined' onClick={handleConfirmEmailResend} >Prześlij Email ponownie</Button>
                    </div>
                );
            case Status.Success:
                return (
                    <div>
                        <Typography color={(theme) => theme.palette.text.primary} variant='body1' gutterBottom marginBottom={2}>Email został zweryfikowany - teraz możesz się zalogować.</Typography>
                        <Button variant='outlined' onClick={() => { router.navigate('/login') }} >Logowanie</Button>
                    </div>
                )
        }
    }

    return (
        <Box sx={{ textAlign: "center" }}>
            <Typography color={(theme) => theme.palette.text.primary} variant='h3' gutterBottom >
                <ForwardToInboxOutlined fontSize="large" sx={{ marginRight: 2}}  />
                Weryfikacja adresu email
            </Typography>
            {getBody()}
        </Box>
    )
}