import { Grid } from "@mui/material";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import AdminPageCard from "./AdminPageCard";

export default function AdminPages() {
    const pages = [
        { displayName: 'Usługi egzaminacyjne', link: "/admin/orders", description: 'Zamówienia', icon: 'SchoolIcon', iconBgColor: 'secondary.dark' },
        { displayName: 'Projekty inwestycyjne', link: "/admin/investments", description: 'Projekty inwestycyjne', icon: 'AddBusinessIcon', iconBgColor: 'primary.main' },
        { displayName: 'Katalog egzaminów', link: "/services", description: 'Katalog egzaminów', icon: 'FormatListNumberedIcon', iconBgColor: 'secondary.main' },
        { displayName: 'Podusługi', link: "/subservices", description: 'Katalog podusług', icon: 'FormatListBulletedIcon', iconBgColor: 'primary.main' },
        { displayName: 'Użytkownicy', link: "/admin/users", description: 'Użytkownicy', icon: 'GroupsIcon', iconBgColor: 'red' },
        { displayName: 'Dokumenty', link: "/admin/documents", description: 'Dokumenty', icon: 'DescriptionIcon', iconBgColor: 'green' },
        { displayName: 'Umowy', link: "/admin/agreements", description: 'Umowy', icon: 'HandshakeIcon', iconBgColor: 'primary.main' },
        // { displayName: 'Konfiguracja systemu', link: "/admin/systemConfigurations", description: 'Konfiguracja systemu', icon: 'BuildIcon', iconBgColor: 'primary.main' }
    ]

    return (
        <>
            <AppBreadcrumbs />
            <Grid container columnSpacing={4}>
                <Grid item xs={12} >
                <Grid container spacing={4}>
                        {pages.map((item) => (
                            <Grid key={item.link} item xs={12} sm={6} md={4}>
                                <AdminPageCard displayName={item.displayName} link={item.link} description={item.description} icon={item.icon} iconBgColor={item.iconBgColor} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {/* <Button onClick={() => agent.Admin.Orders.recalculatePrices()}>Orders Recalculate</Button>
                <Button onClick={() => agent.Services.recalculatePrices()}>Services Recalculate</Button> */}
            </Grid>
        </>
    )
}