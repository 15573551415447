import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import { useForm, FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import AppTextInput from "../../../app/components/AppTextInput";
import { useAppDispatch } from "../../../app/store/configureStore";
import { setDocument } from "./documentsSlice";
import { Document } from "../../../app/models/document";
import AppDropzone from "../../../app/components/AppDropzone";
import AppCheckbox from "../../../app/components/AppCheckbox";
import AutocompleteInputOption from "../../../app/models/autocompleteInputOption";
import AppReturn from "../../../app/components/AppReturn";
import { setClientDocument } from "../../client/documents/clientDocumentsSlice";
import AppAutocomplete from "../../../app/components/AppAutocomplete";

interface Props {
    document?: Document;
    cancelEdit: () => void;
}

export default function DocumentForm({ document, cancelEdit }: Props) {
    const { control, reset, handleSubmit, formState: { isDirty, isSubmitting }, watch, setValue } = useForm({
        // resolver: yupResolver(documentValidationSchema)
    });
    const dispatch = useAppDispatch();
    const watchFile = watch('file', null);
    const watchIsContract = watch('isContract', true);
    const [preSignedUrl, setPreSignedUrl] = useState<string | null>(null);

    const [possibleAgreements, setPossibleAgreements] = useState<AutocompleteInputOption[]>([]);
    useEffect(() => {
        agent.Admin.Agreements.fetchPossibleAgreements()
            .then(result => setPossibleAgreements(result))
            .catch(error => console.log(error))
    }, []);

    useEffect(() => {
        if (document && document.filePath) {
            agent.Admin.Documents.getPreSignedUrl(document.id)
                .then(response => setPreSignedUrl(response.preSignedUrl))
                .catch(error => console.error("Błąd pobierania pre-signed URL:", error));
        }
    }, [document]);

    useEffect(() => {
        if (document && !isDirty) reset(document);
    }, [document, reset, isDirty]);

    const handleIsContractChange = (checked: boolean) => {
        if (!checked) {
            setValue('agreementId', 0);
        }
    };

    async function handleSubmitData(data: FieldValues) {
        if (!data.isContract) {
            data.agreementId = 0;
        }

        console.log("Submitting data:", data);
        try {
            data.isContract = data.isContract ?? true;
            let response: Document;
            if (document) {
                response = await agent.Admin.Documents.update(data);
                toast.success('Uaktualniono dokument');
            } else {
                response = await agent.Admin.Documents.create(data);
                toast.success('Utworzono dokument');
            }
            dispatch(setDocument(response));
            dispatch(setClientDocument(response));
            cancelEdit();
        } catch (error) {
            console.log(error);
        }
    }

    const nameValidation = {
        required: "Nazwa dokumentu jest wymagana",
        minLength: { value: 3, message: "Nazwa dokumentu musi mieć co najmniej 3 znaki" }
    };

    return (
        <>
            <AppReturn title="Edycja dokumentu" cancelEdit={cancelEdit} />
            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    {document ? (
                        <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={6}>
                                <Typography variant="h6">Nr dokumentu: {document.id}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput
                                control={control}
                                name='name'
                                label='Nazwa dokumentu'
                                rules={nameValidation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='comment' label='Komentarz' multiline rows={3} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppCheckbox
                                control={control}
                                name='isContract'
                                label='Czy jest umową powiązaną z projektem inwestycyjnym?'
                                disabled={false}
                                defaultChecked={true}
                                onChange={handleIsContractChange}
                            />
                        </Grid>
                        {watchIsContract && (
                            <Grid item xs={12} sm={12}>
                                <AppAutocomplete
                                    control={control}
                                    name='agreementId'
                                    label='Wskaż umowę'
                                    items={possibleAgreements}
                                    selectedids={document?.agreementId ? document.agreementId.toString() : '0'}
                                    placeholder="Wskaż umowę"
                                    rules={{ required: 'Wybierz której umowy dotyczy dokument' }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end">
                                <AppDropzone control={control} name="file" acceptedFormats={"pdf,jpg,jpeg"} />
                                {watchFile ? (
                                    watchFile.name.endsWith('.pdf') ? (
                                        <Alert severity="info" sx={{ mt: 2 }}>Podgląd plików pdf dostępny jest po zapisaniu dokumentu</Alert>
                                    ) : (
                                        <img
                                            src={watchFile.preview}
                                            style={{ maxHeight: 200, marginTop: 35 }}
                                            alt="Podgląd załączonego obrazu"
                                        />
                                    )
                                ) : (
                                    document?.filePath === null || document?.filePath === "" || document?.filePath === undefined ?
                                        "Brak pliku" :
                                        preSignedUrl ? (
                                            <Link sx={{ mt: 2 }} href={preSignedUrl} target="_blank">
                                                Podgląd dokumentu (niepodpisany)
                                            </Link>
                                        ) : (
                                            <Typography variant="overline">Ładowanie linku do pliku...</Typography>
                                        )
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelEdit} variant='outlined' color='primary'>Anuluj</Button>
                        <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                    </Box>
                </form>
            </Box >
        </>
    );
}