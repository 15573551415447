import { useState, useEffect, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MobileMenu from './MobileMenu';
import useStyles from './headerStyle';
import { Link } from 'react-router-dom';
import { brand } from '../brand';
import logo from '../wechsler_logo_w.svg';
import navMenu from './menu';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { signOut } from '../../../features/account/accountSlice';

interface Props {
  onToggleDark: () => void;
  onToggleDir: () => void;
  invert?: boolean;
  darkMode: boolean;
}

function HomePageHeader({ invert, onToggleDark, darkMode: initialDarkMode }: Props) {
  // Theme breakpoints
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { user } = useAppSelector(state => state.account);
  const [darkMode, setDarkMode] = useState(initialDarkMode);
  const dispatch = useAppDispatch();

  const handleToggleDark = () => {
    onToggleDark();
    setDarkMode(prevMode => !prevMode);
  };

  const [fixed, setFixed] = useState(true);

  const handleScroll = useCallback(() => {
    const doc = document.documentElement;
    const scroll =
      (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    setFixed(scroll > 80);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const { classes, cx } = useStyles() as { classes: Record<string, string>, cx: any };

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      {isMobile && <MobileMenu open={openDrawer} toggleDrawer={handleOpenDrawer} />}
      <AppBar
        component="div"
        position="relative"
        id="header"
        className={cx(
          classes.header,
          fixed && classes.fixed,
          openDrawer && classes.openDrawer
        )}
      >
        <Container>
          <div className={classes.headerContent}>
            <nav className={cx(classes.navLogo, invert && classes.invert)}>
              {isMobile && (
                <IconButton
                  onClick={handleOpenDrawer}
                  className={cx('hamburger hamburger--spin', classes.mobileMenu, openDrawer && 'is-active')}
                  size="large"
                >
                  <span className="hamburger-box">
                    <span className={cx(classes.bar, 'hamburger-inner')} />
                  </span>
                </IconButton>
              )}
              <div className={classes.logo}>
                <Link to='/'>
                  <img src={logo} alt="logo" />
                  {brand.agency.name}
                </Link>
              </div>
            </nav>
            <nav className={cx(classes.navMenu)}>
              {isDesktop && (
                <ul>
                  {navMenu.map((item) => (
                    <li key={item.name}>
                      <Button component={Link} to={item.path}>
                        {item.name}
                      </Button>
                    </li>
                  ))}

                  <li>
                    <Button href={`${'/client'}`}>
                      Strefa Klienta
                    </Button>
                  </li>

                  {user && user.roles?.includes('Admin') && (
                    <li>
                      <Button href={`${'/admin'}`}>
                        Admin
                      </Button>
                    </li>
                  )}

                  {user ? (
                    <li>
                      <Button component={Link} to="/" onClick={() => dispatch(signOut())}>
                        Wyloguj
                      </Button>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Button component={Link} to="/login">
                          Zaloguj
                        </Button>
                      </li>
                      <li>
                        <Button component={Link} to="/register">
                          Rejestracja
                        </Button>
                      </li>
                    </>
                  )}
                </ul>
              )}

              <div>
                <IconButton
                  sx={{
                    mr: 3,
                    color: darkMode ? theme.palette.common.white : (fixed || isMobile) ? theme.palette.common.black : theme.palette.common.white
                  }}
                  onClick={handleToggleDark}
                >
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </div>
            </nav>
          </div>
        </Container>
      </AppBar>
    </>
  );
}

export default HomePageHeader;