import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ChatComment } from "../../../app/models/comment";
import { RootState } from "../../../app/store/configureStore";

const commentsAdapter = createEntityAdapter<ChatComment>();

export const clientHelpInInvestmentCommentsSlice = createSlice({
    name: "clientHelpInInvestmentComments",
    initialState: commentsAdapter.getInitialState({
        status: 'idle',
        helpInClientInvestmentCommentsLoaded: false,
        selectedClientInvestment: 0,
        messages: []
    }),
    reducers: {
        setSelectedClientInvestment: (state, action) => {
            state.selectedClientInvestment = action.payload;
        },
        setComments: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        },
        updateMessage: (state, action) => {
            commentsAdapter.upsertOne(state, action.payload);
        },
        loadMessage: (state, action) => {
            commentsAdapter.setAll(state, action.payload);
        }
    }
});

export const clientHelpInInvestmentCommentsSelectors = commentsAdapter.getSelectors((state: RootState) => state.clientHelpInInvestmentComments);

export const {setSelectedClientInvestment, updateMessage, loadMessage, setComments } = clientHelpInInvestmentCommentsSlice.actions;