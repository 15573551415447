import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useText } from '../../theme/common';
import useStyles from './counterStyle';
import { useMediaQuery, useTheme } from '@mui/material';

function Counter() {
//   const { t } = useTranslation('common');
  const { classes } = useStyles();
  const { classes: text } = useText();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.counterWrap}>
      <Container fixed>
        {/* Dodaj warunek isMobile tutaj */}
        {isMobile ? (
          <Grid container justify-content="center" alignItems="center" spacing={6}>
            <Grid item xs={12}> {/* Użyj xs={12} dla pełnej szerokości na małych ekranach */}
              <div className={classes.counterItem}>
                <div className={classes.text}>
                  <Typography variant="h3" className={text.title}>
                    +200
                  </Typography>
                  <Typography component="p" className={text.subtitle}>
                    Liczba Klientów
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}> {/* Użyj xs={12} dla pełnej szerokości na małych ekranach */}
              <div className={classes.counterItem}>
                <div className={classes.text}>
                  <Typography variant="h3" className={text.title}>
                    +500
                  </Typography>
                  <Typography component="p" className={text.subtitle}>
                    Przeprowadzone egzaminy
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}> {/* Użyj xs={12} dla pełnej szerokości na małych ekranach */}
              <div className={classes.counterItem}>
                <div className={classes.text}>
                  <Typography variant="h3" className={text.title}>
                    +300
                  </Typography>
                  <Typography component="p" className={text.subtitle}>
                    Pakiety egzaminacyjne
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}> {/* Użyj xs={12} dla pełnej szerokości na małych ekranach */}
              <div className={classes.counterItem}>
                <div className={classes.text}>
                  <Typography variant="h3" className={text.title}>
                    +300
                  </Typography>
                  <Typography component="p" className={text.subtitle}>
                    Moduły egzaminów
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
        <Grid container justify-content="center" alignItems="center" spacing={6}>
          <Grid md={3} item>
            <div className={classes.counterItem}>
              <div className={classes.text}>
                <Typography variant="h3" className={text.title}>
                  +200
                </Typography>
                <Typography component="p" className={text.subtitle}>
                  {/* {t('agency-landing.about_employee')} */}
                 Liczba Klientów
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid md={3} item>
            <div className={classes.counterItem}>
              <div className={classes.text}>
                <Typography variant="h3" className={text.title}>
                  +500
                </Typography>
                <Typography component="p" className={text.subtitle}>
                  {/* {t('agency-landing.about_projects')} */}
                  Przeprowadzone egzaminy
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid md={3} item>
            <div className={classes.counterItem}>
              <div className={classes.text}>
                <Typography variant="h3" className={text.title}>
                  +300
                </Typography>
                <Typography component="p" className={text.subtitle}>
                  {/* {t('agency-landing.about_client')} */}
                  Pakiety egzaminacyjne
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid md={3} item>
            <div className={classes.counterItem}>
              <div className={classes.text}>
                <Typography variant="h3" className={text.title}>
                  +300
                </Typography>
                <Typography component="p" className={text.subtitle}>
                  {/* {t('agency-landing.about_client')} */}
                  Moduły egzaminów
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>)}
      </Container>
    </div>
  );
}

export default Counter;
