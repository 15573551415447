import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";
import { SystemConfiguration, SystemConfigurationParams } from "../../../app/models/systemConfiguration";

interface SystemConfigurationsState {
    systemConfigurationsLoaded: boolean;
    systemConfigurationsFiltersLoaded: boolean;
    status: string;
    selectedSystemConfiguration: number;
    // types: string[];
    systemConfigurationsParams: SystemConfigurationParams;
    metaData: MetaData | null;
}

const systemConfigurationsAdapter = createEntityAdapter<SystemConfiguration>();

export const fetchSystemConfigurationsAsync = createAsyncThunk<SystemConfiguration[], void, {state: RootState} >(
    'systemConfigurations/fetchSystemConfigurationsAsync',
    async (_,thunkAPI) => {
        // const params = getAxiosParams(thunkAPI.getState().systemConfigurations.systemConfigurationsParams);
        try {
            const response = await agent.Admin.SystemConfigurations.list();
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchSystemConfigurationAsync = createAsyncThunk<SystemConfiguration, string>(
    'systemConfigurations/fetchSystemConfigurationAsync',
    async (systemConfigurationName, thunkAPI) => {
        try {
            return await agent.Admin.SystemConfigurations.details(systemConfigurationName);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'dateDesc'
    }
}

export const systemConfigurationsSlice = createSlice({
    name: 'systemConfigurations',
    initialState: systemConfigurationsAdapter.getInitialState<SystemConfigurationsState>({
        systemConfigurationsLoaded: false,
        systemConfigurationsFiltersLoaded: false,
        status: 'idle',
        selectedSystemConfiguration: 0,
        // types: [],
        systemConfigurationsParams: initParams(),
        metaData: null
    }),
    reducers: {
        setSystemConfigurationsParams: (state, action) => {
            state.systemConfigurationsLoaded = false;
            state.systemConfigurationsParams = {...state.systemConfigurationsParams, ...action.payload, pageNumber: 1};
        },
        setSystemConfigurationsPageNumber: (state, action) => {
            state.systemConfigurationsLoaded = false;
            state.systemConfigurationsParams = {...state.systemConfigurationsParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetSystemConfigurationsParams: (state) => {
            state.systemConfigurationsParams = initParams();
        },
        setSystemConfiguration: (state, action) => {
            systemConfigurationsAdapter.upsertOne(state, action.payload);
            state.systemConfigurationsLoaded = false;
        },
        removeSystemConfiguration: (state, action) => {
            systemConfigurationsAdapter.removeOne(state, action.payload);
            state.systemConfigurationsLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchSystemConfigurationsAsync.pending, (state) => {
                state.status = 'pendingFetchSystemConfigurations';
            });
            builder.addCase(fetchSystemConfigurationsAsync.fulfilled, (state, action) => {
                systemConfigurationsAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.systemConfigurationsLoaded = true;
            });
            builder.addCase(fetchSystemConfigurationsAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchSystemConfigurationAsync.pending, (state) => {
                state.status = 'pendingFetchSystemConfiguration';
            });
            builder.addCase(fetchSystemConfigurationAsync.fulfilled, (state, action) => {
                systemConfigurationsAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchSystemConfigurationAsync.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const systemConfigurationsSelectors = systemConfigurationsAdapter.getSelectors((state: RootState) => state.systemConfigurations);

export const {setSystemConfigurationsParams, resetSystemConfigurationsParams, setMetaData, setSystemConfigurationsPageNumber, setSystemConfiguration, removeSystemConfiguration} = systemConfigurationsSlice.actions;