import { useState } from 'react';
import { Grid, Box, Avatar, Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ChatComment } from '../models/comment';

interface Props {
    comments: ChatComment[],
}

export default function AppDisplayComments({ comments }: Props) {
    const [showAll, setShowAll] = useState(false);

    const handleShowAllToggle = () => {
        setShowAll(!showAll);
    };

    const sortedComments = comments
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    const displayedComments = showAll
        ? sortedComments
        : sortedComments.slice(0, 2);

    return (
        <Grid container spacing={2} sx={{ flexGrow: 1, mt: 2 }}>
            {comments.length === 0 &&
                <Grid item>
                    <Typography>Brak komentarzy</Typography>
                </Grid>
            }
            {displayedComments.map(comment => (
                <Grid item xs={12} key={comment.id}>
                    <Card sx={{ bgcolor: comment.userIsAdmin ? '#e2e2e2' : '#bbdfff' }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: comment.userIsAdmin ? 'red' : 'green' }} title='Wydający/a dyspozycję'>
                                    <AccountCircleIcon />
                                </Avatar>
                            }
                            title={comment.userDisplayName}
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'black' }
                            }}
                        />
                        <CardContent>
                            <Typography variant='subtitle2' color='black'>
                                {comment.body}
                            </Typography>
                            <Typography variant="caption">
                                <Box display='flex' alignItems='center' color='black'>
                                    <span>
                                        <div>
                                            {'Wysłano: ' + new Date(comment.createdAt).toLocaleString('pl-PL', {
                                                day: '2-digit', // wyświetla dzień jako dwie cyfry
                                                month: '2-digit', // wyświetla miesiąc jako dwie cyfry
                                                year: 'numeric', // wyświetla rok jako cztery cyfry
                                                hour: '2-digit', // wyświetla godzinę jako dwie cyfry
                                                minute: '2-digit', // wyświetla minutę jako dwie cyfry
                                                second: 'numeric', // wyświetla sekundy
                                                hour12: false // używa 24-godzinnego formatu czasu
                                            }).replace(/:\d{2}$/, '')} {/* Usuwa sekundy z wyświetlanego ciągu */}
                                        </div>
                                        <div>
                                            {formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true, locale: pl })}
                                        </div>
                                    </span>
                                </Box>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }

            {comments.length > 2 &&
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button onClick={handleShowAllToggle}>
                        {showAll ? "Zwiń" : "Rozwiń"}
                    </Button>
                </Grid >
            }
        </Grid>
    );
}