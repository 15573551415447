import DoneIcon from '@mui/icons-material/Done';
import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { router } from '../../app/router/Routes';
import useQuery from "../../app/util/hooks";

export default function ForgotPasswordSuccess() {
    const email = useQuery().get('email') as string;
    const theme = useTheme();

    return (
        <Box sx={{textAlign: "center"}}>
            <Typography variant='h3' gutterBottom>
                <DoneIcon color="success" fontSize="large" sx={{ marginRight: 2}}  />
                Procedura odzyskiwania rozpoczęta!
            </Typography>
                
            <Typography style={{ color: theme.palette.text.primary }}>Proszę sprawdź swój adres email (ewentualnie też folder SPAM), by znaleźć email, w którym znajdziesz link do wygenerowania nowego hasła. Jeżeli wskazany email jest przyporządkowany do konta w naszym serwisie - zostanie przesłana stosowana wiadomość.</Typography>
            {email && 
                <>
                    <p style={{ color: theme.palette.text.primary }}>Email nie dotarł? Kliknij poniżej, wygenerować go ponownie</p>
                    <Button variant="outlined" onClick={() => router.navigate("/account/forgotPassword")}>
                        Odzyskaj hasło ponownie
                    </Button>
                </>
            }
        </Box>
    )
}