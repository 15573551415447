import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { accountSlice } from "../../features/account/accountSlice";
import { adminInvestmentsSlice } from "../../features/admin/adminInvestments/adminInvestmentsSlice";
import { adminUsersSlice } from "../../features/admin/users/adminUsersSlice";
import { clientInvestmentsSlice } from "../../features/client/clientInvestments/clientInvestmentsSlice";
import { adminOrdersSlice } from "../../features/admin/orders/adminOrdersSlice";
import { servicesSlice } from "../../features/services/servicesSlice";
import { subservicesSlice } from "../../features/subservices/subservicesSlice";
import { adminHelpInInvestmentCommentsSlice } from "../../features/admin/adminInvestments/adminHelpInInvestmentCommentsSlice";
import { adminHelpInOrderCommentsSlice } from "../../features/admin/orders/adminHelpInOrderCommentsSlice";
import { clientHelpInInvestmentCommentsSlice } from "../../features/client/clientInvestments/clientHelpInInvestmentCommentsSlice";
import { clientOrdersSlice } from "../../features/client/orders/clientOrdersSlice";
import { rolesSlice } from "../../features/admin/users/roles/rolesSlice";
import { documentsSlice } from "../../features/admin/documents/documentsSlice";
import { agreementsSlice } from "../../features/admin/agreements/agreementsSlice";
import { clientDocumentsSlice } from "../../features/client/documents/clientDocumentsSlice";
import { systemConfigurationsSlice } from "../../features/admin/systemConfigurations/systemConfigurationsSlice";
import { clientHelpInOrderCommentsSlice } from "../../features/client/orders/clientHelpInOrderCommentsSlice";
import { aboutsSlice } from "../../features/about/aboutsSlice";

export const store = configureStore({
    reducer: {
        account: accountSlice.reducer,
        adminUsers: adminUsersSlice.reducer,

        adminInvestments: adminInvestmentsSlice.reducer,
        adminHelpInInvestmentComments: adminHelpInInvestmentCommentsSlice.reducer,

        adminOrders: adminOrdersSlice.reducer,
        adminHelpInOrderComments: adminHelpInOrderCommentsSlice.reducer,

        systemConfigurations: systemConfigurationsSlice.reducer,

        clientInvestments: clientInvestmentsSlice.reducer,
        clientHelpInInvestmentComments: clientHelpInInvestmentCommentsSlice.reducer,
        clientOrders: clientOrdersSlice.reducer,
        clientHelpInOrderComments: clientHelpInOrderCommentsSlice.reducer,

        services: servicesSlice.reducer,
        subservices: subservicesSlice.reducer,
        roles: rolesSlice.reducer,
        documents: documentsSlice.reducer,
        clientDocuments: clientDocumentsSlice.reducer,
        agreements: agreementsSlice.reducer,
        abouts: aboutsSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;