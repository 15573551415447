import { Navigate, createBrowserRouter } from "react-router-dom";
import AboutPages from "../../features/about/AboutPages";
import Cerificate from "../../features/about/Certificate";
import Contact from "../../features/about/Contact";
import Institution from "../../features/about/Institution";
import Partners from "../../features/about/Partners";
import References from "../../features/about/References";
import CheckoutAccount from "../../features/account/CheckoutAccount";
import ConfirmEmail from "../../features/account/ConfirmEmail";
import EditAccount from "../../features/account/EditAccount";
import ForgotPassword from "../../features/account/ForgotPassword";
import ForgotPasswordSuccess from "../../features/account/ForgotPasswordSuccess";
import Login from "../../features/account/Login";
import Register from "../../features/account/Register";
import RegisterSuccess from "../../features/account/RegisterSuccess";
import ResetPassword from "../../features/account/ResetPassword";
import ResetPasswordSuccess from "../../features/account/ResetPasswordSuccess";
import AdminInvestmentDetails from "../../features/admin/adminInvestments/AdminInvestmentDetails";
import AdminInvestments from "../../features/admin/adminInvestments/displayCardsManager/AdminInvestments";
import AdminPages from "../../features/admin/AdminPages";
import AdminOrderDetails from "../../features/admin/orders/AdminOrderDetails";
import AdminOrders from "../../features/admin/orders/displayCardsManager/AdminOrders";
import AdminUsersManager from "../../features/admin/users/AdminUsersManager";
import ClientInvestmentDetails from "../../features/client/clientInvestments/ClientInvestmentDetails";
import ClientInvestments from "../../features/client/clientInvestments/displayCardsManager/ClientInvestments";
import ClientZonePages from "../../features/client/ClientZonePages";
import ErrorsPage from "../../features/errors/ErrorsPage";
import Services from "../../features/services/displayCardsManager/Services";
import ServiceDetails from "../../features/services/ServiceDetails";
import NotFound from "../errors/NotFound";
import ServerError from "../errors/ServerError";
import App from "../layout/App";
import RequireAuth from "./RequireAuth";
import ClientOrders from "../../features/client/orders/displayCardsManager/ClientOrders";
import ClientOrderDetails from "../../features/client/orders/ClientOrderDetails";
import ChangeEmail from "../../features/account/ChangeEmail";
import ChangeEmailSuccess from "../../features/account/ChangeEmailSuccess";
import Privacy from "../../features/rodo/Privacy";
import Regulations from "../../features/rodo/Regulations";
import DocumentsManager from "../../features/admin/documents/tableManager/DocumentsManager";
import DocumentDetails from "../../features/admin/documents/DocumentDetails";
import Agreements from "../../features/admin/agreements/displayCardsManager/Agreements";
import AgreementDetails from "../../features/admin/agreements/AgreementDetails";
import Subservices from "../../features/subservices/displayCardsManager/Subservices";
import SubserviceDetails from "../../features/subservices/SubserviceDetails";
import ClientDocumentsManager from "../../features/client/documents/tableManger/ClientDocumentsManager";
// import SystemConfigurations from "../../features/admin/systemConfigurations/SystemConfigurations";


export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [

            {
                element: <RequireAuth />, children: [
                    /* Konto użytkownika */
                    { path: 'account/editAccount', element: <EditAccount /> },
                    { path: 'account/checkoutAccount', element: <CheckoutAccount /> },
                    { path: 'account/changeEmail', element: <ChangeEmail /> },
                    { path: 'account/changeEmailSuccess', element: <ChangeEmailSuccess /> },

                    /* Admin: page */
                    { path: 'admin', element: <AdminPages /> },

                    /* Admin: Użytkownicy */
                    { path: 'admin/users', element: <AdminUsersManager /> },
                    //{path: '/admin/users/:id', element: <UserById />},

                    /* Admin: Projekty inwestycyjne */
                    { path: 'admin/investments', element: <AdminInvestments /> },
                    { path: 'admin/investments/:id', element: <AdminInvestmentDetails /> },

                    // { path: 'admin/investments/valuation/:id', element: <AdminInvestmentValuation /> },

                    /* Admin: Zamówienia */
                    { path: 'admin/orders', element: <AdminOrders /> },
                    { path: 'admin/orders/:id', element: <AdminOrderDetails /> },

                    /* Admin: Dokumenty */
                    { path: 'admin/documents', element: <DocumentsManager /> },
                    { path: 'admin/documents/:id', element: <DocumentDetails /> },

                    /* Strefa Klienta */
                    { path: 'client', element: <ClientZonePages /> },

                    /* Client: Dokumenty */
                    { path: 'client/documents', element: <ClientDocumentsManager />},

                    /* Projekty inwestycyjne */
                    { path: 'client/investments', element: <ClientInvestments /> },
                    { path: 'client/investments/:id', element: <ClientInvestmentDetails /> },

                    /* Zamówienia */
                    { path: 'client/orders', element: <ClientOrders /> },
                    { path: 'client/orders/:id', element: <ClientOrderDetails /> },

                    /* Agreements/Umowy */
                    { path: 'admin/agreements', element: <Agreements /> },
                    { path: 'admin/agreements/:id', element: <AgreementDetails /> },

                    // { path: 'admin/systemConfigurations', element: <SystemConfigurations /> },
                ]
            },

            { path: 'contact', element: <Contact /> },

            /* O Centrum Egzaminacyjnym */
            { path: 'about/institution', element: <Institution /> },
            { path: 'about/references', element: <References /> },
            { path: 'about/partners', element: <Partners /> },
            { path: 'about/contact', element: <Contact /> },
            { path: 'about', element: <AboutPages /> },
            { path: 'about/certificate', element: <Cerificate /> },
            
            { path: 'about/privacy', element: <Privacy /> },
            { path: 'about/regulations', element: <Regulations /> },
            
            /* Konto użytkownika */
            { path: 'login', element: <Login /> },
            { path: 'register', element: <Register /> },
            { path: 'account/registerSuccess', element: <RegisterSuccess /> },
            { path: 'account/verifyEmail', element: <ConfirmEmail /> },
            { path: 'account/forgotPassword', element: <ForgotPassword /> },
            { path: 'account/forgotPasswordSuccess', element: <ForgotPasswordSuccess /> },
            { path: 'account/resetPassword', element: <ResetPassword /> },
            { path: 'account/resetPasswordSuccess', element: <ResetPasswordSuccess /> },


            /* Usługi */
            { path: 'services', element: <Services /> },
            { path: 'services/:id', element: <ServiceDetails /> },

            /* Podusługi */
            { path: 'subservices', element: <Subservices /> },
            { path: 'subservices/:id', element: <SubserviceDetails /> },

            /* Pozostałe */
            { path: 'errors', element: <ErrorsPage /> },
            { path: 'server-error', element: <ServerError /> },
            { path: 'not-found', element: <NotFound /> },
            { path: '*', element: <Navigate replace to='/not-found' /> },
        ]
    }
])