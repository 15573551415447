import { useEffect } from 'react';
import { Typography, Grid, Paper, Box, Button, Alert, TextField } from "@mui/material";
import { Control, Controller, FieldValues, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../app/store/configureStore";
import { Subservice } from "../../app/models/subservice";
import agent from "../../app/api/agent";
import { setSubservice } from "./subservicesSlice";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppTextInput from "../../app/components/AppTextInput";
import AppReturn from "../../app/components/AppReturn";
import { subserviceValidationSchema } from "./subserviceValidation";

interface Props {
    subservice?: Subservice;
    cancelEdit: () => void;
}

interface Effect {
    id: string;
    value: string;
}

interface FormValues {
    isActive: boolean;
    name: string;
    effects: Effect[];
}

export default function SubserviceForm({ subservice, cancelEdit }: Props) {
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, formState: { isDirty, isSubmitting } } = useForm<FormValues>({
        resolver: yupResolver(subserviceValidationSchema),
        defaultValues: {
            isActive: true,
            name: '',
            effects: [{ id: 'effect-0', value: '' }]  // Ensure at least one effect
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'effects'
    });

    useEffect(() => {
        if (subservice && !isDirty) {
            const formattedEffects = subservice.effects ? subservice.effects.split(';').map((effect, index) => ({ id: `effect-${index}`, value: effect })) : [{ id: 'effect-0', value: '' }];
            reset({ ...subservice, effects: formattedEffects });
        }
    }, [subservice, reset, isDirty]);

    async function handleSubmitData(data: FormValues) {
        try {
            // Join the effects into a single string separated by semicolons
            const effectsString = data.effects.map((effect: Effect) => effect.value).join(';');

            const updatedData = { ...data, effects: effectsString };

            let response: Subservice;
            if (subservice) {
                response = await agent.Subservices.update(updatedData);
                toast.success('Uaktualniono podusługę');
            } else {
                response = await agent.Subservices.create(updatedData);
                toast.success('Dodano podusługę');
            }
            dispatch(setSubservice(response));
            cancelEdit();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <AppReturn title="Tworzenie/edycja zamówienia" cancelEdit={cancelEdit} />

            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    <Typography variant='h6' sx={{ mt: 2 }}>
                        Jakaś sekcja
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <AppCheckbox control={control as unknown as Control<FieldValues>} name='isActive' label='Widoczne i aktywne' />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput control={control} name='name' label='Nazwa' />
                        </Grid>
                    </Grid>

                    <Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
                        Efekty
                    </Typography>

                    <Alert sx={{ mb: 2 }} severity="info">W poniższych polach nie używaj znaku średnika ';'</Alert>

                    {fields.map((field, index) => (
                        <Grid container spacing={2} key={field.id}>
                            <Grid item xs={11} marginBottom={2}>
                                <Controller
                                    control={control}
                                    name={`effects.${index}.value` as const}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={`Efekt ${index + 1}`}
                                            error={!!fieldState.error || field.value.includes(';')}
                                            helperText={field.value.includes(';') ? 'Średnik jest niedozwolony' : fieldState.error ? fieldState.error.message : null}
                                            onChange={(e) => {
                                                const newValue = e.target.value.replace(/;/g, '');
                                                field.onChange(newValue);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button 
                                    sx={{ border: 2 }} 
                                    color="error" 
                                    onClick={() => remove(index)}
                                    disabled={fields.length === 1 && index === 0} // Disable remove button if it's the only field
                                >
                                    Usuń
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                    <Box display='flex' justifyContent='space-between' marginTop={4}>
                        <Button sx={{ border: 2 }} onClick={() => append({ id: `effect-${fields.length}`, value: '' })}>Dodaj efekt</Button>
                        <LoadingButton sx={{ border: 2 }} color='success' type="submit" disabled={!isDirty} loading={isSubmitting}>Zapisz</LoadingButton>
                    </Box>
                </form>
            </Box>
        </>
    );
}