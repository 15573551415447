import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, Control, FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../../app/api/agent";
import AutocompleteInputOption from "../../../../app/models/autocompleteInputOption";
import { Order } from "../../../../app/models/order";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { fetchClientOrdersAsync, setClientOrder } from "../clientOrdersSlice";
import { clientOrderValidationSchema } from "../clientOrderValidation";
import AppReturn from "../../../../app/components/AppReturn";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import dayjs from "dayjs";
import { orderFormCalculateSum, states } from "../../../../app/util/util";
import { OrderFormValues } from "../../../../app/models/orderFormValues";
import OrderFormBasicInformation from "../../../../app/components/Order/OrderFormBasicInformation";
import OrderFormPayerData from "../../../../app/components/Order/OrderFormPayerData";
import OrderFormBenefitients from "../../../../app/components/Order/OrderFormBenefitients";
import OrderValueBox from "../../../../app/components/Order/OrderValueBox";
import OrderFormEducationEffectsModal from "../../../../app/components/Order/OrderFormEducationEffectsModal";

interface Props {
    order?: Order;
    cancelEdit: () => void;
}

export default function ClientOrderForm({ order, cancelEdit }: Props) {
    const { control, reset, handleSubmit, setValue, trigger, formState: { errors, isDirty, isSubmitting }, watch } = useForm<OrderFormValues>({
        resolver: yupResolver(clientOrderValidationSchema),
        defaultValues: {
            serviceId: 0,
            customeClientServiceName: '',
            preposedDateTime: '',
            placeOfServiceAndAnotherInformations: '',
            payerInstitutionName: '',
            payerLastName: '',
            payerFirstName: '',
            payerStreet: '',
            payerBuilding: '',
            payerZip: '',
            payerCity: '',
            payerState: '',
            payerCountry: '',
            payerNIP: '',
            rows: [],
            totalItem: 1
        }
    });

    const dispatch = useAppDispatch();
    const [possibleServices, setPossibleServices] = useState<AutocompleteInputOption[]>([]);
    const serviceId = watch('serviceId');
    const user = useAppSelector(state => state.account.user);
    const isAdmin = !!(user && user.roles?.includes('Admin'));
    const [openModal, setOpenModal] = useState(false);
    const [educationEffects, setEducationEffects] = useState<string[]>([]);
    const [manualServiceChange, setManualServiceChange] = useState(false);
    const [unitDiscountedPrice, setUnitDiscountedPrice] = useState<number>(0);
    const [sum, setSum] = useState<number>(0);
    const [totalItemRows, setTotalItemRows] = useState(0);
    const handleSetTotalItemRows = (count: number) => {
        setTotalItemRows(count);
        setSum(orderFormCalculateSum(count, unitDiscountedPrice));
    }

    useEffect(() => {
        async function setServicePrice(serviceId: number) {
            try {
                let response: number;
                if (serviceId !== 0) {
                    response = await agent.Services.price(serviceId);
                    const value = response;
                    setUnitDiscountedPrice(value);
                    toast.success('Cena jednostkowa została zaktualizowana');
                } else {
                    setUnitDiscountedPrice(0);
                }
            } catch (error) {
                console.log(error);
            }
        }
        setServicePrice(serviceId);
    }, [serviceId, order?.unitDiscountedPrice])

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'educationEffects') {
                setEducationEffects((value.educationEffects || '').split(';'));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const handleOpenModal = () => {
        const currentEffects = watch('educationEffects');
        setEducationEffects(currentEffects ? currentEffects.split(';') : []);
        setOpenModal(true);
    };

    const addNewEffect = () => {
        setEducationEffects([...educationEffects, '']);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const updateEffect = (index: number, value: string) => {
        const newValue = value.replace(/;/g, '');
        const updatedEffects = [...educationEffects];
        updatedEffects[index] = newValue;
        setEducationEffects(updatedEffects);
    };

    const handleSaveEducationEffects = () => {
        const filteredEffects = educationEffects
            .filter(effect => effect.trim() !== '')
            .map(effect => effect.replace(/^;|;$/g, ''));

        setValue('educationEffects', filteredEffects.join(';'), { shouldDirty: true });

        trigger('educationEffects');
        handleCloseModal();
    };

    const removeEffect = (index: number) => {
        const newEffects = educationEffects.filter((_, i) => i !== index);
        setEducationEffects(newEffects);
    };

    useEffect(() => {
        async function fetchAndSetEducationEffects(serviceId: number) {
            try {
                const response = await agent.Client.Orders.getEducationEffects(serviceId);
                if (response && response.data) {
                    setValue('educationEffects', response.data, { shouldValidate: true });
                    setEducationEffects(response.data.split(';'));
                } else if (response) {
                    setValue('educationEffects', response, { shouldValidate: true });
                    setEducationEffects(response.split(';'));
                }
            } catch (error) {
                console.error("Błąd podczas pobierania efektów edukacyjnych", error);
            }
        }

        if (serviceId && manualServiceChange) {
            fetchAndSetEducationEffects(serviceId);
        }
    }, [serviceId, manualServiceChange, setValue]);

    useEffect(() => {
        agent.Services.fetchPossibleServices()
            .then(result => setPossibleServices(result))
            .catch(error => console.log(error));
    }, []);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "rows"
    });

    useEffect(() => {
        if (order && !isDirty) {
            const rows = (order?.benefitients ?? '').split(';').map(item => {
                const [firstName, lastName, pesel, birthPlace, birthDate] = item.split('~');
                return { firstName, lastName, pesel, birthPlace, birthDate };
            });
            reset({ ...order, rows, preposedDateTime: dayjs(order.preposedDateTime).toISOString() });
            setValue('educationEffects', order.educationEffects, { shouldValidate: true });
            setEducationEffects(order.educationEffects?.split(';') || []);
        }
    }, [order, reset, isDirty, setValue]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.values(errors).map(error => error?.message ?? '').join(', ');
            toast.error(`Sprawdź formularz: ${errorMessages}`);
        }
    }, [errors]);

    async function handleSubmitData(data: OrderFormValues) {
        try {
            data.benefitients = data.rows.map(row => `${row.firstName}~${row.lastName}~${row.pesel}~${row.birthPlace}~${row.birthDate}`).join(';');
            data.totalItem = data.rows.length;
            let response: Order;
            if (order) {
                response = await agent.Client.Orders.update(data);
                toast.success('Uaktualniono zamówienie');
            } else {
                response = await agent.Client.Orders.create(data);
                toast.success('Utworzono zamówienie');
            }
            dispatch(setClientOrder(response));
            cancelEdit();

            // Fetch and update the order to ensure the latest data is displayed
            const updatedOrder = await agent.Client.Orders.details(response.id);
            if (updatedOrder) {
                reset({ ...updatedOrder, rows: updatedOrder.benefitients.split(';').map((item: string) => {
                    const [firstName, lastName, pesel, birthPlace, birthDate] = item.split('~');
                    return { firstName, lastName, pesel, birthPlace, birthDate };
                })});
                setValue('educationEffects', updatedOrder.educationEffects, { shouldValidate: true });
                setEducationEffects(updatedOrder.educationEffects?.split(';') || []);
            }
            dispatch(fetchClientOrdersAsync());
        } catch (error) {
            console.log(error);
        }
    }

    const fillWithTestData = () => {
        const testData: OrderFormValues = {
            serviceId: 1,
            serviceName: 'Testowy serviceName',
            customeClientServiceName: 'Testowy Egzamin',
            preposedDateTime: dayjs().toISOString(),
            placeOfServiceAndAnotherInformations: 'Testowe miejsce',
            clientComment: 'Testowy komentarz',
            trainingProgram: 'Testowy program szkoleniowy',
            educationEffects: 'Efekt1;Efekt2;Efekt3',
            payerClientName: 'Testowa Instytucja',
            payerClientAddress: 'Adres',
            payerClientNIP: 'NIP',
            payerInstitutionName: 'Testowa Instytucja',
            payerLastName: 'Kowalski',
            payerFirstName: 'Jan',
            payerStreet: 'Testowa 1',
            payerBuilding: '1',
            payerPremises: '2',
            payerZip: '00-001',
            payerCity: 'Warszawa',
            payerState: states[0],
            payerCountry: 'Polska',
            payerNIP: '1234567890',
            rows: [
                {
                    firstName: 'Jan',
                    lastName: 'Kowalski',
                    pesel: '12345678901',
                    birthPlace: 'Warszawa',
                    birthDate: '01.01.1990'
                },
                {
                    firstName: 'Anna',
                    lastName: 'Nowak',
                    pesel: '09876543210',
                    birthPlace: 'Kraków',
                    birthDate: '02.02.1985'
                }
            ],
            totalItem: 2
        };
        reset(testData);
        setEducationEffects(testData.educationEffects?.split(';') || []);
    };

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Tworzenie/edycja zamówienia"} cancelEdit={cancelEdit} />
            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    {isAdmin && (
                        <Box display='flex' justifyContent='center'>
                            <Button onClick={fillWithTestData} variant='outlined' color='secondary'>Wypełnij Danymi</Button>
                        </Box>
                    )}

                    <OrderFormBasicInformation order={order} control={control} possibleServices={possibleServices} watch={watch} handleOpenModal={handleOpenModal} setManualServiceChange={() => setManualServiceChange(true)} isAdmin={isAdmin} />
                    <OrderFormPayerData control={control} setValue={setValue} />
                    <OrderFormBenefitients 
                        control={control as unknown as Control<FieldValues>} 
                        fields={fields}
                        append={append as unknown as (value: FieldValues | FieldValues[]) => void}
                        remove={remove}
                        handleSetTotalItemRows={handleSetTotalItemRows}
                    />
                    <OrderValueBox sum={sum} unitDiscountedPrice={unitDiscountedPrice} totalItem={totalItemRows} />

                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelEdit} variant='outlined' color='primary'>Anuluj</Button>
                        <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                    </Box>

                    <OrderFormEducationEffectsModal
                        openModal={openModal}
                        handleCloseModal={handleCloseModal}
                        educationEffects={educationEffects}
                        updateEffect={updateEffect}
                        removeEffect={removeEffect}
                        addNewEffect={addNewEffect}
                        handleSaveEducationEffects={handleSaveEducationEffects}
                    />
                </form>
            </Box>
        </>
    )
}