import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { PaginatedResponse } from "../models/pagination";
import { router } from "../router/Routes";
import { store } from "../store/configureStore";

const sleep = () => new Promise(resolve => setTimeout(resolve, 500));

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 120000;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use(config => {
    const token = store.getState().account.user?.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
})

axios.interceptors.response.use(async response => {
    if (import.meta.env.DEV) await sleep();
    const pagination = response.headers['pagination']
    if (pagination) {
        response.data = new PaginatedResponse(response.data, JSON.parse(pagination));
        return response;
    }
    return response;
}, (error: AxiosError) => {
    // Sprawdź, czy `error.response` istnieje
    if (!error.response) {
        toast.error('Wystąpił problem z połączeniem z serwerem. Sprawdź swoje połączenie lub spróbuj później.');
        return Promise.reject(error);
    }

    const { data, status } = error.response as { data: any; status: number };
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key]);
                    }
                }
                throw modelStateErrors.flat();
            }
            toast.error(data.title || 'Błąd żądania 400.');
            break;
        case 401:
            toast.error(data.title || 'Nieautoryzowany dostęp.');
            break;
        case 403:
            toast.error('Nie masz uprawnień do tej operacji.');
            break;
        case 500:
            router.navigate('/server-error', { state: { error: data } });
            break;
        default:
            toast.error('Wystąpił nieoczekiwany błąd.');
            break;
    }
    return Promise.reject(error.response);
}
);

const requests = {
    get: (url: string, params?: URLSearchParams, options = {}) => axios.get(url, { params, ...options }).then(responseBody),
    post: <T>(url: string, body?: object) => axios.post<T>(url, body).then(responseBody),
    put: (url: string, body: object) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, data: FormData) => axios.post(url, data, {
        headers: { 'Content-type': 'multipart/form-data' }
    }).then(responseBody),
    putForm: (url: string, data: FormData) => axios.put(url, data, {
        headers: { 'Content-type': 'multipart/form-data' }
    }).then(responseBody),
    putApplication: (url: string, body: object) => axios.put(url, body, {
        headers: { 'Content-Type': 'application/json' }
    }).then(responseBody)
}

function createFormData(item: any) {
    const formData = new FormData();
    for (const key in item) {
        formData.append(key, item[key])
    }
    return formData;
}

const Admin = {
    Users: {
        create: (user: any) => requests.postForm('adminUsers', createFormData(user)),
        update: (user: any) => requests.putForm('adminUsers', createFormData(user)),
        list: (params: URLSearchParams) => requests.get('adminUsers', params),
        details: (id: string) => requests.get(`adminUsers/${id}`),
        fetchFilters: () => requests.get('adminUsers/filters'),
        // userById: (id: string) => requests.get(`adminUsers/${id}`),
        delete: (id: string) => requests.delete(`adminUsers?id=${id}`),
        roles: () => requests.get('adminUsers/roles'),
        roleAddToUser: (userId: string, roleId: string) => requests.post('adminUsers/assignRole', { userId, roleId }),
        roleRemoveFromUser: (userId: string, roleId: string) => requests.post('adminUsers/removeRole', { userId, roleId }),
    },
    Investments: {
        create: (investment: any) => requests.postForm('admin/investments', createFormData(investment)),
        update: (investment: any) => requests.putForm('admin/investments', createFormData(investment)),
        list: (params: URLSearchParams) => requests.get('admin/investments', params),
        details: (id: number) => requests.get(`admin/investments/${id}`),
        fetchFilters: () => requests.get('admin/investments/filters'),
        delete: (id: number) => requests.delete(`admin/investments?id=${id}`),
        getHistoryOfInvestment: (id: number) => requests.get(`admin/investments/${id}/history`)
    },
    Orders: {
        create: (order: any) => requests.postForm('admin/orders', createFormData(order)),
        update: (order: any) => requests.putForm('admin/orders', createFormData(order)),
        list: (params: URLSearchParams) => requests.get('admin/orders', params),
        details: (id: number) => requests.get(`admin/orders/${id}`),
        fetchFilters: () => requests.get('admin/orders/filters'),
        delete: (id: number) => requests.delete(`admin/orders?id=${id}`),
        approveExam: (id: number, examDateISO: string) => {
            const params = new URLSearchParams({ examDate: examDateISO });
            return requests.put(`admin/orders/approve-exam/${id}?examDate=${examDateISO}`, { params });
        },
        getEducationEffects: (id: number) => requests.get(`admin/orders/getEducationEffects/${id}`),
        getHistoryOfOrder: (id: number) => requests.get(`admin/orders/${id}/history`),
        cancelOrder: (id: number) => requests.put(`admin/orders/cancel/${id}`, {}),
        rejectCancellation: (id: number) => requests.put(`admin/orders/reject-cancellation/${id}`, {}),
        changeAvailabilityFlag: (id: number, isTestAvailable: { isTestAvailable: boolean }) => requests.put(`admin/orders/changeAvailabilityFlag/${id}`, isTestAvailable),
        checkIfTestAndKeyTestIsInService: (id: number) => requests.get(`admin/orders/checkIfTestAndKeyTestIsInService?serviceId=${id}`),
        updateServiceToOrder: (id: number) => requests.put(`admin/orders/updateServiceToOrder/${id}`, {})
        // recalculatePrices: () => requests.get("admin/orders/recalculatePrices")
    },
    Roles: {
        create: (roleName: string) => requests.post('adminUsers/roles', { roleName }),
        update: (roleId: string, newRoleName: string) => requests.put(`adminUsers/roles/${roleId}`, { newRoleName }),
        delete: (roleId: string) => requests.delete(`adminUsers/roles/${roleId}`),
    },
    Documents: {
        create: (document: any) => requests.postForm('admin/documents', createFormData(document)),
        update: (document: any) => requests.putForm('admin/documents', createFormData(document)),
        list: (params: URLSearchParams) => requests.get('admin/documents', params),
        details: (id: number) => requests.get(`admin/documents/${id}`),
        fetchFilters: () => requests.get('admin/documents/filters'),
        delete: (id: number) => requests.delete(`admin/documents?id=${id}`),
        addSignatureByAdmin: (id: number) => requests.postForm(`admin/documents/AddSignatureByAdmin/${id}`, createFormData(document)),
        getPreSignedUrl: (documentId: number) => requests.get(`admin/documents/presigned-url/${documentId}`),
        downloadDocument: (documentId: number, isSigned: boolean) => {
            const endpoint = isSigned
                ? `admin/documents/download-signed/${documentId}` // Endpoint do pobrania podpisanego dokumentu
                : `admin/documents/download/${documentId}`;      // Endpoint do pobrania oryginalnego dokumentu

            return axios.get(endpoint, { responseType: 'blob' });
        }
    },
    Agreements: {
        create: (agreement: any) => requests.postForm('admin/agreements', createFormData(agreement)),
        update: (agreement: any) => requests.putForm('admin/agreements', createFormData(agreement)),
        list: (params: URLSearchParams) => requests.get('admin/Agreements', params),
        details: (id: number) => requests.get(`admin/agreements/${id}`),
        fetchFilters: () => requests.get('admin/agreements/filters'),
        delete: (id: number) => requests.delete(`admin/documents?id=${id}`),
        getAgreementByInvestmentPublicId: (publicId: number) => requests.get(`admin/agreements/investment/${publicId}`),
        fetchPossibleAgreements: () => requests.get('admin/agreements/possibleAgreements'),
    },
    SystemConfigurations: {
        update: (id: number, systemConfiguration: any) => requests.put(`SystemConfigurations/${id}`, systemConfiguration),
        list: () => requests.get('systemConfigurations'),
        details: (name: string) => requests.get(`systemConfigurations/${name}`),
        sendReminders: () => requests.post('systemConfigurations/sendReminders'),
        resendReminders: () => requests.post('systemConfigurations/resendEmails')
    }
}

const Client = {
    Investments: {
        create: (investment: any) => requests.postForm('client/investments', createFormData(investment)),
        update: (investment: any) => requests.putForm('client/investments/updateInvestment', createFormData(investment)),
        acceptOrReject: (investment: any) => requests.putForm('client/investments/acceptOrReject', createFormData(investment)),
        list: (params: URLSearchParams) => requests.get('client/investments', params),
        details: (id: number) => requests.get(`client/investments/${id}`),
        fetchClientInvestmentsFilters: () => requests.get('client/investments/filters'),
        delete: (id: number) => requests.delete(`client/investments?id=${id}`),
        getHistoryOfInvestment: (id: number) => requests.get(`client/investments/${id}/history`)
    },
    Orders: {
        create: (order: any) => requests.postForm('client/orders', createFormData(order)),
        update: (order: any) => requests.putForm('client/orders', createFormData(order)),
        list: (params: URLSearchParams) => requests.get('client/orders', params),
        details: (id: number) => requests.get(`client/orders/${id}`),
        fetchFilters: () => requests.get('client/orders/filters'),
        delete: (id: number) => requests.delete(`client/orders?id=${id}`),
        getEducationEffects: (id: number) => requests.get(`client/orders/getEducationEffects/${id}`),
        getHistoryOfOrder: (id: number) => requests.get(`client/orders/${id}/history`),
        cancelOrder: (id: number) => requests.put(`client/orders/cancel/${id}`, {}),
        isAnyOrderWithClientPaymentData: () => requests.get("client/orders/isAnyOrderWithClientPaymentData"),
        getLastPaymentData: () => requests.get("client/orders/getLastPaymentData"),
    },
    ClientDocuments: {
        list: (params: URLSearchParams) => requests.get('client/documents', params),
        details: (id: number) => requests.get(`client/documents/${id}`),
        addSignatureByClient: (id: number) => requests.postForm(`client/documents/AddSignatureByClient/${id}`, createFormData(document)),
        getPreSignedUrl: (documentId: number) => requests.get(`client/documents/presigned-url/${documentId}`),
    }
}

const GenerateCertificate = {
    generateCertificate: (id: number) => axios.post(`generate-certificates/${id}`, {}, { responseType: 'blob' })
}

const TestErrors = {
    get400Error: () => requests.get('buggy/bad-request'),
    get401Error: () => requests.get('buggy/unauthorised'),
    get404Error: () => requests.get('buggy/not-found'),
    get500Error: () => requests.get('buggy/server-error'),
    getValidationError: () => requests.get('buggy/validation-error'),
}

const YupValidation = {
    validatePesel: (pesel: any) => requests.post(`validators/validatePesel?pesel=${pesel}`, {})
}

const Account = {
    login: (values: any) => requests.post('account/login', values),
    register: (values: any) => requests.post('account/register', values),
    currentUser: () => requests.get('account/currentUser'),
    currentUserId: () => requests.get('account/currentUserId'),
    fetchAddress: () => requests.get('account/savedAddress'),
    verifyEmail: (token: string, email: string) => requests.post<void>(`/account/verifyEmail?token=${token}&email=${email}`, {}),
    resendEmailConfirm: (email: string) => requests.get(`/account/resendEmailConfirmationLink?email=${email}`),
    forgotPassword: (email: string) => requests.post(`/account/forgotPassword?email=${email}`, {}),
    resetPassword: (values: any) => requests.post("/account/resetPassword", values),
    usersList: () => requests.get('account'),
    userById: (id: string) => requests.get(`account/${id}`),
    fetchAccountData: () => requests.get('account/fetchAccountData'),
    updateAccountData: (values: any) => requests.putForm('account', createFormData(values)),
    updateAccountEmail: (values: any) => requests.putForm('account/changeEmail', createFormData(values))
}

const HelpInOrderComments = {
    create: (comment: any) => requests.postForm('helpInOrderComments', createFormData(comment)),
    list: (orderId: number) => requests.get(`helpInOrderComments/${orderId}`),
    details: (id: number) => requests.get(`helpInOrderComments/${id}`),
    fetchOrderHelpInOrderComments: (order: any) => requests.get(`helpInOrderComments/GetComments/${order}`),
}

const Services = {
    fetchPossibleServices: () => requests.get('services/possibleServices'),
    fetchServicesFilters: () => requests.get('services/filters'),
    create: (service: any) => requests.postForm('services', createFormData(service)),
    update: (service: any) => requests.putForm('services', createFormData(service)),
    list: (params: URLSearchParams) => requests.get('services', params),
    details: (id: number) => requests.get(`services/${id}`),
    delete: (id: number) => requests.delete(`services/${id}`),
    popular: () => requests.get('services/popular'),
    getTopThree: () => requests.get('services/top'),
    getSubservicesFromService: (ids: string) => requests.get(`services/subservices/${ids}`),
    price: (id: number) => requests.get(`services/price/${id}`),
    // recalculatePrices: () => requests.get("services/recalculatePrices")
}

const Subservices = {
    fetchPossibleSubservices: () => requests.get('subservices/possibleSubservices'),
    fetchSubservicesFilters: () => requests.get('subservices/filters'),
    create: (subservice: any) => requests.postForm('subservices', createFormData(subservice)),
    update: (subservice: any) => requests.putForm('subservices', createFormData(subservice)),
    list: (params: URLSearchParams) => requests.get('subservices', params),
    details: (id: number) => requests.get(`subservices/${id}`),
    delete: (id: number) => requests.delete(`subservices/${id}`),
    popular: () => requests.get('subservices/popular')
}

const OldDbManager = {
    startTransfer_Packages: () => requests.get('oldDbManager/startTransfer_Packages')
}

const Abouts = {
    list: () => requests.get('abouts'),
    details: (name: string) => requests.get(`abouts/${name}`),
    getCount: () => requests.get('abouts/count'),
    updateAboutSortOrder: (id: number, newSortOrder: number) =>
        requests.put(`abouts/sortOrder/${id}?newSortOrder=${newSortOrder}`, { newSortOrder }),
    create: (about: any) => requests.postForm('abouts', createFormData(about)),
    update: (aboutUpdateDto: any) => requests.putApplication('abouts', aboutUpdateDto)
}

const Footer = {
    get: () => requests.get('footer')
}

const TinyMCE = {
    get: () => requests.get('TinyMCE')
}

const BizInApi = {
    apiTestConnection: () => requests.get('bizInApi/apiTestConnection?isDebug=yes'),
    addInvoice: (adminOrderAddInvoiceFormValues: any) => requests.postForm('bizInApi/addInvoice', adminOrderAddInvoiceFormValues),
}

const agent = {
    TestErrors,
    Account,
    Admin,
    Client,
    GenerateCertificate,
    HelpInOrderComments,
    Services,
    Subservices,
    YupValidation,
    OldDbManager,
    Abouts,
    Footer,
    TinyMCE,
    BizInApi
}

export default agent;