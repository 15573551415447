import { Alert, Box, Button, Grid, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import DynamicTextarea from "../AppDynamicTextarea";
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    openModal: boolean;
    handleCloseModal: () => void;
    educationEffects: string[];
    updateEffect: (index: number, value: string) => void;
    removeEffect: (index: number) => void;
    addNewEffect: () => void;
    handleSaveEducationEffects: () => void;
}

export default function OrderFormEducationEffectsModal({openModal,handleCloseModal,educationEffects,updateEffect,removeEffect,addNewEffect,handleSaveEducationEffects}: Props) {
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Modal open={openModal} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Edytuj Efekty Kształcenia
                </Typography>
                <Alert sx={{ mb: 3 }} severity="info">W poniższych polach nie używaj znaku średnika ';'</Alert>
                <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    {educationEffects.map((effect, index) => (
                        <Grid container key={index} spacing={2} alignItems="center" sx={{ mb: 2 }}>
                            <Grid item xs={12} md={11} lg={11}>
                                <DynamicTextarea
                                    label={`Efekt ${index + 1}`}
                                    value={effect}
                                    onChange={(e) => updateEffect(index, e.target.value)}
                                    minRows={1}
                                />
                            </Grid>
                            <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {matchesXS ? (
                                    <Button variant="outlined" color="error" onClick={() => removeEffect(index)} sx={{ width: '100%', mb: 2 }}>
                                        Usuń
                                    </Button>
                                ) : (
                                    <IconButton sx={{ border: 1, mb: { sm: 2, md: 0 } }} size="small" color="error" onClick={() => removeEffect(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                    <Box display='flex' justifyContent='space-between' sx={{ mt: 1, flexDirection: { xs: 'column', md: 'row' } }}>
                        <Button variant="outlined" onClick={addNewEffect} sx={{ width: { xs: '100%', md: 'auto' } }}>Dodaj efekt</Button>
                    </Box>
                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button variant='outlined' onClick={() => handleCloseModal()} sx={{ width: { xs: '100%', sm: 'auto' }, mr: { xs: 0, sm: 2 } }}>Anuluj</Button>
                        <Button variant='contained' onClick={handleSaveEducationEffects} sx={{ width: { xs: '100%', sm: 'auto' } }}>Zapisz</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}