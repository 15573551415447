import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { brand } from '../../app/layout/brand';
import { useTheme } from '@mui/material/styles';
import VideoBanner from '../../app/components/VideoBanner/VideoBanner';
import About from '../../app/components/About/About';
import { appHomeStyles } from '../../app/layout/appHomeStyles';
import HomePageHeader from '../../app/layout/header/HomePageHeader';
import HomePageServices from '../../app/components/HomePageServices/HomePageServices';
import SquareParallax from '../../app/components/Parallax/Square';
import Expertise from '../../app/components/Expertise/Expertise';
import MapAddress from '../../app/components/MapAddress/MapAddress';
import Testimonials from '../../app/components/Testimonials/Testimonials';
// Use this below for Server Side Render/Translation (SSR)
// import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// Use this below for Static Site Generation (SSG)

interface LandingProps {
  onToggleDark: () => void;
  onToggleDir: () => void;
  darkMode: boolean;
}

function Landing(props: LandingProps) {
  const { classes, cx } = appHomeStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery('(max-width: 599px)');


  return (
    <React.Fragment>
      <title>
        {brand.agency.name + ' - Home Page'}
      </title>
      <CssBaseline />
      <div className={cx(classes.mainWrap)}>
        <HomePageHeader
          darkMode={props.darkMode}
          onToggleDark={props.onToggleDark}
          onToggleDir={props.onToggleDir}
        />
        <main className={classes.containerWrap}>
          <SquareParallax />
          <section>
            <VideoBanner />
          </section>
          <section className={isDesktop ? classes.spaceTopShort : classes.spaceTop} id="about">
            <About />
          </section>
          <section className={classes.spaceTop} id="services">
            <HomePageServices />
          </section>
          <section
            className={isDesktop ? classes.spaceTopShort : classes.spaceTop}
            id="our-expertise"
            style={{ display: isTablet ? 'none' : '' }}
          >
            <Expertise />
          </section>
          <section className={isTablet ? classes.spaceTopShort : classes.spaceTop} id="testimonials" >
            <Testimonials />
          </section>
          <section id="case-studies">
          </section>
          <section className={classes.spaceTopShort} id="call-to-action">
          </section>
          {!isMobile ? (
            <section className={classes.spaceTopShort} id="address">
              <MapAddress />
            </section>
          ) : "" }

        </main>
        <section className={classes.spaceTopShort}>
        </section>
      </div>
    </React.Fragment>
  );
}

Landing.propTypes = {
  onToggleDark: PropTypes.func.isRequired,
  onToggleDir: PropTypes.func.isRequired,
};

export default Landing