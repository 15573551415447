const imgAPI = {
    avatar: [
        'client/src/temporaryImages/avatars/pp_boy.svg',
        '../../temporaryImages/avatars/pp_girl2.svg',
        '../../../../temporaryImages/avatars/pp_girl3.svg',
        '../../../temporaryImages/avatars/pp_girl4.svg',
        '../../../temporaryImages/avatars/pp_girl5.svg',
        '../../../temporaryImages/avatars/pp_girl.svg',
        '../../../temporaryImages/avatars/pp_boy.svg',
        '../../../temporaryImages/avatars/pp_boy2.svg',
        '../../../temporaryImages/avatars/pp_boy3.svg',
        '../../../temporaryImages/avatars/pp_boy4.svg',
        '../../temporaryImages/avatars/pp_boy5.svg'
      ],
      photo: [
        'https://via.placeholder.com/675x900/e1ad92/fff',
        'https://via.placeholder.com/967x725/ea6d6d/fff',
        'https://via.placeholder.com/1280x849/ea6db7/fff',
        'https://via.placeholder.com/967x725/bb6dea/fff',
        'https://via.placeholder.com/1048x701/6d6fea/fff',
        'https://via.placeholder.com/1050x700/6dc0ea/fff',
        'https://via.placeholder.com/970x725/6deaa6/fff',
        'https://via.placeholder.com/1051x700/b8de27/fff',
        'https://via.placeholder.com/1051x701/de9f27/fff',
        'https://via.placeholder.com/1050x700/ef4545/fff',
        'https://via.placeholder.com/1050x700/ffc4c4/757575',
        'https://via.placeholder.com/640x447/fdffc4/757575',
        'https://via.placeholder.com/1280x851/c4ffd7/757575',
        'https://via.placeholder.com/640x425/c4cdff/757575',
      ],
      agency: [
        'https://via.placeholder.com/964x723/1071ef/FFFFFF',
        'https://via.placeholder.com/1280x825/99d8ff/FFFFFF',
        'https://via.placeholder.com/490x327/ffc999/FFFFFF',
        'https://via.placeholder.com/490x327/ffc999/FFFFFF',
        'https://via.placeholder.com/490x327/ffc999/FFFFFF',
        'https://via.placeholder.com/331x690/fdcc0c/FFFFFF',
        'https://via.placeholder.com/521x309/4a0cfd/FFFFFF',
        'https://via.placeholder.com/521x309/ffe55b/FFFFFF',
        'https://via.placeholder.com/713x309/79c0ff/FFFFFF',
        'https://via.placeholder.com/711x693/52596b/FFFFFF',
      ],
};

export default imgAPI;