import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Subservice, SubservicesParams } from "../../app/models/subservice";
import { RootState } from "../../app/store/configureStore";

interface SubservicesState {
    subservicesLoaded: boolean;
    subservicesFiltersLoaded: boolean;
    status: string;
    subservicesParams: SubservicesParams;
    metaData: MetaData | null;
}

const subservicesAdapter = createEntityAdapter<Subservice>();

function getAxiosParams(subservicesParams: SubservicesParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', subservicesParams.pageNumber.toString());
    params.append('pageSize', subservicesParams.pageSize.toString());
    params.append('orderBy', subservicesParams.orderBy);
    if (subservicesParams.searchTerm) params.append('searchTerm', subservicesParams.searchTerm);
    return params;
}

export const fetchSubservicesAsync = createAsyncThunk<Subservice[], void, {state: RootState} >(
    'subservices/fetchSubservicesAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().subservices.subservicesParams);
        try {
            const response = await agent.Subservices.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchSubserviceAsync = createAsyncThunk<Subservice, number>(
    'services/fetchSubserviceAsync',
    async (serviceId, thunkAPI) => {
        try {
            return await agent.Subservices.details(serviceId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchSubservicesFilters = createAsyncThunk(
    'services/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Subservices.fetchSubservicesFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: '_'
    }
}

export const subservicesSlice = createSlice({
    name: 'subservices',
    initialState: subservicesAdapter.getInitialState<SubservicesState>({
        subservicesLoaded: false,
        subservicesFiltersLoaded: false,
        status: 'idle',
        subservicesParams: initParams(),
        metaData: null
    }),
    reducers: {
        setSubservicesParams: (state, action) => {
            state.subservicesLoaded = false;
            state.subservicesParams = {...state.subservicesParams, ...action.payload, pageNumber: 1};
        },
        setSubservicesPageNumber: (state, action) => {
            state.subservicesLoaded = false;
            state.subservicesParams = {...state.subservicesParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetSubservicesParams: (state) => {
            state.subservicesParams = initParams();
        },
        setSubservice: (state, action) => {
            subservicesAdapter.upsertOne(state, action.payload);
            state.subservicesLoaded = false;
        },
        removeSubservice: (state, action) => {
            subservicesAdapter.removeOne(state, action.payload);
            state.subservicesLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchSubservicesAsync.pending, (state) => {
                state.status = 'pendingFetchSubservices';
            });
            builder.addCase(fetchSubservicesAsync.fulfilled, (state, action) => {
                subservicesAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.subservicesLoaded = true;
            });
            builder.addCase(fetchSubservicesAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchSubserviceAsync.pending, (state) => {
                state.status = 'pendingFetchSubservice';
            });
            builder.addCase(fetchSubserviceAsync.fulfilled, (state, action) => {
                subservicesAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchSubserviceAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchSubservicesFilters.pending, (state) => {
                state.status = 'pendingFetchSubservicesFilters';
            });
            builder.addCase(fetchSubservicesFilters.fulfilled, (state) => {
                // state.brands = action.payload.brands;
                // state.types = action.payload.types; 
                state.subservicesFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchSubservicesFilters.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const subservicesSelectors = subservicesAdapter.getSelectors((state: RootState) => state.subservices);

export const {setSubservicesParams, resetSubservicesParams, setMetaData, setSubservicesPageNumber, setSubservice, removeSubservice} = subservicesSlice.actions;