import { Grid } from "@mui/material";
import { Service } from "../../../app/models/service";
import { useAppSelector } from "../../../app/store/configureStore";
import ServiceCard from "./ServiceCard";

import ServiceCardSkeleton from "./ServiceCardSkeleton";
import AutocompleteInputOption from "../../../app/models/autocompleteInputOption";

interface Props {
    services: Service[];
    handleCardSelectService?: (handledService: Service) => void;
    possibleSubservices: AutocompleteInputOption[];
}

export default function ServicesList({ services, handleCardSelectService, possibleSubservices }: Props) {
    const { servicesLoaded } = useAppSelector(state => state.services);
    return (
        <Grid container spacing={4}>
            {services.map(service => (
                <Grid item xs={12} sm={12} md={6} key={service.id}>
                    {!servicesLoaded ? (
                        <ServiceCardSkeleton />
                    ) : (
                        <ServiceCard
                            service={service}
                            handleCardSelectService={handleCardSelectService}
                            possibleSubservices={possibleSubservices}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}