import Link, { LinkProps } from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';

const breadcrumbNameMap: { [key: string]: string } = {
  '/about': 'O Centrum Egzaminacyjnym',
  '/about/institution': 'Informacje o placówce',
  '/about/references': 'Referencje',
  '/about/partners': 'Zaufani partnerzy',
  '/about/contact': 'Kontakt',
  '/about/certificate': 'Charakterystyka certyfikatu',
  '/about/privacy': 'Polityka prywatności',
  '/about/regulations': 'Regulamin korzystania',

  '/admin': 'Admin',

  '/admin/users': 'Klienci',
  '/admin/users/:id': 'Szczegóły Klienta',

  '/admin/investments': 'Projekty inwestycyjne',
  '/admin/investments/:id': 'Projekt',

  '/admin/orders': 'Usługi egzaminacyjne',
  '/admin/orders/:id': 'Egzamin',

  '/client': 'Strefa Klienta',

  '/client/investments': 'Projekty inwestycyjne',
  '/client/investments/:id': 'Projekt',

  '/client/orders': 'Usługi egzaminacyjne',
  '/client/orders/:id': 'Egzamin',

  '/services': 'Usługi egzaminacyjne',
  '/services/:id': 'Egzamin'
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

export default function AppBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
      <Breadcrumbs aria-label="breadcrumb" sx={{ m: 1 }}>
        <LinkRouter underline="hover" color="inherit" to="/">
          Start
        </LinkRouter>
        {pathnames.map((_value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
  );
}