import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppPagination from "../../../../app/components/AppPagination";
import RadioButtonGroup from "../../../../app/components/AppRadioButtonGroup";
import useAdminInvestments from "../../../../app/hooks/useAdminInvestments";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { Investment } from "../../../../app/models/investment";
import { useAppSelector, useAppDispatch, RootState } from "../../../../app/store/configureStore";
import { setFooterInvisible, setFooterVisible } from "../../../../app/util/util";
import AdminInvestmentForm from "../AdminInvestmentForm";
import { setAdminInvestmentsParams, setAdminInvestmentsPageNumber } from "../adminInvestmentsSlice";
import AdminInvestmentsList from "./AdminInvestmentsList";
import AdminInvestmentValuation from "../valuation/AdminInvestmentValuation";
import CheckboxButtons from "../../../../app/components/AppCheckboxButtons";
import AppReturn from "../../../../app/components/AppReturn";
import AppUniversalSearch from "../../../../app/components/AppUniversalSearch";

const sortOptions = [
  { value: 'dateDesc', label: 'Data dodania malejąco' },
  { value: 'lastUpdated', label: 'Ostatnio modyfikowane' }
]

export default function AdminInvestments() {
  const { adminInvestments, adminInvestmentsFiltersLoaded, metaData } = useAdminInvestments();
  const { adminInvestmentsParams } = useAppSelector(state => state.adminInvestments);
  const [selectedAdminInvestment, setSelectedAdminInvestment] = useState<Investment | undefined>(undefined);
  const [editModeInvestmentForm, setEditModeInvestmentForm] = useState(false);
  const [editModeInvestmentValuationForm, setEditModeInvestmentValuationForm] = useState(false);
  const dispatch = useAppDispatch();

  const stateNamesMap: { [key: string]: string } = {
    WAITING_FOR_CONFIRMATION: 'Oczekiwanie na potwierdzenie',
    ADMIN_ACCEPTED: 'Zaakceptowane przez admina',
    ADMIN_REJECTED: 'Odrzucone przez admina',
    CLIENT_ACCEPTED: 'Zaakceptowane przez klienta',
    CLIENT_REJECTED: 'Odrzucone przez klienta',
    CLIENT_DATA_FILLED: 'Dane klienta wypełnione',
    COMPLETED: 'Zakończone',
    CANCELED: 'Anulowane'
  }

  function cancelEditInvestmentForm() {
    if (selectedAdminInvestment) setSelectedAdminInvestment(undefined);
    setEditModeInvestmentForm(false);
  }

  function cancelEditInvestmentValuationForm() {
    if (selectedAdminInvestment) setSelectedAdminInvestment(undefined);
    setEditModeInvestmentValuationForm(false);
  }

  function handleCardSelectAdminInvestment(adminInvestment: Investment) {
    setSelectedAdminInvestment(adminInvestment);
    setEditModeInvestmentForm(true);
  }

  function handleCardSelectInvestmentValuation(investment: Investment) {
    setSelectedAdminInvestment(investment);
    setEditModeInvestmentValuationForm(true);
  }

  if (!adminInvestmentsFiltersLoaded) { setFooterInvisible(); return <LoadingComponent /> }
  setFooterVisible();

  if (editModeInvestmentForm) return <AdminInvestmentForm adminInvestment={selectedAdminInvestment} cancelEdit={cancelEditInvestmentForm} />

  if (editModeInvestmentValuationForm) return <AdminInvestmentValuation handleCardSelectInvestmentValuation={handleCardSelectInvestmentValuation} investment={selectedAdminInvestment} cancelEdit={cancelEditInvestmentValuationForm} />

  return (
    <>
      <AppBreadcrumbs />
      <AppReturn title={"Projekty inwestycyjne"} setFunction={() => setEditModeInvestmentForm(true)} />

      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ mb: 2 }}>
            <AppUniversalSearch
              sliceName="adminInvestments"
              searchParamsSelector={(state: RootState) => state.adminInvestments.adminInvestmentsParams}
              setSearchParams={setAdminInvestmentsParams}
              label='Szukaj'
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <RadioButtonGroup
              selectedValue={adminInvestmentsParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setAdminInvestmentsParams({ orderBy: e.target.value }))}
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <CheckboxButtons
              items={stateNamesMap}
              checked={adminInvestmentsParams.states}
              onChange={(checkedItems: string[]) => dispatch(setAdminInvestmentsParams({ states: checkedItems }))}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <AdminInvestmentsList adminInvestments={adminInvestments} handleCardSelectInvestmentValuation={handleCardSelectInvestmentValuation} handleCardSelectAdminInvestment={handleCardSelectAdminInvestment} />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9} sx={{ my: 2 }} >
          {metaData &&
            <AppPagination
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setAdminInvestmentsPageNumber({ pageNumber: page }))}
            />}
        </Grid>
      </Grid>
    </>
  )
}