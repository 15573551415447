import { useMediaQuery } from "@mui/material";
import CookieConsent, { OPTIONS } from "react-cookie-consent";

const ConsentButton = ({ ...props }) => (
    <button type="button" {...props}>
    </button>
);

export default function Cookies() {
    // const paletteType = darkMode ? 'dark' : 'light';
    const isMd = useMediaQuery('(min-width: 900px)');

    return (
        <CookieConsent
            location={OPTIONS.BOTTOM}
            buttonText="Akceptuj"
            cookieName="myCookieName"
            ButtonComponent={ConsentButton}
            // overlay
            // style={{
            //     background: paletteType === 'light' ? '#1E81B0' : '#121212'
            // }}
            buttonStyle={{
                fontSize: "13px",
                borderRadius: "10px",
                position: "absolute",
                left: "10px",
                top: "30%",
                transform: "translateY(-50%)",
              }}
            expires={150}
        >
            <span style={{ fontSize: "16px", display: 'block', textAlign: isMd? 'center' : 'right' }}>Ta strona wykorzystuje pliki cookies.</span>{" "}
            <span style={{ fontSize: "10px", display: 'block', textAlign: isMd? 'center' : 'right' }}>Ta strona wykorzystuje pliki cookies (ciasteczka).</span>
        </CookieConsent>
    )
}