export interface Order {
    // AppOrderBenefitientsStringsConnector handler
    benefitients: string;

    firstName: string;
    lastName: string;
    pesel: string;
    birthPlace: string;
    birthDate: string;

    isLastModify: boolean;
    isTestAvailable: boolean;

    orderState: OrderState;
    id: number;
    isActive: boolean;
    publicId: number;
    userId: string;
    userEmail: string;
    userDisplayName: string;
    userPhone: string;
    orderDateTime: Date;
    updatingUserId: string;
    updatingUserIsAdmin: boolean;
    updatingUserEmail: string;
    updatingUserDisplayName: string;
    updatingUserPhone: string;
    updatingDateTime: Date;
    serviceId: number;
    serviceName: string;
    customeClientServiceName: string;
    clientComment: string;
    publicComment: string;
    internalComment: string;

    trainingProgram: string;
    educationEffects: string;

    paymentOrderState: PaymentOrderState;
    isPaid: boolean;

    // payer
    payerClientName: string;
    payerClientAddress: string;
    payerClientNIP: string;

    payerInstitutionName: string;
    payerLastName: string;
    payerFirstName: string;
    payerStreet: string;
    payerBuilding: string;
    payerPremises: string;
    payerZip: string;
    payerCity: string;
    payerState: string;
    payerCountry: string;
    payerNIP: string;

    preposedDateTime?: Date;
    placeOfServiceAndAnotherInformations: string;

    solvedTestsPaths: string;
    solvedTestsPublicIds: string;
    systemId: string;
    isExamApproved: boolean;
    examDate: Date;

    unitBasePrice: number;
    unitDiscountedPrice: number;
    totalBasePrice: number;
    totalDiscountedPrice: number;
    totalItem: number;
    bizInDocumentId: number;
    accountNumber: string;
    bizInClientId: number;
    bizInInvoiceUrl: string;
    bizInInvoicePdfUrl: string;

    packageTestPath: string;
    packageTestKeyPath: string;
}

export interface OrdersParams {
    orderBy: string;
    searchTerm?: string;
    pageNumber: number;
    pageSize: number;
    states: string[];
    paymentStates: string[];
}

export enum OrderState {
    ORDER_CREATED = 0,
    SCANS_ADDED = 1,
    SCANS_ACCEPTED = 2,
    COMPLETED = 3,
    CANCELLATION_REQUEST = 4,
    CANCELED = 5
}

export enum PaymentOrderState {
    PENDING = 0,
    WAITING_FOR_CONFIRMATION = 1,
    COMPLETED = 2,
    CANCELED = 3
}