import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useState } from "react";

interface Props {
    items: { [key: string]: string };
    checked: string[];
    onChange: (items: string[]) => void;
}

export default function AppCheckboxButtons({items, checked, onChange} : Props) {
    const [checkedItems, setCheckedItems] = useState(checked || [])

    function handleChecked(value: string) {
        const currentIndex = checkedItems.findIndex(item => item === value);
        let newChecked: string[] = [];
        if (currentIndex === -1) newChecked = [...checkedItems, value];
        else newChecked = checkedItems.filter(item => item !== value);
        setCheckedItems(newChecked);
        onChange(newChecked);
    }
    return (
        <FormGroup>
            {Object.entries(items).map(([value, label]) => (
                <FormControlLabel 
                    control={<Checkbox 
                        checked={checkedItems.indexOf(value) !== -1}
                        onClick={() => handleChecked(value)}
                    />} 
                    label={label} 
                    key={value} 
                />
            ))}
        </FormGroup>
    )
}