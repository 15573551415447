import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AppTextInput from "../../../app/components/AppTextInput";
import { ChatComment } from "../../../app/models/comment";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { yupResolver } from '@hookform/resolvers/yup';
import { clientHelpInOrderCommentsSelectors, setComments, setSelectedClientOrder, updateMessage } from "./clientHelpInOrderCommentsSlice";
import AppDisplayComments from "../../../app/components/AppDisplayComments";
import { clientHelpInOrderValidationSchema } from "./clientOrderValidation";

interface Props {
    orderId: number;
}

interface FormData {
    body: string;
}

export default function ClientHelpInOrderChat({ orderId }: Props) {
    const token = useAppSelector(state => state.account.user?.token);
    const userEmail = useAppSelector(state => state.account.user?.email);
    const dispatch = useAppDispatch();
    const comments = useAppSelector(clientHelpInOrderCommentsSelectors.selectAll);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [loading, setLoading] = useState(false);
    const [displayLoading, setDisplayLoading] = useState(true);

    useEffect(() => {
        const baseUrl = import.meta.env.VITE_URL || 'http://localhost:7200/';
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}chat?orderId=` + orderId, {
                accessTokenFactory: () => token!
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
            
        setLoading(true);
        newConnection.start().catch(error => console.log('Wystąpił problem z nawiązaniem połączenia: ', error));

        newConnection.on('LoadComments', (comments: ChatComment[]) => {
            dispatch(setSelectedClientOrder(orderId));
            dispatch(setComments(comments));
            setLoading(false);
        });

        newConnection.on("ReceiveComment", (comment: ChatComment) => {
            dispatch(updateMessage(comment));
        });

        setConnection(newConnection);

        return () => {
            newConnection.stop();
        };
    }, [dispatch, orderId, token]);

    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => setDisplayLoading(false), 500); // Display loading for at least 500ms
            return () => clearTimeout(timer);
        } else {
            setDisplayLoading(true);
        }
    }, [loading]);

    const handleSendComment = async (data: FormData) => {
        const comment = {
            body: data.body,
            orderId: orderId,
            userEmail: userEmail
        } as ChatComment;

        if (connection) {
            try {
                await connection.invoke('SendComment', comment);
                reset({ body: '' });
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Połączenie nie zostało zainicjowane');
        }
    };

    const { control, handleSubmit, reset, formState: { isSubmitting, isValid }, formState: { errors } } = useForm<FormData>({
        mode: 'all',
        resolver: yupResolver(clientHelpInOrderValidationSchema)
    });

    return (
        <>
            {displayLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : <AppDisplayComments comments={comments} />
            }

            <Grid item xs={12} sx={{ mt: 2 }}>
                <Box
                    component="form"
                    onSubmit={handleSubmit(handleSendComment)}
                    noValidate
                >
                    <div>
                        <AppTextInput control={control} name="body" label="Dodaj wiadomość" multiline rows={3} error={!!errors.body} helperText={errors?.body?.message as string} />
                    </div>
                    <LoadingButton
                        disabled={!isValid || isSubmitting}
                        loading={isSubmitting}
                        type="submit"
                        //fullWidth
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Dodaj wiadomość
                    </LoadingButton>
                </Box>
            </Grid>
        </>
    )
}