import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Agreement, AgreementsParams } from "../../../app/models/agreement";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";
import agent from "../../../app/api/agent";

interface AgreementsState {
    agreementsLoaded: boolean;
    agreementsFiltersLoaded: boolean;
    status: string;
    currentAgreementAddedInOrderWizard: Agreement | null,
    agreementsParams: AgreementsParams;
    metaData: MetaData | null;
}

const agreementsAdapter = createEntityAdapter<Agreement>();

function getAxiosParams(agreementsParams: AgreementsParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', agreementsParams.pageNumber.toString());
    params.append('pageSize', agreementsParams.pageSize.toString());
    params.append('orderBy', agreementsParams.orderBy);
    if (agreementsParams.searchTerm) params.append('searchTerm', agreementsParams.searchTerm);
    // if (serviceParams.brands.length > 0) params.append('brands', serviceParams.brands.toString());
    // if (serviceParams.types.length > 0) params.append('types', serviceParams.types.toString());
    return params;
}

export const fetchAgreementsAsync = createAsyncThunk<Agreement[], void, {state: RootState} >(
    'agreements/fetchAgreementsAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().agreements.agreementsParams);
        try {
            const response = await agent.Admin.Agreements.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            console.error("Error in fetchAgreementsAsync:", error);
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchPossibleAgreements = createAsyncThunk(
    'agreements/fetchPossibleAgreements',
    async (_, thunkAPI) => {
        try {
            return agent.Admin.Agreements.fetchPossibleAgreements();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchAgreementAsync = createAsyncThunk<Agreement, number>(
    'agreements/fetchAgreementAsync',
    async (agreementId, thunkAPI) => {
        try {
            return await agent.Admin.Agreements.details(agreementId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchAgreementsFilters = createAsyncThunk(
    'agreements/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Admin.Agreements.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: '_'
    }
}

export const agreementsSlice = createSlice({
    name: 'agreements',
    initialState: agreementsAdapter.getInitialState<AgreementsState>({
        agreementsLoaded: false,
        agreementsFiltersLoaded: false,
        status: 'idle',
        currentAgreementAddedInOrderWizard: null,
        agreementsParams: initParams(),
        metaData: null
    }),
    reducers: {
        setAgreementsParams: (state, action) => {
            state.agreementsLoaded = false;
            state.agreementsParams = {...state.agreementsParams, ...action.payload, pageNumber: 1};
        },
        setAgreementsPageNumber: (state, action) => {
            state.agreementsLoaded = false;
            state.agreementsParams = {...state.agreementsParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetAgreementsParams: (state) => {
            state.agreementsParams = initParams();
        },
        setAgreement: (state, action) => {
            agreementsAdapter.upsertOne(state, action.payload);
            state.agreementsLoaded = false;
        },
        setCurrentAgreementAddedInOrderWizard: (state, action) => {
            state.currentAgreementAddedInOrderWizard = action.payload
        },
        removeAgreement: (state, action) => {
            agreementsAdapter.removeOne(state, action.payload);
            state.agreementsLoaded = false;
        },
        removeAgreements: (state) => {
            agreementsAdapter.removeAll(state);
            state.agreementsLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchAgreementsAsync.pending, (state) => {
                state.status = 'pendingFetchAgreements';
            });
            builder.addCase(fetchAgreementsAsync.fulfilled, (state, action) => {
                agreementsAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.agreementsLoaded = true;
            });
            builder.addCase(fetchAgreementsAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchAgreementAsync.pending, (state) => {
                state.status = 'pendingFetchAgreement';
            });
            builder.addCase(fetchAgreementAsync.fulfilled, (state, action) => {
                agreementsAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchAgreementAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchAgreementsFilters.pending, (state) => {
                state.status = 'pendingFetchAgreementsFilters';
            });
            builder.addCase(fetchAgreementsFilters.fulfilled, (state) => {
                // state.brands = action.payload.brands;
                // state.types = action.payload.types; 
                state.agreementsFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchAgreementsFilters.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const agreementsSelectors = agreementsAdapter.getSelectors((state: RootState) => state.agreements);

export const {setAgreementsParams, resetAgreementsParams, setMetaData, setAgreementsPageNumber, setAgreement, setCurrentAgreementAddedInOrderWizard, removeAgreement, removeAgreements} = agreementsSlice.actions;