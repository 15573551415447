import DoneIcon from '@mui/icons-material/Done';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function ResetPasswordSuccess() {
    return (
        <Box sx={{textAlign: "center"}}>
            <Typography variant='h3' gutterBottom>
                <DoneIcon color="success" fontSize="large" sx={{ marginRight: 2}}  />
                Resetowanie hasła zakończone sukcesem
            </Typography>
        </Box>
    )
}