import React from 'react';
import { SxProps, TextField, Theme } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';

interface Props {
    label: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    minRows: number;
    sx?: SxProps<Theme>;
}

export default function AppDynamicTextarea({ label, value, onChange, minRows = 1, sx }: Props) {
    return (
        <TextField
            fullWidth
            label={label}
            multiline
            value={value}
            onChange={onChange}
            InputProps={{
                inputComponent: TextareaAutosize as any,
                inputProps: {
                    minRows: minRows,
                },
            }}
            sx={sx}
        />
    );
}