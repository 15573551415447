import { Fragment, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Carousel from 'react-slick';
import { useSpring, animated } from 'react-spring';
import useStyles from './expertiseStyle';
import useTitle from '../Title/titleStyle';
import { useText } from '../../theme/common';
import ScrollAnimation from 'react-scroll-animation-wrapper';


const expertiseList = [
  ['Brukarstwo', 'Animacja', 'Logistyka'],
  ['Ogrodnictwo', 'Kasjerzy', 'Gastronomia'],
  ['Opieka', 'Obsługa Klienta', 'Sprzedawcy'],
  ['Pracownicy biurowi', 'marketing', 'Defense/Eco driving'],
  ['Magazynierzy', 'Stylizacje branży beauty', 'Mobile Apps'],
  ['Photography', 'Kosmetyczki', 'Usługi szwalnicze'],
  ['Handel', 'marketing', 'SEO'],
  ['Web Development', 'UI Designs', 'Obsługa programów graficznych'],
  ['Krawiectwo', 'Prace gospodarcze', 'Animatorzy czasu wolnego'],
];

const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x: number, y: number) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x: number, y: number) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans3 = (x: number, y: number) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;

function Expertise() {
  const [loaded, setLoaded] = useState(false);
  // Theme breakpoints
  const theme = useTheme();
  const { classes: text } = useText();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { classes, cx } = useStyles();
  const { classes: title } = useTitle();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true
  };
  const [position, setPosition] = useSpring(() => ({ xy: [0, 0], config: { mass: 50, tension: 550, friction: 140 } }));
  
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Fragment>
      <svg
        fill="#cccccc"
        width={845}
        height={1099}
        className={classes.background}
      >
        <use xlinkHref="../../../temporaryImages/decoration/square-deco-primary.svg#square" />
      </svg>
      <div className={classes.root}>
        <Container fixed onMouseMove={({ clientX: x, clientY: y }) => setPosition({ xy: calc(x, y) })}>
          <Grid container spacing={6}>
            {isDesktop && (
              <Grid item lg={1} />
            )}
            <Grid item md={4} xs={12}>
              <div className={classes.titleDeco}>
                {isDesktop && (
                  <svg
                    fill="#cccccc"
                    width={845}
                    height={1099}
                    className={classes.decoration}
                  >
                    <use xlinkHref="../../../temporaryImages/agency/wave_decoration.svg#main" />
                  </svg>
                )}
                {loaded && isDesktop && (
                  <ScrollAnimation
                    animateOnce
                    animateIn="zoomInShort"
                    offset={-100}
                    delay={100}
                    duration={0.6}
                    animateOnlyOnScrollDown={false}
                    animateOut="zoomOnShort"
                    initiallyVisible={false}
                  >
                    <div className={classes.parallaxWrap}>
                      <animated.div style={{ transform: position.xy.to(trans1) }}>
                        <span className={classes.iconGreen} />
                      </animated.div>
                      <animated.div style={{ transform: position.xy.to(trans2) }}>
                        <span className={classes.iconViolet} />
                      </animated.div>
                      <animated.div style={{ transform: position.xy.to(trans3) }}>
                        <span className={classes.iconBlue} />
                      </animated.div>
                    </div>
                  </ScrollAnimation>
                )}
              </div>
            </Grid>
            <Grid item lg={7} md={8} xs={12}>
              <Typography className={cx(title.default, text.subtitle)} variant="h4">
                Wybrane dziedziny egzaminacyjne
              </Typography>
              <Typography className={cx(classes.desc, text.paragraph)}>
                W naszym katalogu są egzaminy z najróżniejszych specjalizacji:
              </Typography>
              {!isTablet && (
                <div className={classes.runningTag}>
                  <Carousel {...settings}>
                    {expertiseList.map((group, indexGroup) => (
                      <div className={classes.tagGroup} key={indexGroup.toString()}>
                        {group.map((item, indexChild) => (
                          <span className={classes.tagItem} key={indexChild.toString()}>{item}</span>
                        ))}
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
        
      </div>
    </Fragment>
  );
}

export default Expertise;
