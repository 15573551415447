import { Avatar, Button, Card, CardActions, CardContent, Typography, CardHeader, Divider, Dialog, DialogActions, DialogContent, DialogTitle, ButtonGroup } from "@mui/material";
import { Link } from "react-router-dom";
import GradingIcon from '@mui/icons-material/Grading';
import { Investment } from "../../../../app/models/investment";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { formatToLocalDate, formatToLocalTime, getInvestmentStatusColor, getInvestmentStatusTitle } from "../../../../app/util/util";
import agent from "../../../../app/api/agent";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../app/store/configureStore";
import { toast } from "react-toastify";
import { setClientInvestment } from "../clientInvestmentsSlice";
import { keyframes } from '@emotion/react';
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import ClientInvestmentForm from "../ClientInvestmentForm";

interface Props {
    investment: Investment;
    handleCardSelectInvestment?: (handledInvestment: Investment) => void;
    cancelEdit: () => void;
}

export default function ClientInvestmentCard({ investment, handleCardSelectInvestment, cancelEdit }: Props) {
    const { handleSubmit } = useForm();
    const dispatch = useAppDispatch();
    // const orderPhoneHtml = "tel:" + investment.userPhone;
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [, setSelectedInvestment] = useState<Investment | undefined>(undefined);
    const [editMode] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const [isAccepting, setIsAccepting] = useState(false);

    function getStatusIcon(currentState: number) {
        switch (currentState) {
            case 0:
                return <HourglassTopIcon />;
            case 1:
                return <DoneIcon />;
            case 2:
                return <CancelIcon />;
            case 3:
                return <DoneIcon />;
            case 4:
                return <CancelIcon />;
            case 5:
                return <FormatListBulletedIcon />;
            case 6:
                return <GradingIcon />;
            case 7:
                return <ErrorIcon />;
        }
    }

    const onSubmit = async (data: FieldValues) => {
        const updatedInvestment = { ...investment, state: data.accept ? 3 : 4 };
        try {
            await agent.Client.Investments.acceptOrReject(updatedInvestment);
            toast.success('Projekt inwestycyjny zaktualizowany');
            dispatch(setClientInvestment(updatedInvestment));
        } catch (error) {
            console.error("Błąd podczas aktualizacji", error);
        }
    };

    const handleAccept = async () => {
        setIsAccepting(true);
        const updatedInvestment = { ...investment, state: 3 };
        try {
            await agent.Client.Investments.acceptOrReject(updatedInvestment);
            toast.success('Projekt inwestycyjny zaakceptowany');
            dispatch(setClientInvestment(updatedInvestment));
            handleCardSelectInvestment!(updatedInvestment);
        } catch (error) {
            console.error("Błąd podczas akceptowania projektu inwestycyjnego", error);
        } finally {
            setIsAccepting(false);
        }
    };

    const handleReject = async () => {
        setIsRejecting(true);
        const updatedInvestment = { ...investment, state: 4 };
        try {
            await agent.Client.Investments.acceptOrReject(updatedInvestment);
            toast.error('Projekt inwestycyjny odrzucony');
            dispatch(setClientInvestment(updatedInvestment));
        } catch (error) {
            console.error("Błąd podczas odrzucania projektu inwestycyjnego", error);
        } finally {
            setIsRejecting(false);
        }
    };

    function handleOpenRejectDialog(investment: Investment) {
        setSelectedInvestment(investment);
        setOpenRejectDialog(true);
    }

    function handleOpenAcceptDialog(investment: Investment) {
        setSelectedInvestment(investment);
        setOpenAcceptDialog(true);
    }

    function handleCloseRejectDialog() {
        setOpenRejectDialog(false);
    }

    function handleCloseAcceptDialog() {
        setOpenAcceptDialog(false);
    }

    const colorChange = keyframes`
    0% {
        background-color: red;
    }
    100% {
        background-color: orange;
    }
    `;

    if (editMode) return <ClientInvestmentForm clientInvestment={investment} cancelEdit={cancelEdit} />

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: getInvestmentStatusColor(investment.state) }} title={getInvestmentStatusTitle(investment.state)}> {getStatusIcon(investment.state)}</Avatar>
                }
                title={investment.userDisplayName}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
            />
            <Divider />
            <CardContent>
                <Typography gutterBottom>
                    Zamówienie #{investment.publicId}
                </Typography>
                <div style={{ marginBottom: 8 }}>
                    <Typography sx={{ display: 'inline' }} component="span">
                        Status zamówienia:
                    </Typography>
                    <Typography component="span" sx={{ color: getInvestmentStatusColor(investment.state), marginLeft: 1 }}>
                        {getInvestmentStatusTitle(investment.state)}
                    </Typography>
                </div>
                <Typography sx={{ mb: 1 }}>
                    Data zamówienia: {formatToLocalDate(investment.investmentCreatedDateTime.toString())} {formatToLocalTime(investment.investmentCreatedDateTime.toString())}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    Data ostatniej aktualizacji: {formatToLocalDate(investment.updatingDateTime.toString())} {formatToLocalTime(investment.updatingDateTime.toString())}
                </Typography>
                <Typography sx={{ mb: 1 }} fontWeight="bold">
                    {investment.price !== 0 ? 'Wyceniono: ' + investment.price : ''}
                </Typography>
            </CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardActions>
                    <Button
                        sx={{ mr: 1 }}
                        variant='outlined'
                        component={Link} to={`/client/investments/${investment.publicId}`}
                        size="small"
                        title='Więcej'
                    >Więcej</Button>
                    {investment.state === 0 &&
                        <Button
                            variant='outlined'
                            onClick={() => handleCardSelectInvestment!(investment)}
                            size="small"
                            title='Edytuj'
                            color='warning'
                        >Edycja</Button>
                    }
                    {investment.state === 3 &&
                        <Button
                            variant='outlined'
                            onClick={() => handleCardSelectInvestment!(investment)}
                            size="small"
                            title='Wypełnij formularz'
                            color='warning'
                            sx={{
                                animation: `${colorChange} 2s infinite`,
                                marginLeft: 'auto',
                                // Remove default button color when using custom background color animation
                                bgcolor: 'transparent',
                                // Adjust text color to be visible on both orange and red
                                color: 'white',
                            }}
                        >Wypełnij formularz</Button>
                    }
                    {investment.state === 1 &&
                        <>
                            <ButtonGroup
                                disableElevation
                                variant="outlined"
                            >
                                <LoadingButton
                                    loading={isRejecting}
                                    onClick={() => handleOpenRejectDialog(investment)}
                                    size="small"
                                    title='Odrzuć'
                                    color='error'
                                >Odrzuć</LoadingButton>
                                <LoadingButton
                                    loading={isAccepting}
                                    onClick={() => handleOpenAcceptDialog(investment)}
                                    size="small"
                                    title='Akceptuj'
                                    color='success'
                                >Akceptuj</LoadingButton>
                            </ButtonGroup>
                            <Dialog open={openRejectDialog} onClose={handleCloseRejectDialog}>
                                <DialogTitle textAlign='center'>Odrzuć projekt</DialogTitle>
                                <DialogContent>
                                    Potwierdź odrzucenie wyceny:
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: 'space-between' }}>
                                    <Button onClick={handleCloseRejectDialog} variant='outlined'>Anuluj</Button>
                                    <LoadingButton loading={isRejecting} onClick={handleReject} variant='outlined' color="error">
                                        Odrzuć
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openAcceptDialog} onClose={handleCloseAcceptDialog}>
                                <DialogTitle>Akceptuj projekt</DialogTitle>
                                <DialogContent>
                                    Potwierdź akceptację wyceny:
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: 'space-between' }}>
                                    <Button onClick={handleCloseAcceptDialog} variant='outlined'>Anuluj</Button>
                                    <LoadingButton loading={isAccepting} onClick={handleAccept} variant='outlined' color="success">
                                        Akceptuj
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
                        </>
                    }
                </CardActions>
            </form>
        </Card>
    )
}