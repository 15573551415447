import { Grid, Typography } from "@mui/material";

interface ParticipantsListProps {
    benefitients: string;
}

export const ParticipantsList: React.FC<ParticipantsListProps> = ({ benefitients }) => {
    if (!benefitients) return null;

    const participants = benefitients.split(';').map(participant => participant.trim().split('~'));

    return (
        <Grid container spacing={2}>
            {participants.map((participant, index) => (
                <Grid item xs={12} key={index}>
                    <Typography variant="h6">{`Uczestnik ${index + 1}:`}</Typography>
                    <Typography variant="body1">{`${participant[0]}`} {`${participant[1]}`} {`${participant[2]}`} {`${participant[3]}`} {`${participant[4]}`} {`${participant[5] == '' || participant[5] == null || participant[5] == undefined ? '' : participant[5]}`}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};