import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MobileMenu from './MobileMenu';
import useStyles from './headerStyle';
import { Link, useLocation } from 'react-router-dom';
import { brand } from '../brand';
import logo from '../wechsler_logo_w.svg';
import navMenu from './menu';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { signOut } from '../../../features/account/accountSlice';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

interface Props {
  onToggleDark: () => void;
  onToggleDir: () => void;
  invert?: boolean;
  darkMode: boolean;
}

function Header({ invert, onToggleDark, darkMode: initialDarkMode }: Props) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { user } = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const fixed = true;
  const { classes, cx } = useStyles() as { classes: Record<string, string>, cx: any };

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const [darkMode, setDarkMode] = useState(initialDarkMode);

  const handleToggleDark = () => {
    onToggleDark();
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <>
      {isMobile && <MobileMenu open={openDrawer} toggleDrawer={handleOpenDrawer} />}
      <AppBar
        component="div"
        position="relative"
        id="header"
        className={cx(
          classes.header,
          fixed && classes.fixed,
          openDrawer && classes.openDrawer
        )}
      >
        <Container>
          <div className={classes.headerContent}>
            <nav className={cx(classes.navLogo, invert && classes.invert)}>
              {isMobile && (
                <IconButton
                  onClick={handleOpenDrawer}
                  className={cx('hamburger hamburger--spin', classes.mobileMenu, openDrawer && 'is-active')}
                  size="large"
                >
                  <span className="hamburger-box">
                    <span className={cx(classes.bar, 'hamburger-inner')} />
                  </span>
                </IconButton>
              )}
              <div className={classes.logo}>
                {invert ? (
                  <Link to='/'>
                    <img src={logo} alt="logo" />
                    {brand.agency.name}
                  </Link>
                ) : (
                  <Link to='/'>
                    <img src={logo} alt="logo" />
                    {brand.agency.name}
                  </Link>
                )}
              </div>
            </nav>
            <nav className={cx(classes.navMenu)}>
              {isDesktop && (
                <ul>
                  {navMenu.map((item) => (
                    <li key={item.name}>
                      <Button
                        component={Link}
                        to={item.path}
                        className={`${currentPath === item.path ? 'active' : ''}`}
                      >
                        {item.name}
                      </Button>
                    </li>
                  ))}
                  <li>
                    <Button component={Link} to="/client" className={`${currentPath.startsWith("/client") ? 'active' : ''}`}>
                      Strefa klienta
                    </Button>
                  </li>
                  {user && user.roles?.includes('Admin') && (
                    <li>
                      <Button component={Link} to="/admin" className={`${currentPath.startsWith("/admin") ? 'active' : ''}`}>
                        Admin
                      </Button>
                    </li>
                  )}
                  {user ? (
                    <li>
                      <Button component={Link} to="/" onClick={() => dispatch(signOut())}>
                        Wyloguj
                      </Button>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Button component={Link} to="/login">
                          Zaloguj
                        </Button>
                      </li>
                      <li>
                        <Button component={Link} to="/register">
                          Rejestracja
                        </Button>
                      </li>
                    </>
                  )}
                </ul>
              )}
              <IconButton
                sx={{ ml: 6 }}
                onClick={handleToggleDark}
                color="inherit"
              >
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </nav>
          </div>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;