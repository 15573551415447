import { Avatar, Button, Card, CardActions, CardContent, Typography, CardHeader, Divider, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";
import GradingIcon from '@mui/icons-material/Grading';
import { Investment } from "../../../../app/models/investment";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import {getInvestmentStatusTitle, getInvestmentStatusColor, formatToLocalDate, formatToLocalTime} from "../../../../app/util/util";
import { useForm } from "react-hook-form";
import agent from "../../../../app/api/agent";
import { toast } from "react-toastify";
import { setAdminInvestment } from "../adminInvestmentsSlice";
import { useAppDispatch } from "../../../../app/store/configureStore";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    investment: Investment;
    handleCardSelectInvestment?: (handledInvestment: Investment) => void;
    handleCardSelectInvestmentValuation?: (handledInvestment: Investment) => void;
}

export default function AdminInvestmentCard({ investment, handleCardSelectInvestment, handleCardSelectInvestmentValuation }: Props) {
    const dispatch = useAppDispatch();
    const { formState: { isSubmitting } } = useForm();
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openGenerateAgreementDialog, setOpenGenerateAgreementDialog] = useState(false);
    const [, setSelectedInvestment] = useState<Investment | undefined>(undefined);
    const navigate = useNavigate();

    function getStatusIcon(currentState: number) {
        switch (currentState) {
            case 0:
                return <HourglassTopIcon />;
            case 1:
                return <DoneIcon />;
            case 2:
                return <CancelIcon />;
            case 3:
                return <DoneIcon />;
            case 4:
                return <CancelIcon />;
            case 5:
                return <GradingIcon />;
            case 6:
                return <ErrorIcon />;
        }
    }

    const handleComplete = async () => {
        try {
            await agent.Admin.Investments.update({ ...investment, state: 5 });
            toast.success('Projekt inwestycyjny zaakceptowany');
            dispatch(setAdminInvestment({ ...investment, state: 5 }));
        } catch (error) {
            console.error("Błąd podczas akceptowania projektu inwestycyjnego", error);
        }
    };

    function handleOpenAcceptDialog(investment: Investment) {
        setSelectedInvestment(investment);
        setOpenAcceptDialog(true);
    }

    function handleOpenGenerateAgreementDialog(investment: Investment) {
        setSelectedInvestment(investment);
        setOpenGenerateAgreementDialog(true);
    }

    function handleCloseAcceptDialog() {
        setOpenAcceptDialog(false);
    }

    function handleCloseGenerateAgreementDialog() {
        setOpenGenerateAgreementDialog(false);
    }

    async function getAgreementByInvestmentId(investmentId: number) {
        const response = await agent.Admin.Agreements.getAgreementByInvestmentPublicId(investmentId);
        return response;
    }

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: getInvestmentStatusColor(investment.state) }} title={getInvestmentStatusTitle(investment.state)}> {getStatusIcon(investment.state)}</Avatar>
                }
                title={investment.userDisplayName}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
            />
            <Divider />
            <CardContent>
                <Typography gutterBottom>
                    Zamówienie #{investment.publicId}
                </Typography>
                <div style={{ marginBottom: 8 }}>
                    <Typography sx={{ display: 'inline' }} component="span">
                        Status zamówienia:
                    </Typography>
                    <Typography component="span" sx={{ color: getInvestmentStatusColor(investment.state), marginLeft: 1 }}>
                        {getInvestmentStatusTitle(investment.state)}
                    </Typography>
                </div>
                <Typography sx={{ mb: 1 }}>
                    Data zamówienia: {formatToLocalDate(investment.investmentCreatedDateTime.toString())} {formatToLocalTime(investment.investmentCreatedDateTime.toString())}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    {investment.internalComment !== '' ? 'Komentarz wewnętrzny: ' + investment.internalComment : ''}
                </Typography>
                <Typography sx={{ mb: 1 }} fontWeight="bold">
                    {investment.price !== 0 ? 'Wyceniono: ' + investment.price : ''}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{ mt: "auto" }}
            >
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    component={Link} to={`/admin/investments/${investment.publicId}`}
                    size="small"
                    title='Więcej'
                >Więcej</Button>
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    onClick={() => handleCardSelectInvestment!(investment)}
                    size="small"
                    title='Edycja'
                    color='warning'
                >Edycja</Button>

                {investment.state === 0 ? (
                    <Button
                        variant='outlined'
                        onClick={() => handleCardSelectInvestmentValuation!(investment)}
                        size="small"
                        title='Wyceń'
                        color='success'
                    >Wyceń</Button>
                ) : ''}

                {investment.state === 5 && investment.isAgreementCreated === false ? (
                    <Button
                        variant='outlined'
                        onClick={() => {
                            navigate("/admin/agreements", {
                                state: {
                                    selectedInvestment: investment,
                                    openForm: true,
                                    investmentPublicId: investment.publicId,
                                    userPhone: investment.userPhone,
                                    userDisplayName: investment.userDisplayName,
                                    userEmail: investment.userEmail
                                }
                            });
                        }}
                        size="small"
                        title='Umowa'
                        color='secondary'
                    >Wygeneruj umowę</Button>
                ) : ''}

                {investment.state === 5 && investment.isAgreementCreated === true ? (
                    <>
                        <Button
                            variant='outlined'
                            onClick={() => handleOpenGenerateAgreementDialog(investment)}
                            size="small"
                            title='Umowa'
                            color='error'
                        >Wygeneruj umowę ponownie</Button>
                        <Dialog open={openGenerateAgreementDialog} onClose={handleCloseGenerateAgreementDialog}>
                            <DialogTitle textAlign='center'>Akceptuj</DialogTitle>
                            <DialogContent>
                                Istnieje wygenerowana umowa dla tego projektu inwestycyjnego.
                                Czy chcesz stworzyć nową?
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'space-between' }}>
                                <Button onClick={handleCloseGenerateAgreementDialog} variant='outlined' >Anuluj</Button>
                                <LoadingButton loading={isSubmitting} onClick={async () => {
                                    const agreement = await getAgreementByInvestmentId(investment.publicId);

                                    if (!agreement) {
                                        console.error('Nie znaleziono umowy dla tego projektu inwestycyjnego');
                                        return;
                                    }

                                    navigate("/admin/agreements", {
                                        replace: true,
                                        state: {
                                            selectedAgreement: agreement,
                                            openForm: true,
                                            investmentPublicId: investment.publicId,
                                            userPhone: investment.userPhone,
                                            userDisplayName: investment.userDisplayName,
                                            userEmail: investment.userEmail
                                        }
                                    });
                                }} variant='outlined' color="success">
                                    Akceptuj
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </>
                ) : ''}

                {investment.state === 3 &&
                    <>
                        <Button
                            variant='outlined'
                            onClick={() => handleOpenAcceptDialog(investment)}
                            size="small"
                            title='Akceptuj'
                            color='success'
                        >Akceptuj</Button>
                        <Dialog open={openAcceptDialog} onClose={handleCloseAcceptDialog}>
                            <DialogTitle textAlign='center'>Akceptuj projekt</DialogTitle>
                            <DialogContent>
                                Potwierdź akceptację projektu:
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'space-between' }}>
                                <Button onClick={handleCloseAcceptDialog} variant='outlined' >Anuluj</Button>
                                <LoadingButton loading={isSubmitting} onClick={handleComplete} variant='outlined' color="success">
                                    Akceptuj
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </CardActions>
        </Card>
    )
}