import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { AppDeleteModalProps } from "../models/appDeleteModalProps";
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
    modalDeleteModalProps: AppDeleteModalProps
}

const style = {
    position: "absolute" as 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '50%',
    height: 320,
    bgcolor: 'background.default',
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,

}

const styleMobile = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '50%',
    bgcolor: 'background.default',
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,

}

export default function AppDeleteModal({ modalDeleteModalProps }: Props) {
    const { itemDescriptionFirstLine, itemDescriptionSecondLine, handleCloseModal, handleIntDeleteFunction, handleStringDeleteFunction, isIntDeleteFunction, intId, stringId } = modalDeleteModalProps;
    return (
        <>
            {/* Desktop version */}
            <Modal
                open={true}
                onClose={handleCloseModal}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
                <Box sx={style}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography color='red' variant="h3" align="center"><ErrorIcon fontSize="large" /> Uwaga!</Typography>
                            <Typography variant="h4" align="center" sx={{ m: 2 }}>{itemDescriptionFirstLine}</Typography>
                            <Typography variant="h5" align="center">{itemDescriptionSecondLine}</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ position: 'absolute', bottom: 16, left: 0, right: 0, px: 4 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Button fullWidth variant='outlined' onClick={handleCloseModal}>Anuluj</Button>
                            </Grid>
                            <Grid item xs={6}>
                                {isIntDeleteFunction ? (
                                    <Button fullWidth variant='outlined' color='warning' onClick={() => handleIntDeleteFunction!(intId!)}>Usuń</Button>
                                ) : (
                                    <Button fullWidth variant='outlined' color='warning' onClick={() => handleStringDeleteFunction!(stringId!)}>Usuń</Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            {/* Mobile version */}
            <Modal
                open={true}
                onClose={handleCloseModal}
                sx={{ display: { sm: 'flex', md: 'none' } }}
            >
                <Box sx={styleMobile}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography color='red' variant="h3" align="center"><ErrorIcon fontSize="large" /> Uwaga!</Typography>
                            <Typography variant="h4" align="center" sx={{ m: 2 }}>{itemDescriptionFirstLine}</Typography>
                            <Typography variant="h5" align="center">{itemDescriptionSecondLine}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='space-between' >
                                <Button variant='outlined' onClick={handleCloseModal}>Anuluj</Button>
                                {isIntDeleteFunction ?
                                    <Button variant='outlined' color='warning' onClick={() => handleIntDeleteFunction}>Usuń</Button>
                                    :
                                    <Button variant='outlined' color='warning' onClick={() => handleStringDeleteFunction}>Usuń</Button>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}