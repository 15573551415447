import * as yup from 'yup';
import dayjs from 'dayjs';
import { OrderFormValues } from '../../../app/models/orderFormValues';
import { PaymentOrderState } from '../../../app/models/order';

export const adminOrderValidationSchema: yup.ObjectSchema<OrderFormValues> = yup.object({
    serviceId: yup
        .number()
        .required('Wskaż usługę'),
    serviceName: yup
        .string()
        .default(''),
    customeClientServiceName: yup
        .string()
        .required('Nazwa egzaminu jest wymagana'),
    preposedDateTime: yup
        .string()
        .required('Prawidłowa data jest wymagana')
        .test(
            'is-valid-date',
            'Nieprawidłowa data',
            (value) => {
                const parsedDate = dayjs(value);
                return parsedDate.isValid();
            }
        ),
    placeOfServiceAndAnotherInformations: yup
        .string()
        .required('Miejsce realizacji jest wymagane'),
    rows: yup.array().of(
        yup.object().shape({
            firstName: yup.string().required('Wypełnij to pole'),
            lastName: yup.string().required('Wypełnij to pole'),
            pesel: yup.string().required('Wypełnij to pole'),
            birthPlace: yup.string().required('Wypełnij to pole'),
            birthDate: yup.string()
                .required('Data urodzenia jest wymagana')
                .test(
                    'is-valid-date',
                    'Nieprawidłowa data',
                    (value) => {
                        if (!value) return false;
                        const parsedDate = dayjs(value, 'DD.MM.YYYY', true);
                        return parsedDate.isValid();
                    }
                )
                .test(
                    'is-younger-than-110',
                    'Wiek nie może przekraczać 110 lat',
                    (value) => {
                        if (!value) return false;
                        const parsedDate = dayjs(value, 'DD.MM.YYYY', true);
                        return dayjs().diff(parsedDate, 'year') <= 110;
                    }
                ),
                
        })
    ).required(),
    clientComment: yup.string(),
    trainingProgram: yup.string(),
    educationEffects: yup.string(),
    payerClientName: yup.string(),
    payerClientAddress: yup.string(),
    payerClientNIP: yup.string(),
    payerInstitutionName: yup.string().required('Nazwa instytucji jest wymagana'),
    payerLastName: yup.string().required('Nazwisko jest wymagane'),
    payerFirstName: yup.string().required('Imię jest wymagane'),
    payerStreet: yup.string().required('Ulica jest wymagana'),
    payerBuilding: yup.string().required('Budynek jest wymagany'),
    payerPremises: yup.mixed(),
    payerZip: yup.string().required('Kod pocztowy jest wymagany'),
    payerCity: yup.string().required('Miejscowość jest wymagana'),
    payerState: yup.string().required('Województwo jest wymagane'),
    payerCountry: yup.string().required('Kraj jest wymagany'),
    payerNIP: yup.string().required('NIP jest wymagany'),
    benefitients: yup.string(),//.required('Dane osób egzaminowanych są wymagane'),
    totalItem: yup
        .mixed(),
    paymentOrderState: yup
        .mixed<PaymentOrderState>(),
});

export const adminHelpInOrderValidationSchema = yup.object().shape({
    body: yup.string().required('Wiadomość jest wymagana'),
});