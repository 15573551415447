import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { Order, OrdersParams } from "../../../app/models/order";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";

interface AdminOrdersState {
    adminOrdersLoaded: boolean;
    adminOrdersFiltersLoaded: boolean;
    status: string;
    selectedOrder: number;
    states: string[];
    paymentStates: string[];
    adminOrdersParams: OrdersParams;
    metaData: MetaData | null;
}

const adminOrdersAdapter = createEntityAdapter<Order>({
    selectId: (order) => order.publicId,
});

function getAxiosParams(ordersParams: OrdersParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', ordersParams.pageNumber.toString());
    params.append('pageSize', ordersParams.pageSize.toString());
    params.append('orderBy', ordersParams.orderBy);
    if (ordersParams.searchTerm) params.append('searchTerm', ordersParams.searchTerm);
    if (ordersParams.states.length > 0) params.append('states', ordersParams.states.toString());
    if (ordersParams.paymentStates.length > 0) params.append('paymentStates', ordersParams.paymentStates.toString());
    return params;
}

export const fetchAdminOrdersAsync = createAsyncThunk<Order[], void, { state: RootState }>(
    'adminOrders/fetchAdminOrdersAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().adminOrders.adminOrdersParams);
        try {
            const response = await agent.Admin.Orders.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    }
)

export const fetchAdminOrderAsync = createAsyncThunk<Order, number>(
    'adminOrders/fetchAdminOrderAsync',
    async (orderId, thunkAPI) => {
        try {
            return await agent.Admin.Orders.details(orderId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error })
        }
    }
)

export const fetchAdminOrdersFilters = createAsyncThunk(
    'adminOrders/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Admin.Orders.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'dateDesc',
        states: [],
        paymentStates: []
    }
}

export const adminOrdersSlice = createSlice({
    name: 'adminOrders',
    initialState: adminOrdersAdapter.getInitialState<AdminOrdersState>({
        adminOrdersLoaded: false,
        adminOrdersFiltersLoaded: false,
        status: 'idle',
        selectedOrder: 0,
        states: [],
        paymentStates: [],
        adminOrdersParams: initParams(),
        metaData: null
    }),
    reducers: {
        setAdminOrdersParams: (state, action) => {
            state.adminOrdersLoaded = false;
            state.adminOrdersParams = { ...state.adminOrdersParams, ...action.payload, pageNumber: 1 };
        },
        setAdminOrdersPageNumber: (state, action) => {
            state.adminOrdersLoaded = false;
            state.adminOrdersParams = { ...state.adminOrdersParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetAdminOrdersParams: (state) => {
            state.adminOrdersParams = initParams();
        },
        setAdminOrder: (state, action) => {
            adminOrdersAdapter.upsertOne(state, action.payload);
            state.adminOrdersLoaded = false;
        },
        removeAdminOrder: (state, action) => {
            adminOrdersAdapter.removeOne(state, action.payload);
            state.adminOrdersLoaded = false;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchAdminOrdersAsync.pending, (state) => {
            state.status = 'pendingFetchAdminOrders';
        });
        builder.addCase(fetchAdminOrdersAsync.fulfilled, (state, action) => {
            adminOrdersAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.adminOrdersLoaded = true;
        });
        builder.addCase(fetchAdminOrdersAsync.rejected, (state) => {
            state.status = 'idle';
        });

        builder.addCase(fetchAdminOrderAsync.pending, (state) => {
            state.status = 'pendingFetchAdminOrder';
        });
        builder.addCase(fetchAdminOrderAsync.fulfilled, (state, action) => {
            adminOrdersAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchAdminOrderAsync.rejected, (state) => {
            state.status = 'idle';
        });

        builder.addCase(fetchAdminOrdersFilters.pending, (state) => {
            state.status = 'pendingFetchAdminOrdersFilters';
        });
        builder.addCase(fetchAdminOrdersFilters.fulfilled, (state) => {
            state.adminOrdersFiltersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchAdminOrdersFilters.rejected, (state) => {
            state.status = 'idle';
        });
    })
})

export const adminOrdersSelectors = adminOrdersAdapter.getSelectors((state: RootState) => state.adminOrders);

export const { setAdminOrdersParams, resetAdminOrdersParams, setMetaData, setAdminOrdersPageNumber, setAdminOrder, removeAdminOrder } = adminOrdersSlice.actions;