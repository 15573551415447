import { Typography, Grid, Button } from "@mui/material";
import { Control, UseFormSetValue } from "react-hook-form";
import { OrderFormValues } from "../../models/orderFormValues";
import AppTextInput from "../AppTextInput";
import AppSelectStringsList from "../AppSelectStringsList";
import { states } from "../../util/util";
import { useEffect, useState } from "react";
import { OrderPaymentData } from "../../models/orderPaymentData";
import agent from "../../api/agent";

interface Props {
    control: Control<OrderFormValues> | undefined;
    setValue: UseFormSetValue<OrderFormValues>;
}

export default function OrderFormPayerData({ control, setValue }: Props) {
    const [isAnyOrderWithClientPaymentData, setIsAnyOrderWithClientPaymentData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await agent.Client.Orders.isAnyOrderWithClientPaymentData();
                console.log(result);
                setIsAnyOrderWithClientPaymentData(result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    async function handleLoadLastPaymentData() {
        try {
            if (isAnyOrderWithClientPaymentData) {
                const lastPaymentData = await agent.Client.Orders.getLastPaymentData();
                console.log(lastPaymentData);
                setFormValues(lastPaymentData);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setFormValues = (data: OrderPaymentData) => {
        setValue('payerClientName', data.payerClientName, { shouldValidate: true });
        setValue('payerClientAddress', data.payerClientAddress, { shouldValidate: true });
        setValue('payerClientNIP', data.payerClientNIP, { shouldValidate: true });
        setValue('payerInstitutionName', data.payerInstitutionName, { shouldValidate: true });
        setValue('payerLastName', data.payerLastName, { shouldValidate: true });
        setValue('payerFirstName', data.payerFirstName, { shouldValidate: true });
        setValue('payerStreet', data.payerStreet, { shouldValidate: true });
        setValue('payerBuilding', data.payerBuilding, { shouldValidate: true });
        setValue('payerPremises', data.payerPremises);
        setValue('payerZip', data.payerZip, { shouldValidate: true });
        setValue('payerCity', data.payerCity, { shouldValidate: true });
        setValue('payerState', data.payerState, { shouldValidate: true });
        setValue('payerCountry', data.payerCountry, { shouldValidate: true });
        setValue('payerNIP', data.payerNIP, { shouldValidate: true });
    }
    
    return (
        <>
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Dane płatnika</Typography>
            <Grid container spacing={3} marginBottom={3}>
                {isAnyOrderWithClientPaymentData &&
                    <Grid item xs={12} sm={12}>
                        <Button variant="outlined" onClick={handleLoadLastPaymentData}>Wczytaj ostatnie </Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <AppTextInput control={control} name='payerInstitutionName' label='Nazwa instytucji' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerLastName' label='Nazwisko' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerFirstName' label='Imię' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerStreet' label='Ulica' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerBuilding' label='Budynek' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerPremises' label='Nr lokalu' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerZip' label='Kod pocztowy' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerCity' label='Miejscowość' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppSelectStringsList control={control} name='payerState' label='Województwo' items={states} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerCountry' label='Kraj' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='payerNIP' label='NIP' />
                </Grid>
            </Grid>
        </>
    )
}