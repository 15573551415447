import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

// eslint-disable-next-line react-refresh/only-export-components
export enum Align {
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Inherit = 'inherit',
  Justify = 'justify'
}

export interface TimeLineElement {
  dateAndTimeToDisplay: string;
  align: Align;
  header: string;
  subtitle: string;
  icon: JSX.Element;
  iconColor: string;
}

interface Props {
  elements: TimeLineElement[];
}

export default function AppTimeline({ elements }: Props) {
  const [showAll, setShowAll] = useState(false);
  const theme = useTheme();
  const isBetween900And1083 = useMediaQuery('(min-width:900px) and (max-width:1083px)');
  const isLessThan464 = useMediaQuery('(max-width:464px)');
  const displayedElements = showAll ? elements : elements.slice(-3);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {elements.length > 3 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button onClick={handleToggleShowAll}>
            {showAll ? 'Zwiń' : 'Rozwiń'}
          </Button>
        </Box>
      )}
      {isBetween900And1083 || isLessThan464 ?
        <Timeline position="right" sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
          {displayedElements.map((element, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ bgcolor: element.iconColor }}>
                  {element.icon}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" textAlign='center'>
                  {element.header}
                </Typography>
                <Typography textAlign='center'>{element.subtitle}</Typography>
                <Typography variant='body2' textAlign='center'>{element.dateAndTimeToDisplay}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
        :
        <Timeline position="alternate">
          {displayedElements.map(element => (
            <TimelineItem key={element.dateAndTimeToDisplay}>
              <TimelineOppositeContent sx={{ m: 'auto 0', color: theme.palette.text.primary }} align={element.align} variant="body2">
                {element.dateAndTimeToDisplay}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ bgcolor: element.iconColor }}>
                  {element.icon}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  {element.header}
                </Typography>
                <Typography>{element.subtitle}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      }
    </>
  );
}