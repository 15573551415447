import * as yup from 'yup';
import { AdminOrderAddInvoiceFormValues } from '../../../app/models/adminOrderAddInvoiceFormValues';

export const adminOrderAddInvoiceValidationSchema: yup.ObjectSchema<AdminOrderAddInvoiceFormValues> = yup.object({
    isDebug: yup.mixed(),
    orderId: yup.mixed(),
    customeClientServiceName: yup
        .string()
        .required('Nazwa Egzaminu, jaka będzie widniała na FV jest wymagana'),
    payerClientName: yup
        .string()
        .required('Nazwa Klienta jest wymagana'),
    payerClientAddress: yup
        .string()
        .required('Adres Klienta jest wymagany'),
    payerClientNIP: yup
        .string()
        .required('NIP Klienta jest wymagany'),
    unitBasePrice: yup
        .number()
        .required('Podaj cenę bazową na osobę'),
    unitDiscountedPrice: yup
        .number()
        .required('Podaj cenę (po rabacie) na osobę (jak nie ma rabatu - daj cenę taką, jak cena bazowa)'),
    totalItem: yup
        .number()
        .required('Podaj liczbę osób egzaminowanych')
});