import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { fetchClientInvestmentAsync, clientInvestmentsSelectors } from "./clientInvestmentsSlice";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import NotFound from "../../../app/errors/NotFound";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppTimeline, { Align, TimeLineElement } from "../../../app/components/AppTimeLine";
import { formatToLocalDate, formatToLocalTime, getInvestmentStatusColor, getInvestmentStatusTitle } from "../../../app/util/util";
import ClientHelpInInvestmentChat from "./ClientHelpInInvestmentChat";
import agent from "../../../app/api/agent";
import { Investment } from "../../../app/models/investment";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import GradingIcon from '@mui/icons-material/Grading';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AppReturn from "../../../app/components/AppReturn";

export default function ClientInvestmentDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const clientInvestment = useAppSelector(state => clientInvestmentsSelectors.selectById(state, id!));
    const { status: clientInvestmentStatus } = useAppSelector(state => state.clientInvestments);
    const [timeLineElements, setTimeLineElements] = useState<TimeLineElement[]>([]);
    const [loading, setLoading] = useState(false);

    function getStatusIcon(currentState: number) {
        switch (currentState) {
            case 0:
                return <HourglassTopIcon />;
            case 1:
                return <DoneIcon />;
            case 2:
                return <CancelIcon />;
            case 3:
                return <DoneOutlineIcon />;
            case 4:
                return <CancelIcon />;
            case 5:
                return <GradingIcon />;
            case 6:
                return <DoneAllIcon />;
            case 7:
                return <CancelIcon />;
            default:
                return <CancelIcon />;
        }
    }

    useEffect(() => {
        if (!clientInvestment) dispatch(fetchClientInvestmentAsync(parseInt(id!)));
    }, [id, dispatch, clientInvestment]);

    useEffect(() => {
        const fetchHistory = async () => {
            if (clientInvestment) {
                setLoading(true);
                try {
                    const historyOfInvestment: Investment[] = await agent.Client.Investments.getHistoryOfInvestment(clientInvestment.publicId);
                    const timelineData: TimeLineElement[] = historyOfInvestment.map(h => ({
                        dateAndTimeToDisplay: new Date(h.updatingDateTime).toLocaleString(),
                        align: Align.Left,
                        header: h.updatingUserIsAdmin ? 'Administrator' : 'Klient',
                        subtitle: getInvestmentStatusTitle(h.state),
                        icon: getStatusIcon(h.state),
                        iconColor: getInvestmentStatusColor(h.state)
                    }));
                    setTimeLineElements(timelineData);
                } catch (error) {
                    console.error("Failed to fetch investment history:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchHistory();
    }, [clientInvestment]);

    if (clientInvestmentStatus.includes('pending')) return <LoadingComponent />

    if (!clientInvestment) return <NotFound />

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Projekt inwestycyjny #" + clientInvestment.publicId} backPath="/client/investments" />
            <Grid container>
                <Grid item md={7} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Podstawowe informacje'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {clientInvestment.investmentName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa wskazana przez Klienta
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {formatToLocalDate(clientInvestment.investmentCreatedDateTime.toString())} {formatToLocalTime(clientInvestment.investmentCreatedDateTime.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        data i godzina złożenia zamówienia
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {clientInvestment.competition}
                                    </Typography>
                                    <Typography variant="overline">
                                        Rodzaj naboru/konkursu lub link do naboru
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {clientInvestment.clientComment}
                                    </Typography>
                                    <Typography variant="overline">
                                        komentarz Klienta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {formatToLocalDate(clientInvestment.submissionDeadline.toString())} {formatToLocalTime(clientInvestment.submissionDeadline.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        termin składania wniosków
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {clientInvestment.id}
                                    </Typography>
                                    <Typography variant="overline">
                                        tymczasowe Id
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {getInvestmentStatusTitle(clientInvestment.state)}
                                    </Typography>
                                    <Typography variant="overline">
                                        Status zamówienia
                                    </Typography>
                                </Grid>
                                {clientInvestment.state === 1 || clientInvestment.state === 3 || clientInvestment.state === 4 || clientInvestment.state === 5 ?
                                    (<>
                                        <Grid item xs={12}>
                                            <Divider sx={{ mb: 2 }} />
                                            <Typography variant="h6">
                                                {clientInvestment.publicComment}
                                            </Typography>
                                            <Typography variant="overline">
                                                Komentarz wyceniającego
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                {clientInvestment.price}
                                            </Typography>
                                            <Typography variant="overline">
                                                Wycena
                                            </Typography>
                                        </Grid>
                                    </>) : ''
                                }
                                {clientInvestment.state === 3 || clientInvestment.state === 5 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Divider sx={{ mb: 2 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientInstitutionName}
                                            </Typography>
                                            <Typography variant="overline">
                                                Nazwa instytucji
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientLastname}
                                            </Typography>
                                            <Typography variant="overline">
                                                Imię
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientFirstname}
                                            </Typography>
                                            <Typography variant="overline">
                                                Nazwisko
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientPhone}
                                            </Typography>
                                            <Typography variant="overline">
                                                Telefon
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientEmail}
                                            </Typography>
                                            <Typography variant="overline">
                                                Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientStreet}
                                            </Typography>
                                            <Typography variant="overline">
                                                Ulica
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientBuilding}
                                            </Typography>
                                            <Typography variant="overline">
                                                Budynek
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientPremises}
                                            </Typography>
                                            <Typography variant="overline">
                                                Lokal
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientZip}
                                            </Typography>
                                            <Typography variant="overline">
                                                Kod pocztowy
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientCity}
                                            </Typography>
                                            <Typography variant="overline">
                                                Miasto
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientState}
                                            </Typography>
                                            <Typography variant="overline">
                                                Województwo
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientCountry}
                                            </Typography>
                                            <Typography variant="overline">
                                                Kraj
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ mb: 2 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerInstitutionName}
                                            </Typography>
                                            <Typography variant="overline">
                                                Nazwa instytucji płatnika
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerRepresentative}
                                            </Typography>
                                            <Typography variant="overline">
                                                Przedstawiciel
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerStreet}
                                            </Typography>
                                            <Typography variant="overline">
                                                Ulica
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerBuilding}
                                            </Typography>
                                            <Typography variant="overline">
                                                Budynek
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerPremises}
                                            </Typography>
                                            <Typography variant="overline">
                                                Lokal
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerZip}
                                            </Typography>
                                            <Typography variant="overline">
                                                Kod pocztowy
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerCity}
                                            </Typography>
                                            <Typography variant="overline">
                                                Miasto
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerState}
                                            </Typography>
                                            <Typography variant="overline">
                                                Województwo
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerCountry}
                                            </Typography>
                                            <Typography variant="overline">
                                                Kraj
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {clientInvestment.payerNIP}
                                            </Typography>
                                            <Typography variant="overline">
                                                NIP
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ mb: 2 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.projectName}
                                            </Typography>
                                            <Typography variant="overline">
                                                Nazwa projektu
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {formatToLocalDate(clientInvestment.projectDateStart.toString())} {formatToLocalTime(clientInvestment.projectDateStart.toString())}
                                            </Typography>
                                            <Typography variant="overline">
                                                Data rozpoczęcia projektu
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                {formatToLocalDate(clientInvestment.projectDateEnd.toString())} {formatToLocalTime(clientInvestment.projectDateEnd.toString())}
                                            </Typography>
                                            <Typography variant="overline">
                                                Data zakończenia projektu
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.projectMaxBudget}
                                            </Typography>
                                            <Typography variant="overline">
                                                Maksymalny budżet
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.projectDescription}
                                            </Typography>
                                            <Typography variant="overline">
                                                Opis projektu
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ mb: 2 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientExperience}
                                            </Typography>
                                            <Typography variant="overline">
                                                Doświadczenie klienta
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {clientInvestment.clientPotential}
                                            </Typography>
                                            <Typography variant="overline">
                                                Potencjał klienta
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Dane klienta'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h5">
                                        {clientInvestment.userDisplayName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa Klienta
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {clientInvestment.userEmail}
                                    </Typography>
                                    <Typography variant="overline">
                                        email
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {clientInvestment.userPhone === 'null' || clientInvestment.userPhone === '' ?
                                            '-' : clientInvestment.userPhone}
                                    </Typography>
                                    <Typography variant="overline">
                                        telefon kontaktowy
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Historia'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                                        <CircularProgress />
                                    </Box>
                                ) : <AppTimeline elements={timeLineElements} />}
                            </Grid>
                        </Grid>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Korespondencja'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>
                                <ClientHelpInInvestmentChat investmentId={parseInt(id!)} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}