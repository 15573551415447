import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useController, UseControllerProps, FieldValues, Path, PathValue } from "react-hook-form";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    label: string;
    items: string[];
}

export default function AppSelectStringsList<T extends FieldValues>({ label, items, ...props }: Props<T>) {
    const { field, fieldState } = useController<T>({
        ...props,
        defaultValue: '' as PathValue<T, Path<T>> // Ensuring it's an empty string compatible with T
    });

    return (
        <FormControl fullWidth error={!!fieldState.error}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={field.value}
                label={label}
                onChange={field.onChange}
            >
                {items.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
    );
}