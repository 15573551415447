import { useState, useEffect, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useText } from '../../theme/common';
import useStyles from './bannerStyles';
import YouTube from 'react-youtube';
import yt from './youtube';

function VideoBanner() {
  // Theme breakpoints
  const theme = useTheme();
  const { classes: text } = useText();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  // Youtube player
  const [, setPlayed] = useState(false);
  const [playCtrl, setPlayedCtrl] = useState(true);
  const [player, setPlayer] = useState<any[]>([]);
  const { classes, cx } = useStyles();

  const _onEnd = (event: { target: any }) => {
    event.target.playVideo();
  };

  const _onPlay = useCallback(() => {
    if (player[0]) {
      const curTime = player[0].getCurrentTime();
      if (curTime > 0) {
        setPlayed(true);
        setPlayedCtrl(true);
      }
    }
  }, [player]);

  const _onReady = (event: { target: any }) => {
    setPlayer(prevPlayers => [...prevPlayers, event.target]);
  };

  const _onTogglePause = () => {
    setPlayedCtrl(!playCtrl);
    if (playCtrl) {
      player[0].pauseVideo();
    } else {
      player[0].playVideo();
    }
  };

  const opts = {
    height: '720',
    width: '1080',
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      origin: 'http://localhost:3000'
    }
  };

  useEffect(() => {
    if (isDesktop) {
      _onPlay();
    }
  }, [isDesktop, _onPlay]);

  return (
    <div className={classes.heroContent}>
      <Container>
        <Grid container spacing={6}>
          <Grid item md={6} display={{ md: 'flex', sm: 'none', xs: 'none' }}>
            <div className={classes.bannerText}>
              <div className={classes.title}>
                <Typography variant="h4" className={cx(classes.textHelper, text.title)} >
                  Niepubliczne Centrum Kształcenia Zawodowego
                </Typography>
              </div>
            </div>
          </Grid>
          {isTablet && (
            <Grid item md={6}>
              <div className={classes.videoWrap}>
                <div className={classes.videoFigure}>
                  <div className={classes.innerFigure}>
                    {isDesktop && (
                      <IconButton className={classes.btnPlay} onClick={_onTogglePause} size="large">
                        {playCtrl ? <PauseIcon /> : <PlayIcon />}
                      </IconButton>
                    )}
                    {yt.use && (
                      <div className={classes.video}>
                        {isDesktop && (
                          <YouTube
                            videoId="GCpnSyV_Gho"
                            opts={opts}
                            onReady={_onReady}
                            onEnd={_onEnd}
                            onPlay={_onPlay}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default VideoBanner;