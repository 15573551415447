import { Box, Grid, Modal, Typography, IconButton, List, ListItem } from "@mui/material";
import AdminRoleAdd from "./AdminRoleAdd";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../app/store/configureStore";
import { fetchRolesAsync, removeRole, rolesSelectors } from "./rolesSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from "react";
import agent from "../../../../app/api/agent";
import { toast } from "react-toastify";
// import AdminRoleEdit from "./AdminRoleEdit";
// import AdminRoleDelete from "./AdminRoleDelete";

interface Props {
    cancelEditRoles: () => void;
}

const style = {
    position: "absolute" as 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '30%',
    bgcolor: 'background.default',
    borderRadius: "12px",
    height: 420,
    boxShadow: 24,
    p: 4
};

export default function AdminRolesManager({ cancelEditRoles }: Props) {
    const dispatch = useAppDispatch();
    const roles = useSelector((state: RootState) => rolesSelectors.selectAll(state));
    const rolesLoaded = useSelector((state: RootState) => state.roles.rolesLoaded);

    useEffect(() => {
        if (!rolesLoaded) {
            dispatch(fetchRolesAsync());
        }
    }, [dispatch, rolesLoaded]);

    function handleDelete(id?: string) {
        if (id) {
            console.log(id);
            agent.Admin.Roles.delete(id)
            .then(() => dispatch(removeRole(id)))
            .catch(error => console.log(error)); 
                toast.success('Usunięto rolę')
        } else {
            toast.error('Nie można usunąć')
        }
    }

    return (
        <>
            <Modal
                open={true}
                onClose={cancelEditRoles}
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h5">
                                    Zarządzaj rolami
                                </Typography>
                                <IconButton edge="end" color="inherit" onClick={cancelEditRoles} aria-label="close">
                                    <CloseIcon sx={{ bgcolor: 'red' }} />
                                </IconButton>
                            </Box>
                        </Grid>

                        <Grid container justifyContent="space-between">
                            <Grid item xs={9}>
                                <AdminRoleAdd />
                            </Grid>

                            <Grid item xs={3}></Grid>

                            <Grid item xs={12} marginTop={2}>
                                <Typography variant="h6">
                                    Lista ról:
                                </Typography>
                                <List style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                    {roles.map(role => (
                                        <ListItem key={role.id}>
                                            {role.name}
                                            <IconButton onClick={() => handleDelete(role.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal >
        </>
    );
}