import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import useStyles from './footerStyle';
import { brand } from '../brand';
import { useTextAlign } from '../../theme/common';
import logo from '../wechsler_logo_w.svg';
import LocationIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import agent from '../../api/agent';
import { useEffect, useState } from 'react';
import { FooterData } from '../../models/footer';
import FooterColumnFromAbout from './FooterColumnFromAbout';

function Copyright() {
  return (
    <Typography variant="body2" display="block" color="textSecondary">
      &copy;&nbsp;
      {brand.agency.footerText}
    </Typography>
  );
}

interface Props {
  darkMode: boolean;
}

export default function Footer({ darkMode }: Props) {
  // Theme breakpoints
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [footer, setFooter] = useState<FooterData | undefined>(undefined)

  useEffect(() => {
    async function GetFooter() {
      try {
        const footerData: FooterData = await agent.Footer.get();
        if (footerData) setFooter(footerData)
      } catch (error) {
        console.error("Error fetching footer: ", error);
      }
    }
    GetFooter();
  }, []);

  const { classes } = useStyles() as { classes: Record<string, string>, cx: any };
  const { classes: align } = useTextAlign();

  return (
    <Container sx={{ backgroundColor: darkMode ? 'black' : "background.paper", px: { xs: 2, sm: 3, md: 10 } }}
      maxWidth={false}
      component="footer"
      className={classes.footer}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <div className={classes.logo}>
            <img src={logo} alt="logo" />
            <Typography variant="h6" color="textPrimary">
              {brand.agency.projectName}
            </Typography>
          </div>
          <Typography color="textPrimary" className={classes.footerDesc} gutterBottom>
            Wechsler Polska Spółka z ograniczoną odpowiedzialnością
          </Typography>
          <Typography color="textPrimary" className={classes.footerDesc} fontSize={14} >
            <LocationIcon className={classes.icon} sx={{ fontSize: 11, mr: 1 }} />
            1 Maja 191, 25-646 Kielce
          </Typography>
          <Typography color="textPrimary" className={classes.footerDesc} fontSize={14} >
            <PointOfSaleIcon className={classes.icon} sx={{ fontSize: 11, mr: 1 }} />
            NIP 9591948415
          </Typography>
          <Typography color="textPrimary" className={classes.footerDesc} fontSize={14} >
            <InsertChartIcon className={classes.icon} sx={{ fontSize: 11, mr: 1 }} />
            REGON 260607568
          </Typography>
          <Typography color="textPrimary" className={classes.footerDesc} fontSize={14} gutterBottom >
            <BusinessIcon className={classes.icon} sx={{ fontSize: 11, mr: 1 }} />
            KRS 0000421362
          </Typography>
          {isDesktop && <Copyright />}
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={4} justify-content="space-evenly">
            {footer?.footerColumns.map((footerColumn, index) => (
              <FooterColumnFromAbout
                isDesktop={isDesktop}
                isMobile={isMobile}
                footerColumn={footerColumn}
                classes={classes}
                key={index}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={1}>
          <div className={classes.socmed}>
            <IconButton target='_blank' href='https://www.instagram.com/wechsler_polska/' aria-label="FB" className={classes.margin} size="small">
              <i className="ion-logo-facebook" />
            </IconButton>
            <IconButton target='_blank' href='https://www.facebook.com/wechsler.poland/' aria-label="IG" className={classes.margin} size="small">
              <i className="ion-logo-instagram" />
            </IconButton>
          </div>
          {/* <SelectLang toggleDir={toggleDir} /> */}
        </Grid>
      </Grid>
      {
        isMobile && (
          <div className={align.textCenter}>
            <Box p={4}>
              <Copyright />
            </Box>
          </div>
        )
      }
    </Container >
  );
}