import { useEffect } from "react";
import { clientInvestmentsSelectors, fetchClientInvestmentsAsync, fetchClientInvestmentsFilters } from "../../features/client/clientInvestments/clientInvestmentsSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useClientInvestments() {
    const clientInvestments = useAppSelector(clientInvestmentsSelectors.selectAll);
    const {clientInvestmentsLoaded, clientInvestmentsFiltersLoaded, states, metaData} = useAppSelector(state => state.clientInvestments);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!clientInvestmentsLoaded) dispatch(fetchClientInvestmentsAsync());
  }, [clientInvestmentsLoaded, dispatch])

  useEffect(() => {
    if (!clientInvestmentsFiltersLoaded) dispatch(fetchClientInvestmentsFilters());
  }, [clientInvestmentsFiltersLoaded, dispatch])

  return {
    clientInvestments,
    clientInvestmentsLoaded,
    clientInvestmentsFiltersLoaded,
    states,
    //types,
    metaData
  }
}