import { Box, Button, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import NotFound from "../../app/errors/NotFound";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { servicesSelectors, fetchServiceAsync, fetchSubservicesFromService } from "./servicesSlice";
import AppReturn from "../../app/components/AppReturn";
import { currencyFormat } from "../../app/util/util";
import ServiceForm from "./ServiceForm";

interface Subservice {
    id: number;
    name: string;
    effects: string;
}

export default function ServiceDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const service = useAppSelector(state => servicesSelectors.selectById(state, id!));
    const { status: serviceStatus } = useAppSelector(state => state.services);
    const theme = useTheme();
    const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
    const [subservices, setSubservices] = useState<Subservice[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const defaultServicePicture = `${import.meta.env.VITE_URL}images/defaultServicePicture.webp`;

    useEffect(() => {
        if (!service) dispatch(fetchServiceAsync(parseInt(id!)))
    }, [id, dispatch, service])

    useEffect(() => {
        if (service && service.subservices) {
            dispatch(fetchSubservicesFromService(service.subservices))
                .unwrap()
                .then((subservices: any) => {
                    setSubservices(subservices as Subservice[]);
                })
                .catch(error => {
                    console.error(error);
                    setSubservices([]);
                });
        }
    }, [dispatch, service]);

    if (serviceStatus.includes('pending')) return <LoadingComponent />

    if (!service) return <NotFound />

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    if (isEditing) {
        return (
            <ServiceForm service={service} cancelEdit={handleCancelEdit} />
        );
    }

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Usługa egzaminacyjna"} backPath="/services" />
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                    sx={{ m: 2 }}
                    variant='outlined'
                    onClick={handleEdit}
                    size="large"
                    title='Edycja'
                    color='warning'
                >Edycja</Button>
            </Box>

            <Box component={Paper} sx={{ p: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h4" gutterBottom>
                                Id usługi: {service.id}
                                <Divider sx={{ mt: 1 }} />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6'>
                                {service.name}
                                <Divider sx={{ mt: 1 }} />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6'>
                                Cena: {currencyFormat(service.basePrice)}
                                <Divider sx={{ mt: 1 }} />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6'>
                                Kategoria: {service.category}
                                <Divider sx={{ mt: 1 }} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{
                        display: 'flex',
                        placeItems: 'center', // Centrowanie elementów w siatce
                        justifyContent: 'center'
                    }}>
                        <img
                            src={service.imagePath ? service.imagePath : defaultServicePicture}
                            alt={service.name}
                            style={{
                                maxWidth: isXsOrSm ? '70%' : '100%',
                                objectFit: 'cover',
                                borderRadius: 16,
                                height: 'auto'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" textAlign='center' marginBottom={2}>Ta usługa zawiera poniższe podusługi:</Typography>
                        {subservices.map(subservice => (
                            <Box key={subservice.id}>
                                {/* <Typography variant="h6">{subservice.name} - id: {subservice.id}</Typography> */}
                                <Typography variant="h6"><strong>{subservice.name}</strong></Typography>
                                <ol>
                                    {subservice.effects.split(';').filter(effect => effect.trim() !== '').map((effect, index) => (
                                        <li key={index}>
                                            <Typography variant="body1">{effect.trim()}</Typography>
                                        </li>
                                    ))}
                                </ol>
                                <Divider sx={{ my: 2 }} />
                            </Box>
                        ))}
                    </Grid>
                    {/* <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="h5" textAlign='center' marginBottom={2}>
                            Link do zdjęcia usługi:
                        </Typography>
                        {service.imagePath ?
                            <Button
                                onClick={() => window.open(service.imagePath, '_blank')}
                                variant="contained"
                                sx={{ mb: 2 }}
                            >
                                Otwórz zdjęcie
                            </Button>
                            : <Typography textAlign="center" marginBottom={2}>Brak</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="h5" textAlign='center' marginBottom={2}>
                            Link do wzoru arkusza:
                        </Typography>
                        {service.packageTestPath ?
                            <Button
                                onClick={() => window.open(service.packageTestPath, '_blank')}
                                variant="contained"
                                sx={{ mb: 2 }}
                            >
                                Otwórz wzór arkusza
                            </Button>
                            : <Typography textAlign="center" marginBottom={2}>Brak</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="h5" textAlign='center' marginBottom={2}>
                            Link do klucza odpowiedzi:
                        </Typography>
                        {service.packageTestKeyPath ?
                            <Button
                                onClick={() => window.open(service.packageTestKeyPath, '_blank')}
                                variant="contained"
                                sx={{ mb: 2 }}
                            >
                                Otwórz klucz odpowiedzi
                            </Button>
                            : <Typography textAlign="center" marginBottom={2}>Brak</Typography>
                        }
                    </Grid> */}
                </Grid>
            </Box>
        </>
    )
}