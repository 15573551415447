import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NavigationHistoryContextType {
  history: string[];
  addHistoryItem: (location: string) => void;
}

const NavigationHistoryContext = createContext<NavigationHistoryContextType | undefined>(undefined);

interface NavigationHistoryProviderProps {
  children: ReactNode;
}

export const NavigationHistoryProvider: React.FC<NavigationHistoryProviderProps> = ({ children }) => {
  const [history, setHistory] = useState<string[]>([]);

  const addHistoryItem = (location: string) => {
    setHistory((prevHistory) => {
      // Sprawdź, czy ostatni element historii jest różny od obecnej lokalizacji
      if (prevHistory[prevHistory.length - 1] !== location) {
        return [...prevHistory, location];
      }
      // W przeciwnym razie zwróć poprzednią historię bez zmian
      return prevHistory;
    });
  };

  return (
    <NavigationHistoryContext.Provider value={{ history, addHistoryItem }}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useHistoryTracker = (): NavigationHistoryContextType => {
  const context = useContext(NavigationHistoryContext);
  if (context === undefined) {
    throw new Error('useHistoryTracker must be used within a NavigationHistoryProvider');
  }
  return context;
};