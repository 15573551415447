import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import LocationIcon from '@mui/icons-material/LocationOn';
import useStyles from './mapAddressStyle';
import BusinessIcon from '@mui/icons-material/Business';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import logo from '../../layout/wechsler_logo_w.svg';
import ShowEmail from './ShowEmail';

function MapAdress() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Container fixed sx={{ mt: { xs: 4, sm: 4 } }}>
        <Grid container spacing={6} alignItems="flex-start">
          {isDesktop && (
            <Grid item md={1} />
          )}
          <Grid item md={isDesktop ? 5 : 6} xs={12} >
            {<img src={logo} />}
            <Paper className={classes.paper} sx={{ mt: 4 }}>
              <Typography variant="h6" display="block">
                Wechsler Polska Spółka z ograniczoną odpowiedzialnością
              </Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <PhoneIcon className={classes.icon} />
                  (+48) 515 032 366
                </Grid>
                <Grid item sm={6} xs={12}>
                  <PhoneIcon className={classes.icon} />
                  (+48) 515 032 363
                </Grid>
                <Grid item xs={12}>
                  <EmailIcon className={classes.icon} />
                  {ShowEmail('a.bartosz', 'wechsler.pl')}
                </Grid>
                <Grid item xs={12}>
                  <EmailIcon className={classes.icon} />
                  {ShowEmail('kontakt', 'wechsler.pl')}
                </Grid>
                <Grid item xs={12}>
                  <LocationIcon className={classes.icon} />
                  1 Maja 191, 25-646 Kielce (Business Lantern Office)
                </Grid>
                <Grid item xs={12}>
                  <PointOfSaleIcon className={classes.icon} />
                  NIP 9591948415
                </Grid>
                <Grid item xs={12}>
                  <InsertChartIcon className={classes.icon} />
                  REGON 260607568
                </Grid>
                <Grid item xs={12}>
                  <BusinessIcon className={classes.icon} />
                  KRS 0000421362
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <iframe
              title='Tutaj nas znajdziesz na mapie google'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37962.9508199555!2d20.576665653619273!3d50.89268943541663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471827edce6ec2dd%3A0x9065d49dd6ea9dcc!2sWechsler%20Polska%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1669198511217!5m2!1spl!2spl"
              style={{ width: '100%', height: '700px', border: '0', borderRadius: 15 }}
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MapAdress;