/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import AppTextInput from "../../../app/components/AppTextInput";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { fetchAgreementAsync, setAgreement } from "./agreementsSlice";
import { Agreement } from "../../../app/models/agreement";
import RadioButtonGroup from "../../../app/components/AppRadioButtonGroup";
import AppDatePicker from "../../../app/components/AppDatePicker";
import AgreementIsServiceContract from "./AgreementForms/AgreementIsServiceContract";
import { Editor } from "@tinymce/tinymce-react";
import { fetchClientInvestmentsAsync } from "../../client/clientInvestments/clientInvestmentsSlice";
import { fetchAdminInvestmentsAsync } from "../adminInvestments/adminInvestmentsSlice";
import { useNavigate } from "react-router-dom";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppReturn from "../../../app/components/AppReturn";
import { exportContentToPdf } from "../../../app/util/util";
import useAgreementContent from "../../../app/hooks/useAgreementContent";

interface Props {
  agreement: Agreement | undefined;
  cancelEdit: () => void;
  investmentPublicId: number | null;
  userPhone?: string | null;
  userDisplayName?: string | null;
  userEmail?: string | null;
  exportToPdf?: boolean;
}

type FormValues = {
  agreementType: string;
  name: string;
  dateOfConclusion: Date;
  companyName: string;
  companyCity: string;
  companyZip: string;
  companyNIP: string;
  companyRepresentative: string;
  serviceConsistingOf: string;
  dateStart: Date;
  dateEnd: Date;
  changeTheSizeOfServices: string;
  numberOfDaysBeforeIntroduction: number;
  servicesDoNotInclude: string;
  principalsResponsibilities: string;
  contractorsResponsibilities: string;
  prepayment: number;
  payment: number;
  transferOf: string;
  principalWillCallContractorIn: number;
  contractorWillCallPrincipalIn: number;
  attachments: string;
  principalRepresentativeForDirectContacts: string;
  contractorRepresentativeForDirectContacts: string;
  internalComment: string;
}

interface ApiResponse {
  apiKey: string;
}

export default function AgreementForm({ agreement, cancelEdit, investmentPublicId, userPhone, userDisplayName, userEmail, exportToPdf }: Props) {
  const { control, reset, handleSubmit, formState: { isDirty, isSubmitting }, setValue, watch } = useForm<FormValues>({
    defaultValues: { agreementType: '', name: '' }
  });
  const dispatch = useAppDispatch();
  const agreementType = 'isServiceContract';
  const { user } = useAppSelector(state => state.account);
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState('');
  const [contentLoaded, setContentLoaded] = useState(false);

  // Use the custom hook
  const { content, setContent } = useAgreementContent(watch);

  useEffect(() => {
    agent.TinyMCE.get()
      .then((result: ApiResponse) => {
        setApiKey(result.apiKey);
      })
      .catch(error => console.log('Failed to fetch API key:', error));
  }, [apiKey]);

  useEffect(() => {
    if (agreement && !isDirty) {
      reset({ ...agreement, agreementType: '' });
      setValue('agreementType', agreement.isServiceContract ? 'isServiceContract' :
        agreement.is_PZPpostepowanie_Contract ? 'is_PZPpostepowanie_Contract' :
          agreement.is_PZPstala_Contract ? 'is_PZPstala_Contract' :
            agreement.is_PZPswz_Contract ? 'is_PZPswz_Contract' :
              agreement.is_SWI_LSI_Contract ? 'is_SWI_LSI_Contract' : '');
      console.log(agreement)
    }
  }, [agreement, reset, isDirty, setValue]);

  const options = [
    { value: 'isServiceContract', label: 'UMOWA O ŚWIADCZENIE USŁUG' }
  ];

  useEffect(() => {
    if (exportToPdf && agreement && contentLoaded) {
      exportContentToPdf(content, agreement.name);
    }
  }, [exportToPdf, agreement, contentLoaded, content]);

  const handleEditorChange = (content: string) => {
    setContent(content);
    if (!contentLoaded) setContentLoaded(true);
  };

  async function handleSubmitData(data: FieldValues) {
    try {
      let response: Agreement;

      const dataToSend = {
        ...data,
        editorContent: content,
        isServiceContract: agreementType === 'isServiceContract',
        investmentPublicId: agreement ? agreement.investmentPublicId : investmentPublicId,
        userDisplayName: userDisplayName,
        userEmail: userEmail,
        userPhone: userPhone,
      };
      console.log(dataToSend);
      if (agreement) {
        response = await agent.Admin.Agreements.update(dataToSend);
        toast.success('Uaktualniono umowę');
      } else {
        response = await agent.Admin.Agreements.create(dataToSend);
        toast.success('Utworzono umowę');
        toast.info("Aby klient zobaczył umowę pobierz ją i zapisz w panelu 'Dokumenty'");
      }
      dispatch(setAgreement(response));
      dispatch(fetchAgreementAsync(response.id))
      dispatch(fetchClientInvestmentsAsync)
      if (user?.roles?.includes('Admin')) dispatch(fetchAdminInvestmentsAsync)
      cancelEdit();
    } catch (error) {
      console.log(error);
    }
  }

  function fillTestData() {
    const testData: Partial<Agreement> = {
      // Fill in test data for each field
      name: 'Test00',
      companyName: 'Test00',
      companyCity: 'Test00',
      companyZip: 'Test00',
      companyNIP: 'Test00',
      companyRepresentative: 'Test00',
      serviceConsistingOf: 'Test00',
      changeTheSizeOfServices: 'Test00',
      numberOfDaysBeforeIntroduction: 10,
      servicesDoNotInclude: 'Test00',
      principalsResponsibilities: 'Test00',
      contractorsResponsibilities: 'Test00',
      prepayment: 1000,
      payment: 1500,
      transferOf: 'Test00',
      principalWillCallContractorIn: 7,
      contractorWillCallPrincipalIn: 7,
      principalRepresentativeForDirectContacts: 'Test00',
      contractorRepresentativeForDirectContacts: 'Test00',
      attachments: 'attachment01;attachment02;attachment03'
    }
    reset(testData);
  }

  function handleCancel() {
    // Wywołaj funkcję cancelEdit, jeśli istnieje
    if (typeof cancelEdit === 'function') {
      cancelEdit();
    }

    // Następnie nawiguj z powrotem ze zmienionym stanem
    navigate("/admin/agreements", {
      replace: true,
      state: {
        selectedAgreement: agreement, // Używając już dostępnej zmiennej
        openForm: false, // Tutaj zmieniamy stan
        investmentPublicId: 0,
        userPhone,
        userDisplayName,
        userEmail
      }
    });
  }

  return (
    <>
      <AppBreadcrumbs />
      <AppReturn
        title={"Edycja umowy"}
        cancelEdit={handleCancel}
        fillTestData={!!user && user.roles?.includes('Admin') ? fillTestData : undefined}
        isAdmin={!!user && user.roles?.includes('Admin')}
      />

      <Box component={Paper} sx={{ p: 4 }}>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          {agreement ? (
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Typography variant="h6">Nr umowy: {agreement.id}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6}>
              </Grid>
            </Grid>
          )
          }

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AppTextInput control={control as any} name='name' label='Nazwa umowy' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppDatePicker control={control as any} name='dateOfConclusion' label='Umowa zawarta w dniu' disabled={false} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <RadioButtonGroup
                options={options}
                onChange={(e) => setValue('agreementType', e.target.value)}
                selectedValue={agreementType}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="h4">
                Dane zleceniodawcy:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <AppTextInput control={control as any} name='companyName' label='Nazwa firmy' />
            </Grid>
            <Grid item xs={6} sm={6}>
              <AppTextInput control={control as any} name='companyCity' label='Miasto' />
            </Grid>
            <Grid item xs={6} sm={6}>
              <AppTextInput control={control as any} name='companyZip' label='Kod pocztowy' />
            </Grid>
            <Grid item xs={6} sm={6}>
              <AppTextInput control={control as any} name='companyNIP' label='NIP' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput control={control as any} name='companyRepresentative' label='Przedstawiciel/reprezentant' />
            </Grid>
          </Grid>

          {agreementType === 'isServiceContract' && (
            <AgreementIsServiceContract control={control} />
          )}

          <Grid item xs={12} sm={12}>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="h4">
              Treść umowy:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            {apiKey ? (
              <Editor
                apiKey={apiKey}
                value={content}
                onEditorChange={handleEditorChange}
                disabled={true}
                init={{
                  height: 500,
                  menubar: 'preview',
                  toolbar: 'preview',
                }}
              />
            ) : ''}
            <Tooltip
              title={agreement ? '' : 'Musisz zaakceptować umowę, aby zapisać jako PDF'}
              disableHoverListener={!!agreement}
            >
              <span>
                <Button variant="contained" color="primary" onClick={() => exportContentToPdf(content, agreement?.name)} style={{ marginTop: '20px' }} disabled={agreement ? false : true}>
                  Zapisz umowę jako pdf
                </Button>
              </span>
            </Tooltip>
          </Grid>

          <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
            <Button onClick={cancelEdit} variant='outlined' color='primary'>Anuluj</Button>
            <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
          </Box>
        </form>
      </Box >
    </>
  )
}