import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-scroll";

const tableOfContents = [
    { point: 'Polityka prywatności', id: '01' },
    { point: 'Jakie dane przetwarzamy?', id: '02' },
    { point: 'Kto jest administratorem danych?', id: '03' },
    { point: 'Zaufani Partnerzy i inni', id: '04' },
    { point: 'Inspektor ochrony danych oraz jego dane kontaktowe:', id: '05' },
    { point: 'Jakie są cele oraz podstawy prawne przetwarzania Twoich danych osobowych i przez jaki okres będą przetwarzane?', id: '06' },
    { point: 'Czy istnieje wymóg podania danych?', id: '07' },
    { point: 'Do jakich odbiorców przekazywane będą Twoje dane osobowe?', id: '08' },
    { point: 'Wtyczki społecznościowe', id: '09' },
    { point: 'Jakie są Twoje prawa związane z przetwarzaniem danych osobowych?', id: '10' },
    { point: 'Informacje o "cookies"', id: '11' },
    { point: 'Cookies i inne podobne technologie', id: '12' },
    { point: 'Dlaczego z tego korzystamy?', id: '13' },
    { point: 'Rodzaje plików cookies, które wykorzystujemy', id: '14' },
    { point: 'Inne technologie', id: '15' },
    { point: 'Zarządzanie ustawieniami przeglądarki', id: '16' },
    { point: 'Gdzie szukać pomocy?', id: '17' },
    { point: 'Informacje dodatkowe', id: '18' },
    { point: 'Drobne, dobre rady', id: '19' }
]

const linkStyle = {
    color: '#03a9f4',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Roboto, sans-serif'
}

export default function Privacy() {

    return (
        <Paper sx={{ px: 4, py: 4, borderRadius: '12px' }}>

            <Typography variant="h4" textAlign='center' gutterBottom>
                Polityka prywaności
            </Typography>

            <Typography variant="h5" textAlign='left' gutterBottom>
                Spis treści:
            </Typography>
            <ul style={{ marginBottom: 10 }}>
                {tableOfContents.map((item) => (
                    <li key={item.id}>
                        <Link
                            activeClass="active"
                            to={item.id}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={linkStyle}
                        >
                            {item.point}
                        </Link>
                    </li>
                ))}
            </ul>

            <Typography variant="h4" textAlign='left' gutterBottom id="01" sx={{ mt: 4, mb: 2 }}>
                Polityka prywatności
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Polityka prywatności służy jako pomoc w zrozumieniu, jakie dane zbieramy i w jakim celu oraz do czego je wykorzystujemy. Te informacje są ważne, dlatego prosimy o dokładne zapoznanie się z tym dokumentem.
                Korzystając z usług (dalej łącznie „<strong>Usługi</strong>” lub „<strong>Usługi WP</strong>”, a osobno „<strong>Usługa</strong>”) Wechsler Polska Sp. z o.o. (dalej „<strong>WP</strong>” lub „<strong>Wechsler Polska Sp. z o.o.</strong>”) z siedzibą w Kielcach oraz wszelkie podmioty z nią powiązane i od niej zależne, zarówno osobowo, jak i kapitałowo, bezpośrednio lub za pośrednictwem innych podmiotów (dalej „<strong>Podmioty Zależne</strong>”), powierzasz nam swoje informacje.

                Zdarza się również, że informacje to powierzasz również naszym zaufanym partnerom (dalej „Zaufani Partnerzy”) czyli stronom trzecim, które istnieją w środowisku naszych Usług. Współpracujemy z nimi aby zapewnić Ci Usługi na najwyższym poziomie, jak również którzy realizują działania marketingowe, którzy bezpośrednio lub pośrednio zlecają nam działania marketingowe w środowisku naszych Usług (dalej „<strong>Klienci</strong>”).
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="02" sx={{ mt: 4, mb: 2 }}>
                Jakie dane przetwarzamy?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przetwarzamy dane, które podajesz, pozostawiasz lub do których możemy uzyskać dostęp w ramach korzystania z Usług, witryn czy aplikacji WP, witryn i aplikacji naszych Zaufanych Partnerów i innych lokalizacjach w sieci Internet, w tym udostępnianych przez Ciebie w historii przeglądania witryn i aplikacji.
                Przetwarzamy dane, które są niezbędne do udostępnienia Tobie kont lub profili w Usługach np. adres e-mail, imię, nazwisko, wiek, płeć, miasto, datę urodzenia) które nam podajesz np. w ramach formularzy lub okien dostępnych w naszych Usługach, sondach, ankietach czy badaniach.
                Przetwarzamy również dane, które pozostawiasz w ramach poruszania się po naszych Usługach (m.in. te, które pozostawiasz w tzw. plikach cookies), dane o odwiedzanych witrynach, aplikacjach i interesujących Cię treściach, w tym reklamowych, Twoje wyszukiwania, Twoje kliknięcia, sposób w jaki korzystasz z naszych Usług a także dane z witryn naszych Klientów i Zaufanych Partnerów, w tym historie Twoich zakupów, w szczególności dokonywanych po kliknięciu w reklamy dostarczane Tobie w środowisku naszych Usług.
                Przetwarzamy dane o Twojej aktywności w ramach usług, portali, serwisów internetowych i aplikacji Wechsler Polska Sp. z o.o., naszych Zaufanych Partnerów i innych lokalizacji w sieci Internet oraz informacje przechowywane na urządzeniach końcowych z których korzystasz, w tym informacje o przeglądarce, a także dane o Twojej dokładnej lokalizacji geograficznej.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przetwarzamy Twoje dane osobowe zapisane między innymi w plikach cookies instalowanych na naszych witrynach i aplikacjach jak i na witrynach i naszych Zaufanych Partnerów jak i w innych lokalizacjach w sieci Internet czyli poza usługami, portalami i serwisami internetowymi Wechsler Polska Sp. z o.o..
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wyświetlane reklamy i materiały redakcyjne będą targetowane oraz profilowane z wykorzystaniem Twoich danych.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Targetowanie to proces polegający na doborze docelowej grupy odbiorczej, która jest w największym stopniu zainteresowana danymi materiałami jak i reklamowymi.
                Profilowanie to proces zautomatyzowanego przetwarzania Twoich danych, który polega na wykorzystaniu Twoich danych do oceny niektórych czynników charakterystycznych dla Ciebie, w szczególności do analizy lub prognozy Twoich zainteresowań albo Twoich innych zachowań, np. wiarygodności w sieci Internet, lokalizacji, przemieszczania się, preferencji konsumenckich, światopoglądowych czy zawodowych.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wyświetlane reklamy i materiały redakcyjne będą targetowane oraz profilowane z wykorzystaniem Twoich następujących danych i według następujących kryteriów:
                <ul>
                    <li>Twoja aktywność w ramach usług, portali, serwisów internetowych i aplikacji Wechsler Polska Sp. z o.o. i naszych Zaufanych Partnerów w szczególności materiały redakcyjne, z których korzystasz;</li>
                    <li>Twoja aktywność i interakcje z materiałami reklamowymi;</li>
                    <li>Twoja lokalizacja, wielkość miejscowości z której pochodzisz;</li>
                    <li>informacje przechowywane na Twoich urządzeniach końcowych, w szczególności takie jak rodzaj urządzenia końcowego, rodzaj przeglądarki, system operacyjny z którego korzystasz, język ustawiony w systemie operacyjnym z którego korzystasz.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Informacje o Twoim profilu mogą być wykorzystywane do profilowania reklam i treści w usługach, portalach, serwisach internetowych i aplikacjach Wechsler Polska Sp. z o.o. i naszych Zaufanych Partnerów jak i w innych lokalizacjach w sieci Internet, czyli poza usługami, portalami i serwisami internetowymi Wechsler Polska Sp. z o.o..
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Odnosi się to zwłaszcza do sieci reklamowych, które zbierają i pośredniczą w sprzedaży reklam. Sieci reklamowe zbierają dane o użytkownikach z wszystkich witryn współpracujących z tymi sieciami i następnie wykorzystują je do wyświetlania reklam jak najlepiej dopasowanych do Twoich zainteresowań.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przetwarzanie przez nas Twoich danych które podajesz, pozostawiasz lub do których możemy uzyskać dostęp w ramach korzystania z Usług, witryn czy aplikacji WP jest niezbędne do świadczenia przez nas Usług drogą elektroniczną ze względu konieczność dopasowania wszelkiego rodzaju treści do Twoich zainteresowań, umożliwienia Ci bezpiecznego i optymalnego korzystania z naszych Usług.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Zakres danych przetwarzanych w poszczególnych Usługach WP może się różnić – informacje w tym zakresie możesz zawsze znaleźć u naszego Inspektora Ochrony Danych (dane kontaktowe poniżej).
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="03" sx={{ mt: 4, mb: 2 }}>
                Kto jest administratorem danych?
            </Typography>
            <Typography variant="h5" textAlign='left' gutterBottom>
                Wechsler Polska Sp. z o.o.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Administratorem Twoich danych jest Wechsler Polska Sp. z o.o., w której skład wchodzą Wechsler Polska Sp. z o.o. z siedzibą w Kielcach oraz wszelkie podmioty z nią powiązane i od niej zależne, zarówno osobowo, jak i kapitałowo, bezpośrednio lub za pośrednictwem innych podmiotów.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Administratorem Twoich danych osobowych na potrzeby wysłania przez WP wiadomości reklamowych o produktach i usługach naszych Zaufanych Partnerów „marketing cudzy” jest zawsze Wechsler Polska Sp. z o.o.. W tym przypadku Zaufani Partnerzy jak i Klienci nie ustalają celów i sposobów przetwarzania Twoich danych osobowych, nie uzyskują też dostępu do Twoich danych.
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>Nazwa spółki:</strong></TableCell>
                            <TableCell align="center"><strong>Link do strony, na której znajdują się dane rejestrowe i kontaktowe spółek Wechsler Polska Sp. z o.o.:</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">Wechsler Polska Sp. z o.o.</TableCell>
                            <TableCell align="center">
                                <a href="">Kontakt</a>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h4" textAlign='left' gutterBottom id="04" sx={{ mt: 4, mb: 2 }}>
                Zaufani Partnerzy i inni
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Ponadto, w świetle obowiązującego prawa, administratorami Twoich danych w ramach poszczególnych Usług będą również Zaufani Partnerzy, w tym <strong>Klienci</strong>.
                Zaufani Partnerzy to podmioty tradycyjnie występujące na rynku reklamy oraz te charakterystyczne dla nowoczesnych form automatycznej sprzedaży i zakupu reklamy internetowej z którymi współpracuje Wechsler Polska Sp. z o.o.. Listę Zaufanych Partnerów wraz ze wskazaniem ich celów przetwarzania Twoich danych znajdziesz pod linkiem.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Zaufani Partnerzy to:
                <ul>
                    <li>reklamodawcy, którzy bezpośrednio lub pośrednio zlecają nam działania marketingowe oraz remarketingowe,</li>
                    <li>podmioty z obszaru e-commerce, których oferty towarów i usług możesz znaleźć w za pośrednictwem usług, portali i serwisów internetowymi Wechsler Polska Sp. z o.o.,</li>
                    <li>domy mediowe i sieci reklamowe występujące w imieniu naszych reklamodawców,</li>
                    <li>brokerzy i inni pośrednicy działający na rynku reklamy internetowej,</li>
                    <li>agencje interaktywne specjalizujące się w marketingu internetowym,</li>
                    <li>
                        sieci reklamowe działające na rynku reklamy internetowej w technologii programmatic, czyli modelu polegającego na automatyzacji sprzedaży i zakupu reklamy internetowej, w której wyodrębnić można:
                        <ul>
                            <li>operatorów platform do zbierania i zarządzania danymi o Tobie, które mogą być wykorzystywane zarówno przez Wirtualną Polskę, Klientów jak i reklamodawców współpracujących z tymi platformami,</li>
                            <li>operatorów platform strony popytowej, których reklamodawca lub dom mediowy, sieć reklamowa lub agencja używa do kupowania powierzchni reklamowej,</li>
                            <li>operatorów platform strony podażowej, umożliwiających Wirtualnej Polsce udostępnienie powierzchni reklamowej do sprzedaży reklamodawcom, domom mediowym, sieciom reklamowym czy też agencjom reklamowym.</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Sieci reklamowe to w szczególności podmioty oferujące usługi automatyzacji sprzedaży i zakupu reklamy internetowej, takie jak platformy udostępniające powierzchnię reklamową reklamodawcom, platformy zbierania i zarządzania danymi o użytkownikach na potrzeby reklamodawców, a także do kupowania powierzchni reklamowej przez reklamodawców z którymi współpracuje Wechsler Polska Sp. z o.o..
                <ul>
                    <li>podmioty świadczące usługi analityczne.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Listę Zaufanych Partnerów znajdziesz pod linkiem
                https://certsystem.pl/Home/Partners
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Jednocześnie pragniemy wskazać, że zdarza się, że np. z uwagi na brak bezpośredniej relacji z niektórymi Zaufanymi Partnerami – szczególnie będącymi tzw. Klientami np. w przypadku gdy zlecają Oni umieszczenie reklamy w naszych powierzchniach za pośrednictwem Zaufanego Partnera będącego np. siecią reklamową, nie jesteśmy w stanie z przyczyn obiektywnych podać tutaj ich nazw.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Jeżeli chcesz dowiedzieć się więcej o Zaufanych Partnerach zaangażowanych w przetwarzanie Twoich danych na potrzeby reklamy internetowej proszę, skontaktuj się z naszym Inspektorem Ochrony Danych (dane poniżej).
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="05" sx={{ mt: 4, mb: 2 }}>
                Inspektor ochrony danych oraz jego dane kontaktowe
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wyznaczyliśmy inspektora ochrony danych dla całej Wechsler Polska Sp. z o.o.. Jest to osoba, z którą można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z tym przetwarzaniem. Jeżeli chcesz skontaktować się z Inspektorem ochrony danych skorzystaj z danych kontaktowych wskazanych poniżej:
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Adres korespondencyjny:</strong></TableCell>
                            <TableCell align="left">Wechsler Polska Sp. z o.o. / Mariusz Marciniak / IODO / 1 Maja 191, Kielce</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left"><strong>E-mail</strong></TableCell>
                            <TableCell align="left">kontakt@wechsler.pl</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h4" textAlign='left' gutterBottom id="06" sx={{ mt: 4, mb: 2 }}>
                Jakie są cele oraz podstawy prawne przetwarzania Twoich danych osobowych i przez jaki okres będą przetwarzane?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Podstawy prawne przetwarzania Twoich danych w poszczególnych celach są następujące:
            </Typography>
            <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Cele przetwarzanie danych:</strong></TableCell>
                            <TableCell align="left"><strong>Podstawy prawne:</strong></TableCell>
                            <TableCell align="left"><strong>Okres przetwarzania:</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">wykonanie umowy na linii WP - użytkownik (tymi umowami są najczęściej regulaminy, które akceptuje użytkownik przed skorzystaniem z danej Usługi), w tym świadczenie Usług na rzecz użytkowników, dopasowywanie Usług do potrzeb użytkowników niezbędne do świadczenia Usług na rzecz użytkowników, utrzymanie i wsparcie techniczne Usług niezbędne do świadczenia Usług na rzecz użytkowników, analizowanie i udoskonalanie Usług niezbędne do świadczenia Usług na rzecz użytkowników oraz zapewnianie bezpieczeństwa Usług, zapobieganiem oszustwom i usuwanie błędów niezbędne do świadczenia Usług na rzecz użytkowników (dalej „wykonanie Usług”)</TableCell>
                            <TableCell align="left">art. 6 ust. 1 lit b) RODO - niezbędność do wykonania umowy, której stroną jest użytkownik</TableCell>
                            <TableCell align="left">do czasu wygaśnięcia danej umowy pomiędzy Tobą, a WP, z tym zastrzeżeniem, że czasami, dane te mogą być przetwarzane również po wygaśnięciu tej umowy, jednak wyłącznie jeżeli jest to dozwolone lub wymagane w świetle obowiązującego prawa</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">dopasowywanie Usług do potrzeb użytkowników, tworzenie profilu spersonalizowanych treści i Usług, wybór spersonalizowanych treści i Usług (dalej „optymalizacja”), techniczne dostarczenie treści, Usług lub reklam, utrzymanie i wsparcie techniczne Usług, pomiary wydajności treści, Usług lub reklam, (dalej „techniczne wykonanie Usług”), pomiary statystyczne, analizowanie i udoskonalanie Usług, stosowanie badań rynkowych w celu generowania opinii odbiorców (dalej „statystyka”); zapewnienie bezpieczeństwa Usług, zapobieganie oszustwom i usuwanie błędów (dalej: „bezpieczeństwo”) marketing (w tym analizowanie i profilowanie danych w celach marketingowych) produktów i usług administratora (dalej również „marketing własny”)</TableCell>
                            <TableCell align="left">art. 6 ust. 1 lit. f) RODO - uzasadniony interes administratora.</TableCell>
                            <TableCell align="left">do czasu zgłoszenia skutecznego sprzeciwu, przy czym nie dłużej niż 3 lata</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">marketing (w tym analizowanie i profilowanie danych w celach marketingowych) dotyczący produktów i usług podmiotów trzecich (dalej również „marketing cudzy”). Marketing cudzy obejmuje wybór podstawowych reklam, tworzenie profilu spersonalizowanych reklam, wybór spersonalizowanych reklam oraz pomiary wydajności reklam.</TableCell>
                            <TableCell align="left">Art. 6 ust. 1 lit. a) – dobrowolna zgoda</TableCell>
                            <TableCell align="left">do czasu wycofania zgody</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">opracowanie i udzielenie odpowiedzi na zapytanie lub inne zgłoszenie kierowane do WP</TableCell>
                            <TableCell align="left">W zależności od celu w jakim skierowane zostało zapytanie lub inne zgłoszenie będzie to podjęcie czynności zmierzających do zawarcia umowy (art. 6 ust. 1 lit. b RODO);obowiązek prawny (art. 6 ust. 1 lit. c RODO w zw. z art. 15-22 RODO) lub prawnie uzasadniony interes administratora (art. 6 ust 1 lit f RODO) polegający na możliwości przekazania odpowiedzi na pytanie lub zgłoszenie</TableCell>
                            <TableCell align="left">do czasu opracowania i udzielenia odpowiedzi na zapytanie lub inne zgłoszenie skierowane do WP, w przypadku zaś zgłoszeń dotyczących realizacji praw podmiotów danych 5 lat od zrealizowania lub udzielenia odpowiedzi na zgłoszenie</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">ustalanie i dochodzenia roszczeń lub obrona przed nimi</TableCell>
                            <TableCell align="left">prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO) polegający na możliwości ustalenia, dochodzenia lub obrony przed roszczeniami.</TableCell>
                            <TableCell align="left">do czasu przedawnienia roszczeń, nie dłużej niż 6 lat od zakończenia roku kalendarzowego w którym zaistniało zdarzenie mogące stanowić podstawę roszczeń</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Realizacja obowiązków ustawowych wynikających z przepisów podatkowych i przepisów o rachunkowości polegających m.in. na konieczności wystawienia i archiwizowania rachunku</TableCell>
                            <TableCell align="left">Obowiązek prawny (art. 6 ust. 1 lit. c RODO w zw. z art. 74 Ustawy o rachunkowości i innych dot. podatków)</TableCell>
                            <TableCell align="left">5 lat od zakończenia roku kalendarzowego, licząc od następnego roku w którym miało zdarzenie powodującego powstanie obowiązku prawnego</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Obsługa żądań oraz postępowań prowadzonych przez uprawnione organy np. sądy, prokuratury, organy ścigania, urzędy które mogą dotyczyć danych użytkowników</TableCell>
                            <TableCell align="left">Obowiązek prawny (art. 6 ust. 1 lit. c RODO w zw. z przepisami uprawniającymi odpowiedni organ do złożenia żądania)</TableCell>
                            <TableCell align="left">do czasu realizacji żądania</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wechsler Polska Sp. z o.o. prosi Cię o udzielenie zgody w celu przetwarzania Twoich danych jakim jest marketing cudzy. Zgoda jest dobrowolna i możesz ją udzielić korzystając z funkcjonalności platformy udzielania zgody jak i możesz ją w dowolnym momencie wycofać korzystając z ustawień zaawansowanych tej platformy.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Cel przetwarzania określany jako <strong>marketing cudzy</strong> szczegółowo opisujemy w ustawieniach zaawansowanych i obejmuje on:
                <ul>
                    <li>wybór podstawowych reklam,</li>
                    <li>tworzenie profilu spersonalizowanych reklam,</li>
                    <li>wybór spersonalizowanych reklam,</li>
                    <li>pomiary wydajności reklam</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Na potrzeby realizacji marketingu cudzego korzystamy z następujących funkcji oraz funkcji specjalnych:
                <ul>
                    <li>dopasowanie i połączenie źródeł danych offline,</li>
                    <li>łączenie informacji o Twoich różnych urządzeniach,</li>
                    <li>odbieranie i wykorzystywanie automatycznie wysłanej charakterystyki Twojego urządzenia do Twojej identyfikacji,</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Pamiętaj, że Wechsler Polska Sp. z o.o. może przetwarzać Twoje dane bez konieczności uprzedniego wyrażenia przez Ciebie zgody.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przetwarzamy Twoje dane bez konieczności uprzedniego wyrażenia przez Ciebie zgody w celu <strong>wykonania Usługi</strong>.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Podstawą prawną przetwarzania Twoich danych jest wówczas niezbędność do świadczenia usługi.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W ramach <strong>wykonania Usług</strong> możemy wskazać szczegółowe cele przetwarzania danych, o których przeczytasz w ustawieniach zaawansowanych:
                <ul>
                    <li>dopasowywanie Usług do potrzeb użytkownika niezbędne do świadczenia Usług na rzecz użytkownika,</li>
                    <li>utrzymanie i wsparcie techniczne Usług niezbędne do świadczenia Usług na rzecz użytkownika,</li>
                    <li>opracowywanie i ulepszanie Usług niezbędne do świadczenia Usług na rzecz użytkownika, oraz</li>
                    <li>zapewnianie bezpieczeństwa Usług, zapobieganiem oszustwom i usuwanie błędów niezbędne do świadczenia Usług na rzecz użytkownika, przy czym w celu realizacji zadań związanych z zapewnieniem bezpieczeństwa i wyłącznie w niezbędnym zakresie mogą być przetwarzane dane geolokalizacyjne i dane będące efektem skanowania urządzenia dla celów identyfikacji,</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Na potrzeby <strong>wykonania Usług</strong> korzystamy z następujących szczegółowych funkcji oraz funkcji specjalnych:
                <ul>
                    <li>dopasowanie i połączenie źródeł danych offline,</li>
                    <li>łączenie informacji o Twoich różnych urządzeniach,</li>
                    <li>odbieranie i wykorzystywanie automatycznie wysłanej charakterystyki Twojego urządzenia do Twojej identyfikacji,</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przetwarzamy ponadto Twoje dane bez konieczności uprzedniego wyrażenia przez Ciebie zgody w celu <strong>optymalizacji, technicznego wykonania Usługi, statystyki i bezpieczeństwa</strong> niepowiązanego w żaden sposób z <strong>wykonaniem Usługi</strong> oraz <strong>marketingu własnego</strong>.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Podstawą prawną przetwarzania Twoich danych jest zgodny z prawem, nie godzący w Twoje prawa, wolności oraz indywidualne interesy, uzasadniony interes Wechsler Polska Sp. z o.o.. Prawnie uzasadniony interes Wechsler Polska Sp. z o.o. będzie stanowił podstawę przetwarzania Twoich danych, niezbędnych do jego realizacji bez konieczności wyrażenia przez Ciebie zgody, o ile przeprowadzony przez Wirtualną Polskę test równowagi wykaże, że takie przetwarzanie przez Nas Twoich danych pozostaje w równowadze z Twoimi prawami, w szczególności prawami do prywatności i poufności.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W ramach optymalizacji, technicznego wykonania Usługi, statystyki, bezpieczeństwa i marketingu własnego możemy wskazać szczegółowe cele przetwarzania danych:
                <ul>
                    <li>dopasowywanie Usług do potrzeb użytkownika,</li>
                    <li>tworzenie profilu spersonalizowanych treści,</li>
                    <li>wybór spersonalizowanych treści,</li>
                    <li>pomiary wydajności treści lub reklam,</li>
                    <li>techniczne dostarczenie treści lub reklam,</li>
                    <li>utrzymanie i wsparcie techniczne Usług,</li>
                    <li>pomiary statystyczne,</li>
                    <li>opracowywanie i ulepszanie Usług, stosowanie badań rynkowych w celu generowania opinii odbiorców o Usługach, oraz</li>
                    <li>zapewnianie bezpieczeństwa Usług, zapobieganiem oszustwom i usuwanie błędów, przy czym w celu realizacji zadań związanych z zapewnieniem bezpieczeństwa i wyłącznie w niezbędnym zakresie mogą być przetwarzane dane geolokalizacyjne i dane będące efektem skanowania urządzenia dla celów identyfikacji,</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Na potrzeby <strong>optymalizacji, technicznego wykonania Usługi, statystyki, bezpieczeństwa i marketingu własnego</strong> korzystamy z następujących szczegółowych funkcji oraz funkcji specjalnych:
                <ul>
                    <li>dopasowanie i połączenie źródeł danych offline,</li>
                    <li>łączenie informacji o Twoich różnych urządzeniach,</li>
                    <li>odbieranie i wykorzystywanie automatycznie wysłanej charakterystyki Twojego urządzenia do Twojej identyfikacji</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Pamiętaj, że nie udostępniany mechanizmu wyrażania sprzeciwu dla przetwarzania Twoich danych w oparciu o uzasadniony interes Wechsler Polska Sp. z o.o. z wykorzystaniem platformy udzielania zgody. W przypadku przetwarzania Twoich danych w oparciu o uzasadniony interes Wechsler Polska Sp. z o.o. masz możliwość wyrażenia sprzeciwu kontaktując się z Inspektorem ochrony danych:
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Adres korespondencyjny:</strong></TableCell>
                            <TableCell align="left">Wechsler Polska Sp. z o.o. / Mariusz Marciniak / IODO / 1 Maja 191, Kielce</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left"><strong>E-mail</strong></TableCell>
                            <TableCell align="left">kontakt@wechsler.pl</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W odniesieniu do przetwarzania Twoich danych w oparciu o uzasadniony interes Wechsler Polska Sp. z o.o. zawsze przeprowadzamy test równowagi pomiędzy naszym uzasadnionym prawnie interesem, a Twoimi prawami. Tym samym pamiętaj, iż w przypadku tak podstawowych celi, funkcji i funkcji specjalnych jak utrzymanie i wsparcie techniczne Usług, zapewnienie bezpieczeństwa, zapobieganie oszustwom i usuwanie błędów, techniczne dostarczanie reklam lub treści, pomiary statystyczne, pomiary wydajności treści lub reklam, Twój sprzeciw może nie być skuteczny.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Zaufani Partnerzy przetwarzają Twoje dane osobowe w szczególności na podstawie Twojej dobrowolnej zgody. Pamiętaj jednak, że mogą przetwarzać Twoje dane bez konieczności uprzedniego wyrażenia przez Ciebie zgody. W przypadku przetwarzania Twoich danych bez konieczności uprzedniego wyrażenia przez Ciebie zgody na podstawie uzasadnionego interesu naszych Zaufanych Partnerów sprzeciwienia się takiemu przetwarzaniu. W tym celu skontaktuj się z nami i wskaż, jakie dane nie chcesz, by były przetwarzane. W najbardziej podstawowych celach obejmujących zapewnienie bezpieczeństwa, zabezpieczenie przed oszustwami i usuwanie błędów, oraz techniczne dostarczanie treści lub reklam nie masz możliwości sprzeciwienia się takiemu przetwarzaniu przez Zaufanych Partnerów. Skorzystanie z prawa sprzeciwu możliwe będzie wyłącznie po przeprowadzeniu testu równowagi pomiędzy uzasadnionym interesem Zaufanego Partnera, a Twoimi prawami. W tym celu powinieneś indywidualnie skontaktować się z Zaufanym Partnerem, po zapoznaniu się z polityką prywatności takiego Zaufanego Partnera.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Szczegółowe cele przetwarzania Twoich danych osobowych przez pozostałych Zaufanych Partnerów oraz sposób zakomunikowania im Twojej woli skorzystania z przysługujących praw znajdziesz w politykach prywatności tych Zaufanych Partnerów.
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="07" sx={{ mt: 4, mb: 2 }}>
                Czy istnieje wymóg podania danych?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Podanie danych jest dobrowolne jednak niepodanie danych (w tym także odmowa dostępu do informacji i ich przechowywania na Twoim urządzeniu, w tym z wykorzystaniem plików cookies) oznaczonych jako niezbędne do świadczenia usług na Twoją rzecz uniemożliwi ich świadczenie, w szczególności brak możliwości rejestracji i korzystania z usług, portali i serwisów internetowych Wechsler Polska Sp. z o.o., brak możliwości dostarczenia Tobie informacji, treści i ofert których poszukujesz, brak możliwości rozpatrzenia i udzielenia odpowiedzi na reklamację, brak możliwości udzielenia odpowiedzi na zapytanie lub inne zgłoszenie.
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="08" sx={{ mt: 4, mb: 2 }}>
                Do jakich odbiorców przekazywane będą Twoje dane osobowe?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Twoje dane mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie administratora m.in. dostawcom usług IT, dostawcom usług płatniczych, agencjom marketingowym itp. – przy czym takie podmioty przetwarzają dane na podstawie umowy z administratorem i wyłącznie zgodnie z poleceniami administratora. Twoje dane mogą być również wydane podmiotom uprawnionym do ich uzyskania na podstawie obowiązującego prawa np. organom ścigania w razie zgłoszenia przez organ żądania na odpowiedniej podstawie prawnej (np. dla potrzeb toczącego się postępowania karnego). Twoje dane mogą być, w niektórych przypadkach przekazywane do odbiorców z państw trzecich tj. z państw spoza Europejskiego Obszaru Gospodarczego np. jeżeli będzie to niezbędne dla świadczenia Usług na Twoją rzecz lub jeżeli będzie to warunkowane inną, ważną podstawą prawną. Informacje na ten temat znajdziesz w ramach poszczególnych Usług. Przed przekazaniem Twoich danych osobowych weryfikujemy, czy w państwach trzecich, tj. państwach spoza Europejskiego Obszaru Gospodarczego zapewniony jest odpowiedni stopień ochrony Twoich danych osobowych. W określonych sytuacjach przekazanie Twoich danych osobowych do państw trzecich może jednak wiązać się z ryzykiem z uwagi na brak decyzji stwierdzającej odpowiedni stopień ochrony poza Europejskim Obszarem Gospodarczym oraz brak zabezpieczeń i skutecznych środków ochrony prawnej w państwach trzecich.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Nasi Zaufani Partnerzy to często podmioty z państw trzecich tj. z państw spoza Europejskiego Obszaru Gospodarczego. Pamiętaj, iż wyrażając zgodę na przetwarzania Twoich danych osobowych przez naszych Zaufanych Partnerów w calach marketingowych (w tym do zautomatyzowanego dopasowywania reklam do Twoich zainteresowań i mierzenia ich skuteczności). Podstawą przekazania Twoich danych osobowych poza Europejski Obszar Gospodarczy w celach marketingowych będzie Twoja dobrowolna zgoda. W każdej chwili możesz skonfigurować ustawienia względem Zaufanych Partnerów i nie zezwalać na przetwarzanie Twoich danych osobowych przez Zaufanych Partnerów mających siedzibę poza Europejskim Obszarem Gospodarczym.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W związku z przetwarzaniem przez naszych Zaufanych Partnerów Twoich danych osobowych poza terytorium Europejskiego Obszaru Gospodarczego, weryfikujemy gwarancje wysokiego stopnia ochrony tych danych i stosujemy standardowe klauzule umowne dotyczące przekazywania danych osobowych podmiotom przetwarzającym dane mającym siedzibę w krajach trzecich. Masz prawo żądać od nas przekazania kopii standardowych klauzul umownych kontaktując się z Inspektorem ochrony danych:
            </Typography>
            <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Adres korespondencyjny:</strong></TableCell>
                            <TableCell align="left">Wechsler Polska Sp. z o.o. / Mariusz Marciniak / IODO / 1 Maja 191, Kielce</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left"><strong>E-mail</strong></TableCell>
                            <TableCell align="left">kontakt@wechsler.pl</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Ponadto Twoje dane osobowe będą przekazywane poza Europejski Obszar Gospodarczy gdy jest to niezbędne do zawarcia i wykonania umowy między Tobą a Wirtualną Polską, podejmowania na Twoje wyraźne żądanie czynności przed zawarciem umowy pomiędzy Tobą w Wirtualną Polską. Takie działania zawsze mają charakter sporadyczny.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Domena wp.pl gromadzi, przechowuje i udostępnia informacje o użytkowniku końcowym, w tym adresy IP, unikalny identyfikator i informacje umożliwiające identyfikacje ze stron internetowych, witryn lub aplikacji Wechsler Polska Sp. z o.o..
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Domena wp.pl i domena adtotal.pl nie gromadzi, nie przechowuje i nie udostępnia informacji o użytkowniku końcowym, w tym adresu IP, unikalnego identyfikatora i innyche informacji umożliwiających identyfikację, ze stron internetowych, witryn lub aplikacji nie należących do Wechsler Polska Sp. z o.o..
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="09" sx={{ mt: 4, mb: 2 }}>
                Wtyczki społecznościowe
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wykorzystujemy technologie takie jak wtyczki portali społecznościowych w tym wtyczkę Facebook Social Plugin, dzięki którym dostawcy usług portali społecznościowych mogą uzyskać dostęp do Twoich danych. Na naszych witrynach znajdują się przyciski Lubię to i Rekomenduj/Podziel się powiązane z serwisem Facebook. W tym celu w odpowiednich sekcjach i stronach umieszczany jest kod odwołujący się do serwisu Facebook. Używając przycisku Lubię to lub rekomendując obraz bądź sekcje strony, użytkownik loguje się w serwisie Facebook, w którym obowiązują zasady ochrony prywatności firmy Facebook. Z zasadami tymi można zapoznać się http://pl-pl.facebook.com/help/cookies
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Szczegółowe informacje na temat zakresu i celu przetwarzania Twoich danych w ramach Facebook Social Plugin znajdują się pod linkiem https://www.facebook.com/full_data_use_policy.
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="10" sx={{ mt: 4, mb: 2 }}>
                Jakie są Twoje prawa związane z przetwarzaniem danych osobowych?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przysługują Ci następujące prawa w związku z przetwarzaniem przez Nas Twoich danych osobowych:
                <ul>
                    <li>prawo dostępu do Twoich danych, w tym uzyskania kopii danych,</li>
                    <li>prawo żądania sprostowania danych</li>
                    <li>prawo do usunięcia danych (w określonych sytuacjach),</li>
                    <li>prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych,</li>
                    <li>prawo do ograniczenia przetwarzania danych.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Jeżeli Twoje dane są przetwarzane na podstawie zgody możesz dodatkowo skorzystać z poniższych praw:
                <ul>
                    <li>prawo do wycofania zgody w zakresie w jakim są przetwarzane na tej podstawie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Zgoda jest dobrowolna i możesz ją udzielić korzystając z funkcjonalności platformy udzielania zgody jak i możesz ją w dowolnym momencie wycofać korzystając z ustawień zaawansowanych tej platformy.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wechsler Polska Sp. z o.o. umożliwia Tobie udzielenie zgody jak i jej wycofanie z wykorzystaniem platformy zarejestrowanej i zweryfikowanej przez IAB. Nasz numer rejestracji w standardzie Transparency & Consent Framework to 72.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Jeżeli Twoje dane są przetwarzane na podstawie zgody lub w ramach świadczonej usługi (dane są niezbędne w celu świadczenia usługi) możesz dodatkowo skorzystać z poniższych praw:
                <ul>
                    <li>prawo do przenoszenia danych osobowych, tj. do otrzymania od administratora Twoich danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Możesz przesłać te dane innemu administratorowi danych.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Jeżeli Twoje dane są przetwarzane na podstawie uzasadnionego interesu administratora możesz dodatkowo skorzystać z poniższych praw:
                <ul>
                    <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych z przyczyn związanych z Twoją szczególną sytuacją.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Po skutecznym wniesieniu prawa do sprzeciwu Twoje dane nie będą przetwarzane o ile nie będzie istnieć ważna prawnie uzasadniona podstawa do przetwarzania, nadrzędna wobec Twoich interesów, praw i wolności lub podstawa do ustalenia, dochodzenia lub obrony roszczeń. Twoje dane nie będą przetwarzane w celu marketingu własnego po zgłoszeniu sprzeciwu.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W przypadku przetwarzania Twoich danych w oparciu o uzasadniony interes Wechsler Polska Sp. z o.o. masz możliwość wyrażenia sprzeciwu kontaktując się z Inspektorem ochrony danych:
            </Typography>
            <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Adres korespondencyjny:</strong></TableCell>
                            <TableCell align="left">Wechsler Polska Sp. z o.o. / Mariusz Marciniak / IODO / 1 Maja 191, Kielce</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left"><strong>E-mail</strong></TableCell>
                            <TableCell align="left">kontakt@wechsler.pl</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W celu skorzystania z powyższych praw należy skontaktować się z administratorem lub z Inspektorem ochrony danych (dane powyżej).
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="11" sx={{ mt: 4, mb: 2 }}>
                Informacje o "cookies"
            </Typography>
            <Typography variant="h4" textAlign='left' gutterBottom id="12" sx={{ mt: 4, mb: 2 }}>
                Cookies i inne podobne technologie
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wechsler Polska Sp. z o.o. oraz Zaufani Partnerzy zamieszczają informacje w formie plików cookies (tzw. ciasteczek) i w innej formie na urządzeniach końcowych (np. komputerze, laptopie, smartfonie, Smart TV) oraz uzyskują dostęp do informacji zbieranych z wykorzystaniem plików cookies i innych technologii identyfikujących urządzenia końcowe.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Pliki cookies stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym użytkownika Usług, w tym portali i serwisów internetowych czy aplikacji. Cookies zawierają nazwę domeny serwisu internetowego, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalną nazwę. W plikach cookie przechowywane są informacje, które są często niezbędne do prawidłowego działania witryny internetowej. W plikach cookie może być przechowywany unikalny numer który identyfikuje urządzenie użytkownika ale na jego podstawie nie jest ustalana tożsamość użytkownika.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Strona internetowa wydawcy może umieścić plik cookie w przeglądarce, jeśli przeglądarka to umożliwia. Co ważne, przeglądarka zezwala stronie internetowej na dostęp jedynie do plików cookies umieszczonych przez tę stronę, a nie do plików umieszczonych przez inne strony internetowe.
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="13" sx={{ mt: 4, mb: 2 }}>
                Dlaczego z tego korzystamy?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Korzystamy z plików cookies i innych podobnych technologii w celach:
                <ul>
                    <li>świadczenia Usług</li>
                    <li>dostosowywania zawartości portali, serwisów i aplikacji do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych; np. pliki cookies pozwalają w szczególności rozpoznać urządzenie użytkownika i odpowiednio wyświetlić stronę internetową dostosowaną do jego indywidualnych potrzeb;</li>
                    <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy korzystają ze serwisów i aplikacji, co umożliwia ulepszanie ich struktury i zawartości; badań i pomiarów oglądalności naszych Usług, treści redakcyjnych w tym reklam w celu analizy, oceny, wprowadzania, ulepszania i rozwijania naszych Usług, witryn, serwisów lub aplikacji; w tym celu też tworzymy segmenty Użytkowników, czyli odbiorców poszczególnych Usług i treści, czy ich kategorii;</li>
                    <li>utrzymania sesji użytkownika (po zalogowaniu), dzięki czemu użytkownik nie musi na każdej podstronie internetowej danego serwisu i aplikacji ponownie wpisywać loginu i hasła;</li>
                    <li>prezentacji reklam, m.in. w sposób uwzględniający zainteresowania użytkownika czy jego miejsce zamieszkania (indywidualizowanie przekazu reklamowego) i z gwarancją wyłączenia możliwości wielokrotnego prezentowania użytkownikowi tej samej reklamy, remarketing polegający na wyświetlaniu reklam Klientów, których witryny odwiedzałeś lub których towarami lub usługami byłeś zainteresowany, np. kliknąłeś wcześniej w reklamę Klienta;</li>
                    <li>realizacji ankiet - w szczególności by uniknąć wielokrotnej prezentacji tej samej ankiety temu samemu odbiorcy oraz by prezentować ankiety w sposób uwzględniający zainteresowania odbiorców.</li>
                    <li>powiązania ze sobą wielu urządzeń należących do tego samego końcowego użytkownika na potrzeby realizacji jednego albo większej liczby celów opisanych powyżej.</li>
                </ul>
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="14" sx={{ mt: 4, mb: 2 }}>
                Rodzaje plików cookies, które wykorzystujemy
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Z uwagi na czas życia cookies i innych podobnych technologii, stosujemy dwa zasadnicze rodzaje tych plików:
                <ul>
                    <li>sesyjne - pliki tymczasowe przechowywane w urządzeniu końcowym użytkownika do czasu wylogowania, opuszczenia strony internetowej i aplikacji lub wyłączenia oprogramowania (przeglądarki internetowej);</li>
                    <li>stałe - przechowywane w urządzeniu końcowym użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez użytkownika.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Ze względu na cel, jakiemu służą pliki cookies i inne podobne technologie, stosujemy ich następujące rodzaje:
                <ul>
                    <li>niezbędne do działania usługi i aplikacji- umożliwiające korzystanie z naszych usług, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania;</li>
                    <li>pliki służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania;</li>
                    <li>wydajnościowe - umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych i aplikacji;</li>
                    <li>funkcjonalne - umożliwiające "zapamiętanie" wybranych przez użytkownika ustawień i personalizację interfejsu użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi użytkownik, rozmiaru czcionki, wyglądu strony internetowej i aplikacji itp.;</li>
                    <li>reklamowe - umożliwiające dostarczanie użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań;</li>
                    <li>statystyczne - służące do zliczana statystyk dotyczących stron internetowych i aplikacji.</li>
                </ul>
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="15" sx={{ mt: 4, mb: 2 }}>
                Inne technologie
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Dla zapewnienia wygody użytkowania naszych serwisów używamy technologii Local Storage (LS), zbliżonej zasadą działania do plików cookies, mającej jednak nieco inne właściwości. LS to wydzielona część pamięci przeglądarki, służąca do przechowywania danych zapisywanych przez serwisy. Dostęp do niej może uzyskać tylko strona internetowa działający w tej samej witrynie, z której dane zostały zapisane, jednak inaczej niż w przypadku cookies, nie są one wysyłane przez przeglądarkę przy każdym odwołaniu do serwera. Dane w LS są długotrwale przechowywane przez przeglądarkę i nie są usuwane po zamknięciu przeglądarki ani nie mają określonego czasu ważności. Podkreślamy, że nie używamy tej technologii w celu "śledzenia" lub identyfikowania użytkowników, a wyłącznie dla wygody i w celu świadczenia usług na jak najwyższym poziomie. Używamy technologii LS np. w celu zapamiętania ustawień, zachowania konfiguracji radia lub zapamiętania danych na temat zarekomendowanych wpisów.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wykorzystujemy technologię Web Push aby przekazywać Ci na bieżąco krótkie powiadomienia, w szczególności najświeższe wiadomości, informacje o naszych Usługach i oferty naszych Zaufanych Partnerów i Reklamodawców.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Powiadomienia przekazywane są w czasie rzeczywistym i obsługiwane przez przeglądarkę internetową z której korzystasz. W tym celu w Twojej przeglądarce zapisany zostanie unikalny identyfikator. Aby otrzymywać od Nas powiadomienia musisz wyrazić zgodę. Zgodę na otrzymywanie powiadomień możesz w każdej chwili wycofać korzystając z ustawień Twojej przeglądarki:
                <ul>
                    <li><a style={linkStyle} href="https://blogs.windows.com/msedgedev/2016/05/16/web-notifications-microsoft-edge/">Internet Explorer</a></li>
                    <li><a style={linkStyle} href="https://support.mozilla.org/en-US/kb/push-notifications-firefox">Mozilla Firefox</a></li>
                    <li><a style={linkStyle} href="https://support.google.com/chrome/answer/3220216">Chrome</a></li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Przetwarzamy dokładne dane dotyczące Twojej lokalizacji geograficznej, wykorzystujemy w tym celu technologie udostępnianą przez przeglądarkę Internetową z której korzystasz. Wykorzystujemy następnie dokładne dane dotyczące lokalizacji geograficznej na potrzeby realizacji jednego albo większej liczby celów przetwarzania, takich jak dostarczenie Tobie informacji na temat pogody w miejscu w którym się znajdujesz, informacji drogowych, zaprezentowanie reklam restauracji znajdujących się w pobliżu. Abyśmy mogli korzystać z danych o Twojej precyzyjnej lokalizacji musisz wyrazić zgodę.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Zgodę możesz w każdej chwili wycofać korzystając z ustawień Twojej przeglądarki:
                <ul>
                    <li><a style={linkStyle} href="https://support.microsoft.com/pl-pl/windows/zmienianie-ustawie%C5%84-zabezpiecze%C5%84-i-prywatno%C5%9Bci-dotycz%C4%85cych-programu-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe">Internet Explorer</a></li>
                    <li><a style={linkStyle} href="https://support.mozilla.org/pl/kb/czy-firefox-udostepnia-moje-polozenie-stronom?redirectlocale=en-US&redirectslug=does-firefox-share-my-location-web-sites">Mozilla Firefox</a></li>
                    <li><a style={linkStyle} href="https://support.google.com/accounts/answer/3467281?hl=pl#location_accuracy">Chrome</a></li>
                    <li><a style={linkStyle} href="https://www.apple.com/pl/legal/privacy/data/pl/location-services/">Urządzenia Apple</a></li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Ponadto na naszych witrynach w celu prowadzenia pomiarów statystycznych używamy technologii pochodzących od naszych Zaufanych Partnerów, w szczególności Google Analitycs.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Wskazane podmioty zamieszczają na Twoich urządzeniach końcowych specjalne kody umożliwiające zbieranie danych służących nam do zliczania statystyk i optymalizacji naszych Usług.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W każdej chwili użytkownik może sprzeciwić się zbieraniu danych przez oprogramowanie Google Analitycs poprzez zmiany ustawień przeglądarki internetowej.
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Możesz też zablokować udostępnianie Google Analytics informacji o Twojej aktywności na naszej witrynie korzystając z dostępnych tu rozwiązań:
                <ul>
                    <li><a style={linkStyle} href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></li>
                </ul>
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="16" sx={{ mt: 4, mb: 2 }}>
                Zarządzanie ustawieniami przeglądarki
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie informacji w formie plików cookies i innych podobnych technologii w urządzeniu końcowym użytkownika. Użytkownik może jednak w każdym czasie dokonać zmiany tych ustawień. Niedokonanie zmian oznacza, że w/w informacje mogą być zamieszczane i przechowywane w jego urządzeniu końcowym, a tym samym że będziemy przechowywać informacje w urządzeniu końcowym użytkownika i uzyskiwać dostęp do tych informacji. Z poziomu przeglądarki internetowej, z której użytkownik korzysta możliwe jest np. samodzielne zarządzanie plikami cookies. W najpopularniejszych przeglądarkach istnieje m.in. możliwość:
                <ul>
                    <li>zaakceptowania obsługi cookies, co pozwala użytkownikowi na pełne korzystanie z opcji oferowanych przez witryny internetowe;</li>
                    <li>zarządzania plikami cookies na poziomie pojedynczych, wybranych przez użytkownika witryn;</li>
                    <li>określania ustawień dla różnych typów plików cookie, na przykład akceptowania plików stałych jako sesyjnych itp.;</li>
                    <li>blokowania lub usuwania cookies.</li>
                </ul>
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="17" sx={{ mt: 4, mb: 2 }}>
                Gdzie szukać pomocy?
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Kompleksowe informacje dostępne są w ustawieniach oprogramowania (przeglądarki internetowej). Szczegóły dla osób korzystających z poszczególnych przeglądarek internetowych, m.in.:
                <ul>
                    <li><a style={linkStyle} href="https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a></li>
                    <li><a style={linkStyle} href="https://support.mozilla.org/pl/kb/ciasteczka">Mozilla Firefox</a></li>
                    <li><a style={linkStyle} href="https://support.google.com/chrome/answer/95647?hl=pl">Chrome</a></li>
                    <li><a style={linkStyle} href="https://help.opera.com/pl/latest/security-and-privacy/">Opera</a></li>
                    <li>Itp.</li>
                </ul>
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Informujemy, że ograniczenia bądź wyłączenia stosowania plików cookies i innych podobnych technologii mogą wpłynąć na niektóre funkcjonalności dostępne w naszych serwisach.
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="18" sx={{ mt: 4, mb: 2 }}>
                Informacje dodatkowe
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Dodatkowe informacje na temat plików cookies i innych technologii można znaleźć m.in. pod adresem <a style={linkStyle} href="https://wszystkoociasteczkach.pl/">wszystkoociasteczkach.pl</a>, <a style={linkStyle} href="https://www.youronlinechoices.com/pl/">youronlinechoices.com</a> lub w sekcji Pomoc w menu przeglądarki internetowej.
            </Typography>

            <Typography variant="h4" textAlign='left' gutterBottom id="19" sx={{ mt: 4, mb: 2 }}>
                Drobne, dobre rady
            </Typography>
            <Typography variant="h6" textAlign='left' gutterBottom>
                Użytkowniku, pamiętaj o tym, że:
                <ul>
                    <li>gdy korzystasz z jednego komputera wspólnie z innymi użytkownikami, po zakończeniu korzystania z serwisów Wechsler Polska Sp. z o.o., do których jesteś zalogowany, koniecznie się wyloguj, czyli "wyjdź" ze swoich ustawień, aby nikt nie mógł ich podejrzeć;</li>
                    <li>dane podane przez Ciebie przy rejestracji znasz tylko Ty i Wechsler Polska Sp. z o.o.. Z naszej strony gwarantujemy, że nikomu nie zostaną ujawnione, Ty też zachowaj ostrożność w udostępnianiu ich osobom trzecim;</li>
                    <li>zdarza się, że osoby korzystające z Sieci chcą "wyciągnąć" od Ciebie informacje na Twój temat i użyć ich przeciwko Tobie bądź innym osobom. Mogą to czynić np. z chęci zysku lub, w ich mniemaniu, dla dobrej zabawy. Zachowaj ostrożność. Upewnij się, kim jest osoba, która pyta Cię o dane osobowe, jaką reprezentuje instytucję. Pamiętaj, że to właśnie Ciebie chronią stosowne regulacje prawne, w tym dotyczące ochrony danych osobowych.</li>
                </ul>
            </Typography>
        </Paper>
    )
}