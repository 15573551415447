import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import agent from '../../app/api/agent';
import { router } from '../../app/router/Routes';

export default function ForgotPassword() {
    const {register, handleSubmit, formState: {isSubmitting, errors, isValid}} = useForm({
        mode: 'all'
    });

  return (
      <Container component={Paper} maxWidth="sm" sx={{display: 'flex', flexDirection: 'column', aligntItems: 'center', p: 4}}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            Zapomniane hasło / Wygeneruj token ponownie
        </Typography>
        <Box 
            component="form" 
            onSubmit={handleSubmit((data) => 
                agent.Account.forgotPassword(data.email)
                    .then(() => {
                        toast.success('Hasło zostało zresetowane. Sprawdź swój adres email, by wygenerować nowe hasło.')
                        router.navigate(`/account/forgotPasswordSuccess?email=${data.email}`);
                    })
                    .catch(error => toast.error(error)))
            } 
            noValidate 
            sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                label="Email"
                autoFocus
                {...register('email', {
                    required: 'Email jest wymagany',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Email jest nieprawidłowy'
                    }
                })}
                error={!!errors.email}
                helperText={errors?.email?.message?.toString()}
            />

            <LoadingButton
                disabled={!isValid} 
                loading={isSubmitting}
                type="submit"
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
            >
                Resetuj hasło
            </LoadingButton>
        </Box>
    </Container>
  );
}