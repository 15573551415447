import { Grid } from "@mui/material";
import { Investment } from "../../../../app/models/investment";
import { useAppSelector } from "../../../../app/store/configureStore";
import AdminInvestmentCard from "./AdminInvestmentCard";
import AdminInvestmentCardSkeleton from "./AdminInvestmentCardSkeleton";

interface Props {
    adminInvestments: Investment[];
    handleCardSelectAdminInvestment?: (handledAdminInvestment: Investment) => void;
    handleCardSelectInvestmentValuation?: (handledAdminInvestmentValuation: Investment) => void;
}

export default function AdminInvestmentsList({ adminInvestments, handleCardSelectAdminInvestment, handleCardSelectInvestmentValuation }: Props) {
    const { adminInvestmentsLoaded } = useAppSelector(state => state.adminInvestments);
    return (
        <Grid container spacing={4}>
            {adminInvestments.map((adminInvestment, index) => (
                <Grid key={index} item xs={12} sm={12} md={6}>
                    {!adminInvestmentsLoaded ? (
                        <AdminInvestmentCardSkeleton />
                    ) : (
                        <AdminInvestmentCard
                            investment={adminInvestment}
                            handleCardSelectInvestment={handleCardSelectAdminInvestment}
                            handleCardSelectInvestmentValuation={handleCardSelectInvestmentValuation}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
}