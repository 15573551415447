import { Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, CircularProgress, Typography } from "@mui/material";
import AppPagination from "../../../../app/components/AppPagination";
import useDocuments from "../../../../app/hooks/useDocuments";
import { setDocument, setDocumentsPageNumber, setDocumentsParams } from "../documentsSlice";
import { Link, useNavigate } from "react-router-dom";
import DocumentForm from "../DocumentForm";
import { useState, useEffect } from "react";
import { Document } from "../../../../app/models/document";
import agent from "../../../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppReturn from "../../../../app/components/AppReturn";
import AppUniversalSearch from "../../../../app/components/AppUniversalSearch";
import { RootState, useAppDispatch } from "../../../../app/store/configureStore";
import { formatToLocalDate } from "../../../../app/util/util";

export default function DocumentsManager() {
    const { documents, metaData } = useDocuments();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<Document | undefined>(undefined);
    const [openSignDialog, setOpenSignDialog] = useState(false);
    const [documentToSign, setDocumentToSign] = useState<Document | null>(null);
    const [loading, setLoading] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (documents.length > 0 || metaData) {
            setLoadingDocuments(false);
        }
    }, [documents, metaData]);

    function cancelEdit() {
        if (selectedDocument) setSelectedDocument(undefined);
        setEditMode(false);
    }

    function handleSelectDocument(document: Document) {
        setSelectedDocument(document);
        setEditMode(true);
    }

    const handleOpenSignDialog = (document: Document) => {
        setDocumentToSign(document);
        setOpenSignDialog(true);
    };

    const handleCloseSignDialog = () => {
        setOpenSignDialog(false);
    };

    const handleConfirmSignDialog = async () => {
        setLoading(true);
    
        try {
            if (documentToSign) {
                const response = await agent.Admin.Documents.addSignatureByAdmin(documentToSign.id);
    
                if (response) {
                    toast.success('Podpisano dokument');
                    dispatch(setDocument(response));
                } else {
                    toast.error('Nie udało się podpisać dokumentu. Prawdopodobnie plik jest za duży lub nieprzystosowany do podpisania.');
                    navigate('/admin/documents');
                }
            }
        } catch (error: any) {
            // Przechwyć i wyświetl błąd
            toast.error(`Wystąpił błąd podczas podpisywania dokumentu. Dokument może być za duży aby go podpisać lub format pliku jest nieodpowiedni.`);
            navigate('/admin/documents'); // Powrót do listy dokumentów
        } finally {
            setLoading(false);
            handleCloseSignDialog();
        }
    };

    if (editMode) return <DocumentForm document={selectedDocument} cancelEdit={cancelEdit} />

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title="Dokumenty" setFunction={() => setEditMode(true)} />

            <Paper sx={{ mb: 2 }}>
                <AppUniversalSearch
                    sliceName="documents"
                    searchParamsSelector={(state: RootState) => state.documents.documentsParams}
                    setSearchParams={setDocumentsParams}
                    label='Szukaj'
                />
            </Paper>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="left">Nazwa</TableCell>
                            <TableCell align="left" sx={{ minWidth: 150 }}>Data dodania</TableCell>
                            <TableCell align="left">Użytkownik</TableCell>
                            <TableCell align="left">Komentarz</TableCell>
                            <TableCell align="left" sx={{ minWidth: 150 }}>Data ostatniej edycji</TableCell>
                            <TableCell align="left" sx={{ minWidth: 100 }}>Format dokumentu</TableCell>
                            <TableCell align="center" style={{ minWidth: 300 }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingDocuments ? (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {documents.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            <Typography variant='h6' color={(theme) => theme.palette.text.primary}>Brak dokumentów</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    documents.map((item) => {
                                        const url = item.filePath;
                                        const extension = url ? '.' + url.split('.').pop() : '';

                                        return (
                                            <TableRow
                                                key={item.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {item.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Box display='flex' alignItems='center'>
                                                        <span>{formatToLocalDate(item.dateCreated?.toString() ?? '')}</span>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.userEmail}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.comment}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Box display='flex' alignItems='center'>
                                                        <span>{formatToLocalDate(item.updatingDateTime?.toString() ?? '')}</span>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {extension ? extension : "Brak pliku"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Button
                                                            sx={{ mb: 1, width: '60%' }}
                                                            variant='outlined'
                                                            component={Link} to={`/admin/documents/${item.id}`}
                                                            size="small"
                                                            title='Więcej'
                                                        >Więcej</Button>
                                                        <Button
                                                            sx={{ mb: 1, width: '60%' }}
                                                            variant='outlined'
                                                            onClick={() => handleSelectDocument!(item)}
                                                            size="small"
                                                            title='Edycja'
                                                            color='warning'
                                                        >Edycja</Button>
                                                        {extension === '.pdf' ?
                                                            <Button
                                                                sx={{ width: '60%' }}
                                                                variant='outlined'
                                                                onClick={() => handleOpenSignDialog(item)}
                                                                size="small"
                                                                title="Podpisz"
                                                                color={item.isSignedByAdmin ? 'error' : 'inherit'}
                                                            >{item.isSignedByAdmin ? 'Podpisz ponownie' : 'Podpisz'}</Button>
                                                            : null
                                                        }
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}
                            </>
                        )}
                    </TableBody>
                    <Dialog open={openSignDialog} onClose={handleCloseSignDialog}>
                        <DialogTitle>Podpisz dokument</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {documentToSign && documentToSign.isSignedByAdmin ? 'Ten dokument był już podpisywany. Czy chcesz go podpisać ponownie?' : 'Czy na pewno chcesz podpisać dokument?'}
                                {documentToSign && documentToSign.isSignedByClient ? <Alert sx={{ mt: 2 }} severity="warning">Klient podpisał już ten dokument. Jeżeli podpiszesz dokument ponownie klient będzie musiał złożyć podpis ponownie.</Alert> : ''}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' color="error" onClick={handleCloseSignDialog}>
                                Anuluj
                            </Button>
                            <LoadingButton
                                variant='outlined'
                                color='success'
                                onClick={handleConfirmSignDialog}
                                loading={loading}
                            >Podpisz</LoadingButton>
                        </DialogActions>
                    </Dialog>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination metaData={metaData}
                        onPageChange={(page: number) => dispatch(setDocumentsPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    );
}