import { List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import PropTypes from 'prop-types';
import navMenu from './menu';
import useStyles from './headerStyle';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { Link, useLocation } from 'react-router-dom';
import { signOut } from '../../../features/account/accountSlice';
import CallIcon from '@mui/icons-material/Call';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

interface MobileMenuProps {
  toggleDrawer: () => void;
  open: boolean;
}

function MobileMenu(props: MobileMenuProps) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { toggleDrawer, open } = props;
  const { classes, cx } = useStyles();
  const { user } = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  function getIcon(iconName: string) {
    switch (iconName) {
      case 'InfoIcon': return (<InfoIcon fontSize='large' />);
      case 'ShoppingCartIcon': return (<ShoppingCartIcon fontSize='large' />);
      case 'CallIcon': return (<CallIcon fontSize='large' />);
      case 'ShoppingBasketIcon': return (<ShoppingBasketIcon fontSize='large' />);
      case 'AdminPanelSettingsIcon': return (<AdminPanelSettingsIcon fontSize='large' />);
      case 'LogoutIcon': return (<LogoutIcon fontSize='large' />);
      case 'LoginIcon': return (<LoginIcon fontSize='large' />);
      case 'PersonAddIcon': return (<PersonAddIcon fontSize='large' />);
    }
  }

  const SideList = () => (
    <div
      className={classes.mobileNav}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div className={cx(classes.menu, open && classes.menuOpen)}>
        <List component="nav" >
          {navMenu.map((item, index) => (
            <ListItem
              component="a"
              href={`${item.path}`}
              key={index.toString()}
              style={{ animationDuration: index * 0.15 + 's' }}
              className={currentPath === item.path ? 'active' : ''}
            >
              <ListItemIcon>{getIcon(item.icon)}</ListItemIcon>
              <ListItemText className={classes.menuList}>{item.name}</ListItemText>
            </ListItem>
          ))}
          <ListItem
            component="a"
            href={'/client'}
            style={{ animationDuration: '0.6s' }}
            className={currentPath === '/client' ? 'active' : ''}
          >
            <ListItemIcon>{getIcon('ShoppingBasketIcon')}</ListItemIcon>
            <ListItemText className={classes.menuList}>Strefa klienta</ListItemText>
          </ListItem>
          {user && user.roles?.includes('Admin') &&
            <ListItem
              component="a"
              href={'/admin'}
              style={{ animationDuration: '0.75s' }}
              className={currentPath === '/admin' ? 'active' : ''}
            >
              <ListItemIcon>{getIcon('AdminPanelSettingsIcon')}</ListItemIcon>
              <ListItemText className={classes.menuList}>Admin</ListItemText>
            </ListItem>
          }
          {user ? (
            <ListItem
              component={Link}
              to={'/'}
              style={{ animationDuration: '0.75s' }}
              onClick={() => dispatch(signOut())}
            >
              <ListItemIcon>{getIcon('LogoutIcon')}</ListItemIcon>
              <ListItemText className={classes.menuList}>Wyloguj</ListItemText>
            </ListItem>
          ) : (
            <>
              <ListItem
                component={Link}
                to="/login"
                style={{ animationDuration: '0.75s' }}
              >
                <ListItemIcon>{getIcon('LoginIcon')}</ListItemIcon>
                <ListItemText className={classes.menuList}>Zaloguj</ListItemText>
              </ListItem>
              <ListItem
                component={Link}
                to="/register"
                style={{ animationDuration: '0.75s' }}
              >
                <ListItemIcon>{getIcon('PersonAddIcon')}</ListItemIcon>
                <ListItemText className={classes.menuList}>Rejestracja</ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </div>
    </div>
  );

  return (
    <SwipeableDrawer
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      classes={{
        paper: classes.paperNav
      }}
    >
      <SideList />
    </SwipeableDrawer>
  );
}

MobileMenu.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default MobileMenu;
