import { Typography } from "@mui/material";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import DOMPurify from "dompurify";
import { useEffect } from "react";
import useAbouts from "../../app/hooks/useAbouts";
import { useAppDispatch } from "../../app/store/configureStore";
import { fetchAboutByName } from "./aboutsSlice";

export default function Cerificate() {

    const dispatch = useAppDispatch();
    const { abouts } = useAbouts();
    const aboutName = 'certificate'; // Przykładowa nazwa, którą chcesz znaleźć
    const about = abouts.find(a => a.name === aboutName); // Wyszukuje po nazwie, jeśli masz pole 'name' w swoich danych

    console.log(about?.text);

    useEffect(() => {
        dispatch(fetchAboutByName(aboutName));
    }, [dispatch, aboutName]);

    let cleanHTML = '';
    if (about?.text) {
        cleanHTML = DOMPurify.sanitize(about?.text);
    }

    return (
        <>
            <AppBreadcrumbs />
            <Typography align="justify" gutterBottom paddingLeft={1} >
                <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
            </Typography>
        </>
    )
}