import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface Props {
    options: { value: string; label: string }[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedValue: string;
}

export default function AppRadioButtonGroup({ options, onChange, selectedValue }: Props) {
    /* "handleChange" dodany dla do wyświetlania np. pól formularza po wybraniu wartości z RadioGroup */
    /* w standardowej wersji tego komponentu nie ma "handleChange" */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
    };

    return (
        <FormControl component="fieldset">
            {/* w standardowej wersji tego komponentu był kod jak poniżej: */}
            {/* <RadioGroup onChange={onChange} value={selectedValue}> */}
            <RadioGroup onChange={handleChange} value={selectedValue}>
                {options.map(({ value, label }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}