import { Typography } from "@mui/material";
import { UserContractPreview } from "../../app/models/userContractPreview";

interface Props {
    userContractPreview: UserContractPreview | null;
}

export default function ContractPreview({ userContractPreview }: Props) {

    return (
        <>
            {userContractPreview &&
                <>
                    <Typography variant="h4" gutterBottom align="center">
                        Porozumienie o współpracy
                    </Typography>
                    <p>W dniu ......... we Wrocławiu , pomiędzy <strong>Fundacją Rozwoju Obywatelskiego</strong> z siedzibą we <strong>Wrocławiu przy Al. Poprzecznej 68a/3, 51-157 Wrocław NIP: 8952033293, REGON: 022456042, wpisaną do Krajowego Rejestru Sądowego pod numerem KRS 0000516274</strong> reprezentowaną przez <strong>Grzegorz Wąsik - Prezes Zarządu</strong> zwaną w dalszej części <strong>Korzystającym</strong></p>
                    <p>a</p>
                    <p>Panią(nem) <strong>{userContractPreview.firstName} {userContractPreview.lastName}</strong> zamieszkałą(łym) przy <strong>ul. {userContractPreview.street} {userContractPreview.building} {userContractPreview.premises != 'null' ? (`/ ${userContractPreview.premises}`) : ( '') }, {userContractPreview.zip} {userContractPreview.city}</strong> zwaną(nym) w dalszej części <strong>Wolontariuszem</strong>, zostało zawarte porozumienie następującej treści:</p> 
                    <p></p>
                    <Typography variant='h5' gutterBottom align='center'>
                        Wstęp
                    </Typography>
                    <p>Korzystający oświadcza, że jest podmiotem na rzecz którego zgodnie z art. 42 ust. 1 ustawy z dnia 24 kwietnia 2003 r. o działalności pożytku publicznego i o wolontariacie (Dz. U. 2014 r. poz. 1118, z późn. zm.) mogą być wykonywane świadczenia przez wolontariuszy.</p>
                    <p>Wolontariusz oświadcza, że posiada kwalifikacje i spełnia wymagania niezbędne do wykonywania powierzonych niżej czynności.</p>
                    <p>Wolontariusz oświadcza, że posiada pełnię praw do podpisania niniejszej umowy bądź posiada zgodę opiekuna prawnego na zawarcie niniejszego porozumienia, która stanowi załącznik nr 1 do porozumienia.</p>
                    <p><strong>Mając na względzie ideę wolontariatu, u podstaw której stoi dobrowolne, bezpłatne wykonywanie czynności, a także biorąc pod uwagę charytatywny, pomocniczy i uzupełniający charakter wykonywanych przez wolontariuszy świadczeń, Strony porozumienia uzgadniają, co następuje:</strong></p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 1.
                    </Typography>
                    <ol>
                        <li>Korzystający powierza wykonywanie Wolontariuszowi, a Wolontariusz dobrowolnie podejmuje się wykonania na rzecz Korzystającego czynności związanych ze wsparciem Seniorów z Wrocławia, w tym: 
                            <p>1) Organizacje dostępu do podstawowej i specjalistycznej opieki zdrowotnej poprzez: 
                                <p>a)	pomoc w umówieniu wizyty lekarskiej oraz pomoc w dowiezieniu na wizytę lekarską, asystowanie podczas wizyty; </p>
                                <p>b)	pomoc w organizacji dojazdu na szczepienie; </p>
                                <p>c)	pomoc w załatwieniu drobnych spraw urzędowych, w tym dowiezienie seniora na wizytę w urzędzie. </p>
                            </p>
                            <p>2) Organizację i wsparcie seniorów w czynnościach życia codziennego: 
                                <p>a)	pomoc/ towarzyszenie na spacerze;</p>
                                <p>b)	pomoc w utrzymaniu porządku w domu;</p>
                                <p>c)	pomoc w dostarczeniu produktów żywnościowych z organizacji pozarządowych dystrybuujących żywność; </p>
                                <p>d)   dostarczenie seniorom zakupów;</p>
                                <p>e)   zakup i dostarczenie ciepłych posiłków;</p>
                                <p>f)	wsparcie seniorów w uzyskaniu paczek okolicznościowych;</p>
                            </p>
                            <p>Wszystkie powyższe działania odpowiadają na zdiagnozowane potrzeby i bariery seniorów z gminy Wrocław i pozwolą na poprawę ich życia społecznego i zdrowotnego oraz są odpowiedzią na sytuację społeczną.</p>
                        </li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 2.
                    </Typography>
                    <ol>
                        <li>Strony Umowy uzgadniają, że czynności określone w § 1 ust. 1 będą wykonywane w ilości maksymalnie 40 h/miesięcznie  w okresie od dnia podpisania umowy aż do rozwiązania niniejszej umowy.</li>
                        <li>Miejscem wykonywania czynności będzie miejsce wskazane przez Korzystającego.</li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 3.
                    </Typography>
                    <p>Z uwagi na charakter i ideę wolontariatu:</p>
                    <ol>
                        <li>Wolontariusz jest obowiązany wykonywać uzgodnione czynności osobiście;</li>
                        <li>Wolontariusz za swoje czynności nie otrzyma wynagrodzenia;</li>
                        <li>W trakcie wykonywania czynności powinien posiadać identyfikator wydany przez Korzystającego;</li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 4.
                    </Typography>
                    <ol>
                        <li>Korzystający zobowiązuje się zapewnić Wolontariuszowi bezpieczne i higieniczne warunki wykonywania przez niego świadczeń.</li>
                        <li>Korzystający zobowiązuje się poinformować Wolontariusza o specyfice wykonywania świadczeń oraz o przysługujących Wolontariuszowi prawach i ciążących obowiązkach, jak również zapewnić dostępność tych informacji.</li>
                        <li>Korzystający zobowiązuje się poinformować Opiekuna prawnego i Wolontariusza o ryzyku dla zdrowia i bezpieczeństwa związanym z wykonywanymi świadczeniami oraz o zasadach ochrony przed zagrożeniami.</li>
                        <li>Wolontariusz zobowiązany jest do wskazania osoby, którą należy poinformować w razie wypadku.</li>
                        <li>Korzystający na czas wykonywania świadczeń przekazuje Wolontariuszowi następujące środki ochrony indywidualnej:
                            <ul>
                                <li>Maseczki ochronne</li>
                                <li>Rękawiczki jednorazowe</li>
                                <li>Środki dezynfekujące </li>
                                <li>Inne, w miarę indywidualnych potrzeb. </li>
                            </ul>
                        </li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 5.
                    </Typography>
                    <p>Wolontariusz dobrowolnie wyraża zgodę na przetwarzanie swoich danych osobowych oraz wizerunku w celu organizacji i promocji wolontariatu przez Fundację Rozwoju Obywatelskiego.</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 6.
                    </Typography>
                    <p>Korzystający zobowiązuje się pokrywać niezbędne koszty ponoszone przez Wolontariusza, związane z wykonywaniem świadczeń na rzecz Korzystającego w następujący sposób:</p>
                    <p>a) Zwrot kosztów dojazdu</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 7.
                    </Typography>
                    <ol>
                        <li>Korzystający ma obowiązek ubezpieczyć wolontariusza od następstw nieszczęśliwych wypadków.</li>
                        <li>Korzystający zapewnia wolontariuszowi nie posiadającemu żadnego tytułu ubezpieczenia - ubezpieczenie zdrowotne, w terminie 7 dni od dnia zawarcia Porozumienia.</li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 8.
                    </Typography>
                    <ol>
                        <li>Korzystający pokrywa, na dotyczących pracowników zasadach określonych w odrębnych przepisach, koszty podróży służbowych i diet Wolontariusza.
                        </li>
                        <li>Zwrot wydatków nastąpi w terminie 7 dni po dostarczeniu przez Wolontariusza stosownego rozliczenia wraz z dowodami poniesionych wydatków.
                        </li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 9.
                    </Typography>
                    <p>Wolontariusz zobowiązuje się do zachowania w tajemnicy informacji, które uzyskał w związku w wykonywaniem świadczeń na rzecz Korzystającego, a które stanowią tajemnicę Korzystającego. Dotyczy to w szczególności informacji związanych z:</p>
                    <p>1)	Funkcjonowaniem organizacji Korzystającego;</p>
                    <p>2)	Stosowaniem zasad współpracy Korzystającego z innymi podmiotami;</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 10.
                    </Typography>
                    <ol>
                        <li>Porozumienie może być rozwiązane przez każdą ze Stron za 14 dniowym wypowiedzeniem.</li>
                        <li>Porozumienie może być rozwiązane przez każdą ze Stron bez wypowiedzenia z ważnych przyczyn.</li>
                        <li>Za ważne przyczyny Strony uznają w szczególności:
                            <p>1)	Nieprawidłowe wykonywanie czynności;</p>
                            <p>2)	Działanie na szkodę Korzystającego;</p>
                        </li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 11.
                    </Typography>
                    <p>Za wyrządzone szkody Strony odpowiadają na zasadach określonych w Kodeksie cywilnym.</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 12.
                    </Typography>
                    <p>W sprawach nieuregulowanych przepisami ustawy o działalności pożytku publicznego i o wolontariacie lub niniejszym Porozumieniem zastosowanie będą miały odpowiednio przepisy Kodeksu cywilnego.</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 13.
                    </Typography>
                    <p>Wszelkie zmiany Porozumienia będą dokonywane w formie pisemnej pod rygorem nieważności.</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 14.
                    </Typography>
                    <p>Spory mogące powstać w związku z realizacją niniejszego Porozumienia strony zobowiązują się rozstrzygać w drodze negocjacji, a w przypadku ich niepowodzenia przed sądem powszechnym właściwym miejscowo dla siedziby Korzystającego.</p>
                    <Typography variant='h5' gutterBottom align='center'>
                        § 15.
                    </Typography>
                    <ol>
                        <li>Umowę sporządzono w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.</li>
                        <li>Wolontariusz może w każdym czasie domagać się wydania przez Korzystającego pisemnego zaświadczenia o wykonaniu świadczeń przez Wolontariusza. Zaświadczenie powinno zawierać informację o zakresie wykonywanych świadczeń.</li>
                    </ol>
                    <Typography variant='h5' gutterBottom align='center'>
                        Osoby niepełnoletnie będą potrzebować oświadczenie opiekuna prawnego - na wyrażenie zgody, by zawrzeć niniejszą umowę.
                    </Typography>
                    <Typography variant='h5' gutterBottom align='center'>
                    ZGODA NA PRZETWARZANIE DANYCH OSOBOWYCH
                    </Typography>
                    <p>Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w porozumieniu wolontariackim dla potrzeb niezbędnych do realizacji wolontariatu, zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku oraz ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz.U.2018 poz. 1000) oraz zgodnie klauzulą informacyjną dołączoną do mojej zgody.</p>
                </>

            }
        </>
    )
}
