import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import agent from '../../app/api/agent';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface RegisterFormData {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    reCaptchaToken: string;
    url?: string;
}

export default function Register() {
    const { register, handleSubmit, setError, formState: { isSubmitting, errors, isValid }, watch } = useForm<RegisterFormData>({
        mode: 'all'
    });
    const theme = useTheme();
    const navigate = useNavigate();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const honeypot = watch('url');
    const isLessThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    function handleApiErrors(errors: any) {
        if (errors) {
            errors.forEach((error: string) => {
                if (error.includes('Password')) {
                    setError('password', { message: error })
                } else if (error.includes('Email')) {
                    setError('email', { message: error })
                } else if (error.includes('LastName')) {
                    setError('lastName', { message: error })
                } else if (error.includes('FirstName')) {
                    setError('firstName', { message: error })
                } else if (error.includes('Phone')) {
                    setError('phone', { message: error })
                }
                // } else if (error.includes('Street')) {
                //     setError('street', {message: error})
                // } else if (error.includes('Building')) {
                //     setError('building', {message: error})
                // } else if (error.includes('Premises')) {
                //     setError('premises', {message: error})
                // } else if (error.includes('City')) {
                //     setError('city', {message: error})
                // } else if (error.includes('State')) {
                //     setError('state', {message: error})
                // } else if (error.includes('Zip')) {
                //     setError('zip', {message: error})
                // } else if (error.includes('Country')) {
                //     setError('country', {message: error})
                // }
            })
        }
    }

    async function onFormSubmit(data: RegisterFormData) {
        if (honeypot) {
            toast.error("Rejestracja nieudana.");
            navigate('/');
        } else {
            try {
                const recaptchaValue = recaptchaRef.current?.getValue();
                if (!recaptchaValue) {
                    toast.error('Proszę zaznaczyć "Nie jestem robotem"');
                    return;
                }

                data.reCaptchaToken = recaptchaValue;
                await agent.Account.register({ ...data });
                toast.success('Zarejestrowano poprawnie - możesz się zalogować');
                navigate(`/account/registerSuccess?email=${data.email}`);
            } catch (error) {
                handleApiErrors(error);
            }
        }
    }

    return (
        <Container component={Paper} maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', aligntItems: 'center', p: 4 }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Rejestracja
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(onFormSubmit)}
                noValidate
                sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Email"
                    autoFocus
                    {...register('email', {
                        required: 'Email jest wymagany',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email jest nieprawidłowy'
                        }
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message?.toString()}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Hasło"
                    type="password"
                    {...register('password', {
                        required: 'Hasło jest wymagane',
                        pattern: {
                            value: /(?=^.{6,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Hasło nie spełnia wymagań bezpieczeństwa'
                        }
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message?.toString()}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Imię"
                    {...register('firstName', {
                        required: 'Imię jest wymagane'
                    })}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message?.toString()}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Nazwisko"
                    {...register('lastName', {
                        required: 'Nazwisko jest wymagane'
                    })}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message?.toString()}
                />

                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                    <input
                        {...register('url')}
                        style={{ position: "absolute", left: "-5000px" }}
                        tabIndex={-1}
                    />
                </div>

                <TextField
                    margin="normal"
                    fullWidth
                    label="Telefon kontaktowy"
                    {...register('phone', {
                        required: 'Telefon kontaktowy jest wymagany'
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message?.toString()}
                />

                <Box display="flex" justifyContent="center" width="100%" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LeVzL0pAAAAAOQ1_E7ujIfcYJok7XmzJL8RmHUl"
                    />
                </Box>

                <LoadingButton
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Rejestracja
                </LoadingButton>
                {isLessThanSm ?
                    <Grid container>
                        <Grid item xs={12} textAlign='center'>
                            <Link href="/account/forgotPassword">
                                Zapomniane hasło?
                            </Link>
                        </Grid>
                        <Grid item xs={12} textAlign='center'>
                            <Link href="/login">
                                Masz już konto? Zaloguj się
                            </Link>
                        </Grid>
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs>
                            <Link href="/account/forgotPassword" variant="body2">
                                Zapomniane hasło?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/login">
                                Masz już konto? Zaloguj się
                            </Link>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Container>
    );
}