import { UploadFile } from '@mui/icons-material';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { useCallback, useEffect, useState, CSSProperties } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { useController, UseControllerProps } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props extends UseControllerProps {
  acceptedFormats: string;
}

const parseAcceptedFormats = (formats: string): Accept => {
  const formatArray = formats.split(',').map(format => format.trim());
  const accept: Accept = formatArray.reduce((acc, format) => {
    const mimeType = getMimeType(format);
    if (mimeType) {
      acc[mimeType] = [];
    }
    return acc;
  }, {} as Accept);
  return accept;
};

const getMimeType = (extension: string): string | null => {
  const mimeTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf',
    zip: 'application/zip',
    // Dodaj inne potrzebne typy MIME tutaj
  };
  return mimeTypes[extension] || null;
};

export default function AppDropzone({ acceptedFormats, ...props }: Props) {
  const { fieldState, field } = useController({ ...props, defaultValue: null });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 600);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dzStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    border: 'dashed 3px #eee',
    borderColor: '#eee',
    borderRadius: '5px',
    paddingTop: '30px',
    alignItems: 'center',
    height: '200px',
    width: isSmallScreen ? '100%' : '500px'
  };

  const dzActive: CSSProperties = {
    borderColor: 'green'
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      toast.error('Nie wybrano żadnego pliku.');
      return;
    }

    const file = acceptedFiles[0];

    try {
      const preview = URL.createObjectURL(file);
      field.onChange(Object.assign(file, { preview }));
    } catch (error) {
      toast.error('Wystąpił błąd podczas przetwarzania pliku.');
      console.error('Error creating object URL:', error);
    }
  }, [field]);

  const onDropRejected = useCallback(() => {
    toast.error('Nieprawidłowy format pliku. Akceptowane formaty: ' + acceptedFormats);
  }, [acceptedFormats]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: parseAcceptedFormats(acceptedFormats)
  });

  return (
    <div {...getRootProps()} style={{ display: 'flex', justifyContent: 'center' }}>
      <FormControl style={isDragActive ? { ...dzStyles, ...dzActive } : dzStyles} error={!!fieldState.error}>
        <input {...getInputProps()} />
        <UploadFile sx={{ fontSize: '100px' }} />
        <Typography variant='h4'>Przeciągnij plik</Typography>
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      </FormControl>
    </div>
  );
}