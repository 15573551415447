export interface Investment {
    id: number;
    isActive: boolean;
    publicId: number;
    isLastModify: boolean;
    isAgreementCreated: boolean;

    state: InvestmentState;

    userId: string;
    userEmail: string;
    userDisplayName: string;
    userPhone: string;
    investmentCreatedDateTime: Date;
    updatingUserId: string;
    updatingUserIsAdmin: boolean;
    updatingUserEmail: string;
    updatingUserDisplayName: string;
    updatingUserPhone: string;
    updatingDateTime: Date;
    investmentName: string;
    clientComment: string;
    publicComment: string;
    internalComment: string;

    competition: string;
    submissionDeadline: string;

    price: number;

    clientInstitutionName: string;
    clientLastname: string;
    clientFirstname: string;
    clientPhone: string;
    clientEmail: string;

    clientStreet: string;
    clientBuilding: string;
    clientPremises: string;
    clientZip: string;
    clientCity: string;
    clientState: string;
    clientCountry: string;
    clientNIP: string;
    clientREGON: string;
    clientKRS: string;

    payerInstitutionName: string;
    payerStreet: string;
    payerBuilding: string;
    payerPremises: string;
    payerZip: string;
    payerCity: string;
    payerState: string;
    payerCountry: string;
    payerNIP: string;
    payerRepresentative: string;

    clientExperience: string;
    clientPotential: string;

    projectName: string;
    projectDateStart: Date;
    projectDateEnd: Date;
    projectMaxBudget: number;
    projectDescription: string;
}

export interface InvestmentParams {
    orderBy: string;
    searchTerm?: string;
    pageNumber: number;
    pageSize: number;
    states: string[];
}

export enum InvestmentState {
    WAITING_FOR_CONFIRMATION = 0,
    ADMIN_ACCEPTED = 1,
    ADMIN_REJECTED = 2,
    CLIENT_ACCEPTED = 3,
    CLIENT_REJECTED = 4,
    CLIENT_DATA_FILLED = 5,
    COMPLETED = 6,
    CANCELED = 7
}