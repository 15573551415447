import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import useStyles from './titleStyle';

interface WithIconProps {
  text: string;
  extended: any,
  icon: any
}

function WithDecoration(props: WithIconProps) {
  const { classes, cx } = useStyles();
  const { text, extended, icon } = props;

  const error = console.error;
  console.error = (...args) => {
    if (/defaultProps will be removed/.test(args[0])) return;
    error.apply(console, args);
  };

  return (
    <div className={cx(classes.iconDeco, extended && classes.extend)}>
      <Icon className={classes.icon}>{icon}</Icon>
      <Typography variant="h3">
        {text}
      </Typography>
    </div>
  );
}

WithDecoration.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  extended: PropTypes.bool
};

WithDecoration.defaultProps = {
  extended: false,
  icon: 'apps'
};

export default WithDecoration;
