import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Typography } from "@mui/material";
import { FooterColumn } from "../../models/footer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    isDesktop: boolean;
    isMobile: boolean;
    footerColumn: FooterColumn;
    classes: Record<string, string>;
}

export default function FooterColumnFromAbout({ isDesktop, isMobile, footerColumn, classes }: Props) {
    return (
        <Grid item xs={12} md={3} key={footerColumn.headerTitle} className={classes.siteMapItem}>
            {isDesktop && (
                <div>
                    <Typography variant="h6" className={classes.title} color="textPrimary" gutterBottom>
                        {footerColumn.headerTitle}
                    </Typography>
                    <ul>
                        {footerColumn.headerTitle == 'INSTYTUCJA' ?
                            <li>
                                <Link href='/about' variant="subtitle1" color="textSecondary">
                                    O Centrum
                                </Link>
                            </li> :
                            null
                        }
                        {footerColumn.footerLinks.map((item, index) => (
                            <li key={index}>
                                <Link href={item.link} variant="subtitle1" color="textSecondary">
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {isMobile && (
                <Accordion
                    square
                    classes={{
                        root: classes.accordionRoot,
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{
                            content: classes.accordionContent,
                        }}
                    >
                        <strong>
                            {footerColumn.headerTitle}
                        </strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            {footerColumn.footerLinks.map((item, index) => (
                                <li key={index}>
                                    <Link href={item.link} variant="subtitle1" color="textSecondary">
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                    </AccordionDetails>
                </Accordion>
            )}
        </Grid>
    )
}