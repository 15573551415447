import { useEffect } from "react";
import { documentsSelectors, fetchDocumentsAsync, fetchDocumentsFilters } from "../../features/admin/documents/documentsSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useDocuments() {
    const documents = useAppSelector(documentsSelectors.selectAll);
    const {documentsLoaded, documentsFiltersLoaded, metaData} = useAppSelector(state => state.documents);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!documentsLoaded) dispatch(fetchDocumentsAsync());
  }, [documentsLoaded, dispatch])

  useEffect(() => {
    if (!documentsFiltersLoaded) dispatch(fetchDocumentsFilters());
  }, [documentsFiltersLoaded, dispatch])

  return {
    documents,
    documentsLoaded,
    documentsFiltersLoaded,
    //brands,
    //types,
    metaData
  }
}