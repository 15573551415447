import { useState } from "react";
import agent from "../../../../app/api/agent";
import { toast } from "react-toastify";
import { InputLabel, TextField, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../../app/store/configureStore";
import { fetchRolesAsync } from "./rolesSlice";

export default function AdminRoleAdd() {
  const [newRole, setNewRole] = useState<string>("");
  const [ isLoading, setIsLoading ] = useState(false);
  const dispatch = useAppDispatch();

  const handleRoleAdd = async (roleName: string) => {
    try {
      setIsLoading(true);
      const response = await agent.Admin.Roles.create(roleName);
      console.log(response);
      toast.success("Dodano pomyślnie");
      setIsLoading(false);
      dispatch(fetchRolesAsync())
      return response;
    } catch (error) {
      toast.error("Błąd");
      setIsLoading(false);
    }
  };

  const handleNewRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  return (
    <>
      <InputLabel id="select-role-label"></InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            id="newRoleToAdd"
            label="Nazwa nowej roli"
            value={newRole}
            onChange={handleNewRoleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleRoleAdd(newRole)}
            fullWidth
          >
            Dodaj
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
