import DoneIcon from '@mui/icons-material/Done';
import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import useQuery from "../../app/util/hooks";

export default function RegisterSuccess() {
    const email = useQuery().get('email') as string;
    const theme = useTheme();

    function handleConfirmEmailResend() {
        agent.Account.resendEmailConfirm(email).then(() => {
            toast.success('Email weryfikacyjny przesłany ponownie - sprawdź swoją pocztę email');
        }).catch(error => console.log(error));
    }

    return (
        <Box sx={{textAlign: "center"}}>
            <Typography variant='h3' gutterBottom style={{ color: theme.palette.text.primary }}>
                <DoneIcon color="success" fontSize="large" sx={{ marginRight: 2}}  />
                Rejestracja zakończona sukcesem!
            </Typography>
                
            <p style={{ color: theme.palette.text.primary }}>Proszę sprawdź swój adres email (ewentualnie też folder SPAM), by znaleźć email weryfikacyjny</p>
            {email && 
                <>
                    <p style={{ color: theme.palette.text.primary }}>Email nie dotarł? Kliknij poniżej, by przesłać ponownie</p>
                    <Button variant="outlined" onClick={handleConfirmEmailResend}>
                        Prześlij email ponownie
                    </Button>
                </>
            }
        </Box>
    )
}