export const brand = {
  agency: {
    name: 'wechsler polska',
    desc: 'wechsler - egzaminy dla wymagających',
    prefix: 'wechsler',
    footerText: 'Wechsler Polska Sp. z o.o. - All Rights Reserved 2016 - 2024',
    logoText: 'wechsler polska',
    projectName: 'certsystem.pl',
    url: 'certsystem.pl',
    img: 'client/public/images/wechsler_logo_w.svg',
    notifMsg: 'Witaj na stronie certsystem.pl'
  }
};
