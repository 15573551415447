import { Checkbox, FormControlLabel } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

interface Props extends UseControllerProps {
    label: string;
    disabled?: boolean;
    defaultChecked?: boolean;
    onChange?: (checked: boolean) => void;
}

export default function AppCheckbox(props: Props) {
    const { field } = useController({ ...props, defaultValue: props.defaultChecked ?? false });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        if (props.onChange) {
            props.onChange(event.target.checked);
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field}
                    checked={field.value || false} // Ensure it's a boolean
                    color='secondary'
                    disabled={props.disabled ?? false}
                    onChange={handleChange}
                />
            }
            label={props.label}
        />
    );
}