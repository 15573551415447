import { useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Carousel from 'react-slick';
import useStyles from './servicesStyle';
import TitleIcon from '../Title/WithIcon';
import Card from '../Cards/Default';
import DotsParallax from '../Parallax/Dots';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { fetchTopServicesAsync } from '../../../features/services/servicesSlice';

function HomePageServices() {
  const dispatch = useAppDispatch();
  const topServices = useAppSelector((state) => state.services.topServices);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { classes } = useStyles();
  const slider = useRef<Carousel>(null);
  // sprawdzić poniższą ścieżkę
  const defaultServicePicture = `${import.meta.env.VITE_URL}images/defaultServicePicture.webp`;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 1,
    responsive: [{
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
      }
    }, {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }]
  };

  useEffect(() => {
    dispatch(fetchTopServicesAsync());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <DotsParallax />
      <div className={classes.carouselHandle}>
        <div className={classes.carouselWrap}>
          <Carousel ref={slider} {...settings}>
            {isDesktop && (
              <div className={classes.item}>
                <div className={classes.carouselProp}>
                  <div />
                </div>
              </div>
            )}
            {topServices.map((item, index) => (
              <div className={classes.item} key={index.toString()}>
                <Card
                  title={item.name}
                  img={(item.imagePath == '' || item.imagePath == null) ? defaultServicePicture : item.imagePath}
                  link={'services/' + item.id}
                  button={'Szczegóły'}
                />
              </div>
            ))}
            <div className={classes.item} key='all'>
              <Card
                title='Wszystkie'
                desc='Zobaczy wszystkie egzaminy dostępne w naszej ofercie.'
                img={defaultServicePicture}
                link='services'
                button={'Sprawdź'}
              />
            </div>

            {isDesktop && (
              <div className={classes.item}>
                <div className={classes.carouselProp}>
                  <div />
                </div>
              </div>
            )}
          </Carousel>
        </div>
      </div>
      <div className={classes.floatingTitle}>
        <Container fixed>
          <div className={classes.title}>
            <TitleIcon text='Najpopularniejsze' icon="" extended />
          </div>
        </Container>
      </div>
    </div>
  );
}

export default HomePageServices;