import { TextField } from "@mui/material";
import { FieldValues, Path, PathValue, useController, UseControllerProps } from "react-hook-form";
import 'dayjs/locale/pl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, plPL } from '@mui/x-date-pickers';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    label: string;
    disabled: boolean;
}

export default function AppDateTimePicker<T extends FieldValues>({ label, disabled, ...props }: Props<T>) {
    const { field, fieldState } = useController<T>({
        ...props,
        defaultValue: '' as PathValue<T, Path<T>> // Ensuring it's an empty string compatible with T
    });

    const handleDateChange = (date: any) => {
        const isValidDate = date && !isNaN(new Date(date).getTime());
        const utcDate = isValidDate ? new Date(date).toISOString() : '';
        field.onChange(utcDate);
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='pl'
            localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DateTimePicker
                label={label}
                disabled={disabled}
                value={field.value ? new Date(field.value) : null}
                onChange={handleDateChange}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                    />
                )}
            />
        </LocalizationProvider>
    );
}