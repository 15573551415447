import { useState } from "react";
import { Box, Button, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from "@mui/material";
import useRoles from "../../../../app/hooks/useRoles";
import agent from "../../../../app/api/agent";
import { toast } from "react-toastify";
import { AdminUser } from "../../../../app/models/adminUser";
import { useAppDispatch } from "../../../../app/store/configureStore";
import { fetchAdminUserAsync } from "../adminUsersSlice";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    adminUser: AdminUser;
    handleCloseModal: () => void;
}

export default function AdminUserRoles({ handleCloseModal, adminUser }: Props) {
    const { roles } = useRoles();
    const [role, setRole] = useState<string>('');
    const dispatch = useAppDispatch();
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false);

    const handleRoleChange = async (roleId: string) => {
        try {
            setIsLoadingAdd(true);
            const response = await agent.Admin.Users.roleAddToUser(adminUser.id, roleId);
            toast.success("Dodano pomyślnie");
            setIsLoadingAdd(false);
            handleCloseModal();
            dispatch(fetchAdminUserAsync(adminUser.id))
            return response;
        } catch (error) {
            toast.error("Błąd");
            setIsLoadingAdd(false);
        }
    };

    const handleRoleRemove = async (roleId: string) => {
        try {
            setIsLoadingRemove(true);
            const response = await agent.Admin.Users.roleRemoveFromUser(adminUser.id, roleId);
            toast.success("Usunięto pomyślnie");
            setIsLoadingRemove(false);
            handleCloseModal();
            dispatch(fetchAdminUserAsync(adminUser.id))
            return response;
        } catch (error) {
            toast.error("Błąd");
            setIsLoadingRemove(false);
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value as string);
    };

    const style = {
        position: "absolute" as 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        bgcolor: 'background.default',
        borderRadius: "12px",
        // height: 390,
        boxShadow: 24,
        p: 4
    };

    const styleMobile = {
        position: "absolute" as 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: 'background.default',
        borderRadius: "12px",
        height: 480,
        boxShadow: 24,
        p: 4
    };

    return (
        <>
            <Modal
                open={true}
                onClose={handleCloseModal}
                sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item md={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h5">
                                    Role użytkownika # {adminUser?.displayName}
                                </Typography>
                                <Button 
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={handleCloseModal}
                                >
                                    <CloseIcon />
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item md={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <ul style={{ padding: 0 }}>
                                        {(adminUser.roles || []).map((adminUserRole, index) => (
                                            <li key={index}>{adminUserRole}</li>
                                        ))}
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item md={12}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                            }}>
                                <Grid item md={12} sx={{ mb: 3, textAlign: "center" }}>
                                    <InputLabel id="input">Wybierz rolę</InputLabel>
                                    <Select
                                        style={{ minWidth: 300 }}
                                        onChange={handleChange}
                                        id="simple-select"
                                        value={role}
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    md={12}
                                    sx={{
                                        mb: 0,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LoadingButton
                                        loading={isLoadingAdd}
                                        type="button"
                                        color="success"
                                        variant="outlined"
                                        sx={{
                                            minWidth: 200,
                                            ml: 1,
                                        }}
                                        onClick={() => handleRoleChange(role)}
                                        disabled={!role}
                                    >
                                        Dodaj
                                    </LoadingButton>
                                    <LoadingButton
                                        loading={isLoadingRemove}
                                        type="button"
                                        color="error"
                                        variant="outlined"
                                        sx={{
                                            minWidth: 200,
                                            ml: 1,
                                        }}
                                        onClick={() => handleRoleRemove(role)}
                                        disabled={!role}
                                    >
                                        Usuń
                                    </LoadingButton>
                                </Grid>
                            </form>
                        </Grid>

                    </Grid>
                </Box>
            </Modal >

            {/* Mobile version */}
            <Modal
                open={true}
                onClose={handleCloseModal}
                sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
            >
                <Box sx={styleMobile} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}>
                                <Typography variant="h5" >
                                    Role użytkownika
                                </Typography>
                                <Typography variant="h5">
                                    #{adminUser?.displayName}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <ul style={{ padding: 0 }}>
                                        {(adminUser.roles || []).map((adminUserRole, index) => (
                                            <li key={index}>{adminUserRole}</li>
                                        ))}
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleRoleChange(role);
                            }}>
                                <Grid item sx={{ mb: 3, textAlign: "center" }}>
                                    <InputLabel id="input">Wybierz rolę</InputLabel>
                                    <Select
                                        style={{ minWidth: 185 }}
                                        onChange={handleChange}
                                        id="simple-select"
                                        value={role}
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item sx={{ mb: 4, alignItems: "center" }}>
                                    <LoadingButton
                                        loading={isLoadingAdd}
                                        type='button'
                                        color='success'
                                        variant='outlined'
                                        sx={{
                                            minWidth: 150,
                                            borderRadius: '15px',
                                            ml: 1
                                        }}
                                        onClick={() => handleRoleChange(role)}
                                        disabled={!role}
                                    >Dodaj</LoadingButton>
                                    <LoadingButton
                                        loading={isLoadingRemove}
                                        type='button'
                                        color='error'
                                        variant='outlined'
                                        sx={{
                                            minWidth: 150,
                                            borderRadius: '15px',
                                            ml: 1
                                        }}
                                        onClick={() => handleRoleRemove(role)}
                                        disabled={!role}
                                    >Usuń</LoadingButton>
                                </Grid>

                                <Grid item sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}>
                                    <Button
                                        type='button'
                                        color='primary'
                                        variant='outlined'
                                        sx={{
                                            minWidth: 300,
                                            borderRadius: '15px',
                                            ml: 1
                                        }}
                                        onClick={() => handleCloseModal()}
                                    >Anuluj</Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
