import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store/configureStore';
import { signInUser } from './accountSlice';
import { toast } from "react-toastify";
import { User } from '../../app/models/user';

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {register, handleSubmit, formState: {isSubmitting, errors, isValid}} = useForm({
        mode: 'all'
    });

    async function submitForm(data: FieldValues) {
        try {
            const response = await dispatch(signInUser(data));
            const test = response.payload as User;
            if (test.completedData === false) { 
                toast.warning('Do rozpoczęcia współpracy uzupełnij dodatkowe dane');
                navigate('/account/checkoutAccount');
            } else {
                navigate('/'); // Nawigacja na stronę główną po zalogowaniu
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container component={Paper} maxWidth="sm" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4}}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Logowanie
            </Typography>
        <Box component="form" onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Nazwa użytkownika"
                    autoFocus
                    {...register('email', {required: 'Email jest wymagany'})}
                    error={!!errors.email}
                    helperText={errors?.email?.message?.toString()}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Hasło"
                    type="password"
                    {...register('password', {required: 'Hasło jest wymagane'})}
                    error={!!errors.password}
                    helperText={errors?.password?.message?.toString()}
                />
                <LoadingButton
                    disabled={!isValid} 
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Zaloguj
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <Link href="/account/forgotPassword" variant="body2">
                            Zapomniane hasło?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/register">
                            {"Nie masz konta? Zarejestruj się"}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}