import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import NotFound from "../../../app/errors/NotFound";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppTimeline, { Align, TimeLineElement } from "../../../app/components/AppTimeLine";
import { clientOrdersSelectors, fetchClientOrderAsync } from "./clientOrdersSlice";
import ClientHelpInOrderChat from "./ClientHelpInOrderChat";
import { Order } from "../../../app/models/order";
import agent from "../../../app/api/agent";
import { currencyFormat, formatToLocalDate, formatToLocalTime, getOrderStatusColor, getOrderStatusTitle } from "../../../app/util/util";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import AppReturn from "../../../app/components/AppReturn";
import ClientOrderScanUploadForm from "./ClientOrderScanUploadForm";
import DownloadAndPreviewButtons from "../../../app/components/AppDownloadAndPreviewButtons";
import { ParticipantsList } from "../../../app/components/Order/OrderParticipantsList";

export default function ClientOrderDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const order = useAppSelector(state => clientOrdersSelectors.selectById(state, id!));
    const { status: orderStatus } = useAppSelector(state => state.clientOrders);
    const [timeLineElements, setTimeLineElements] = useState<TimeLineElement[]>([]);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const isMobile = useMediaQuery('(max-width: 900px)');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!order) dispatch(fetchClientOrderAsync(parseInt(id!)))
    }, [id, dispatch, order])

    function getStatusIcon(currentState: number) {
        switch (currentState) {
            case 0:
                return <HourglassTopIcon />;
            case 1:
                return <HourglassTopIcon />;
            case 2:
                return <DoneIcon />;
            case 3:
                return <DoneIcon />;
            case 4:
                return <HelpOutlineIcon />;
            case 5:
                return <CancelIcon />;
            default:
                return <ErrorIcon />;
        }
    }

    useEffect(() => {
        const fetchHistory = async () => {
            if (order) {
                setLoading(true);
                try {
                    const historyOfOrder: Order[] = await agent.Client.Orders.getHistoryOfOrder(order.publicId);
                    const timelineData: TimeLineElement[] = [];
                    const statusTracker: { [key: number]: boolean } = {};
                    let isTestAvailableDisplayed = false; // Tracker for the first occurrence of isTestAvailable

                    historyOfOrder.forEach(h => {
                        const isFirstOccurrence = !statusTracker[h.orderState];
                        statusTracker[h.orderState] = true;

                        // Check for the first occurrence of isTestAvailable == true
                        let subtitle = isFirstOccurrence ? getOrderStatusTitle(h.orderState) : 'Edytowano';
                        if (h.isTestAvailable && !isTestAvailableDisplayed) {
                            subtitle = 'Udostępniono testy';
                            isTestAvailableDisplayed = true;
                        }

                        timelineData.push({
                            dateAndTimeToDisplay: new Date(h.updatingDateTime).toLocaleString(),
                            align: Align.Left,
                            header: h.updatingUserIsAdmin == true ? 'Administrator' : 'Klient',
                            subtitle: subtitle,
                            icon: getStatusIcon(h.orderState),
                            iconColor: getOrderStatusColor(h.orderState)
                        });
                    });

                    setTimeLineElements(timelineData);
                } catch (error) {
                    console.error("Failed to fetch investment history:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchHistory();
    }, [order]);

    if (orderStatus.includes('pending')) return <LoadingComponent />

    if (!order) return <NotFound />

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmCancel = async () => {
        setIsCancelling(true);
        try {
            await agent.Client.Orders.cancelOrder(order.publicId);
            toast.success('Zamówienie zostało anulowane.');
            dispatch(fetchClientOrderAsync(parseInt(id!)));
        } catch (error) {
            console.error('Wystąpił błąd podczas anulowania zamówienia:', error);
            toast.error('Nie udało się anulować zamówienia.');
        } finally {
            setIsCancelling(false);
            handleCloseDialog();
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Zamówienie #" + order.publicId} backPath="/client/orders" />

            {isMobile ? (
                <Button
                    onClick={handleOpenDialog}
                    sx={{ width: '100%', mb: 2 }}
                    color="error"
                    variant='outlined'
                    size='large'
                    disabled={order.orderState == 0 ? false : order.orderState == 1 ? false : true}
                >
                    Anuluj zamówienie
                </Button>
            ) : (
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        onClick={handleOpenDialog}
                        sx={{ m: 2 }}
                        color="error"
                        variant='outlined'
                        size='large'
                        disabled={order.orderState == 0 ? false : order.orderState == 1 ? false : true}
                    >
                        Anuluj zamówienie
                    </Button>
                </Box>
            )}

            <Grid container>
                <Grid item md={7} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Podstawowe informacje'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.customeClientServiceName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa wskazana przez Klienta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.serviceName}
                                    </Typography>
                                    <Typography variant="overline">
                                        bazowa nazwa
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {formatToLocalDate(order.orderDateTime.toString())} {formatToLocalTime(order.orderDateTime.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        data i godzina złożenia zamówienia
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {formatToLocalDate(order.orderDateTime.toString())} 
                                    </Typography>
                                    <Typography variant="overline">
                                        proponowana data realizacji egzaminu
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.trainingProgram}
                                    </Typography>
                                    <Typography variant="overline">
                                        wskazany program szkolenia (jeżeli dotyczy)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.clientComment}
                                    </Typography>
                                    <Typography variant="overline">
                                        komentarz Klienta
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Cena'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {currencyFormat(order.totalDiscountedPrice)}
                                    </Typography>
                                    <Typography variant="overline">
                                        wartość zamówienia
                                    </Typography>
                                    <Typography variant="h5">
                                        {currencyFormat(order.unitDiscountedPrice)}
                                    </Typography>
                                    <Typography variant="overline">
                                        cena jedostkowa
                                    </Typography>
                                    <Typography variant="h5">
                                        {order.totalItem}
                                    </Typography>
                                    <Typography variant="overline">
                                        liczba osób
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Dane klienta'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.userDisplayName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa Klienta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.userEmail}
                                    </Typography>
                                    <Typography variant="overline">
                                        email
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.userPhone}
                                    </Typography>
                                    <Typography variant="overline">
                                        telefon kontaktowy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.trainingProgram}
                                    </Typography>
                                    <Typography variant="overline">
                                        wskazany program szkolenia (jeżeli dotyczy)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.clientComment}
                                    </Typography>
                                    <Typography variant="overline">
                                        komentarz Klienta
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Dane Płatnika'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {order.payerClientName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa Płatnika
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.payerClientAddress}
                                    </Typography>
                                    <Typography variant="overline">
                                        adres Płatnika
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {order.payerClientNIP ? order.payerClientNIP : order.payerNIP}
                                    </Typography>
                                    <Typography variant="overline">
                                        NIP Płatnika
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Historia'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                                        <CircularProgress />
                                    </Box>
                                ) : <AppTimeline elements={timeLineElements} />}
                            </Grid>
                        </Grid>
                    </Card>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Korespondencja'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <Grid container>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>
                                <ClientHelpInOrderChat orderId={parseInt(id!)} />
                            </Grid>
                        </Grid>
                    </Card>
                    {order.solvedTestsPaths != null && order.solvedTestsPaths != '' ?
                        (
                            <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                                <CardHeader title='Lista uczestników'
                                    titleTypographyProps={{
                                        sx: { fontWeight: 'bold', color: 'secondary' }
                                    }} />
                                <Divider />
                                <CardContent>
                                    <ParticipantsList benefitients={order.benefitients} />
                                </CardContent>
                            </Card>
                        ) : ''}

                    {order.isTestAvailable && order.packageTestPath ? (
                        <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                            <CardHeader title='Pobierz arkusze egzaminacyjne'
                                titleTypographyProps={{
                                    sx: { fontWeight: 'bold', color: 'secondary' }
                                }} />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DownloadAndPreviewButtons order={{
                                            path: order.packageTestPath
                                        }} count={false} />
                                        <Typography variant="overline">
                                            egzamin
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : <Alert severity="info" sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        W tym miejscu możliwe będzie pobranie testów egzaminacyjnych.
                    </Alert>
                    }
                    
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Przesłane skany'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <DownloadAndPreviewButtons order={{
                                path: order.solvedTestsPaths
                            }} count={true} />
                        </CardContent>
                        <Divider />
                        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                sx={{ height: '40px' }}
                                variant='outlined'
                                onClick={handleOpen}
                                size="small"
                                title='Załącz'
                                color='success'
                            >
                                Załącz
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Anulowanie zamówienia"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {order.isPaid ? 'Twoje zamówienie zostało już opłacone, czy chcesz rozpocząć proces zapytania o anulowanie zamówienia?'
                            : 'Czy na pewno chcesz anulować to zamówienie?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box width='100%' display="flex" justifyContent='space-between'>
                        <Button onClick={handleCloseDialog} variant='outlined' sx={{ ml: 2 }}>Nie</Button>
                        <LoadingButton
                            onClick={handleConfirmCancel}
                            autoFocus
                            variant='outlined'
                            color='error'
                            loading={isCancelling}
                            sx={{ mr: 2 }}
                        >
                            Tak
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <ClientOrderScanUploadForm order={order} cancelUpload={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    )
}