import { makeStyles } from "tss-react/mui";

const sectionMargin = (margin: number) => (margin * 20);

export const appHomeStyles = makeStyles({ uniqId: 'home' })(theme => ({
    mainWrap: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      background: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    spaceBottom: {
      marginBottom: theme.spacing(20),
      [theme.breakpoints.down('lg')]: {
        marginBottom: sectionMargin(6)
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(10),
      }
    },
    spaceTop: {
      marginTop: theme.spacing(20),
      [theme.breakpoints.down('lg')]: {
        marginTop: sectionMargin(1)
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      }
    },
    spaceBottomShort: {
      marginBottom: theme.spacing(10),
    },
    spaceTopShort: {
      marginTop: theme.spacing(4.5),
    },
    containerWrap: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(10),
      },
      '& > section': {
        position: 'relative'
      }
    }
  }));