import { Box, Typography, Button, Card, CardContent, CardHeader, Divider, Grid, Paper } from "@mui/material";
import { Investment } from "../../../../app/models/investment";
import { LoadingButton } from "@mui/lab";
import AppTextInput from "../../../../app/components/AppTextInput";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { toast } from "react-toastify";
import agent from "../../../../app/api/agent";
import { setAdminInvestment } from "../adminInvestmentsSlice";
import AppNumberInput from "../../../../app/components/AppNumberInput";
import { useEffect } from "react";
import AppSelect from "../../../../app/components/AppSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppReturn from "../../../../app/components/AppReturn";
import { adminInvestmentValuationValidationSchema } from "../adminInvestmentValidation";
import { formatToLocalDate, formatToLocalTime } from "../../../../app/util/util";

interface Props {
    investment?: Investment;
    cancelEdit: () => void;
    handleCardSelectInvestmentValuation: (handledInvestment: Investment) => void;
}

interface FormData {
    state: number;
    price: number;
    publicComment?: string;
    internalComment?: string;
}

export default function AdminInvestmentValuation({ investment, cancelEdit }: Props) {
    const { control, reset, handleSubmit, formState: { isDirty, isSubmitting } } = useForm<FormData>({
        resolver: yupResolver(adminInvestmentValuationValidationSchema)
    });
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.account);

    useEffect(() => {
        if (investment && !isDirty) reset({
            ...investment,
            price: investment.price  // Ensure price is a string for form compatibility
        });
    }, [investment, reset, isDirty]);

    async function handleSubmitData(data: FormData) {
        try {
            const formattedData = {
                ...data,
                price: Number(data.price)  // Convert price back to number before submission
            };

            const response: Investment = await agent.Admin.Investments.update(formattedData);
            toast.success('Uaktualniono projekt inwestycyjny');
            dispatch(setAdminInvestment(response));
            cancelEdit();
        } catch (error) {
            console.log(error);
        }
    }

    function fillTestData() {
        if (investment?.state === 0) {
            const testData: Partial<Investment> = {
                id: investment?.id,
                publicId: investment?.publicId,
                state: 1,
                isActive: true,
                isLastModify: true,
                investmentName: investment?.investmentName,
                clientComment: investment?.clientComment,
                competition: investment?.competition,
                publicComment: "TestAdmin publicComment",
                internalComment: "TestAdmin internalComment",
                price: 1234,
                submissionDeadline: investment.submissionDeadline
            }
            reset(testData);
        }
    }

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn
                title={"Projekt inwestycyjny # " + investment?.publicId}
                cancelEdit={cancelEdit}
                fillTestData={!!user && user.roles?.includes('Admin') ? fillTestData : undefined}
                isAdmin={!!user && user.roles?.includes('Admin')}
            />

            <Grid container>
                <Grid item md={7} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: 2 }}>
                        <CardHeader title='Podstawowe informacje'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {investment?.investmentName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa wskazana przez Klienta
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {investment && formatToLocalDate(investment.investmentCreatedDateTime.toString())} {investment && formatToLocalTime(investment.investmentCreatedDateTime.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        data i godzina złożenia zamówienia
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {investment?.competition}
                                    </Typography>
                                    <Typography variant="overline">
                                        Rodzaj naboru/konkursu lub link do naboru
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {investment?.clientComment}
                                    </Typography>
                                    <Typography variant="overline">
                                        komentarz Klienta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {investment && formatToLocalDate(investment.submissionDeadline.toString())} {investment && formatToLocalTime(investment.submissionDeadline.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        termin składania wniosków
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* DANE KLIENTA */}
                <Grid item md={5} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: 2 }}>
                        <CardHeader title='Dane klienta'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {investment?.userDisplayName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa Klienta
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h6">
                                        {investment?.userEmail}
                                    </Typography>
                                    <Typography variant="overline">
                                        email
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5">
                                        {investment?.userPhone}
                                    </Typography>
                                    <Typography variant="overline">
                                        telefon kontaktowy
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* FORMULARZ */}
                <Grid item md={12} sm={12}>
                    <Box component={Paper} sx={{ p: 4 }}>
                        <form onSubmit={handleSubmit(handleSubmitData)}>
                            {investment ? (
                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">Nr projektu: {investment.publicId}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">Zamawiający: {investment.userDisplayName}</Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                    <Grid item xs={12} sm={6}>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='publicComment' label='Komentarz' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppNumberInput control={control} name='price' label='Wycena' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AppTextInput control={control} name='internalComment' label='Wewnętrzny komentarz (niewidoczny dla użytkownika)' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <AppSelect control={control} name='state' label='Akceptuj/Odrzuć' items={[{ label: 'Akceptuj', value: 1 }, { label: 'Odrzuć', value: 2 }]} />
                                </Grid>
                            </Grid>
                            <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                                <Button onClick={cancelEdit} variant='outlined' color='primary'>Anuluj</Button>
                                <LoadingButton loading={isSubmitting} disabled={!isDirty} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                            </Box>
                        </form>
                    </Box >
                </Grid>
            </Grid>
        </>
    )
}