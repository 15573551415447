import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../store/configureStore";
import { clientDocumentsSelectors, fetchClientDocumentsAsync} from "../../features/client/documents/clientDocumentsSlice";

export default function useDocuments() {
    const clientDocuments = useAppSelector(clientDocumentsSelectors.selectAll);
    const {clientDocumentsLoaded, clientDocumentsFiltersLoaded, metaData} = useAppSelector(state => state.clientDocuments);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!clientDocumentsLoaded) dispatch(fetchClientDocumentsAsync());
  }, [clientDocumentsLoaded, dispatch])

  return {
    clientDocuments,
    clientDocumentsLoaded,
    clientDocumentsFiltersLoaded,
    metaData
  }
}