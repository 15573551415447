import { Grid } from "@mui/material";
import { Order } from "../../../../app/models/order";
import { useAppSelector } from "../../../../app/store/configureStore";
import OrderCard from "./AdminOrderCard";
import OrderCardSkeleton from "./AdminOrderCardSkeleton";


interface Props {
    orders: Order[];
    handleCardSelectOrder?: (handledOrder: Order) => void;
    handleCardSelectAddInvoice?: (handledOrder: Order) => void;
}

export default function AdminOrdersList({ orders, handleCardSelectOrder, handleCardSelectAddInvoice }: Props) {
    const { adminOrdersLoaded } = useAppSelector(state => state.adminOrders);
    return (
        <Grid container spacing={4} >
            {orders.map(order => (
                <Grid key={order.publicId} item xs={12} sm={12} md={6}>
                    {!adminOrdersLoaded ? (
                        <OrderCardSkeleton />
                    ) : (
                        <OrderCard order={order}
                            handleCardSelectOrder={handleCardSelectOrder}
                            handleCardSelectAddInvoice={handleCardSelectAddInvoice}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}