import { Card, CardHeader, Avatar, CardContent, Typography, CardActions, Button, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/store/configureStore";
import { About } from "../../app/models/about";
import { selectCurrentUser } from "../account/accountSlice";
import { styled } from "@mui/material/styles";

interface Props {
    about: About;
    displayName: React.ReactNode;
    link: string;
    description: string;
    icon: React.ReactNode;
    iconBgColor: React.ReactNode;
    aboutsCount: number; // Ilość obiektów About
    onSortOrderChange: (newSortOrder: number) => void; // Funkcja do zmiany SortOrder
    currentSortOrder: number; // Aktualna wartość SortOrder dla tej karty
    handleCardSelectAbout: (handledAbout: About) => void;
}

const StyledCard = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Zapewnia, że treść i akcje karty są równomiernie rozłożone.
    height: '100%', // Zapewnia, że karty rozciągają się, aby wypełnić dostępną wysokość.
    width: '100%', // Zapewnia, że karty rozciągają się, aby wypełnić dostępną szerokość w swoim kontenerze.
  });


export default function AboutPageCard({ about, displayName, link, description, icon, iconBgColor, aboutsCount, onSortOrderChange, currentSortOrder, handleCardSelectAbout }: Props) {
    const currentUser = useAppSelector(selectCurrentUser);
    const isAdmin = currentUser?.roles?.includes('Admin');

    const handleChange = (event: SelectChangeEvent) => {
        const valueAsNumber = Number(event.target.value);
        onSortOrderChange(valueAsNumber);
    };

    return (
        <StyledCard>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: `${iconBgColor}`, width: 56, height: 56 }}>
                        {icon}
                    </Avatar>
                }
                title={displayName}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
            />
            <CardContent>
                <Typography gutterBottom color='secondary' variant="h5">{description}</Typography>
            </CardContent>
            <CardActions>
                <Button variant="outlined" component={Link} to={link} size="small">Przejdź</Button>
                {isAdmin &&
                    <>
                        <Button
                            variant="outlined"
                            color="warning"
                            onClick={() => handleCardSelectAbout!(about)}
                            size="small"
                            sx={{ ml: 2 }}
                        >
                            Edytuj
                        </Button>

                        <Select
                            value={currentSortOrder !== undefined ? currentSortOrder.toString() : ''}
                            onChange={handleChange}
                            displayEmpty
                            size="small"
                            style={{ marginLeft: 'auto' }}
                        >
                            {Array.from({ length: aboutsCount }, (_, index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                }
            </CardActions>
        </StyledCard>
    )
}