import { Button, Card, CardContent, CardHeader, Divider, Grid, Paper, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import NotFound from "../../../app/errors/NotFound";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { fetchDocumentAsync, documentsSelectors, setDocument } from "./documentsSlice";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import AppReturn from "../../../app/components/AppReturn";
import { formatToLocalDate, formatToLocalTime } from "../../../app/util/util";
import agent from '../../../app/api/agent'; // Import the API agent for making requests
import saveAs from "file-saver";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DocumentForm from "./DocumentForm";

export default function DocumentDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const document = useAppSelector(state => documentsSelectors.selectById(state, id!));
    const { status: documentStatus } = useAppSelector(state => state.documents);

    const [editMode, setEditMode] = useState(false);
    const [openSignDialog, setOpenSignDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const url = document?.filePath;
    const extension = '.' + url?.split('.').pop();

    useEffect(() => {
        if (!document) dispatch(fetchDocumentAsync(parseInt(id!)));
    }, [id, dispatch, document]);

    if (documentStatus.includes('pending')) return <LoadingComponent />;
    if (!document) return <NotFound />;

    const updatingDate = new Date(document.updatingDateTime);
    const creationDate = new Date(document.dateCreated);

    const isSameMinute = updatingDate.getFullYear() === creationDate.getFullYear() &&
        updatingDate.getMonth() === creationDate.getMonth() &&
        updatingDate.getDate() === creationDate.getDate() &&
        updatingDate.getHours() === creationDate.getHours() &&
        updatingDate.getMinutes() === creationDate.getMinutes();

    // Funkcja do pobierania dokumentu
    const handleOpenDocument = async (documentId: number, isSigned: boolean) => {
        try {
            const response = await agent.Admin.Documents.getPreSignedUrl(documentId);
            const preSignedUrl = isSigned ? response.preSignedSignedUrl : response.preSignedUrl;
            window.open(preSignedUrl, '_blank');
        } catch (error) {
            console.error("Error fetching pre-signed URL", error);
        }
    };

    // Funkcja do pobierania dokumentu
    const handleDownloadDocument = async (documentId: number, isSigned: boolean) => {
        try {
            const response = await agent.Admin.Documents.downloadDocument(documentId, isSigned);

            if (response && response.data) {
                const contentType = response.headers['content-type'] || 'application/octet-stream';
                const file = new Blob([response.data], { type: contentType });
                const fileName = document.name + (isSigned ? '_podpisany' : '') + extension;
                saveAs(file, fileName);
            } else {
                console.error('No response data found.');
            }
        } catch (error) {
            console.error("Error downloading document:", error);
            alert("Failed to download document. Please try again.");
        }
    };

    // Obsługa podpisywania dokumentu
    const handleOpenSignDialog = () => {
        setOpenSignDialog(true);
    };

    const handleCloseSignDialog = () => {
        setOpenSignDialog(false);
    };

    const handleConfirmSignDialog = async () => {
        setLoading(true);
        try {
            const response = await agent.Admin.Documents.addSignatureByAdmin(document.id);
            if (response) {
                toast.success('Podpisano dokument');
                dispatch(setDocument(response));
            } else {
                toast.error('Nie udało się podpisać dokumentu. Prawdopodobnie plik jest za duży lub nieprzystosowany do podpisania.');
                navigate('/admin/documents');
            }
        } catch (error) {
            toast.error('Błąd podczas podpisywania dokumentu.');
        }
        setLoading(false);
        handleCloseSignDialog();
    };

    // Obsługa edycji dokumentu
    const handleSelectDocument = () => {
        setEditMode(true);
    };

    if (editMode) return <DocumentForm document={document} cancelEdit={() => setEditMode(false)} />

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Dokument #" + document.id} backPath="/admin/documents" />

            <Grid container>
                <Grid item md={12} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader
                            title={
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 'bold', color: 'secondary' }}>Podstawowe informacje</Typography>
                                    <Box>
                                        <Button
                                            variant="outlined"
                                            color="warning"
                                            onClick={handleSelectDocument}
                                            sx={{ mr: 1 }}
                                        >
                                            Edycja
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color={document.isSignedByAdmin ? 'error' : 'inherit'}
                                            onClick={handleOpenSignDialog}
                                        >
                                            {document.isSignedByAdmin ? 'Podpisz ponownie' : 'Podpisz'}
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                {/* Podstawowe informacje */}
                                <Grid item xs={12}>
                                    <Typography variant="h5">{document.name}</Typography>
                                    <Typography variant="overline">nazwa wskazana przez Klienta</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{formatToLocalDate(document.dateCreated.toString())} {formatToLocalTime(document.dateCreated.toString())}</Typography>
                                    <Typography variant="overline">data i godzina dodania</Typography>
                                </Grid>
                                {!isSameMinute && (
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{formatToLocalDate(document.updatingDateTime.toString())} {formatToLocalTime(document.updatingDateTime.toString())}</Typography>
                                        <Typography variant="overline">data i godzina ostatniej edycji</Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Typography variant="h6">{document.comment}</Typography>
                                    <Typography variant="overline">komentarz</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{document.userId}</Typography>
                                    <Typography variant="overline">id użytkownika</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{document.userEmail}</Typography>
                                    <Typography variant="overline">email użytkownika</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{document.userDisplayName}</Typography>
                                    <Typography variant="overline">imię i nazwisko użytkownika</Typography>
                                </Grid>
                                {/* Pobieranie i podgląd dokumentów */}
                                <Grid item xs={12} sm={12}>
                                    {document.filePath ? (
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Dokument bez podpisu:</Typography>
                                            <Button
                                                variant='contained'
                                                size="small"
                                                onClick={() => handleDownloadDocument(document.id, false)}
                                                sx={{ mt: 2 }}
                                            >
                                                Pobierz dokument
                                            </Button><br />
                                            <Button
                                                variant='contained'
                                                size="small"
                                                onClick={() => handleOpenDocument(document.id, false)}
                                                sx={{ mt: 2 }}
                                            >
                                                Zobacz dokument
                                            </Button><br />
                                            <Divider sx={{ width: '50%', my: 2 }} />
                                            {document.signedFilePath && (
                                                <>
                                                    <Typography variant="h6">Dokument podpisany:</Typography>
                                                    <Button
                                                        variant='contained'
                                                        size="small"
                                                        onClick={() => handleDownloadDocument(document.id, true)}
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Pobierz podpisany dokument
                                                    </Button><br />
                                                    <Button
                                                        variant='contained'
                                                        size="small"
                                                        onClick={() => handleOpenDocument(document.id, true)}
                                                        sx={{ mt: 2 }}
                                                    >
                                                        Zobacz podpisany dokument
                                                    </Button><br />
                                                </>
                                            )}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={6}>
                                            <ReportGmailerrorredIcon /><br />
                                            <Typography variant="overline" sx={{ color: 'red' }}>Brak pliku</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Dialog podpisywania dokumentu */}
            <Dialog open={openSignDialog} onClose={handleCloseSignDialog}>
                <DialogTitle>Podpisz dokument</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {document.isSignedByAdmin ? 'Ten dokument był już podpisywany. Czy chcesz go podpisać ponownie?' : 'Czy na pewno chcesz podpisać dokument?'}
                        {document.isSignedByClient && <Alert sx={{ mt: 2 }} severity="warning">Klient podpisał już ten dokument. Jeżeli podpiszesz dokument ponownie klient będzie musiał złożyć podpis ponownie.</Alert>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="error" onClick={handleCloseSignDialog}>Anuluj</Button>
                    <LoadingButton
                        variant='outlined'
                        color='success'
                        onClick={handleConfirmSignDialog}
                        loading={loading}
                    >
                        Podpisz
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}