import { Controller, FieldValues, Control, FieldArrayWithId, UseFormWatch } from "react-hook-form";
import { Grid, Typography, Button, TextField, Divider, Stack, IconButton } from "@mui/material";
import { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import DateInput from "./AppDateInput";

interface AppStringConnectorProps {
    control: Control<FieldValues>;
    remove: (index?: number | number[] | undefined) => void;
    fields: Partial<FieldArrayWithId<FieldValues, "id">>[];
    append: (value: FieldValues | FieldValues[]) => void;
    dataToLoad?: { firstName: string, lastName: string, pesel: string, birthPlace: string, birthDate: string }[];
    customBackgroundColor?: string;
    setValue?: (name: string, value: string) => void;
    watch?: UseFormWatch<FieldValues>;
    label: string;
    handleSetTotalItemRows: (count: number) => void;
}

export const AppOrderBenefitientsStringsConnector: React.FC<AppStringConnectorProps> = ({ control, remove, fields, append, dataToLoad, customBackgroundColor, watch, label, handleSetTotalItemRows }) => {
    useEffect(() => {
        handleSetTotalItemRows(fields.length);
    },[fields.length,handleSetTotalItemRows])
    
    useEffect(() => {
        if (dataToLoad) {
            dataToLoad.forEach(data => {
                console.log(data);
                append(data);
            });
        } else {
            append({ firstName: "", lastName: "", pesel: "", birthPlace: "", singleTaskGroupNumber: "", birthDate: "", singleTaskGroupFrequency: "" });
        }
    }, [append, dataToLoad]);

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5">{label}</Typography>
            </Grid>

            {fields.map((field, index) => (

                <Grid spacing={1} container key={field.id} sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ ml: 1 }}>{index + 1}.</Typography>
                    <Grid item xs={12} md={2}>
                        <Controller
                            control={control}
                            name={`rows.${index}.firstName`}
                            render={({ field }) => (
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/[~;]/g, '');
                                        field.onChange(e);
                                    }}
                                    placeholder="Imię"
                                    sx={{ backgroundColor: customBackgroundColor }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Controller
                            control={control}
                            name={`rows.${index}.lastName`}
                            render={({ field }) => (
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/[~;]/g, '');
                                        field.onChange(e);
                                    }}
                                    placeholder="Nazwisko"
                                    sx={{ backgroundColor: customBackgroundColor }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Controller
                            control={control}
                            name={`rows.${index}.pesel`}
                            render={({ field }) => (
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/[~;]/g, '');
                                        field.onChange(e);
                                    }}
                                    placeholder="Pesel"
                                    sx={{ backgroundColor: customBackgroundColor }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Controller
                            control={control}
                            name={`rows.${index}.birthPlace`}
                            render={({ field }) => (
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/[~;]/g, '');
                                        field.onChange(e);
                                    }}
                                    placeholder="Miejsce urodzenia"
                                    sx={{ backgroundColor: customBackgroundColor }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <DateInput
                            name={`rows.${index}.birthDate`}
                            control={control}
                            label="Data urodzenia"
                            disabled={watch?.(`rows.${index}.birthDate`) ?? false}
                        />
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" flexWrap="nowrap">
                            <Controller
                                control={control}
                                name={`rows.${index}.certId`}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        {...field}
                                        disabled={true}
                                        placeholder="Certyfikat"
                                        sx={{ backgroundColor: customBackgroundColor }}
                                    />
                                )}
                            />
                            <IconButton
                                size="small"
                                color="error"
                                onClick={() => {
                                    remove(index);
                                }}
                                disabled={fields.length === 1}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    </Grid>
                    {index + 1 !== fields.length && (
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ my: 3 }} />
                        </Grid>
                    )}
                </Grid >
            ))}
            <Button
                variant="outlined"
                onClick={() => { append({ firstName: "", lastName: "", pesel: "", birthPlace: "", birthDate: "" }); }}
                sx={{ mt: 3 }}>
                Dodaj Uczestnika
            </Button>
        </>
    );
};