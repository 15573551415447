import { Grid, Divider, Typography, Box } from "@mui/material";
import AppDatePicker from "../../../../app/components/AppDatePicker";
import AppTextInput from "../../../../app/components/AppTextInput";
import { Control } from "react-hook-form";

interface Props {
    control: Control<any>;
}

export default function AgreementIsServiceContract({ control }: Props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Typography variant="h4">
                    § 1
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control as any} name='serviceConsistingOf' label='Zleceniodawca zleca, a Zleceniobiorca przyjmuje do wykonania usługę w zakresie/ polegającą na' />
            </Grid>
            <Grid item xs={2} sm={2}>
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <Typography variant="h5">
                        W okresie:
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={5} sm={5}>
                <AppDatePicker control={control as any} name='dateStart' label='od' disabled={false} />
            </Grid>
            <Grid item xs={5} sm={5}>
                <AppDatePicker control={control as any} name='dateEnd' label='do' disabled={false} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control as any} name='changeTheSizeOfServices' label='Zamawiający może zmienić wielkość usług. Wielkość ta nie może jednak wzrosnąć lub zmaleć o' />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <Typography variant="h5">
                        Zmiany, o których mowa ..., mogą nastąpić po pisemnym zawiadomieniu Wykonawcy, przesłanym na
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3} sm={3}>
                <AppTextInput control={control as any} name='numberOfDaysBeforeIntroduction' label='dni' />
            </Grid>
            <Grid item xs={9} sm={9}>
                <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                    <Typography variant="h5">
                        przed terminem ich wprowadzenia.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control as any} name='servicesDoNotInclude' label='Usługi nie obejmują' />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Typography variant="h4">
                    § 2
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                    1. Do obowiązków Zleceniodawcy należy:
                </Typography>
                <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>terminowe przekazywanie Zleceniobiorcy wynagrodzenia według zasad ustalonych w § 3;</li>
                    <li>udostępnienie Zleceniobiorcy niezbędnych informacji/dokumentacji/materiałów w celu realizacji usług wynikających z niniejszej umowy;</li>
                    <li style={{ display: "flex", alignItems: "center", marginLeft: "-17px", marginTop: '10px' }}>
                        <Typography variant="body1" component="span" style={{ marginRight: "5px" }}>c.</Typography>
                        <AppTextInput control={control as any} name='principalsResponsibilities' label='obowiązki Zleceniodawcy (opcjonalne)' />
                    </li>
                </ol>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                    2. Do obowiązków Zleceniobiorcy należy:
                </Typography>
                <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>wykonanie usług objętych niniejszą umową z zachowaniem należytej staranności;</li>
                    <li style={{ display: "flex", alignItems: "center", marginLeft: "-17px", marginTop: '10px' }}>
                        <Typography variant="body1" component="span" style={{ marginRight: "5px"}}>b.</Typography>
                        <AppTextInput control={control as any} name='contractorsResponsibilities' label='obowiązki Zleceniobiorcy (opcjonalne)' />
                    </li>
                </ol>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Typography variant="h4">
                    § 3
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                    1. Wynagrodzenie Zleceniobiorcy, zwane dalej „Wynagrodzeniem”, z tytułu wykonania czynności opisanych
                    w §1 Umowy wynosi:
                </Typography>
                <Typography variant="h6">
                    2. Kwota, o której mowa w § 3 ust. 1 wypłacana będzie w następujących ratach:
                </Typography>

                <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li style={{ display: "flex", alignItems: "center", marginLeft: "-17px" }}>
                        <Typography variant="body1" component="span" style={{ marginRight: "5px" }}>a.</Typography>
                        <Grid item xs={2} sm={2}>
                            <AppTextInput control={control as any} name='prepayment' label='zł netto' />
                        </Grid>
                        <Grid item xs={10} sm={10}>
                            <Typography variant="body1" component="p" style={{ marginLeft: "10px" }}>
                                zł netto (słownie złotych: zł 00/100) plus należny podatek VAT - przedpłata bezzwrotna
                                płatna po podpisaniu umowy w ciągu 7 dni od daty wystawienia faktury;
                            </Typography>
                        </Grid>
                    </li>
                    <li style={{ display: "flex", alignItems: "center", marginLeft: "-17px", marginTop: '10px' }}>
                        <Typography variant="body1" component="span" style={{ marginRight: "5px" }}>b.</Typography>
                        <Grid item xs={2} sm={2}>
                            <AppTextInput control={control as any} name='payment' label='zł netto' />
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            <Typography variant="body1" component="p" style={{ marginLeft: "10px" }}>
                                zł netto (słownie złotych: zł 00/100) plus należny podatek VAT - płatne w ciągu 7 dni od
                                daty wystawienia faktury, po przekazaniu
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <AppTextInput control={control as any} name='transferOf' label='po przekazaniu' />
                        </Grid>
                    </li>
                </ol>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Typography variant="h4">
                    § 4
                </Typography>
            </Grid>
            <Grid item xs={9} sm={9}>
                <Typography variant="h6">
                    1. Jeżeli Zleceniobiorca nie wykonuje lub nienależycie realizuje umowę, naruszając obowiązki określone w
                    umowie, Zleceniodawca wezwie Zleceniobiorcę, aby w ciągu
                </Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
                <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                    <AppTextInput control={control as any} name='principalWillCallContractorIn' label='dni' />,
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                    od otrzymania pisemnego wezwania wypełnił nałożone na niego obowiązki. Po bezskutecznym upływie wyznaczonego terminu Zleceniodawca
                    może odstąpić od umowy.
                </Typography>
            </Grid>
            <Grid item xs={9} sm={9}>
                <Typography variant="h6">
                    2. Jeżeli Zleceniodawca nie wykonuje lub nienależycie wykonuje umowę, naruszając swoje obowiązki określone
                    w niniejszej umowie, Zleceniobiorca wezwie Zleceniodawcę, aby w ciągu
                </Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
                <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                    <AppTextInput control={control as any} name='contractorWillCallPrincipalIn' label='dni' />,
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                    od otrzymania pisemnego wezwania dokonał zapłaty należnego mu wynagrodzenia. Po bezskutecznym upływie
                    wyznaczonego terminu Wykonawca może odstąpić od umowy.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Typography variant="h4">
                    § 10
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6" marginBottom={2}>
                    1. Integralną część Umowy stanowią następujące dokumenty:
                </Typography>
                <AppTextInput control={control as any} name='attachments' label='załącznik01; załącznik02; ...' />
            </Grid>
        </Grid>
    )
}