import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import AppPagination from "../../../app/components/AppPagination";
import RadioButtonGroup from "../../../app/components/AppRadioButtonGroup";
import useServices from "../../../app/hooks/useServices";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Service } from "../../../app/models/service";
import { useAppSelector, useAppDispatch, RootState } from "../../../app/store/configureStore";
import { setFooterInvisible, setFooterVisible } from "../../../app/util/util";
import ServiceForm from "../ServiceForm";
import { setServicesParams, setServicesPageNumber } from "../servicesSlice";
import ServicesList from "./ServicesList";
import AppReturn from "../../../app/components/AppReturn";
import AppUniversalSearch from "../../../app/components/AppUniversalSearch";

const sortOptions = [
  { value: '_', label: 'Id usługi: \u2193' },
  { value: 'id', label: 'Id usługi: \u2191' },
  { value: 'name', label: 'Nazwa: A - Z \u2191' },
  { value: 'nameDesc', label: 'Nazwa: Z - A \u2193' }
]

export default function Services() {
  const { services, servicesFiltersLoaded, possibleSubservices, metaData } = useServices();
  const [editMode, setEditMode] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | undefined>(undefined);
  const { servicesParams } = useAppSelector(state => state.services);
  const dispatch = useAppDispatch();

  function cancelEdit() {
    if (selectedService) setSelectedService(undefined);
    setEditMode(false);
  }

  function handleCardSelectService(service: Service) {
    setSelectedService(service);
    setEditMode(true);
  }

  if (!servicesFiltersLoaded) { setFooterInvisible(); return <LoadingComponent /> }
  setFooterVisible();

  if (editMode) return <ServiceForm service={selectedService} cancelEdit={cancelEdit} />

  return (
    <>
      <AppBreadcrumbs />
      <AppReturn title={"Usługi egzaminacyjne"} setFunction={() => setEditMode(true)} />

      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ mb: 2 }}>
            <AppUniversalSearch
              sliceName="services"
              searchParamsSelector={(state: RootState) => state.services.servicesParams}
              setSearchParams={setServicesParams}
              label='Szukaj po nazwie usługi'
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <RadioButtonGroup
              selectedValue={servicesParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setServicesParams({ orderBy: e.target.value }))}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <ServicesList
            services={services}
            handleCardSelectService={handleCardSelectService}
            possibleSubservices={possibleSubservices}
          />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9}>
          {metaData &&
            <AppPagination
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setServicesPageNumber({ pageNumber: page }))}
            />}
        </Grid>
      </Grid>
    </>
  )
}