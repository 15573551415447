import { useEffect } from "react";
import { adminOrdersSelectors, fetchAdminOrdersAsync, fetchAdminOrdersFilters } from "../../features/admin/orders/adminOrdersSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useAdminOrders() {
    const adminOrders = useAppSelector(adminOrdersSelectors.selectAll);
    const {adminOrdersLoaded, adminOrdersFiltersLoaded, metaData} = useAppSelector(state => state.adminOrders);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!adminOrdersLoaded) dispatch(fetchAdminOrdersAsync());
  }, [adminOrdersLoaded, dispatch])

  useEffect(() => {
    if (!adminOrdersFiltersLoaded) dispatch(fetchAdminOrdersFilters());
  }, [adminOrdersFiltersLoaded, dispatch])

  return {
    adminOrders,
    adminOrdersLoaded,
    adminOrdersFiltersLoaded,
    //brands,
    //types,
    metaData
  }
}