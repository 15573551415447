import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Service, ServicesParams } from "../../app/models/service";
import { RootState } from "../../app/store/configureStore";
import AutocompleteInputOption from "../../app/models/autocompleteInputOption";

interface ServicesState {
    servicesLoaded: boolean;
    servicesFiltersLoaded: boolean;
    status: string;
    possibleSubservices: AutocompleteInputOption[];
    possibleSubservicesLoaded: boolean;
    servicesParams: ServicesParams;
    metaData: MetaData | null;
    topServices: Service[];
}

const servicesAdapter = createEntityAdapter<Service>();

function getAxiosParams(servicesParams: ServicesParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', servicesParams.pageNumber.toString());
    params.append('pageSize', servicesParams.pageSize.toString());
    params.append('orderBy', servicesParams.orderBy);
    if (servicesParams.searchTerm) params.append('searchTerm', servicesParams.searchTerm);
    return params;
}

export const fetchServicesAsync = createAsyncThunk<Service[], void, {state: RootState} >(
    'services/fetchServicesAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().services.servicesParams);
        try {
            const response = await agent.Services.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
);

export const fetchPossibleSubservices = createAsyncThunk(
    'services/fetchPossibleSubservices',
    async (_, thunkAPI) => {
        try {
            return agent.Subservices.fetchPossibleSubservices();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchServiceAsync = createAsyncThunk<Service, number>(
    'services/fetchServiceAsync',
    async (serviceId, thunkAPI) => {
        try {
            return await agent.Services.details(serviceId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchServicesFilters = createAsyncThunk(
    'services/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Services.fetchServicesFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchTopServicesAsync = createAsyncThunk<Service[]>(
    'services/fetchTopServicesAsync',
    async (_, thunkAPI) => {
        try {
            return await agent.Services.getTopThree();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const fetchSubservicesFromService = createAsyncThunk<Service[], string>(
    'services/fetchSubservicesFromService',
    async (subservicesIds, thunkAPI) => {
        try {
            return await agent.Services.getSubservicesFromService(subservicesIds);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: '_'
    }
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState: servicesAdapter.getInitialState<ServicesState>({
        servicesLoaded: false,
        servicesFiltersLoaded: false,
        status: 'idle',
        possibleSubservices: [],
        possibleSubservicesLoaded: false,
        servicesParams: initParams(),
        metaData: null,
        topServices: []
    }),
    reducers: {
        setServicesParams: (state, action) => {
            state.servicesLoaded = false;
            state.servicesParams = {...state.servicesParams, ...action.payload, pageNumber: 1};
        },
        setServicesPageNumber: (state, action) => {
            state.servicesLoaded = false;
            state.servicesParams = {...state.servicesParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetServicesParams: (state) => {
            state.servicesParams = initParams();
        },
        setService: (state, action) => {
            servicesAdapter.upsertOne(state, action.payload);
            state.servicesLoaded = false;
        },
        removeService: (state, action) => {
            servicesAdapter.removeOne(state, action.payload);
            state.servicesLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchServicesAsync.pending, (state) => {
                state.status = 'pendingFetchServices';
            });
            builder.addCase(fetchServicesAsync.fulfilled, (state, action) => {
                servicesAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.servicesLoaded = true;
            });
            builder.addCase(fetchServicesAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchServiceAsync.pending, (state) => {
                state.status = 'pendingFetchService';
            });
            builder.addCase(fetchServiceAsync.fulfilled, (state, action) => {
                servicesAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchServiceAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchServicesFilters.pending, (state) => {
                state.status = 'pendingFetchServicesFilters';
            });
            builder.addCase(fetchServicesFilters.fulfilled, (state) => {
                state.servicesFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchServicesFilters.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchPossibleSubservices.pending, (state) => {
                state.status = 'pendingFetchPossibleSubservices'
            })
            builder.addCase(fetchPossibleSubservices.fulfilled, (state, action) => {
                state.possibleSubservices = action.payload;
                state.possibleSubservicesLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchPossibleSubservices.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchTopServicesAsync.fulfilled, (state, action) => {
                // Tutaj możesz dodać logikę, np. zapisywanie pobranych usług w stanie
                state.topServices = action.payload;
            });

            builder.addCase(fetchSubservicesFromService.pending, (state) => {
                state.status = 'pendingFetchSubservices';
            });
            builder.addCase(fetchSubservicesFromService.fulfilled, (state) => {
                // Tutaj możesz dodać logikę aktualizacji stanu, na przykład aktualizację listy subservices
                state.status = 'idle';
            });
            builder.addCase(fetchSubservicesFromService.rejected, (state) => {
                state.status = 'idle';
            });            
        }
    )
})

export const servicesSelectors = servicesAdapter.getSelectors((state: RootState) => state.services);

export const {setServicesParams, resetServicesParams, setMetaData, setServicesPageNumber, setService, removeService} = servicesSlice.actions;