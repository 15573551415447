import { Grid } from "@mui/material";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import ClientPageCard from "./ClientZonePageCard";

export default function ClientZonePages() {
    const pages = [
        { displayName: 'Moje usługi egzaminacyjne', link: "/client/orders", description: 'Moje usługi egzaminacyjne', icon: 'SchoolIcon', iconBgColor: 'secondary.dark' },
        { displayName: 'Moje projekty inwestycyjne', link: "/client/investments", description: 'Moje projekty inwestycyjne', icon: 'AddBusinessIcon', iconBgColor: 'primary.main' },
        { displayName: 'Moje dokumenty', link: "/client/documents", description: 'Moje dokumenty', icon: 'DescriptionIcon', iconBgColor: 'green' },
        { displayName: 'Moje dane i ustawienia konta', link: "/account/editAccount", description: 'Moje dane i ustawienia konta', icon: 'PersonIcon ', iconBgColor: 'error.light' }
    ]
    return (
        <>
            <AppBreadcrumbs />
            <Grid container columnSpacing={4}>
                <Grid item xs={12} >
                    <Grid container spacing={4} >
                        {pages.map((item) => (
                            <Grid key={item.link} item xs={12} sm={6} >
                                <ClientPageCard displayName={item.displayName} link={item.link} description={item.description} icon={item.icon} iconBgColor={item.iconBgColor} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}