import { Typography, Grid, Alert, Button } from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Order, PaymentOrderState } from "../../models/order";
import AppAutocomplete from "../AppAutocomplete";
import AutocompleteInputOption from "../../models/autocompleteInputOption";
import AppTextInput from "../AppTextInput";
import AppDatePicker from "../AppDatePicker";
import AppSelect from "../AppSelect";
import { OrderFormValues } from "../../models/orderFormValues";

interface Props {
    order: Order | undefined;
    control: Control<OrderFormValues> | undefined;
    possibleServices: AutocompleteInputOption[];
    watch: UseFormWatch<OrderFormValues>;
    handleOpenModal: () => void;
    setManualServiceChange: () => void;
    isAdmin: boolean;
}

export default function OrderFormBasicInformation({ order, control, possibleServices, watch, handleOpenModal, setManualServiceChange, isAdmin }: Props) {
    const location = useLocation();
    const selectedServiceId = watch('serviceId');
    const [serviceHasNoTestPath, setServiceHasNoTestPath] = useState<boolean>(false);

    useEffect(() => {
        const selectedService = possibleServices.find(service => service.id === selectedServiceId);
        setServiceHasNoTestPath(selectedService ? !selectedService.packageTestPath : false);
    }, [selectedServiceId, possibleServices]);

    const paymentOrderStateLabels = {
        [PaymentOrderState.PENDING]: 'Oczekujące',
        [PaymentOrderState.WAITING_FOR_CONFIRMATION]: 'Oczekuje na potwierdzenie',
        [PaymentOrderState.COMPLETED]: 'Zakończone',
        [PaymentOrderState.CANCELED]: 'Anulowane',
    };

    const paymentOrderStateOptions = Object.entries(PaymentOrderState)
        .filter(([, value]) => typeof value === 'number')
        .map(([, value]) => ({ label: paymentOrderStateLabels[value as PaymentOrderState], value: value as number }));

    const isPathAdminOrders = location.pathname.startsWith('/admin/orders');

    return (
        <>
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Podstawowe dane o egzaminie</Typography>
            <Grid container spacing={2} marginBottom={3}>
                {order && (
                    <>
                        <Grid item xs={12}>
                            <AppSelect<OrderFormValues>
                                control={control}
                                name='paymentOrderState'
                                label='Stan płatności'
                                items={paymentOrderStateOptions}
                                disabled={!isPathAdminOrders}
                            />
                        </Grid>
                        {isAdmin && !order.packageTestPath && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {'Brak testów egzaminacyjnych w usłudze "'}<a href={'/services/' + order.serviceId}>{order.serviceName}</a>." Brak możliwości udostępnienia testów klientowi.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Typography variant="h6">Nr zamówienia: {order.publicId}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6">Zamawiający: {order.userDisplayName}</Typography>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <AppAutocomplete<OrderFormValues>
                        control={control}
                        name='serviceId'
                        label='Wskaż egzamin'
                        items={possibleServices}
                        selectedids={order?.serviceId.toString()}
                        onChange={setManualServiceChange}
                    />
                </Grid>
                {serviceHasNoTestPath && isAdmin && (
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Alert severity="warning">
                            Wybrana usługa nie posiada wzoru arkusza egzaminacyjnego. Udostępnienie testów będzie możliwe po dodaniu arkusza do odpowiedniej usługi.
                        </Alert>
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={3} marginBottom={3}>
                <Grid item xs={12}>
                    <AppTextInput control={control} name='customeClientServiceName' label='Nazwa egzaminu, jaka ma pojawić się na certyfikatach i protokołach' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppDatePicker control={control} name='preposedDateTime' label='Proponowana data realizacji' disabled={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AppTextInput control={control} name='placeOfServiceAndAnotherInformations' label='Miejsce realizacji (ulica, nr budynku/lokalu, miejscowość)' />
                </Grid>
                <Grid item xs={12}>
                    <AppTextInput control={control} name='clientComment' label='Twój komentarz/uwagi' />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AppTextInput control={control} name='trainingProgram' label='Program szkolenia' multiline rows={3} />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">Edytuj poniższe pole w sytuacji, gdy oczekujesz istotnie odmiennego opisu efektów kształcenia, niż widniejące we wzorze</Alert>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" sx={{ mt: 2 }}>Efekty kształcenia:</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {watch('educationEffects') ? watch('educationEffects')?.split(';').map((effect: string, index: number) => (
                        <Typography key={index}>{index + 1}. {effect}</Typography>
                    )) : null}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button variant="outlined" onClick={handleOpenModal}>Edytuj Efekty Kształcenia</Button>
                </Grid>
            </Grid>
        </>
    );
}