import { Typography, Grid } from "@mui/material";
import { currencyFormat, getPolishLiteral } from "../../util/util";

interface Props {
    unitDiscountedPrice: number;
    totalItem: number;
    sum: number;
}

export default function OrderValueBox({unitDiscountedPrice, totalItem, sum}:Props) {
    return (
        <>
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Wartość zamówienia</Typography>
            <Grid container spacing={3} marginBottom={3}>
                <Grid item xs={12}>
                    <Typography>
                        {currencyFormat(sum)} ( {currencyFormat(unitDiscountedPrice)} x {getPolishLiteral(totalItem)} )
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}