import ReactDOM from 'react-dom/client';
import './app/layout/styles.css';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store/configureStore';
import { router } from './app/router/Routes';
import { NavigationHistoryProvider } from './app/context/NavigationHistoryContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <NavigationHistoryProvider>
      <RouterProvider router={router} />
    </NavigationHistoryProvider>
  </Provider>
);