export default function LoadingComponent() {
    return (
            <div
            id="preloader"
            style={{
              position: 'fixed',
              zIndex: 2001,
              width: '100%',
              height: '100%',
            }}
          >
            <img
              style={{
                opacity: 0.5,
                position: 'fixed',
                top: 'calc(50% - 50px)',
                left: 'calc(50% - 50px)'
              }}
              src="/images/loading.gif"
              alt="loading"
            />
          </div>
    )
}