import { Grid } from "@mui/material";
import { Order } from "../../../../app/models/order";
import { useAppSelector } from "../../../../app/store/configureStore";
import OrderCard from "./ClientOrderCard";
import OrderCardSkeleton from "./ClientOrderCardSkeleton";


interface Props {
    orders: Order[];
    handleCardSelectOrder?: (handledOrder: Order) => void;
}

export default function ClientOrdersList({orders, handleCardSelectOrder} : Props) {
    const {clientOrdersLoaded} = useAppSelector(state => state.clientOrders);

    return (
        <Grid container spacing={4} >
            {orders.map(order => (
                <Grid key={order.publicId} item xs={12} sm={12} md={6}>
                    {!clientOrdersLoaded ? (
                        <OrderCardSkeleton />
                    ) : (
                        <OrderCard order={order} 
                            
                            handleCardSelectOrder={handleCardSelectOrder} 
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}