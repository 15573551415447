import * as yup from 'yup';

interface Effect {
    id: string;
    value: string;
}

interface FormValues {
    isActive: boolean;
    name: string;
    effects: Effect[];
}

export const subserviceValidationSchema: yup.ObjectSchema<FormValues> = yup.object({
    isActive: yup.boolean().required('Wskazanie podusługi jest wymagane'),
    name: yup.string().required('Wskazanie nazwy jest wymagane'),
    effects: yup.array().of(
        yup.object({
            id: yup.string().required(),
            value: yup.string().required('Efekt nie może być pusty')
        })
    ).required(),
});