import { TextField } from "@mui/material";
import { useController, UseControllerProps, Control } from "react-hook-form";

interface Props extends UseControllerProps {
    label: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    control: Control<any>; // Ensure control prop is explicitly typed
}

export default function AppNumberInput({ control, name, label, disabled, error, helperText }: Props) {
    const { field, fieldState } = useController({
        name,
        control,
        defaultValue: '',  // Ensure defaultValue is set
    });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const floatVal = parseFloat(event.target.value);
        if (isNaN(floatVal)) {
            field.onChange('');
        } else {
            field.onChange(floatVal);
        }
    };

    return (
        <TextField
            {...field}
            label={label}
            type="number"
            fullWidth
            variant="outlined"
            error={error || !!fieldState.error}
            helperText={helperText || fieldState.error?.message}
            onChange={handleOnChange}
            disabled={disabled}
        />
    );
}