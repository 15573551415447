import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";
import { useController, UseControllerProps, FieldValues } from "react-hook-form";
import AutocompleteInputOption from "../models/autocompleteInputOption";
import { useEffect, useState } from "react";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    label: string;
    items: AutocompleteInputOption[];
    selectedids?: string;
    multiple?: boolean;
    placeholder?: string;
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: AutocompleteInputOption | AutocompleteInputOption[] | null) => void;
}

export default function AppAutocomplete<T extends FieldValues>({ label, items, selectedids, multiple, placeholder, onChange, ...props }: Props<T>) {
    const { fieldState, field } = useController<T>({ ...props, rules: { ...props.rules } });

    const [singleValue, setSingleValue] = useState<AutocompleteInputOption | null>(null);
    const [multiValues, setMultiValues] = useState<AutocompleteInputOption[]>([]);

    useEffect(() => {
        if (selectedids) {
            const defaultValues = items.filter(item =>
                selectedids.split(',').includes(item.id.toString())
            );
            if (multiple) {
                setMultiValues(defaultValues);
            } else {
                setSingleValue(defaultValues[0] || null);
            }
        } else {
            if (multiple) {
                setMultiValues([]);
            } else {
                setSingleValue(null);
            }
        }
    }, [items, selectedids, multiple]);

    return (
        <FormControl fullWidth error={!!fieldState.error}>
            <Autocomplete
                multiple={multiple}
                fullWidth
                value={multiple ? multiValues : singleValue}
                options={items.sort((a, b) => -b.name.localeCompare(a.name))}
                getOptionLabel={(option) => `${option.name} - id: ${option.id}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder} // Using the placeholder prop here
                        error={!!fieldState.error}
                    />
                )}
                onChange={(event, newValues) => {
                    if (multiple) {
                        const multiVal = (newValues as AutocompleteInputOption[]) || [];
                        setMultiValues(multiVal);
                        field.onChange(multiVal.map((v: AutocompleteInputOption) => v.id).join(','));
                    } else {
                        const newValue = newValues as AutocompleteInputOption | null;
                        setSingleValue(newValue);
                        field.onChange(newValue?.id ?? '');
                    }
                    if (onChange) {
                        onChange(event, newValues);
                    }
                }}
            />
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
    );
}