import { Card, CardHeader, Avatar, CardContent, Typography, CardActions, Button } from "@mui/material";
import { Link } from "react-router-dom";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SupportIcon from '@mui/icons-material/Support';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SchoolIcon from '@mui/icons-material/School';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';

interface Props {
    displayName: string;
    link: string;
    description: string;
    icon: string;
    iconBgColor: string;
}

export default function ClientPageCard({ displayName, link, description, icon, iconBgColor }: Props) {
    function getIcon() {
        switch (icon) {
            case 'CrisisAlertIcon': return (<CrisisAlertIcon fontSize='large' />);
            case 'AccessibilityNewIcon': return (<AccessibilityNewIcon fontSize='large' />);
            case 'AddLocationAltIcon': return (<AddLocationAltIcon fontSize='large' />);
            case 'MoreTimeIcon': return (<MoreTimeIcon fontSize='large' />);
            case 'SupportIcon': return (<SupportIcon fontSize='large' />);
            case 'AssistWalkerIcon': return (<AssistWalkerIcon fontSize='large' />);
            case 'FormatListNumberedIcon': return (<FormatListNumberedIcon fontSize='large' />);
            case 'SchoolIcon': return (<SchoolIcon fontSize='large' />);
            case 'AddBusinessIcon': return (<AddBusinessIcon fontSize='large' />);
            case 'PersonIcon': return (<PersonIcon fontSize='large' />);
            case 'DescriptionIcon': return (<DescriptionIcon fontSize='large' />);
        }
    }
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: `${iconBgColor}`, width: 56, height: 56 }}>
                        {getIcon()}
                    </Avatar>
                }
                title={displayName}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
            />
            {/* <CardMedia
            component="img"
            sx={{height: 120, objectFit: 'contain', bgcolor: 'primary.light'}}
            image={service.pictureUrl}
            alt={service.name}
        /> */}
            <CardContent>
                <Typography gutterBottom color='secondary' variant="h5">{description}</Typography>
                <Typography variant="body2" color="text.secondary">
                    <>
                        {/* Coś zamiast typu i brandu np. godziny od {senior.hourStart} do {senior.hourEnd} */}
                    </>
                </Typography>
            </CardContent>
            <CardActions>
                <Button variant="outlined" component={Link} to={link} size="small">Przejdź</Button>
            </CardActions>
        </Card>
    )
}