import { Typography, Grid, Paper, Box, Button, Link, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, FieldValues, Control } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Service } from "../../app/models/service";
import { useAppDispatch } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import { setService } from "./servicesSlice";
import AppCheckbox from "../../app/components/AppCheckbox";
import AutocompleteInputOption from "../../app/models/autocompleteInputOption";
import AppAutocomplete from "../../app/components/AppAutocomplete";
import AppDropzone from "../../app/components/AppDropzone";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import AppReturn from "../../app/components/AppReturn";
import { getFileFormat } from "../../app/util/util";
import AppTextInput from "../../app/components/AppTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { serviceValidationSchema } from "./serviceValidation";
import AppNumberInput from "../../app/components/AppNumberInput";
import { toast } from "react-toastify";

interface Props {
    service?: Service;
    cancelEdit: () => void;
}

interface ServiceFormData {
    isActive: boolean;
    name: string;
    basePrice: number;
    category: string;
    subservices: string;
    file?: any;
    packageTestFile?: any;
    packageTestKeyFile?: any;
}

export default function ServiceForm({ service, cancelEdit }: Props) {
    const dispatch = useAppDispatch();
    const { control, handleSubmit, formState: { isSubmitting, isDirty }, watch, reset } = useForm<ServiceFormData>({
        resolver: yupResolver(serviceValidationSchema),
        defaultValues: {
            isActive: service?.isActive ?? true,
            name: service?.name ?? "",
            basePrice: service?.basePrice ?? 0,
            category: service?.category ?? "",
            subservices: service?.subservices ?? "",
            file: service?.imagePath ?? "",
            packageTestFile: service?.packageTestKeyPath ?? "",
            packageTestKeyFile: service?.packageTestKeyPath ?? ""
        }
    });

    const watchFile = watch('file');
    const watchTestFile = watch('packageTestFile');
    const watchTestKeyFile = watch('packageTestKeyFile');
    const [possibleSubservices, setPossibleSubservices] = useState<AutocompleteInputOption[]>([]);

    useEffect(() => {
        agent.Subservices.fetchPossibleSubservices()
            .then(result => setPossibleSubservices(result))
            .catch(error => console.log(error))
    }, []);

    useEffect(() => {
        if (service && !isDirty) reset(service);
    }, [service, reset, isDirty]);

    async function handleSubmitData(data: ServiceFormData) {
        try {
            let response: Service;
            if (service) {
                response = await agent.Services.update(data);
                toast.success('Uaktualniono usługę');
            } else {
                response = await agent.Services.create(data);
                toast.success('Dodano usługę');
                console.log(data);
            }
            dispatch(setService(response));
            cancelEdit();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn
                title={"Tworzenie/edycja usługi"}
                cancelEdit={cancelEdit}
            />
            <Box component={Paper} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit(handleSubmitData)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} >
                            <AppCheckbox control={control as unknown as Control<FieldValues>} name='isActive' label='Widoczne i aktywne' disabled={false} defaultChecked />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <AppTextInput control={control as unknown as Control<FieldValues>} name='name' label='Nazwa' />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <AppNumberInput control={control as unknown as Control<FieldValues>} name='basePrice' label='Cena bazowa' />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <AppTextInput control={control as unknown as Control<FieldValues>} name='category' label='Kategoria' />
                        </Grid>

                        {service?.imagePath ? (
                            <Grid item xs={12} sm={12} >
                                <img
                                    src={service.imagePath}
                                    alt="Podgląd istniejącego"
                                    style={{ maxHeight: 200, marginTop: 35 }}
                                />
                            </Grid>
                        ) : ''}

                        <Grid item xs={12} sm={12} >
                            <AppAutocomplete
                                control={control as unknown as Control<FieldValues>}
                                name='subservices'
                                label='Podusługi (moduły)'
                                items={possibleSubservices}
                                multiple
                                selectedids={service?.subservices} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end">
                                <Typography variant='h4' sx={{ mt: 1, mb: 1 }}>Zdjęcie usługi:</Typography>
                                <AppDropzone
                                    name="file"
                                    control={control as unknown as Control<FieldValues>}
                                    acceptedFormats="jpg,jpeg,png"
                                />
                                {watchFile ? (
                                    <img src={watchFile.preview} alt="Plik" style={{ maxHeight: 200, marginTop: 35 }} />
                                ) : (
                                    service?.imagePath && (
                                        <img
                                            src={service.imagePath}
                                            alt="existing preview"
                                            style={{ maxHeight: 200, marginTop: 35 }}
                                        />
                                    )
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} marginTop={3}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end">
                                <Typography variant='h4' sx={{ mt: 1, mb: 1 }} textAlign='center'>Wzór arkusza egzaminu:</Typography>
                                <AppDropzone
                                    name="packageTestFile"
                                    control={control as unknown as Control<FieldValues>}
                                    acceptedFormats="pdf,zip"
                                />
                                {watchTestFile ? (
                                    <Alert severity="info" sx={{ mt: 2 }}>Podgląd plików pdf i zip dostępny jest po zapisaniu usługi</Alert>
                                ) : (
                                    service?.packageTestPath && (
                                        getFileFormat(service.packageTestPath) === "pdf" || getFileFormat(service.packageTestPath) === "zip" ? (
                                            <Link sx={{ mt: 2 }} href={service.packageTestPath} target="_blank">{service.packageTestPath}</Link>
                                        ) : (
                                            <img
                                                src={service.packageTestPath}
                                                alt="Błąd wyświetlenia podglądu"
                                                style={{ maxHeight: 200, marginTop: 35 }}
                                            />
                                        )
                                    )
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} marginTop={3}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end">
                                <Typography variant='h4' sx={{ mt: 1, mb: 1 }}>Klucz odpowiedzi:</Typography>
                                <AppDropzone
                                    name="packageTestKeyFile"
                                    control={control as unknown as Control<FieldValues>}
                                    acceptedFormats="zip,pdf"
                                />
                                {watchTestKeyFile ? (
                                    <Alert severity="info" sx={{ mt: 2 }}>Podgląd plików pdf i zip dostępny jest po zapisaniu usługi</Alert>
                                ) : (
                                    service?.packageTestKeyPath && (
                                        getFileFormat(service.packageTestKeyPath) === "pdf" || getFileFormat(service.packageTestKeyPath) === "zip" ? (
                                            <Link sx={{ mt: 2 }} href={service.packageTestKeyPath} target="_blank">{service.packageTestKeyPath}</Link>
                                        ) : (
                                            <img
                                                src={service.packageTestKeyPath}
                                                alt="Błąd wyświetlenia podglądu"
                                                style={{ maxHeight: 200, marginTop: 35 }}
                                            />
                                        )
                                    )
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                        <Button onClick={cancelEdit} variant='outlined' color='inherit'>Anuluj</Button>
                        <LoadingButton disabled={!isDirty} loading={isSubmitting} type='submit' variant='outlined' color='success'>Zapisz</LoadingButton>
                    </Box>
                </form>
            </Box>
        </>
    )
}