import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert, Button, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import useQuery from '../../app/util/hooks';
import agent from '../../app/api/agent';
import { Link, useNavigate } from 'react-router-dom';

export default function ResetPassword() {
    const token = useQuery().get('token') as string;
    const { register, watch, handleSubmit, setError, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'all'
        // mode: 'all',
        // resolver: yupResolver(resetPasswordValidationSchema)
    });
    const navigate = useNavigate();

    function handleApiErrors(errors: any) {
        if (errors) {
            errors.forEach((error: string) => {
                if (error.includes('Password')) {
                    setError('password', { message: error })
                } else if (error.includes('ConfirmPassword')) {
                    setError('confirmPassword', { message: error })
                } else if (error.includes('Email')) {
                    setError('email', { message: error })
                } else if (error.includes('token')) {
                    toast.error('Token jest nieprawidłowy lub wygasł. Powtórz procedurę resetowania hasła.')
                }
            })
        }
    }

    return (
        <Container component={Paper} maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', aligntItems: 'center', p: 4 }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>
                Reset hasła
            </Typography>
            <Alert severity="info">W związku z wdrożeniem nowych standardów bezpieczeństwa i aktualizacją systemu - niezbędne jest zresetowanie hasła. Otrzymany link zawiera token z ograniczonym czasen ważności. Jeżeli token wygasł - naciśnij przycisk "WYGENERUJ TOKEN PONOWNIE", by odnowić token bezpieczeństwa. Zostanie on wysłany na Twój adres email.</Alert>
            <Box
                component="form"
                onSubmit={handleSubmit((data) =>
                    agent.Account.resetPassword(data)
                        .then(() => {
                            toast.success('Hasło zostało zresetowane. Możesz zalogować się z użyciem nowego hasła.');
                            navigate("/account/resetPasswordSuccess");
                        })
                        .catch(error => handleApiErrors(error)))
                }
                noValidate
                sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Email"
                    autoFocus
                    {...register('email', {
                        required: 'Email jest wymagany',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email jest nieprawidłowy'
                        }
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message?.toString()}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Hasło"
                    type="password"
                    {...register('password', {
                        required: 'Hasło jest wymagane',
                        pattern: {
                            value: /(?=^.{6,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Hasło nie spełnia wymagań bezpieczeństwa'
                        }
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message?.toString()}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Powtórz hasło"
                    type="password"
                    {...register('confirmPassword', {
                        required: 'Powtórzenie hasła jest wymagane',
                        pattern: {
                            value: /(?=^.{6,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message: 'Hasło nie spełnia wymagań bezpieczeństwa'
                        },
                        validate: (val: string) => {
                            if (watch('password') !== val) {
                                return "Hasło i jego potwierdzenie muszą być identyczne";
                            }
                        }
                    })}
                    error={!!errors.confirmPassword}
                    helperText={errors?.confirmPassword?.message?.toString()}
                />

                <TextField 
                    sx={{display: 'none'}}
                    label='token'
                    value={token}
                    {...register('token')}
                />

                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Resetuj hasło
                </LoadingButton>
                <Button fullWidth component={Link} to='/account/forgotPassword'>WYGENERUJ TOKEN PONOWNIE</Button>
            </Box>
        </Container>
    );
}