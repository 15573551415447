import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { AdminUser, AdminUsersParams } from "../../../app/models/adminUser";

import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";

interface AdminUsersState {
    adminUsersLoaded: boolean;
    adminUsersFiltersLoaded: boolean;
    status: string;
    // brands: string[];
    // types: string[];
    adminUsersParams: AdminUsersParams;
    metaData: MetaData | null;
}

const adminUsersAdapter = createEntityAdapter<AdminUser>();

function getAxiosParams(adminUsersParams: AdminUsersParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', adminUsersParams.pageNumber.toString());
    params.append('pageSize', adminUsersParams.pageSize.toString());
    params.append('orderBy', adminUsersParams.orderBy);
    if (adminUsersParams.searchTerm) params.append('searchTerm', adminUsersParams.searchTerm);
    // if (serviceParams.brands.length > 0) params.append('brands', serviceParams.brands.toString());
    // if (serviceParams.types.length > 0) params.append('types', serviceParams.types.toString());
    return params;
}

export const fetchAdminUsersAsync = createAsyncThunk<AdminUser[], void, {state: RootState} >(
    'adminUsers/fetchAdminUsersAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().adminUsers.adminUsersParams);
        try {
            const response = await agent.Admin.Users.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchAdminUserAsync = createAsyncThunk<AdminUser, string>(
    'adminUsers/fetchAdminUserAsync',
    async (adminUserId, thunkAPI) => {
        try {
            return await agent.Admin.Users.details(adminUserId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

export const fetchAdminUsersFilters = createAsyncThunk(
    'adminUsers/fetchAdminUsersFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Admin.Users.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'id'//,
        // brands: [],
        // types: []
    }
}

export const adminUsersSlice = createSlice({
    name: 'adminUsers',
    initialState: adminUsersAdapter.getInitialState<AdminUsersState>({
        adminUsersLoaded: false,
        adminUsersFiltersLoaded: false,
        status: 'idle',
        // brands: [],
        // types: [],
        adminUsersParams: initParams(),
        metaData: null
    }),
    reducers: {
        setAdminUsersParams: (state, action) => {
            state.adminUsersLoaded = false;
            state.adminUsersParams = {...state.adminUsersParams, ...action.payload, pageNumber: 1};
        },
        setAdminUsersPageNumber: (state, action) => {
            state.adminUsersLoaded = false;
            state.adminUsersParams = {...state.adminUsersParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetAdminUsersParams: (state) => {
            state.adminUsersParams = initParams();
        },
        setAdminUser: (state, action) => {
            adminUsersAdapter.upsertOne(state, action.payload);
            state.adminUsersLoaded = false;
        },
        removeAdminUser: (state, action) => {
            adminUsersAdapter.removeOne(state, action.payload);
            state.adminUsersLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchAdminUsersAsync.pending, (state) => {
                state.status = 'pendingFetchAdminUsers';
            });
            builder.addCase(fetchAdminUsersAsync.fulfilled, (state, action) => {
                adminUsersAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.adminUsersLoaded = true;
            });
            builder.addCase(fetchAdminUsersAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchAdminUserAsync.pending, (state) => {
                state.status = 'pendingFetchAdminUser';
            });
            builder.addCase(fetchAdminUserAsync.fulfilled, (state, action) => {
                adminUsersAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchAdminUserAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchAdminUsersFilters.pending, (state) => {
                state.status = 'pendingFetchAdminUsersFilters';
            });
            builder.addCase(fetchAdminUsersFilters.fulfilled, (state) => {
                // state.brands = action.payload.brands;
                // state.types = action.payload.types; 
                state.adminUsersFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchAdminUsersFilters.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const adminUsersSelectors = adminUsersAdapter.getSelectors((state: RootState) => state.adminUsers);

export const {setAdminUsersParams, resetAdminUsersParams, setMetaData, setAdminUsersPageNumber, setAdminUser, removeAdminUser} = adminUsersSlice.actions;