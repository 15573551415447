import { Grid } from "@mui/material";
import AppBreadcrumbs from "../../app/components/AppBreadcrumbs";
import AboutPageCard from "./AboutPageCard";
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import CallIcon from '@mui/icons-material/Call';
import GradingIcon from '@mui/icons-material/Grading';
import SecurityIcon from '@mui/icons-material/Security';
import GavelIcon from '@mui/icons-material/Gavel';
import useAbouts from "../../app/hooks/useAbouts";
import { updateAboutSortOrderAsync } from "./aboutsSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useState } from "react";
import { About } from "../../app/models/about";
import AboutForm from "./AboutForm";
import { selectCurrentUser } from "../account/accountSlice";
//import { renderPolishName } from "../../app/util/util";

function getIconByName(name: string) {
    switch (name) {
        case 'institution': return (<InfoIcon fontSize='large' />);
        case 'references': return (<AssignmentIcon fontSize='large' />);
        case 'partners': return (<Diversity1Icon fontSize='large' />);
        case 'contact': return (<CallIcon fontSize='large' />);
        case 'certificate': return (<GradingIcon fontSize='large' />);
        case 'privacy': return (<SecurityIcon fontSize='large' />);
        case 'regulations': return (<GavelIcon fontSize='large' />);
    }
}


function getIconColorByName(name: string) {
    switch (name) {
        case 'institution': return 'primary.main';
        case 'references': return 'secondary.main';
        case 'partners': return 'red';
        case 'contact': return 'orange';
        case 'certificate': return 'green';
        case 'privacy': return 'indigo';
        case 'regulations': return 'red';
    }
}


export default function AboutPages() {
    const { abouts, aboutsCount } = useAbouts();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedAbout, setSelectedAbout] = useState<About | undefined>(undefined);
    const currentUser = useAppSelector(selectCurrentUser);
    const isAdmin = currentUser?.roles?.includes('Admin');

    const handleSortOrderChange = (id: number, newSortOrder: number) => {
        if (isAdmin) {
            dispatch(updateAboutSortOrderAsync({ id, newSortOrder }));
        }
    };

    function handleCardSelectAbout(about: About) {
        setSelectedAbout(about);
        setEditMode(true);
    }

    function cancelEdit() {
        if (selectedAbout) setSelectedAbout(undefined);
        setEditMode(false);
    }

    if (editMode) return <AboutForm about={selectedAbout} cancelEdit={cancelEdit} />

    return (
        <>
            <AppBreadcrumbs />
            <Grid container spacing={4} alignItems="stretch">
                {abouts.map((about) => (
                    <Grid key={about.id} item xs={12} sm={6} md={4} display="flex">
                        <AboutPageCard
                            about={about}
                            displayName={about.title}
                            link={`/about/${about.name}`}
                            description={about.description}
                            icon={getIconByName(about.name)}
                            iconBgColor={getIconColorByName(about.name)}
                            aboutsCount={aboutsCount}
                            onSortOrderChange={(newSortOrder) => handleSortOrderChange(about.id, newSortOrder)}
                            currentSortOrder={about.sortOrder}
                            handleCardSelectAbout={handleCardSelectAbout}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}