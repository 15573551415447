import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import AppBreadcrumbs from "../../../../app/components/AppBreadcrumbs";
import AppPagination from "../../../../app/components/AppPagination";
import RadioButtonGroup from "../../../../app/components/AppRadioButtonGroup";
import useClientOrders from "../../../../app/hooks/useClientOrders";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { Order } from "../../../../app/models/order";
import { RootState, useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { paymentStateNamesMap, setFooterInvisible, setFooterVisible, stateNamesMap } from "../../../../app/util/util";
import { setClientOrdersPageNumber, setClientOrdersParams } from "../clientOrdersSlice";
import OrderForm from "../clientOrderForm/ClientOrderForm";
import ClientOrdersList from "./ClientOrdersList";
import AppReturn from "../../../../app/components/AppReturn";
import AppUniversalSearch from "../../../../app/components/AppUniversalSearch";
import CheckboxButtons from "../../../../app/components/AppCheckboxButtons";

const sortOptions = [
  { value: 'dateDesc', label: 'Data malejąco \u2193' },
  { value: 'date', label: 'Data rosnąco \u2191' },
  { value: 'serviceName', label: 'Nazwa usługi rosnąco \u2191' },
  { value: 'serviceNameDesc', label: 'Nazwa usługi malejąco \u2193' },
  { value: 'preposedDate', label: 'Proponowana data rosnąco \u2191' },
  { value: 'preposedDateDesc', label: 'Proponowana data malejąco \u2193' }
]

export default function ClientOrders() {
  const { clientOrders, clientOrdersFiltersLoaded, metaData } = useClientOrders();
  const { clientOrdersParams } = useAppSelector(state => state.clientOrders);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();

  function cancelEdit() {
    if (selectedOrder) setSelectedOrder(undefined);
    setEditMode(false);
  }

  function handleCardSelectOrder(order: Order) {
    setSelectedOrder(order);
    setEditMode(true);
  }

  if (!clientOrdersFiltersLoaded) { setFooterInvisible(); return <LoadingComponent /> }
  setFooterVisible();

  if (editMode) return <OrderForm order={selectedOrder} cancelEdit={cancelEdit} />

  return (
    <Box>
      <AppBreadcrumbs />
      <AppReturn title={"Usługi egzaminacyjne"} setFunction={() => setEditMode(true)} />

      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ mb: 2 }}>
            <AppUniversalSearch
              sliceName="clientOrders"
              searchParamsSelector={(state: RootState) => state.clientOrders.clientOrdersParams}
              setSearchParams={setClientOrdersParams}
              label='Szukaj wpisując nazwisko/imię zamawiającego, liczbą dany dzień, miesiąc, rok lub fragment komentarza/uwagi'
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <Typography variant="subtitle1" gutterBottom textAlign='center'>
              W powyższym polu możesz filtrować zamówienia według:
            </Typography>
            <Typography variant="body2">
              - Id zamówienia<br />
              - Nazwy<br />
              {/* - Proponowanej daty realizacji<br /> */}
              - Danych płatnika<br />
              - Imion, nazwisk i numerów PESEL uczestników
            </Typography>
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <RadioButtonGroup
              selectedValue={clientOrdersParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setClientOrdersParams({ orderBy: e.target.value }))}
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>Filtruj po statusie zamówienia</Typography>
            <Divider sx={{ my: 1 }} />
            <CheckboxButtons
              items={stateNamesMap}
              checked={clientOrdersParams.states}
              onChange={(checkedItems: string[]) => dispatch(setClientOrdersParams({ states: checkedItems }))}
            />
          </Paper>
          <Paper sx={{ mb: 2, p: 2 }}>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>Filtruj po statusie płatności</Typography>
            <Divider sx={{ my: 1 }} />
            <CheckboxButtons
              items={paymentStateNamesMap}
              checked={clientOrdersParams.paymentStates}
              onChange={(checkedItems: string[]) => dispatch(setClientOrdersParams({ paymentStates: checkedItems }))}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <ClientOrdersList orders={clientOrders} handleCardSelectOrder={handleCardSelectOrder} />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9} sx={{ my: 2 }} >
          {metaData &&
            <AppPagination
              metaData={metaData}
              onPageChange={(page: number) => dispatch(setClientOrdersPageNumber({ pageNumber: page }))}
            />}
        </Grid>
      </Grid>
    </Box>
  )
}