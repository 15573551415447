import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { Order, OrdersParams } from "../../../app/models/order";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";

interface ClientOrdersState {
    clientOrdersLoaded: boolean;
    clientOrdersFiltersLoaded: boolean;
    status: string;
    selectedOrder: number;
    states: string[];
    paymentStates: string[];
    clientOrdersParams: OrdersParams;
    metaData: MetaData | null;
}

const clientOrdersAdapter = createEntityAdapter<Order>({
    selectId: (order) => order.publicId,
});

function getAxiosParams(ordersParams: OrdersParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', ordersParams.pageNumber.toString());
    params.append('pageSize', ordersParams.pageSize.toString());
    params.append('orderBy', ordersParams.orderBy);
    if (ordersParams.searchTerm) params.append('searchTerm', ordersParams.searchTerm);
    if (ordersParams.states.length > 0) params.append('states', ordersParams.states.toString());
    if (ordersParams.paymentStates.length > 0) params.append('paymentStates', ordersParams.paymentStates.toString());
    return params;
}

export const fetchClientOrdersAsync = createAsyncThunk<Order[], void, { state: RootState }>(
    'clientOrders/fetchClientOrdersAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().clientOrders.clientOrdersParams);
        try {
            const response = await agent.Client.Orders.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    }
)

export const fetchClientOrderAsync = createAsyncThunk<Order, number>(
    'clientOrders/fetchClientOrderAsync',
    async (orderId, thunkAPI) => {
        try {
            return await agent.Client.Orders.details(orderId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error })
        }
    }
)

export const fetchClientOrdersFilters = createAsyncThunk(
    'clientOrders/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Client.Orders.fetchFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'dateDesc',
        states: [],
        paymentStates: []
    }
}

export const clientOrdersSlice = createSlice({
    name: 'clientOrders',
    initialState: clientOrdersAdapter.getInitialState<ClientOrdersState>({
        clientOrdersLoaded: false,
        clientOrdersFiltersLoaded: false,
        status: 'idle',
        selectedOrder: 0,
        states: [],
        paymentStates: [],
        clientOrdersParams: initParams(),
        metaData: null
    }),
    reducers: {
        setClientOrdersParams: (state, action) => {
            state.clientOrdersLoaded = false;
            state.clientOrdersParams = { ...state.clientOrdersParams, ...action.payload, pageNumber: 1 };
        },
        setClientOrdersPageNumber: (state, action) => {
            state.clientOrdersLoaded = false;
            state.clientOrdersParams = { ...state.clientOrdersParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetClientOrdersParams: (state) => {
            state.clientOrdersParams = initParams();
        },
        setClientOrder: (state, action) => {
            clientOrdersAdapter.upsertOne(state, action.payload);
            state.clientOrdersLoaded = false;
        },
        removeClientOrder: (state, action) => {
            clientOrdersAdapter.removeOne(state, action.payload);
            state.clientOrdersLoaded = false;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchClientOrdersAsync.pending, (state) => {
            state.status = 'pendingFetchClientOrders';
        });
        builder.addCase(fetchClientOrdersAsync.fulfilled, (state, action) => {
            clientOrdersAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.clientOrdersLoaded = true;
        });
        builder.addCase(fetchClientOrdersAsync.rejected, (state) => {
            state.status = 'idle';
        });

        builder.addCase(fetchClientOrderAsync.pending, (state) => {
            state.status = 'pendingFetchClientOrder';
        });
        builder.addCase(fetchClientOrderAsync.fulfilled, (state, action) => {
            clientOrdersAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchClientOrderAsync.rejected, (state) => {
            state.status = 'idle';
        });
        builder.addCase(fetchClientOrdersFilters.pending, (state) => {
            state.status = 'pendingFetchClientOrdersFilters';
        });
        builder.addCase(fetchClientOrdersFilters.fulfilled, (state) => {
            // state.brands = action.payload.brands;
            // state.types = action.payload.types; 
            state.clientOrdersFiltersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchClientOrdersFilters.rejected, (state) => {
            state.status = 'idle';
        });
    }
    )
})

export const clientOrdersSelectors = clientOrdersAdapter.getSelectors((state: RootState) => state.clientOrders);

export const { setClientOrdersParams, resetClientOrdersParams, setMetaData, setClientOrdersPageNumber, setClientOrder, removeClientOrder } = clientOrdersSlice.actions;