import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import { About } from "../../app/models/about";

interface AboutsState {
    loaded: boolean;
    status: string;
    aboutsLoaded: boolean; // Assuming this indicates if abouts are loaded
    aboutsFiltersLoaded: boolean; // Assuming this indicates if filters for abouts are loaded
    metaData: any; // Define a more specific type if possible
    count: number;
    updateStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

const aboutsAdapter = createEntityAdapter<About>();

const initialState = aboutsAdapter.getInitialState<AboutsState>({
    loaded: false,
    status: 'idle',
    aboutsLoaded: false,
    aboutsFiltersLoaded: false,
    metaData: null, // or an appropriate initial value
    count: 0,
    updateStatus: 'idle'
});

export const fetchAboutsAsync = createAsyncThunk<About[], void, { state: RootState }>(
    'abouts/fetchAboutsAsync',
    async (_, thunkAPI) => {
        try {
            const response = await agent.Abouts.list();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const fetchAboutsCount = createAsyncThunk(
    'abouts/fetchAboutsCount',
    async (_, thunkAPI) => {
        try {
            const count = await agent.Abouts.getCount();
            return count;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateAboutSortOrderAsync = createAsyncThunk(
    'abouts/updateSortOrder',
    async ({ id, newSortOrder }: { id: number; newSortOrder: number }, thunkAPI) => {
        try {
            const response = await agent.Abouts.updateAboutSortOrder(id, newSortOrder);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const fetchAboutByName = createAsyncThunk(
    'abouts/fetchAboutByName',
    async (name: string, thunkAPI) => { // Upewnij się, że `name` jest zdefiniowane jako string
        try {
            const response = await agent.Abouts.details(name);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const aboutsSlice = createSlice({
    name: 'abouts',
    initialState,
    reducers: {
        setAbout: (state, action) => {
            aboutsAdapter.upsertOne(state, action.payload);
            state.aboutsLoaded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAboutsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAboutsAsync.fulfilled, (state, action) => {
                aboutsAdapter.setAll(state, action.payload);
                state.loaded = true;
                state.status = 'idle';
            })
            .addCase(fetchAboutsAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchAboutsCount.fulfilled, (state, action) => {
                // Zakładając, że action.payload zawiera liczbę obiektów About
                state.count = action.payload
            })
            .addCase(updateAboutSortOrderAsync.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateAboutSortOrderAsync.fulfilled, (state) => {
                state.updateStatus = 'fulfilled';
            })
            .addCase(updateAboutSortOrderAsync.rejected, (state) => {
                state.updateStatus = 'rejected';
            })
            .addCase(fetchAboutByName.fulfilled, (state, action) => {
                // Możesz zdecydować, co zrobić z danymi - np. dodać je do stanu
                aboutsAdapter.upsertOne(state, action.payload);
            });
    },
});

export const aboutsSelectors = aboutsAdapter.getSelectors((state: RootState) => state.abouts);

export const {setAbout} = aboutsSlice.actions;