import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../../app/api/agent";
import { RootState } from "../../../../app/store/configureStore";
import { setMetaData } from "../adminUsersSlice";
import { Role } from "../../../../app/models/role";

interface RolesState {
    rolesLoaded: boolean;
    rolesFiltersLoaded: boolean;
    status: string;
}

const rolesAdapter = createEntityAdapter<Role>();

export const fetchRolesAsync = createAsyncThunk<Role[], void, {state: RootState} >(
    'roles/fetchRolesAsync',
    async (_,thunkAPI) => {
        try {
            const response = await agent.Admin.Users.roles();
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const deleteRoleAsync = createAsyncThunk<string, string, {state: RootState}>(
    'roles/deleteRoleAsync',
    async (roleId, thunkAPI) => {
        console.log("Attempting to delete role with ID:", roleId);
        try {
            const response = await agent.Admin.Roles.delete(roleId);
            console.log("Role deleted successfully:", response);
            return response;
        } catch (error: any) {
            console.error("Error deleting role:", error);
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: rolesAdapter.getInitialState<RolesState>({
        rolesLoaded: false,
        rolesFiltersLoaded: false,
        status: 'idle',
    }),
    reducers: {
        setRole: (state, action) => {
            rolesAdapter.upsertOne(state, action.payload);
            state.rolesLoaded = false;
        },
        removeRole: (state, action) => {
            rolesAdapter.removeOne(state, action.payload);
            state.rolesLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchRolesAsync.pending, (state) => {
                state.status = 'pendingFetchRoles';
            });
            builder.addCase(fetchRolesAsync.fulfilled, (state, action) => {
                rolesAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.rolesLoaded = true;
            });
            builder.addCase(fetchRolesAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(deleteRoleAsync.fulfilled, (state, action) => {
                rolesAdapter.removeOne(state, action.payload);
                state.status = 'idle';
            });            
            builder.addCase(deleteRoleAsync.rejected, (state) => {
                state.status = 'idle';
            });         
        }
    )
})

export const rolesSelectors = rolesAdapter.getSelectors((state: RootState) => state.roles);

export const {setRole, removeRole} = rolesSlice.actions;