import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';

interface ButtonDownloadFileProps {
  url: string;
  filename: string;
}

const ButtonDownloadFile: React.FC<ButtonDownloadFileProps> = ({ url, filename }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Błąd sieci');
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename); // Poprawne ustawienie atrybutu pobierania
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Wystąpił problem podczas operacji pobierania:', error);
    }
  };

  return (
    <Button variant="contained" onClick={handleDownload}>
      Pobierz
    </Button>
  );
};

interface DownloadButtonsProps {
  order: {
    path: string;
  };
  count: boolean;
}

const AppDownloadAndPreviewButtons: React.FC<DownloadButtonsProps> = ({ order, count }) => {
  if (!order?.path) return <Typography>Brak plików</Typography>;

  const paths = order.path.split('; ');

  return (
    <Box>
      {paths.map((path, index) => {
        // Wyodrębnienie rozszerzenia pliku ze ścieżki
        const fileExtension = path.slice(((path.lastIndexOf('.') - 1) >>> 0) + 2);

        // Konstrukcja nazwy pliku z rozszerzeniem
        const filename = `Dokument_${index + 1}.${fileExtension}`;

        return (
          <Box key={index} sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {count ? <Typography variant="h6" sx={{ mr: 2 }}>
                {`Wersja ${index + 1}`}
              </Typography> : ''}
              <ButtonDownloadFile url={path} filename={filename} />
              <Button
                variant="outlined"
                sx={{ ml: 2 }}
                onClick={() => window.open(path, '_blank')}
              >
                Podgląd
              </Button>
            </Box>
            {index < paths.length - 1 && (
              <Divider
                sx={{
                  width: '95%',
                  mx: 'auto',
                  my: 2,
                  borderStyle: 'dashed', // Ustawienie stylu przerywanego
                }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default AppDownloadAndPreviewButtons;