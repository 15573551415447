import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBreadcrumbs from "../../../app/components/AppBreadcrumbs";
import NotFound from "../../../app/errors/NotFound";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { fetchAgreementAsync, agreementsSelectors } from "./agreementsSlice";
import AppReturn from "../../../app/components/AppReturn";
import { formatToLocalDate, formatToLocalTime } from "../../../app/util/util";

export default function AgreementDetails() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const agreement = useAppSelector(state => agreementsSelectors.selectById(state, id!));
    const { status: agreementStatus } = useAppSelector(state => state.agreements);
    const navigate = useNavigate();

    useEffect(() => {
        if (!agreement) dispatch(fetchAgreementAsync(parseInt(id!)))
    }, [id, dispatch, agreement])

    if (agreementStatus.includes('pending')) return <LoadingComponent />

    if (!agreement) return <NotFound />

    return (
        <>
            <AppBreadcrumbs />
            <AppReturn title={"Umowa #" + agreement.id} backPath="/admin/agreements" />
            
            <Grid container>
                <Grid item md={12} sm={12}>
                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Podstawowe informacje'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {agreement.name}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa umowy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {formatToLocalDate(agreement.dateOfConclusion.toString())} {formatToLocalTime(agreement.dateOfConclusion.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        data zawarcia umowy
                                    </Typography>
                                </Grid>

                                {agreement.investmentPublicId === 0 ? '' :
                                    <Grid item xs={12}>
                                        <Button variant='outlined'
                                            onClick={() => {
                                                navigate(`/admin/investments/${agreement.investmentPublicId}`);
                                            }}
                                            size="small"
                                            title='Projekt inwestycyjny'
                                            color='secondary'>
                                            Projekt inwestycyjny #{agreement.investmentPublicId}
                                        </Button><br />
                                        <Typography variant="overline">
                                            dotyczy projektu inwestycyjnego
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Dane zleceniodawcy'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {agreement.companyName}
                                    </Typography>
                                    <Typography variant="overline">
                                        nazwa firmy
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {agreement.companyCity}
                                    </Typography>
                                    <Typography variant="overline">
                                        miasto
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {agreement.companyZip}
                                    </Typography>
                                    <Typography variant="overline">
                                        kod pocztowy
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        {agreement.companyNIP}
                                    </Typography>
                                    <Typography variant="overline">
                                        NIP
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.companyRepresentative}
                                    </Typography>
                                    <Typography variant="overline">
                                        przedstawiciel
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Szczegóły umowy: § 1'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.serviceConsistingOf}
                                    </Typography>
                                    <Typography variant="overline">
                                        zakres usługi
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">
                                        od <b>{formatToLocalDate(agreement.dateStart.toString())}</b> {formatToLocalTime(agreement.dateStart.toString())}
                                    </Typography>
                                    <Typography variant="h6">
                                        do <b>{formatToLocalDate(agreement.dateEnd.toString())}</b> {formatToLocalTime(agreement.dateEnd.toString())}
                                    </Typography>
                                    <Typography variant="overline">
                                        okres obowiązywania umowy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Zamawiający może zmienić wielkość usług. Wielkość ta nie może jednak wzrosnąć lub zmaleć o <b>{agreement.changeTheSizeOfServices}</b>.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Zmiany, o których mowa w ust. 2, mogą nastąpić po pisemnym zawiadomieniu Wykonawcy, przesłanym na <b>{agreement.numberOfDaysBeforeIntroduction} dni</b> przed terminem ich wprowadzenia.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Usługi nie obejmują <b>{agreement.servicesDoNotInclude}</b>.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Szczegóły umowy: § 2, § 3'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.principalsResponsibilities}
                                    </Typography>
                                    <Typography variant="overline">
                                        obowiązki zleceniodawcy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.contractorsResponsibilities}
                                    </Typography>
                                    <Typography variant="overline">
                                        obowiązki zleceniobiorcy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        <b>{agreement.prepayment}zł netto plus należny podatek VAT</b> - przedpłata bezzwrotna płatna po podpisaniu umowy w ciągu 7 dni od daty wystawienia faktury;
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        <b>{agreement.payment}zł netto plus należny podatek VAT</b> - płatne w ciągu 7 dni od daty wystawienia faktury, po przekazaniu {agreement.transferOf}.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card component={Paper} sx={{ p: 3, mb: 2, mr: { xs: 0, md: 2 } }}>
                        <CardHeader title='Szczegóły umowy: § 4, inne'
                            titleTypographyProps={{
                                sx: { fontWeight: 'bold', color: 'secondary' }
                            }} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Zleceniodawca wezwie Zleceniobiorcę, aby w ciągu <b>{agreement.principalWillCallContractorIn} dni</b> od otrzymania pisemnego wezwania wypełnił nałożone na niego obowiązki. Po bezskutecznym upływie wyznaczonego terminu Zleceniodawca może odstąpić od umowy.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Jeżeli Zleceniodawca nie wykonuje lub nienależycie wykonuje umowę, naruszając swoje obowiązki określone w niniejszej umowie, Zleceniobiorca wezwie Zleceniodawcę, aby w ciągu <b>{agreement.contractorWillCallPrincipalIn} dni</b> od otrzymania pisemnego wezwania dokonał zapłaty należnego mu wynagrodzenia. Po bezskutecznym upływie wyznaczonego terminu Wykonawca może odstąpić od umowy.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.attachments}
                                    </Typography>
                                    <Typography variant="overline">
                                        Załączniki
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.principalRepresentativeForDirectContacts}
                                    </Typography>
                                    <Typography variant="overline">
                                        przedstawiciel zleceniodawcy do bezpośrednich kontaktów
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {agreement.contractorRepresentativeForDirectContacts}
                                    </Typography>
                                    <Typography variant="overline">
                                        przedstawiciel zleceniobiorcy do bezpośrednich kontaktów
                                    </Typography>
                                </Grid>
                                {agreement.internalComment !== '' && agreement.internalComment !== null && agreement.internalComment !== undefined &&
                                    <>
                                        <Typography variant="h6">
                                            {agreement.internalComment}
                                        </Typography>
                                        <Typography variant="overline">
                                            komentarz wewnętrzny (widoczny tylko dla admina)
                                        </Typography>
                                    </>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
            <Box sx={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'flex-end', m: 2 }}>
                <Button onClick={() => navigate('/admin/agreements')} variant='outlined' size='large'>Powrót</Button>
            </Box>
        </>
    )
}