import { Avatar, Button, Card, CardActions, CardContent, Typography, CardHeader, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import GradingIcon from '@mui/icons-material/Grading';
import { Agreement } from "../../../../app/models/agreement";
import { formatToLocalDate } from "../../../../app/util/util";

interface Props {
    agreement: Agreement;
    handleCardSelectAgreement?: (handledAgreement: Agreement, exportPdf?: boolean) => void;
}

export default function AgreementCard({ agreement, handleCardSelectAgreement }: Props) {

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'green' }} title='AdminAgreement'>
                        <GradingIcon />
                    </Avatar>
                }
                title={agreement.name}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
            />
            <Divider />
            <CardContent>
                <Typography gutterBottom>
                    Umowa #{agreement.id}
                </Typography>
                <Typography gutterBottom>
                    Nazwa umowy #{agreement.name}
                </Typography>
                <Typography gutterBottom>
                    Id inwestycji: {agreement.investmentPublicId == 0 ? '-' : agreement.investmentPublicId}
                </Typography>
                <Typography gutterBottom>
                    Data zawarcia umowy: {formatToLocalDate(agreement.dateOfConclusion.toString())}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{ mt: "auto" }}
            >
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    component={Link} to={`/admin/agreements/${agreement.id}`}
                    size="small"
                    title='Więcej'
                >Więcej</Button>
                <Button
                    sx={{ mr: 1 }}
                    variant='outlined'
                    onClick={() => handleCardSelectAgreement!(agreement)}
                    size="small"
                    title='Edycja'
                    color='warning'
                >Edycja</Button>
                <Button
                    variant='outlined'
                    onClick={() => handleCardSelectAgreement!(agreement, true)}
                    size="small"
                    title='Pobierz umowę'
                    color='info'
                >Pobierz umowę</Button>
            </CardActions>
        </Card>
    )
}