import { LoadingButton } from "@mui/lab";
import { Paper, Typography, Box, Stepper, Step, StepLabel, Button } from "@mui/material";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import agent from "../../app/api/agent";
import { useEffect, useState } from "react";
import EditAccountDataForm from "./EditAccountDataForm";
import { checkoutAccountSchemaValidation } from "./checkoutAccountSchemaValidation";
import ContractPreview from "./ContractPreview";
import { UserContractPreview } from "../../app/models/userContractPreview";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const steps = ['Uzupełnienie danych rejestracyjnych', 'Podgląd umowy o przetwarzaniu danych', 'Podsumowanie'];

export default function CheckoutAccount() {
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userContractPreview, setUserContractPreview] = useState<null | UserContractPreview>(null);

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <EditAccountDataForm />;
            case 1:
                return <ContractPreview userContractPreview={userContractPreview} />
            case 2:
                return (
                    <>
                        <p>Po rejestracji skontaktujemy się z Tobą w celu umówienia spotkania do podpisania umowy oraz przygotowania kwestii ubezpieczeniowych na czas świadczenia wolontariatu. Spodziewaj się naszego telefonu w ciągu 1-2 dni roboczych. </p>
                        <p>Otrzymasz zaproszenie do naszego biura <strong>ul. Kościerzyńska 25 lok. 105, Wrocław</strong> w dogodnym terminie.</p>
                        <p>W razie pytań nasi pracownicy są dostępni w godzinach 08:00 - 14:00 pod nr telefonu: <strong>794-554-930</strong></p>
                    </>

                );
            default:
                throw new Error('Nieznany krok');
        }
    }

    const currentValidationSchema = checkoutAccountSchemaValidation[activeStep];

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(currentValidationSchema)
    });

    useEffect(() => {
        agent.Account.fetchAccountData()
            .then(response => {
                if (response) {
                    console.log(response);
                    methods.reset({ ...methods.getValues(), ...response });
                    setUserContractPreview(response as UserContractPreview);
                }
            })
    }, [methods])

    async function submitAccountUpdate(data: FieldValues) {
        setLoading(true);
        console.log(data);
        try {
            await agent.Account.updateAccountData(data)
                .then(response => {
                    setUserContractPreview(response as UserContractPreview);
                    toast.success('Dane zostały pomyślnie zaktualizowane')
                }).catch((error) => {
                    console.log(error);
                    toast.error(error)
                })
            //setPaymentMessage('Dziękujemy - otrzymaliśmy Twoją płatność')
            setActiveStep(activeStep + 1);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handleNext = async (data: FieldValues) => {
        if (activeStep === 0) {
            submitAccountUpdate(data);
        }
        if (activeStep === steps.length - 1) {
            //await submitOrder(data);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    function submitDisabled(): boolean {
        if (activeStep === steps.length) {
            return true;
            // !cardComplete.cardCvc 
            //     || !cardComplete.cardExpiry 
            //     || !cardComplete.cardNumber
            //     || !methods.formState.isValid
        } else {
            return !methods.formState.isValid
        }
    }
    return (
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    Finalizowanie rejestracji
                </Typography>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel sx={{ display: { xs: 'none', md: 'flex' }}} >{label}</StepLabel>
                            <StepLabel sx={{ display: { xs: 'flex', md: 'none' }}} ></StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <>
                    {activeStep === steps.length ? (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Zakończono proces finalizacji rejestracji.
                            </Typography>
                        </>
                    ) : (
                        <form onSubmit={methods.handleSubmit(handleNext)}>
                            {getStepContent(activeStep)}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                        Powrót
                                    </Button>
                                )}
                                {activeStep === steps.length - 1 ? (
                                    <Button
                                        variant="outlined"
                                        component={Link}
                                        to='/contact'
                                        type='submit'
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        Gotowe
                                    </Button>
                                ) : (
                                    <LoadingButton

                                        loading={loading}
                                        disabled={submitDisabled()}
                                        variant="outlined"
                                        type='submit'
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        Dalej
                                    </LoadingButton>
                                )}


                            </Box>
                        </form>
                    )}
                </>
            </Paper>
        </FormProvider>
    )
}