import { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useController, UseControllerProps } from 'react-hook-form';
import agent from '../api/agent';

interface Props extends UseControllerProps {
    label: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
}

interface ApiResponse {
    apiKey: string;
}

export default function AppRichTextEditor({ error, helperText, ...props }: Props) {
    const editorRef = useRef<any>(null);
    const [apiKey, setApiKey] = useState('');

    useEffect(() => {
        agent.TinyMCE.get()
            .then((result: ApiResponse) => {
                setApiKey(result.apiKey);
            })
            .catch(error => console.log('Failed to fetch API key:', error));
    }, []);

    const {
        field: { onChange, value: fieldValue },
        fieldState
    } = useController({ ...props, defaultValue: '' });

    return (
        <>
            {apiKey ? (
                <Editor
                    onInit={(_evt, editor) => editorRef.current = editor}
                    value={fieldValue}
                    onEditorChange={(content) => {
                        onChange(content);
                    }}
                    apiKey={apiKey}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'preview', "visualblocks",
                            "advlist", "autolink", "lists", "link",
                            "searchreplace", "visualblocks",
                            "table", "wordcount"
                        ],
                        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | fontsizeselect | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help | export',
                        export_image_proxy: 'proxy.php'
                    }}
                />) : (
                <div>Wczytywanie treści...</div>
            )}
            {error || fieldState.error ? (
                <p style={{ color: 'red' }}>
                    {helperText || fieldState.error?.message}
                </p>
            ) : null}
        </>
    );
}