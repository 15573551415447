import { TextField } from "@mui/material";
import { FieldValues, Path, PathValue, useController, UseControllerProps } from "react-hook-form";
import 'dayjs/locale/pl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, plPL } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    label: string;
    disabled?: boolean;
}

export default function AppDatePicker<T extends FieldValues>({ label, disabled, ...props }: Props<T>) {
    const { field, fieldState } = useController<T>({
        ...props,
        defaultValue: '' as PathValue<T, Path<T>> // Ensuring it's an empty string compatible with T
    });

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='pl'
            localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DatePicker
                {...field}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error?.message : 'dd.mm.rrrr'}
                        fullWidth
                        onBlur={field.onBlur}
                    />
                )}
                onChange={(date: Dayjs | null) => {
                    if (date && date.isValid()) {
                        field.onChange(date.toISOString());
                    } else {
                        field.onChange('');
                    }
                }}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}