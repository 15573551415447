import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { Investment, InvestmentParams } from "../../../app/models/investment";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configureStore";

interface ClientInvestmentsState {
    clientInvestmentsLoaded: boolean;
    clientInvestmentsFiltersLoaded: boolean;
    status: string;
    selectedClientInvestment: number;
    states: string[];
    clientInvestmentsParams: InvestmentParams;
    metaData: MetaData | null;
}

const clientInvestmentsAdapter = createEntityAdapter<Investment>({
    selectId: (clientInvestment) => clientInvestment.publicId,
});

function getAxiosParams(clientInvestmentsParams: InvestmentParams) {
    const params = new URLSearchParams();
    params.append('pageNumber', clientInvestmentsParams.pageNumber.toString());
    params.append('pageSize', clientInvestmentsParams.pageSize.toString());
    params.append('orderBy', clientInvestmentsParams.orderBy);
    if (clientInvestmentsParams.searchTerm) params.append('searchTerm', clientInvestmentsParams.searchTerm);
    if (clientInvestmentsParams.states.length > 0) params.append('states', clientInvestmentsParams.states.toString());
    // if (serviceParams.types.length > 0) params.append('types', serviceParams.types.toString());
    return params;
}

export const fetchClientInvestmentsAsync = createAsyncThunk<Investment[], void, {state: RootState} >(
    'clientInvestments/fetchClientInvestmentsAsync',
    async (_,thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().clientInvestments.clientInvestmentsParams);
        try {
            const response = await agent.Client.Investments.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
)

export const fetchClientInvestmentAsync = createAsyncThunk<Investment, number>(
    'clientInvestments/fetchClientInvestmentAsync',
    async (clientInvestmentId, thunkAPI) => {
        try {
            return await agent.Client.Investments.details(clientInvestmentId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error })
        }
    }
);

export const fetchClientInvestmentsFilters = createAsyncThunk(
    'clientInvestments/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return agent.Client.Investments.fetchClientInvestmentsFilters();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 6,
        orderBy: 'dateDesc',
        states: []
        // types: []
    }
}

export const clientInvestmentsSlice = createSlice({
    name: 'clientInvestments',
    initialState: clientInvestmentsAdapter.getInitialState<ClientInvestmentsState>({
        clientInvestmentsLoaded: false,
        clientInvestmentsFiltersLoaded: false,
        status: 'idle',
        selectedClientInvestment: 0,
        states: [],
        // types: [],
        clientInvestmentsParams: initParams(),
        metaData: null
    }),
    reducers: {
        setClientInvestmentsParams: (state, action) => {
            state.clientInvestmentsLoaded = false;
            state.clientInvestmentsParams = {...state.clientInvestmentsParams, ...action.payload, pageNumber: 1};
        },
        setClientInvestmentsPageNumber: (state, action) => {
            state.clientInvestmentsLoaded = false;
            state.clientInvestmentsParams = {...state.clientInvestmentsParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetClientInvestmentsParams: (state) => {
            state.clientInvestmentsParams = initParams();
        },
        setClientInvestment: (state, action) => {
            clientInvestmentsAdapter.upsertOne(state, action.payload);
            state.clientInvestmentsLoaded = false;
        },
        removeClientInvestment: (state, action) => {
            clientInvestmentsAdapter.removeOne(state, action.payload);
            state.clientInvestmentsLoaded = false;
        }
    },
    extraReducers: (builder => {
            builder.addCase(fetchClientInvestmentsAsync.pending, (state) => {
                state.status = 'pendingFetchClientInvestments';
            });
            builder.addCase(fetchClientInvestmentsAsync.fulfilled, (state, action) => {
                clientInvestmentsAdapter.setAll(state, action.payload);
                state.status = 'idle';
                state.clientInvestmentsLoaded = true;
            });
            builder.addCase(fetchClientInvestmentsAsync.rejected, (state) => {
                state.status = 'idle';
            });

            builder.addCase(fetchClientInvestmentAsync.pending, (state) => {
                state.status = 'pendingFetchClientInvestment';
            });
            builder.addCase(fetchClientInvestmentAsync.fulfilled, (state, action) => {
                clientInvestmentsAdapter.upsertOne(state, action.payload);
                state.status = 'idle';
            });
            builder.addCase(fetchClientInvestmentAsync.rejected, (state) => {
                state.status = 'idle';
            });
            builder.addCase(fetchClientInvestmentsFilters.pending, (state) => {
                state.status = 'pendingFetchClientInvestmentsFilters';
            });
            builder.addCase(fetchClientInvestmentsFilters.fulfilled, (state) => {
                // state.brands = action.payload.brands;
                // state.types = action.payload.types; 
                state.clientInvestmentsFiltersLoaded = true;
                state.status = 'idle';
            });
            builder.addCase(fetchClientInvestmentsFilters.rejected, (state) => {
                state.status = 'idle';
            });
        }
    )
})

export const clientInvestmentsSelectors = clientInvestmentsAdapter.getSelectors((state: RootState) => state.clientInvestments);

export const {setClientInvestmentsParams, resetClientInvestmentsParams, setMetaData, setClientInvestmentsPageNumber, setClientInvestment, removeClientInvestment} = clientInvestmentsSlice.actions;