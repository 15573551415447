import { Grid } from "@mui/material";
import { Subservice } from "../../../app/models/subservice";
import { useAppSelector } from "../../../app/store/configureStore";
import SubserviceCard from "./SubserviceCard";

import SubserviceCardSkeleton from "./SubserviceCardSkeleton";

interface Props {
    subservices: Subservice[];
    handleCardSelectSubservice?: (handledSubservice: Subservice) => void;
}

export default function SubsubservicesList({subservices, handleCardSelectSubservice} : Props) {
    const {subservicesLoaded} = useAppSelector(state => state.subservices);
    return (
        <Grid container spacing={4}>
            {subservices.map(subservice => (
                <Grid key={subservice.id} item xs={12} sm={12} md={6}>
                    {!subservicesLoaded ? (
                        <SubserviceCardSkeleton />
                    ) : (
                        <SubserviceCard subservice={subservice} handleCardSelectSubservice={handleCardSelectSubservice} />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}