import { useEffect } from "react";
import { fetchSubservicesAsync, fetchSubservicesFilters, subservicesSelectors } from "../../features/subservices/subservicesSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useSubservices() {
    const subservices = useAppSelector(subservicesSelectors.selectAll);
    const {subservicesLoaded, subservicesFiltersLoaded, metaData} = useAppSelector(state => state.subservices);
    const dispatch = useAppDispatch();

  useEffect(() => { 
    if (!subservicesLoaded) dispatch(fetchSubservicesAsync());
  }, [subservicesLoaded, dispatch])

  useEffect(() => {
    if (!subservicesFiltersLoaded) dispatch(fetchSubservicesFilters());
  }, [subservicesFiltersLoaded, dispatch])

  return {
    subservices,
    subservicesLoaded,
    subservicesFiltersLoaded,
    //brands,
    //types,
    metaData
  }
}