/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

const useAgreementContent = (watch: UseFormWatch<any>) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        const dateOfConclusion: Date = new Date(watch('dateOfConclusion'));
        const day_dateOfConclusion = ("0" + dateOfConclusion.getDate()).slice(-2);
        const month_dateOfConclusion = ("0" + (dateOfConclusion.getMonth() + 1)).slice(-2);
        const year_dateOfConclusion = dateOfConclusion.getFullYear();
        const formattedDate_dateOfConclusion = `${day_dateOfConclusion}.${month_dateOfConclusion}.${year_dateOfConclusion}`;

        const companyName = watch('companyName');
        const companyCity = watch('companyCity');
        const companyZip = watch('companyZip');
        const companyNIP = watch('companyNIP');
        const companyRepresentative = watch('companyRepresentative');
        const serviceConsistingOf = watch('serviceConsistingOf');

        const dateStart: Date = new Date(watch('dateStart'));
        const day_dateStart = ("0" + dateStart.getDate()).slice(-2);
        const month_dateStart = ("0" + (dateStart.getMonth() + 1)).slice(-2);
        const year_dateStart = dateStart.getFullYear();
        const formattedDate_dateStart = `${day_dateStart}.${month_dateStart}.${year_dateStart}`;

        const dateEnd: Date = new Date(watch('dateEnd'));
        const day_dateEnd = ("0" + dateEnd.getDate()).slice(-2);
        const month_dateEnd = ("0" + (dateEnd.getMonth() + 1)).slice(-2);
        const year_dateEnd = dateEnd.getFullYear();
        const formattedDate_dateEnd = `${day_dateEnd}.${month_dateEnd}.${year_dateEnd}`;

        const changeTheSizeOfServices = watch('changeTheSizeOfServices');
        const numberOfDaysBeforeIntroduction = watch('numberOfDaysBeforeIntroduction');
        const servicesDoNotInclude = watch('servicesDoNotInclude');
        const principalsResponsibilities = watch('principalsResponsibilities');
        const contractorsResponsibilities = watch('contractorsResponsibilities');
        const prepayment = watch('prepayment');
        const payment = watch('payment');
        const transferOf = watch('transferOf');
        const principalWillCallContractorIn = watch('principalWillCallContractorIn');
        const contractorWillCallPrincipalIn = watch('contractorWillCallPrincipalIn');

        const attachments = watch('attachments') || '';  // Ensure attachments is a string
        const attachmentsArray = attachments.split(';');
        const listItems = attachmentsArray.map((attachment: string, index: number) => `<li key=${index}>${attachment}</li>`).join('');

        setContent(`<center><b>UMOWA O ŚWIADCZENIE USŁUG</b></center><br />
                      zawarta w dniu ${formattedDate_dateOfConclusion === 'aN.aN.NaN' ? '...' : formattedDate_dateOfConclusion + 'r.'} w Kielcach, pomiędzy<br />
                      <p style="padding-left: 20px;">- ${companyName} Sp. o.o. z siedzibą w miejscowości ${companyCity}, ${companyZip}, NIP ${companyNIP} którą<br />
                      reprezentuje:<br />
                      ${companyRepresentative} - Prezes Zarządu, zwanym w treści umowy <b>Zleceniodawcą</b>,</p>
                      a<br />
                      <p style="padding-left: 40px;"><b>Wechsler Polska Sp. z o.o. z siedzibą w 25-646 Kielce, ul. 1 Maja 191</b>, wpisaną do
                      Krajowego Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla miasta Kielce
                      pod numerem KRS 0000421362, NIP 959 194 84 15 którą reprezentuje:
                      <b>Mariusz Marciniak - Prezes Zarządu/Dyrektor Generalny</b>, zwanym w treści umowy
                      <b>Zleceniobiorcą</b>,</p>
                      o treści poniższej:<br /><br>
                      <center><b>§ 1</b></center><br>
                      <ol>
                        <li>Zleceniodawca zleca, a Zleceniobiorca przyjmuje do wykonania usługę w zakresie/ polegającą na ${serviceConsistingOf},
                        w okresie od ${formattedDate_dateStart} do ${formattedDate_dateEnd}, na warunkach określonych w niniejszej umowie oraz
                        zgodnie z obowiązującym prawem.</li>
                        <li>Zamawiający może zmienić wielkość usług. Wielkość ta nie może jednak wzrosnąć lub zmaleć o ${changeTheSizeOfServices}</li>
                        <li>Zmiany, o których mowa w ust. 2, mogą nastąpić po pisemnym zawiadomieniu Wykonawcy, przesłanym na 
                        ${numberOfDaysBeforeIntroduction} dni przed terminem ich wprowadzenia.</li>
                        <li>Zmiany wielkości usług wymagają zgody obydwu Stron.</li>
                        <li>Usługi nie obejmują ${servicesDoNotInclude}</li>
                      </ol><br>
                      <center><b>§ 2</b></center>
                      <ol>
                        <li>Do obowiązków Zleceniodawcy należy:
                          <ol style="list-style-type:lower-alpha">
                            <li>terminowe przekazywanie Zleceniobiorcy wynagrodzenia według zasad ustalonych w § 3;</li>
                            <li>udostępnienie Zleceniobiorcy niezbędnych informacji/dokumentacji/materiałów w celu realizacji usług
                            wynikających z niniejszej umowy;</li>
                            ${principalsResponsibilities === '' ? '' : `<li>${principalsResponsibilities};</li>`}
                          </ol>
                        </li>
                        <li>Do obowiązków Zleceniobiorcy należy, w szczególności:
                          <ol style="list-style-type:lower-alpha">
                            <li>terminowe przekazywanie Zleceniobiorcy wynagrodzenia według zasad ustalonych w § 3;</li>
                            <li>udostępnienie Zleceniobiorcy niezbędnych informacji/dokumentacji/materiałów w celu realizacji usług
                            wynikających z niniejszej umowy;</li>
                            ${contractorsResponsibilities === '' ? '' : `<li>${contractorsResponsibilities};</li><br>`}
                          </ol>
                        </li>
                      </ol>
                      <center><b>§ 3</b></center><br>
                      <ol>
                        <li>Wynagrodzenie Zleceniobiorcy, zwane dalej „Wynagrodzeniem”, z tytułu wykonania czynności opisanych
                        w §1 Umowy wynosi:</li>
                        <li>Kwota, o której mowa w <b>§ 3 ust. 1</b> wypłacana będzie w następujących ratach:
                          <ol style="list-style-type:lower-alpha">
                            <li>${prepayment}<b> zł netto</b> (słownie złotych: zł 00/100) plus należny podatek VAT - przedpłata bezzwrotna
                            płatna po podpisaniu umowy w ciągu 7 dni od daty wystawienia faktury;</li>
                            <li>${payment}<b> zł netto</b> (słownie złotych: zł 00/100) plus należny podatek VAT - płatne w ciągu 7 dni od
                            daty wystawienia faktury, po przekazaniu ${transferOf};</li>
                          </ol>
                        </li>
                        <li>W przypadku nieterminowych płatności lub braku współdziałania ze strony Zleceniodawcy mogącym
                        skutkować opóźnieniem w wykonaniu usług objętych umową, Zleceniobiorcy przysługuje prawo
                        wstrzymania wykonanych prac do czasu otrzymania pełnej zapłaty zgodnie z umową lub - odpowiednio -
                        przystąpienia przez Zleceniodawcę do działań umożliwiających dalszą realizację umowy (w tym
                        przypadku braku terminowej zapłaty kwoty wskazanej w <b>ust. 2a,b)</b> - odmowy wydania przedmiotu
                        umowy do czasu zapłaty i uzgodnionego przez strony zabezpieczenia zapłaty pozostałej kwoty
                        wynagrodzenia wskazanego w <b>ust. 2b)</b>). W takim przypadku termin realizacji Umowy przez
                        Zleceniobiorcę ulega odpowiedniemu przesunięciu o czas wstrzymania bez konieczności sporządzania
                        dodatkowych aneksów. W takim przypadku przekroczenie terminu wykonania umowy przez
                        Zleceniobiorcę nie stanowi nienależytego wykonania umowy i podstawy do dochodzenia odszkodowań.</li>
                        <li>Walutą rozliczania Umowy jest polski złoty.</li>
                        <li>Zapłata należności, o których mowa w § ustępach powyżej nastąpi na podstawie faktur VAT wystawionych
                        przez Zleceniobiorcę, na konto bankowe Zleceniobiorcy, nr rachunku:<br>
                        <u>09 84930004 0020 0335 3975 0001</u></li>
                        <li>Zleceniodawca wyraża zgodę, aby faktury wysyłane były na jego adres za pośrednictwem Poczty Polskiej na
                        adres Zleceniodawcy wskazany w umowie. Zleceniodawca oświadcza, że posiada potencjał techniczny,
                        organizacyjny i finansowy do wykonania niniejszej umowy, w szczególności oświadcza, że jest wypłacalny i
                        brak jest jakichkolwiek przeszkód do terminowej zapłaty wynagrodzenia wskazanego w umowie.</li>
                      </ol>
                      <center><b>§ 4</b></center><br>
                      <ol>
                        <li>Jeżeli Zleceniobiorca nie wykonuje lub nienależycie realizuje umowę, naruszając obowiązki określone w
                        umowie, Zleceniodawca wezwie Zleceniobiorcę, aby w ciągu ${principalWillCallContractorIn} dni od otrzymania pisemnego wezwania
                        wypełnił nałożone na niego obowiązki. Po bezskutecznym upływie wyznaczonego terminu Zleceniodawca
                        może odstąpić od umowy.</li>
                        <li>Jeżeli Zleceniodawca nie wykonuje lub nienależycie wykonuje umowę, naruszając swoje obowiązki określone
                        w niniejszej umowie, Zleceniobiorca wezwie Zleceniodawcę, aby w ciągu ${contractorWillCallPrincipalIn} dni od otrzymania
                        pisemnego wezwania dokonał zapłaty należnego mu wynagrodzenia. Po bezskutecznym upływie
                        wyznaczonego terminu Wykonawca może odstąpić od umowy.</li>
                        <li>Oświadczenie o odstąpieniu od umowy powinno być złożone w formie pisemnej pod rygorem nieważności.</li>
                      </ol>
                      <center><b>§ 5</b></center><br>
                      Strony ustalają, że wszelkie dane osobowe przetwarzane będą na podstawie rozporządzenia Parlamentu
                      Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
                      przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
                      95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1), ustawy z 10.05.2018 r. o ochronie
                      danych osobowych (Dz.U. z 2019 r. poz. 1781).
                      <center><b>§ 6</b></center><br>
                      <ol>
                        <li>Zleceniobiorca zobowiązuje się̨ do zachowania w tajemnicy wszelkich informacji i danych otrzymanych i
                        uzyskanych od Zleceniodawcy w związku z wykonaniem zobowiązań wynikających z niniejszej umowy.
                        Zobowiązanie to dotyczy nie tylko okresu realizacji umowy, ale także okresu późniejszego.</li>
                        <li>Strony zobowiązują̨ się̨ do przestrzegania przy wykonywaniu umowy wszystkich postanowień́
                        zawartych w obowiązujących przepisach prawnych związanych z ochroną tajemnicy skarbowej,
                        informacji niejawnych oraz danych osobowych.</li>
                        <li>Strony zobowiązują się̨ do zachowania w tajemnicy wszelkich informacji technicznych, technologicznych,
                        prawnych i organizacyjnych dotyczących zasobów sprzętowych i programowych systemu
                        teleinformatycznego ich dotyczących a uzyskanych w trakcie wykonywania umowy niezależnie od formy
                        przekazania tych informacji i ich źródła.</li>
                        <li>Obowiązek określony w ust. 1 nie dotyczy informacji powszechnie znanych oraz udostępniania
                        informacji na podstawie bezwzględnie obowiązujących przepisów prawa, a w szczególności na żądanie
                        sądu, prokuratury, organów podatkowych lub organów kontrolnych.</li>
                        <li>Strony odpowiadają względem siebie za szkodę̨ wyrządzoną sobie na wzajem przez ujawnienie,
                        przekazanie, wykorzystanie, zbycie lub oferowanie do zbycia informacji otrzymanych przez jedną Stronę
                        drugiej Stronie wbrew postanowieniom umowy. Zobowiązanie to wiąże Strony również̇ po wykonaniu
                        umowy lub jej rozwiązaniu, bez względu na przyczynę̨ i podlega wygaśnięciu według zasad określonych
                        w przepisach dotyczących zabezpieczania informacji niejawnych i innych tajemnic prawnie chronionych.</li>
                        <li>Strony zobowiązują się do odnotowywania i zgłaszania nawzajem wszelkich zaobserwowanych lub
                        podejrzewanych słabości związanych z bezpieczeństwem informacji w systemach lub usługach.</li>
                        <li><b>Ochrona danych osobowych:</b></li>
                      </ol>
                      Zleceniobiorca oświadcza, że w przypadku otrzymania od Zleceniodawcy, w związku z wykonaniem niniejszej
                      umowy, danych osobowych:
                      <ol>
                        <li>przetwarzanie tych danych następować będzie na zasadach powierzenia w trybie art. 37 ustawy z dnia 29
                        sierpnia 1997 r. o ochronie danych osobowych (Dz.U.02.101.926 j.t. ze zm.), administratorem tych danych
                        osobowych nadal pozostaje Zleceniodawca, który zobowiązany jest do wypełniania obowiązków stąd
                        wynikających, w szczególności w zakresie obowiązków dotyczących uzyskania zgody na przetwarzanie
                        danych osobowych osób zainteresowanych oraz w trakcie przetwarzania (obowiązki informacyjne, takie
                        jak prawo do wglądu, prawo do poprawiania danych, prawo do żądania ich usunięcia, prawo do
                        informacji o sposobie i celu przetwarzania, itd.);</li>
                        <li>przetwarzanie tych danych następować będzie jedynie w celu wykonania obowiązków wynikających z
                        niniejszej umowy, zgodnie z warunkami i wymogami ustawy z dnia 29 sierpnia 1997 r. o ochronie danych
                        osobowych (Dz.U.02.101.926 j.t. ze zm.) oraz aktów wykonawczych wydanych na jej podstawie;</li>
                        <li>przetwarzane dane osobowe zachowa w poufności i nie przekaże im osobom i podmiotom do tego nie
                        uprawnionym.</li>
                      </ol>
                      <center><b>§ 7</b></center><br>
                      <ol>
                        <li>Zleceniobiorca ma prawo zlecić niektóre prace związane z wykonaniem niniejszej umowy podwykonawcy.</li>
                        <li>Zleceniobiorca zobowiązuje się w terminie 14 dniu od dnia zawarcia umowy z podwykonawcą
                        poinformować Zleceniodawcę o realizacji określonej części prac związanych z przedmiotem niniejszej
                        umowy przez podwykonawcę ze wskazaniem danych identyfikujących podwykonawcę.</li>
                      </ol>
                      <center><b>§ 8</b></center><br>
                      <ol>
                        <li>Żadna ze Stron nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie Umowy z
                        przyczyn spowodowanych działaniem siły wyższej. Za siłę wyższą należy rozumieć wystąpienie zdarzeń,
                        na zaistnienie których żadna ze Stron nie miała wpływu, ani nie mogła ich przewidzieć lub im zapobiec, a
                        w szczególności zdarzeń takich jak: wojna, wojna domowa, zamieszki, rewolucje i sabotaż, katastrofy
                        naturalne takie jak silne burze, huragany, trzęsienia ziemi, powodzie, zniszczenie przez piorun,
                        wyładowania atmosferyczne, wybuchy, pożar, zniszczenie maszyn, fabryk lub wszelkiego typu instalacji,
                        bojkoty, strajki, epidemie i pandemie, działania władzy lub działania innych sił natury.</li>
                        <li>Jeżeli zdarzenie stanowiące siłę wyższą w rozumieniu niniejszego paragrafu spowoduje niewykonanie lub
                        nienależyte wykonanie zobowiązań z Umowy przez jedną ze Stron, Strona ta niezwłocznie zawiadomi
                        drugą Stronę o powstaniu i zakończeniu tego zdarzenia.</li>
                      </ol>
                      <center><b>§ 9</b></center><br>
                      Strony oświadczają, że w przypadku, gdy którekolwiek z postanowień Umowy, z mocy prawa bądź
                      ostatecznego lub prawomocnego orzeczenia jakiegokolwiek organu administracyjnego lub sądu
                      powszechnego, uznane zostanie nieważnym bądź bezskutecznym pozostałe postanowienia Umowy
                      pozostawiają, zachowując je w mocy.
                      <center><b>§ 10</b></center><br>
                      <ol>
                        <li>Integralną część Umowy stanowią następujące dokumenty:
                          ${attachments === '' ? 'brak dokumentów'
                : `<ol style="list-style-type:lower-alpha">
                              ${listItems}
                              </ol>`
            }
                        </li>
                        <li>W przypadku rozbieżności przepisów poszczególnych dokumentów wymienionych w ust. 1 niniejszego
                        paragrafu z niniejszą umową, pierwszeństwo mają przepisy umowy.</li>
                      </ol>
                      <center><b>§ 11</b></center><br>
                      <ol>
                        <li>W sprawach nieuregulowanych w umowie zastosowanie będą miały powszechnie obowiązujące przepisy
                        polskiego prawa, w szczególności Kodeksu cywilnego.</li>
                        <li>Strony zobowiązane są do niezwłocznego informowania się wzajemnie o zmianie adresu do korespondencji. W
                        przypadku zaniedbania powyższego obowiązku przyjmuje się, że korespondencja kierowana na adres
                        wskazany w komparycji niniejszej Umowy została doręczona z upływem 14 dni od pierwszego jej awizowania.</li>
                        <li>Spory związane z wykonywaniem umowy rozstrzygane będą przez sąd powszechny właściwy dla siedziby
                        Zleceniobiorcy.</li>
                        <li>Wszelkie zmiany umowy wymagają zachowania formy pisemnej pod rygorem nieważności.</li>
                        <li>Umowa została sporządzona w dwóch jednobrzmiących egzemplarzach po jednym dla każdej ze Stron.</li>
                      </ol>
                      `)
    }, [watch('dateOfConclusion'),
    watch('companyName'),
    watch('companyCity'),
    watch('companyZip'),
    watch('companyNIP'),
    watch('companyRepresentative'),
    watch('serviceConsistingOf'),
    watch('dateStart'),
    watch('dateEnd'),
    watch('changeTheSizeOfServices'),
    watch('numberOfDaysBeforeIntroduction'),
    watch('servicesDoNotInclude'),
    watch('principalsResponsibilities'),
    watch('contractorsResponsibilities'),
    watch('prepayment'),
    watch('payment'),
    watch('transferOf'),
    watch('principalWillCallContractorIn'),
    watch('contractorWillCallPrincipalIn'),
    watch('attachments')
    ])

    return { content, setContent };
};

export default useAgreementContent;